import { CircularProgress, TableCell, TableRow } from "@material-ui/core";
import ResourceTable from "components/ResourceTable";
import Typography from "components/Typography";
import React, { useState, useEffect } from "react";
import Paper from "components/StyledPaper";
import { MenuItem } from "@material-ui/core";
import { Select, TextField } from "components/Fields";
import Icon from "components/Icon";
import ActionButton from "components/Buttons/ActionButton";
import phoneExtensions from "helpers/phone-extensions";
import { useOrganization } from "features/organization";
import "./index.scss";

const Contacts = props => {

    const { contacts, createContact, removeContact, readOnly } = props;
    const [role, setRole] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneExtension, setPhoneExtension] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const { isOrganizationLoading, contactRoles } = useOrganization();

    const closeFormHandler = () => {
        setRole("");
        setName("");
        setEmail("");
        setPhoneExtension("");
        setPhoneNumber("");
    }

    const createExpenseHandler = () => {
        setRole("");
        setName("");
        setEmail("");
        setPhoneExtension("");
        setPhoneNumber("");
        createContact(role, name, email, phoneExtension, phoneNumber);
    }

    const contactForm = (
        <TableRow>
            <TableCell>
                {!isOrganizationLoading
                    ? <Select
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value)
                        }}>
                            {contactRoles.map((contactRole, ix) => (
                                <MenuItem key={ix} value={contactRole.id}>
                                    {contactRole.text}
                                </MenuItem>
                            ))}
                    </Select>
                    : <CircularProgress size={30} />
                }
            </TableCell>
            <TableCell>
                <TextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                />
            </TableCell>
            <TableCell>
                <div className="text-field-group">
                    <Select
                        value={phoneExtension}
                        onChange={(e) => {
                            setPhoneExtension(e.target.value)
                        }}>
                        {phoneExtensions.map((extension, ix) => (
                            <MenuItem key={ix} value={extension.code}>
                                {`${extension.country} (${extension.code})`}
                            </MenuItem>

                        ))}
                    </Select>
                    <TextField
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                    />
                </div>
            </TableCell>
            <TableCell>
                <ActionButton onClick={createExpenseHandler}>
                    <Icon type="plus" />
                </ActionButton>
            </TableCell>
        </TableRow>
    );

    return (
        <Paper className="general-information__contacts">
            <ResourceTable
                title={<Typography variant="subtitle">Kontakter</Typography>}
                readOnly={readOnly}
                headers={["Role", "Navn", "E-mail", "Telefon", ""]}
                sizes={["20%", "20%", "20%", "30%", "10%"]}
                form={contactForm}
                onFormClose={closeFormHandler}>
                {contacts.map((contact, ix) => (
                    <TableRow key={ix}>
                        <TableCell>{contact.role?.text}</TableCell>
                        <TableCell>{contact.name}</TableCell>
                        <TableCell>{contact.email}</TableCell>
                        <TableCell>{contact.phone?.extension} {contact.phone?.number}</TableCell>
                        <TableCell className="actions-cell">
                            <Icon onClick={() => removeContact(contact.id)} type="bin" />
                        </TableCell>
                    </TableRow>
                ))}
            </ResourceTable>
        </Paper>
    );
};

export default Contacts;