import { useState, useCallback } from "react";
import OpenhousesService from 'services/api/openhouses';

/*
    useOpenhouse hook handles open houses loading. 
*/
const useOpenhouses = () => {

    const [isOpenHousesLoading, setOpenHousesLoading] = useState(true);
    const [openHousesData, setOpenHousesData] = useState([]);

    const loadAllTenancyOpenhouses = useCallback(async (tenancyId) => {
        const result = await OpenhousesService.getAllTenancyOpenhouses(tenancyId);
        if (result !== null) {
            setOpenHousesData(result.data);
        }

        setOpenHousesLoading(false);
    }, []);

	return {
		isOpenHousesLoading,
		openHousesData,
		loadAllTenancyOpenhouses,
    }
};

export default useOpenhouses;
