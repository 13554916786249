import React from 'react'
import '../index.scss'
import Typography from 'components/Typography'
import Toggle from 'components/Toggle2'
import TextInput from 'components/TextInput'

const AlternativeMeetingLocation = ({ state, dispatch }) => {
  return (
    <div className='section virtual'>
      <div className='text-container'>
        <Typography className='pb15' variant='label'>
          Alternativt mødested
        </Typography>
        <p className='subtext'>Mødestedet er ikke lejemålets adresse.</p>
      </div>
      <Toggle
        className={'toggle'}
        checked={state.hasAlternativeMeetingLocation}
        onChange={value => dispatch({ type: 'SET_HAS_ALTERNATIVE_MEETING_LOCATION', payload: value })}
      />
      {state.hasAlternativeMeetingLocation && (
        <TextInput
          className={'meeting-input'}
          value={state.alternativeMeetingLocation}
          onChange={value => dispatch({ type: 'SET_ALTERNATIVE_MEETING_LOCATION', payload: value })}
		  placeholder={'Beskriv mødestedet'}
        />
      )}
    </div>
  )
}

export default AlternativeMeetingLocation
