import React from 'react';
import "./index.scss";

const PhoneNumberInput = ({ value, onChange, className}) => {
  
  return <input
  	type='number'
	className={`styled_phone_number_input ${className}`}
	value={value}
	onChange={(e) => onChange(e.target.value)}
  />
}

export default PhoneNumberInput;