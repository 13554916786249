import React from "react";
import { withStyles } from '@material-ui/core/styles';
import MaterialTab from '@material-ui/core/Tab';

const Tab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '16px',
        marginRight: theme.spacing(4),
        fontFamily: 'Montserrat, -apple-system sans-serif !important',
        color: '#A8A8A8',
        '&:hover': {
            color: '#566FFF',
            opacity: 1,
        },
        '&$selected': {
            color: '#566FFF',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#566FFF',
        },
        '&:last-of-type': {
            marginRight: 0
        }
    },
    selected: {},
}))((props) => <MaterialTab disableRipple {...props} />);

export default Tab;