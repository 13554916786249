import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";

const ChipFilter = props => {

	const { label, onDelete } = props;

   return (
		<div className="chip-filter__wrapper">
			<div className="chip-filter__label">{label}</div>
			<CloseIcon className="chip-filter__close-icon" onClick={onDelete} />
		</div>
   )
};

ChipFilter.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
};

ChipFilter.defaultProps = {
	label: "Not defined",
  	number: 0,
  };
  
export default ChipFilter;