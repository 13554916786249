import { styled } from "@material-ui/core/styles";

const ActionButton = styled('button')({
    '&': {
        borderRadius: "50px",
        backgroundColor: "#E2E6FB",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "48px",
        minWidth: "72px",
        border: 0,
        cursor: "pointer",
        color: "#2C3589",
        padding: "0px 22px",
        fontWeight: 500,

        '& svg': {
            width: "16px",
            height: "16px",

            '& line': {
                stroke: "#2C3589",
                strokeWidth: "1px"
            }
        },
        '&:disabled': {
            cursor: "not-allowed"
        }
    }
});

export default ActionButton;