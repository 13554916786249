import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Icon from "components/Icon";
import { ReactComponent as CasesDraftIcon } from "assets/icons/jorato-cases-draft-light-blue.svg";
import { ReactComponent as CasesActiveIcon } from "assets/icons/jorato-cases-active-light-blue.svg";
import { ReactComponent as CasesReservedIcon } from "assets/icons/jorato-cases-reserved-light-blue.svg";
import { ReactComponent as CasesCompletedIcon } from "assets/icons/jorato-cases-completed-light-blue.svg";
import { ReactComponent as CasesDeletedIcon } from "assets/icons/jorato-cases-deleted-light-blue.svg";
import { ReactComponent as CommentIcon } from "assets/icons/jorato-comment.svg";
import { formatDate } from 'helpers/date';

const LogEntry = props => {

    const { data, removeComment } = props;
    // const userInitials = data.author ? data.author.name.split(" ").map((n) => n[0]).join("") : null;

    const getIcon = (icon) => {
        switch (icon) {
            case "Comment":
                return <CommentIcon></CommentIcon>
            case "Draft":
                return <CasesDraftIcon></CasesDraftIcon>
            case "Active":
                return <CasesActiveIcon></CasesActiveIcon>
            case "Reserved":
                return <CasesReservedIcon></CasesReservedIcon>
            case "Completed":
                return <CasesCompletedIcon></CasesCompletedIcon>
            case "Deleted":
                return <CasesDeletedIcon></CasesDeletedIcon>
            default:
                return ""
        }
    }

    return (
        <div className={`logEntry___container ${props.index % 2 === 1 ? "reversed" : ""}`}>
            <div className="logEntry___avatar">
                <Avatar alt={data.author ? data.author.name : null}>{getIcon(data.icon)}</Avatar>
            </div>
            <div className="logEntry___content">
                <div className="logEntry__content_top">
                    <p className="logEntry___content__title">{data.title}</p>
                    {data.icon === "Comment" && (
                        <div className="logEntry___actions">
                            <Icon className="remove-comment" onClick={() => removeComment(data.id)} type="bin" />
                        </div>
                    )}
                </div>
                <p className="logEntry___content__data">{formatDate(data.createdAt, "dd/MM/yyyy")} - {formatDate(data.createdAt, "dd/MM/yyyy")}</p>
                {data.content && (
                    <p className="logEntry___content__note">{data.content}</p>
                )}
            </div>
            
        </div>
    );
}

LogEntry.propTypes = {
    data: PropTypes.object.isRequired
};

export default LogEntry;