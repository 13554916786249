import DocumentsManger from "components/DocumentsManger";
import SortableImages from "components/SortableImages";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import React, { useState, useEffect } from "react";
import ProspectusManager from "./components/ProspectusManager/ProspectusManager";
import "./index.scss";
import { useToasts } from "features/toasts";
import { TextField } from "components/Fields";

const Files = props => {

	const { images, uploadImages, floorPlans, deleteImage, changeImageOrder, uploadFloorPlans, deleteFloorPlan, changeFloorPlanOrder, documents,
		uploadDocument, deleteDocument, prospectusEnabled, prospectus, deleteProspectus, uploadProspectus, readOnly, virtualTour, updateVirtualTour } = props;

	const [isImageUploading, setImageUploading] = useState(false);
	const [isFloorPlanUploading, setFloorPlanUploading] = useState(false);
	const [virtualTourTemp, setVirtualTourTemp] = useState(virtualTour);
	const addToast = useToasts();

	useEffect(() => {
		setImageUploading(false);
	}, [images]);

	useEffect(() => {
		setFloorPlanUploading(false);
	}, [floorPlans]);

	const handleFloorPlansUpload = (images, order) => {
		const filteredImages = images.filter(x => x.type === "image/png" || x.type === "image/jpeg" || x.type === "image/jpg");
		if (images.length !== filteredImages.length) {
			addToast("Couldn't upload some of the files, because of wrong file type.");
		}
		setFloorPlanUploading(true);
		uploadFloorPlans(filteredImages);

	};

	const handleImagesUpload = (images, order) => {
		const filteredImages = images.filter(x => x.type === "image/png" || x.type === "image/jpeg" || x.type === "image/jpg");
		if (images.length !== filteredImages.length) {
			addToast("Couldn't upload some of the files, because of wrong file type.");
		}
		setImageUploading(true);
		uploadImages(filteredImages);
	};

	return (
		<Paper className="general-information__files">
			<Typography variant="subtitle">Medier</Typography>
			<div>
				{images && (
					<>
						<h3 className="file-list-name">Billeder</h3>
						<SortableImages
							loaderId={"addImageCta"}
							editable={!readOnly}
							readOnly={readOnly}
							withAdd={(images, order) => handleImagesUpload(images, order)}
							variant={"image"}
							onItemReorder={(imageId, order) => {
								changeImageOrder(imageId, order);
							}}
							onItemDelete={(id) => {
								deleteImage(id);
							}}
							items={images}
							isUploadingImage={isImageUploading}
						/>
					</>
				)}
				{floorPlans && (
					<>
						<h3 className="file-list-name">Plantegning</h3>
						<SortableImages
							loaderId={"addFloorPlanCta"}
							editable={!readOnly}
							readOnly={readOnly}
							withAdd={(images, order) => handleFloorPlansUpload(images, order)}
							variant={"image"}
							onItemReorder={(imageId, order) => {
								changeFloorPlanOrder(imageId, order);
							}}
							onItemDelete={(id) => {
								deleteFloorPlan(id);
							}}
							items={floorPlans}
							isUploadingImage={isFloorPlanUploading}
						/>
					</>
				)}
				{(documents && deleteDocument && uploadDocument) && (
					<>
						<h3 className="file-list-name">Dokumenter</h3>
						<DocumentsManger
							onDelete={(documentId) => deleteDocument(documentId)}
							readOnly={readOnly}
							documents={documents}
							onConfirm={(document, type) => uploadDocument(document, type)} />
					</>
				)}
				{prospectusEnabled && (
					<>
						<h3 className="file-list-name">Prospekt</h3>
						<ProspectusManager
							data={prospectus}
							onDelete={deleteProspectus}
							onUpload={(file) => uploadProspectus(file)} />
					</>
				)}
				{virtualTour != null && (
					<>
						<h3 className="file-list-name">Virtual Tour</h3>
						<TextField
							value={virtualTourTemp}
							disabled={readOnly}
							className="virtual-tour-field"
							onChange={(event) => setVirtualTourTemp(event.target.value)}
							onExit={() => setVirtualTourTemp(virtualTour)}
							onFinish={() => {
								virtualTour !== virtualTourTemp && updateVirtualTour(virtualTourTemp)
							}}
						/>
					</>
				)}
			</div>
		</Paper>
	);
};

export default Files;