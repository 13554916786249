import React from 'react';
import { Select } from "components/Fields";
import { MenuItem } from "@material-ui/core";

const IntervalPicker = ({ value, onChange }) => {
	return (
		<Select
			className="interval-picker"
			value={value}
			onChange={(e) => onChange(e.target.value)}
		>
			<MenuItem value="monthly">
				Måneder
			</MenuItem>
			<MenuItem value="weekly">
				Uger
			</MenuItem>
		</Select>
	);
};

export default IntervalPicker;