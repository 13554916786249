import React from "react";
import { useHistory } from "react-router-dom";
import { ActionButton } from "components/Buttons";

const CreateShowcasingAction = ({ caseId }) => {

	const history = useHistory();

	const openCreateShowcasingPage = () => {
		history.push(`/showcasings/create?caseId=${caseId}`);
	}

	return (
		<ActionButton onClick={openCreateShowcasingPage}>Opret fremvisning</ActionButton>
	);
}

export default CreateShowcasingAction;