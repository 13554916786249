import React, { useEffect, useState } from "react";
import arrayMove from "array-move";
import "./index.scss";
import List from "./components/List";

const SortableImages = (props) => {

	const [sortedData, setSortedData] = useState([]);

	useEffect(() => {
        const sorted = [...props.items].sort((a, b) => a.order - b.order);
        setSortedData(sorted);
    }, [props.items]);

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex)
			return;

		const images = arrayMove(sortedData, oldIndex, newIndex);
		const target = sortedData[oldIndex];
		const leftImage = images[newIndex - 1] || null;
		const rightImage = images[newIndex + 1] || null;

		let newOrder = 0;
		if (leftImage && rightImage) {
			newOrder = (leftImage.order + rightImage.order) / 2;
		} else if (leftImage) {
			newOrder = leftImage.order + 1;
		} else if (rightImage) {
			newOrder = rightImage.order - 1;
		}

		props.onItemReorder(target.id, newOrder);
	};

	return (
		<div className={`sortableGrid ${props.disabled ? "disabled" : ""}`}>
			<List
				style={props.style}
				selected={props.selected}
				isUploadingImage={props.isUploadingImage}
				withAdd={props.withAdd}
				loaderId={props.loaderId}
				onItemDelete={props.onItemDelete}
				onItemClick={props.onItemClick}
				editable={props.editable}
				readOnly={props.readOnly}
				variant={props.variant}
				data={sortedData}
				useDragHandle
				axis={"xy"}
				onSortEnd={onSortEnd}
			/>
		</div>
	);
}

export default SortableImages;
