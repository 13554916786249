import React from "react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { PublicClientApplication } from "@azure/msal-browser";
import Icon from "components/Icon/Icon";

const MSAccountIntegration = props => {

	const { accountConnected, connectAccount, disconnectAccount, setMsConsentError } = props;

	const msalConfig = {
		auth: {
		  clientId: process.env.REACT_APP_MS_CLIENT_ID,
		  authority: process.env.REACT_APP_MS_AUTHORITY,
		  redirectUri: process.env.REACT_APP_MS_REDIRECT_URI,
		}
	};

	const scopes = ["openid", "profile", "Calendars.Read", "Calendars.ReadWrite"];

	const handleResponse = async (resp) => {
		const result = await connectAccount(resp.idToken);
		if (!result) {
			setMsConsentError(true);
		}
	}

	const handleDisconnectAccount = async () => {
		await disconnectAccount();
	}

	const connectMicrosoftAccount = () => {
		const loginRequest = {
			scopes: scopes
		};		   
		  
		const myMSALObj = new PublicClientApplication(msalConfig);

		myMSALObj.loginPopup(loginRequest).then(handleResponse).catch(error => {
			console.error(error);
		});	
	}

	if (accountConnected) {
		return (
			<div className="integration-status-container">
				<PrimaryButton className="status-button">Microsoft konto forbundet</PrimaryButton>
				<PrimaryButton className="disconnect-button" onClick={handleDisconnectAccount}><Icon type="close" /></PrimaryButton>
			</div>
		);
	}

	return (
		<div className="integration-status-container">
			<PrimaryButton className="connect-button" onClick={connectMicrosoftAccount}>Forbind Microsoft konto</PrimaryButton>
		</div>
	);
};

export default MSAccountIntegration;