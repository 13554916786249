import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { useApplication, useCase, useProperty, useTenancy } from "hooks/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Applicants from "./components/Applicants";
import Documents from "./components/Documents";
import ApplicationActions from "./components/ApplicationActions";
import ApplicationDetails from "./components/ApplicationDetails";
import ContractData from "./components/ContractData";
import Signer from "./components/Signer";
import "./index.scss";

const Application = props => {

    const { isApplicationLoading, applicationData, loadApplication,
        selectApplication, rejectContract, removeContractFromPenneo, sendContract, openContract, updateContract,
        updateContractData, toggleContractPropertyFacility, toggleContractTenancyFacility, screenApplicant, isContractGenerating, isContractCreating } = useApplication();
    const { isCaseLoading, caseData, loadCase } = useCase();
	const { tenancyData, loadTenancy } = useTenancy();
	const { propertyData, loadProperty } = useProperty();
    const params = useParams();

	const getDocuments = () => {
		if (!propertyData || !tenancyData)
			return [];

		const tenancyDocTypes = tenancyData.documents.map(x => x.type);
		const propertyDocs = propertyData?.documents.filter(x => !tenancyDocTypes.includes(x.type));

		return [...tenancyData.documents, ...propertyDocs];
	};
	
    useEffect(() => {
        loadApplication(params.id);
    }, [params.id, loadApplication]);

    useEffect(() => {
        if (applicationData) {
			loadCase(applicationData.caseId);
        }
    }, [applicationData, loadCase]);

	useEffect(() => {
        if (caseData) {
			loadTenancy(caseData.tenancyId);
        }
    }, [caseData, loadTenancy]);

	useEffect(() => {
        if (tenancyData) {
			loadProperty(tenancyData.propertyId);
        }
    }, [tenancyData, loadProperty]);

    if (isApplicationLoading || isCaseLoading) {
        return (
            <div className="loading-container"><LoadingComponent /></div>
        );
    }

    if (!applicationData || !caseData) {
        return (
            <div className="application-container">Error</div>
        );
    }

    return (
        <div className="application-container">
            <div className="application-header">
                <ApplicationActions
                    applicationData={applicationData}
                    selectApplication={selectApplication}
                    rejectContract={rejectContract}
					removeContractFromPenneo={removeContractFromPenneo}
                    sendContract={sendContract}
                    openContract={openContract}
					documents={getDocuments()}
					isContractGenerating={isContractGenerating}
					isContractCreating={isContractCreating} />
            </div>
            <div className="application-content">
                <ApplicationDetails caseData={caseData} applicationData={applicationData} />
                <Applicants applicants={applicationData.applicants} screenApplicant={screenApplicant} />
				<Documents documents={getDocuments()} />
				{applicationData.contract &&
                    <Signer
                        contract={applicationData.contract}
                        updateContract={updateContract} />
                }
                {applicationData.contract &&
                    <ContractData
						documents={getDocuments()}
                        contract={applicationData.contract}
                        updateContractData={updateContractData}
                        toggleContractPropertyFacility={toggleContractPropertyFacility}
                        toggleContractTenancyFacility={toggleContractTenancyFacility} />
                }
            </div>
        </div>
    );
};

export default Application;