import FilterModal  from "./FilterModal";  
import { connect } from "react-redux";

const mapDispatchToProps = () => {
  return {
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    employees: state.app.employees,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);