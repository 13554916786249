import React, { useState } from "react";
import "./index.scss";
import { translateString } from "helpers";
import FileSelector from "components/FileSelector";
import { PrimaryButton } from "components/Buttons";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { Select } from "components/Fields";
import { MenuItem } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlined";

const DocumentsManager = props => {

	const { documents, onConfirm, onDelete, readOnly } = props;

	const [selectedDocument, setSelectedDocument] = useState(null);
	const [selectedType, setSelectedType] = useState("")
	const [isModalEnabled, setModalEnabled] = useState(false);

	const documentSelectedHandler = (file) => {
		setSelectedDocument(file[0])
		setModalEnabled(true);
	}

	const confirmHandler = () => {
		onConfirm(selectedDocument, selectedType);
		setModalEnabled(false);
		setSelectedType("");
		setSelectedDocument(null);
	}

	return (
		<>
			<div className="documents-manager-container">
				{documents && documents.map((document, ix) => (
					<div key={document.id} className="document">
						<a href={document.url} target="_blank"><p className="document__name">{translateString(document.type)}</p></a>
						{onDelete && !readOnly &&
							<DeleteOutlineIcon
								className="delete-document-icon"
								onClick={() => onDelete(document.id)}
							/>
						}
					</div>
				))}
				{!readOnly && 
					<FileSelector
						acceptedFileTypes="application/pdf"
						filesSelected={e => documentSelectedHandler(e)}
					/>
				}
			</div>
			<FullScreenModal
				className="documents__upload-modal"
				open={isModalEnabled}
				onClose={() => setModalEnabled(false)}>
				<Typography variant="subtitle">Vælg dokument type</Typography>
				<Select
					value={selectedType}
					onChange={(e) => {
						setSelectedType(e.target.value)
					}}>
					<MenuItem value="Paragraph11">
						{translateString("Paragraph11")}
					</MenuItem>
					<MenuItem value="DataPolicy">
						{translateString("DataPolicy")}
					</MenuItem>
					<MenuItem value="HouseRules">
						{translateString("HouseRules")}
					</MenuItem>
					<MenuItem value="EnergyLabel">
						{translateString("EnergyLabel")}
					</MenuItem>
					<MenuItem value="Other">
						{translateString("Other")}
					</MenuItem>
				</Select>
				<PrimaryButton onClick={confirmHandler}>Bekræft</PrimaryButton>
			</FullScreenModal>
		</>
	);
}

export default DocumentsManager;
