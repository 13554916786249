import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const Maintenance = ({ tenancyContractData, updateTenancyContract }) => {

    const [amount, setAmount] = useState(tenancyContractData.maintenance.amount ?? 0);
    const [amountLastUpdatedAt, setAmountLastUpdatedAt] = useState(tenancyContractData.maintenance.amountLastUpdatedAt ?? "");

    return (
        <Paper className="tenancy-contract-details__maintenance-fields">
            <Typography variant="subtitle">Vedligeholdelse</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Indvending vedligeholdelse påhviler udlejer</Typography>
                    <Switch
                        checked={tenancyContractData.maintenance.isLandlordResponsible}
                        onChange={(e) => updateTenancyContract({ maintenance: { isLandlordResponsible: e.target.checked } })}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Beløb på vedligeholdelseskonto</Typography>
                    <TextField
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        onExit={() => setAmount(tenancyContractData.maintenance.amount)}
                        onFinish={() => {
                            amount !== tenancyContractData.maintenance.amount && updateTenancyContract({ maintenance: { amount: amount } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Beløb sidst opdateret</Typography>
                    <TextField
                        value={amountLastUpdatedAt ?? ""}
                        type="date"
                        onChange={(event) => setAmountLastUpdatedAt(event.target.value)}
                        onExit={() => setAmountLastUpdatedAt(tenancyContractData.maintenance.amountLastUpdatedAt)}
                        onFinish={() => {
                            amountLastUpdatedAt !== tenancyContractData.maintenance.amountLastUpdatedAt && updateTenancyContract({ maintenance: { amountLastUpdatedAt } })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default Maintenance;