import { format } from "date-fns";
import numeral from "numeral";
import "numeral/locales/da-dk";

export const formatNumber = (number, type) => {
	switch (type) {
		case "currencyPerMonth":
			const separatedNumber = number
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
			const perMonthExpression = "kr./md.";
			return `${separatedNumber} ${perMonthExpression}`;
		case "phoneNumber":
			const separatedPhoneNumber = number.number
				.toString()
				.replace(/\B(?=(\d{4})+(?!\d))/g, " ");
			return `${number.extension} ${separatedPhoneNumber}`;
		case "months":
			return number > 1 ? `mdr.` : `md.`;
		case "financial":
			numeral.locale('da-dk');
			const myNumeral = numeral(number).format("0,0");
			return myNumeral;
		default:
			return number;
	}
};
export const formatDate = (date) => {
	const dateObject = new Date(date);
	var formattedDate = format(dateObject, "dd/MM/yyyy");
	return formattedDate;
};
export const getDanishStringFromState = (state) => {
	switch (state) {
		case "Reserved":
			return "Reserveret";
		case "Active":
			return "Aktive";
		case "Draft":
			return "Draft";
		case "Completed":
			return "Udlejet";
		default:
			return "";
	}
};
export const locationTypes = [
	"Bus",
	"Train",
	"Subway",
	"Airport",
	"School",
	"Kindergarden",
	"Nursery",
	"Playground",
	"GroceryStore",
];

export const translateString = (string) => {
	switch (string) {
		case "Apartment":
		case "apartment":
			return "Lejlighed"
		case "CommunalLaundry":
			return "Fællesvaskeri"
		case "Monthly":
			return "Måned"
		case "Quarterly":
			return "Kvartal"
		case "BicycleParking":
			return "Cykelparkering"
		case "Atticormasementroom":
			return "Loft-/Kælderrum"
		case "SharedCourtyard":
			return "Fællesgårdanlæg"
		case "Reserved":
		case "reserved":
			return "Reserveret";
		case "Water":
			return "Vand"
		case "Heating":
			return "Varme"
		case "Operating":
			return "Drift"
		case "Electricity":
			return "Elektricitet"
		case "Cooling":
			return "Køling"
		case "Antenna":
			return "Antenne"
		case "Internet":
			return "Internet"
		case "ResidentRepresentation":
			return "Beboerrepræsentation";
		case "Bus":
			return "Bus";
		case "Train":
			return "Tog";
		case "Subway":
			return "Metro";
		case "Airport":
			return "Lufthavn";
		case "School":
			return "Skole";
		case "Kindergarden":
			return "Børnehave";
		case "Nursery":
			return "Vuggestue";
		case "Playground":
			return "Legeplads";
		case "GroceryStore":
			return "Indkøb";
		case "Draft":
		case "draft":
			return "Kladde";
		case "Active":
		case "active":
			return "Aktiv";
		case "Completed":
		case "completed":
			return "Udlejet";
		case "Business":
		case "business":
			return "Erhverv";
		case "Residential":
		case "residential":
			return "Bolig";
		case "Other":
		case "other":
			return "Andet";
		case "Storage":
			return "Lager";
		case "Production":
			return "Produktion";
		case "Shop":
			return "Butik";
		case "Clinic":
			return "Klinik";
		case "Workshop":
			return "Værksted";
		case "Public":
		case "public":
			return "Publiceret - søgbar";
		case "Invisible":
		case "invisible":
			return "Ikke synlig";
		case "Private":
		case "private":
			return "Publiceret - ikke søgbar";
		case "title":
			return "Titel"
		case "constructed":
			return "Opført"
		case "deposit":
			return "Depositum periode"
		case "prepaid":
			return "Forudbetalt leje periode"
		case "street":
			return "Vej"
		case "zipCode":
			return "Postnummer"
		case "Paragraph11":
			return "Paragraf 11"
		case "DataPolicy":
			return "Persondatapolitik"
		case "HouseRules":
			return "Husorden"
		case "EnergyLabel":
			return "Energimærkning"
		case "city":
			return "By"
		case "House":
			return "Hus"
		case "Terrace":
			return "Rækkehus"
		case "Office":
			return "Kontor"
		case "TakeAway":
			return "Take away"
		case "OpenHouse":
			return "Åbent hus"
		case "Showcasing":
			return "Fremvisning"
		case "Stove":
			return "Komfur"
		case "Refrigerator":
			return "Køleskab"
		case "Freezer":
			return "Fryser"
		case "Dishwasher":
			return "Opvaskemaskine"
		case "WashingMachine":
			return "Vaskemaskine"
		case "Dryer":
			return "Tørretumbler"
		case "KitchenHood":
			return "Emhætte"
		case "RefrigeratorAndFreezer":
			return "Køle-/fryseskab"
		case "WashingMachineAndDryer":
			return "Vaskemaskine/Tørretumbler"
		case "AtticOrBasementRoom":
			return "Loft-/kælderrum"
		case "SingleRoom":
		case "single_room":
			return "Enkeltværelse"
		case "HousingCooperative":
		case "housing_cooperative":
			return "Andelsbolig"
		case "Condominium":
		case "condominium":
			return "Ejerlejlighed"
		case "TownHouse":
		case "town_house":
			return "Rækkehus"
		default:
			return string;
	}
};

export const getIntegrationDataObject = (string) => {
	switch (string) {
		case "AnsvarligMedarbejder":
			return {
				label: "Sagsansvarlig"
			}
		case "Ansvarlig":
			return {
				label: "Erindringsansvarlig"
			}
		case "StatusNr":
			return {
				label: "Status"
			}
		case "Boligtype":
			return {
				label: "Unit Type"
			}
		case "Areal":
			return {
				label: "BBR areal"
			}
		case "AnskafSum":
			return {
				label: "Target Rent"
			}
		case "NaesteOpfolgning":
			return {
				label: "Latest View",
				type: "date"
			}
		case "KontraktAfsendtDato":
			return {
				label: "Lejekontrakt fremsendt",
				type: "date"
			}
		case "KontraktReturDato":
			return {
				label: "Lejekontrakt underskrevet",
				type: "date"
			}
		case "DepositumBetaltDato":
			return {
				label: "Betaling modtaget",
				type: "date"
			}
		case "IndflytDato":
			return {
				label: "Indflytning",
				type: "date"
			}
		case "Energimaerke":
			return {
				label: "Energimærke"
			}
		case "Notat":
			return {
				label: "Notat",
				span: "4",
				sortOrder: "100"
			}
		case "EjendomBemarkning":
			return {
				label: "Ejendom - bemærkning",
			}
		case "LejemaalBemarkning":
			return {
				label: "Lejemål - bemærkning",
			}
		default:
			return {
				label: string
			};
	}
};

export const formatContacts = (arr) => {
	return arr.map((contact) => {
		return {
			...contact,
			phone: {
				value: contact?.phone?.number ?? "",
				adornment: contact?.phone?.extension ?? "",
				reversedAdornment: true,
			},
			role: {
				value: contact?.role?.text ?? "",
				id: contact?.role?.id ?? ""
			}
		}
	});
};

export const formatExpenses = (arr) => {
	return arr.map((cost, index) => {
		return {
			...cost,
			amount: {
				value: cost.amount.value,
				adornment: "kr./md.",
			},
			type: cost.type,
		};
	});
};


export const formatApplicants = (arr) => {
	return arr.map((application, index) => {
		const applicant = application.applicants[0];
		return {
			...applicant,
			id: application.id,
			phone: applicant.phone?.extension + " " + applicant.phone?.number ?? "",
			street: applicant.address?.street ?? "",
			city: applicant.address?.city ?? "",
			zipCode: applicant.address?.zipCode ?? "",
			country: applicant.address?.country ?? "",
		};
	});
};

export const formatAttributes = (arr, group) => {
	return arr
		.filter((attribute) => {
			return attribute?.definition?.group?.text === group;
		})
		.map((attribute) => {
			return {
				id: attribute.definition.id,
				type: attribute.definition.description,
				enabled: {
					options: attribute.definition.options
						? attribute.definition.options
						: undefined,
					value:
						attribute.value !== undefined || attribute.definition.kind === "Boolean"
							? attribute.value
							: attribute.definition.options.find(
								(option) => option.id === attribute.optionId
							)?.text ?? "",
				},
			};
		});
};

export const getStateLogLabel = (string) => {
	switch (string) {
		case "Draft":
			return "Sag oprettet"
		case "Active":
			return "Sag aktiveret"
		case "Reserved":
			return "Sag reserveret"
		case "Completed":
			return "Sag afsluttet"
		default:
			return string;
	}
};