  
import { translateString } from 'helpers';
import { 
  API,
  FETCH_PROPERTIES,
  FETCH_TENANCIES,
  FETCH_PROPERTY,
  FETCH_TENANCY,
  FETCH_CASES,
  FETCH_CASE,
  UPDATE_CASE,
  ADD_CASE_IMAGE,
  UPDATE_CASE_IMAGE,
  UPDATE_TENANCY,
  CREATE_PROPERTY,
  UPDATE_PROPERTY,
  QUERY_TENANCIES,
  QUERY_PROPERTIES,
  QUERY_CASES,
  FETCH_CASES_STATS,
  FETCH_ATTRIBUTES,
  FETCH_CONTACT_ROLES,
  FETCH_EXPENSE_TYPES,
  FETCH_TENANCY_TYPES,
  UPDATE_TENANCY_LOCATIONS,
  UPDATE_TENANCY_TYPES,
  FETCH_PROPERTY_TENANCIES,
  API_ERROR,
  UPDATE_CASE_TYPES,
  UPDATE_CASE_FLOOR_PLAN,
  FETCH_TENANCIES_STATS,
  FETCH_PROPERTY_STATS,
  ADD_CASE_FLOOR_PLAN, 
  DELETE_CASE_PROSPECTUS,
  FETCH_MATCHES,
  FETCH_CASE_INTEGRATIONS,
  CREATE_TENANCY,
  FETCH_CASE_APPLICATIONS,
  FETCH_CASE_APPLICATION,
  DELETE_CASE_DOCUMENT,
  FETCH_APPLICATION_CONTRACT,
  FINISH_CONTRACT_PAYMENT_LOADING,
  CREATE_CASE_COMMENT,
  SET_CASE_COMMENT,
  GET_CASE_COMMENTS,
  SET_CASE_COMMENTS,
  DELETE_CASE_COMMENT,
  FETCH_ORGANIZATION_INTEGRATIONS,
  FETCH_ORGANIZATION_EMPLOYEES,
  CREATE_SHOWCASING,
  FETCH_SHOWCASINGS,
  FETCH_OPENHOUSES,
  FETCH_TENANCY_KINDS,
  UPDATE_PROFILE_PICTURE,
  READ_ME,
  UPDATE_ME
} from 'redux/actionTypes';
import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

function apiAction({
  url = "",
  data = null,
  method = "GET",
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headers = {
    "Content-Type": "application/json"
  }
}) {
  return {
    type: API,
    payload: {
      headers:{
        ...headers
      },
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label
    }
  };
}

export function readShowcasings () {
  return apiAction({
    url: `showcasings`,
    method: "GET",
    onSuccess: (data) => setShowcasings(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_SHOWCASINGS
  });
}

function setShowcasings (data) {
  return {
    type: FETCH_SHOWCASINGS,
    payload: data
  };
}



export function readOpenHouses () {
  return apiAction({
    url: `showcasings`,
    method: "GET",
    onSuccess: (data) => setOpenHouses(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_OPENHOUSES
  });
}

function setOpenHouses (data) {
  return {
    type: FETCH_OPENHOUSES,
    payload: data
  };
}

export function getCaseIntegrations (id) {
  return apiAction({
    url: `cases/${id}/integrations`,
    method: "GET",
    onSuccess: (data) => setCaseIntegrations(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_CASE_INTEGRATIONS
  });
}

export function getCaseApplications (id) {
  return apiAction({
    url: `cases/${id}/applications`,
    method: "GET",
    onSuccess: (data) => setCaseApplications(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_CASE_APPLICATIONS
  });
}

export function getCaseApplication (id) {
  return apiAction({
    url: `applications/${id}`,
    method: "GET",
    onSuccess: (data) => setCaseApplication(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_CASE_APPLICATION
  });
}

export function rejectContract (id, applicationId) {
  return apiAction({
    url: `/contracts/${id}/reject`,
    method: "POST",
    onSuccess: (data) => getApplicationContract(applicationId),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_APPLICATION_CONTRACT
  });
}

export function sendContract (id, applicationId) {
  return apiAction({
    url: `/contracts/${id}/send`,
    method: "POST",
    onSuccess: () => getApplicationContract(applicationId),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_APPLICATION_CONTRACT
  });
}

export function getApplicationContract (id) {
  return apiAction({
    url: `applications/${id}/contract`,
    method: "GET",
    onSuccess: (data) => setCaseApplicationContract(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_APPLICATION_CONTRACT
  });
}


function setCaseApplicationContract (data) {
  return {
    type: FETCH_APPLICATION_CONTRACT,
    payload: data
  };
}

export function selectCaseApplication (id) {
  return apiAction({
    url: `applications/${id}/select`,
    method: "POST",
    onSuccess: (data) => setCaseApplicationContract(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: FETCH_APPLICATION_CONTRACT
  });
}

function setCaseApplication (data) {
  return {
    type: FETCH_CASE_APPLICATION,
    payload: data
  };
}

function setCaseApplications (data) {
  return {
    type: FETCH_CASE_APPLICATIONS,
    payload: data
  };
}

export function addCaseIntegration (integration, id) {
  let newIntegration = {};
  newIntegration[integration] = {
    status:"",
    error:""
  }
  newIntegration[integration]["status"] = "Success";
  return apiAction({
    url: `cases/${id}/integrations/${integration}`,
    method: "POST",
    onSuccess:() => setCaseIntegrations(newIntegration),
    onFailure:() => { console.log("Error occured enabling integration") },
    label: FETCH_CASE_INTEGRATIONS
  });
}

function setCaseIntegrations (data) {
    return {
      type: FETCH_CASE_INTEGRATIONS,
      payload: data
    };
}


export function deleteCaseIntegration (integration, id) {
  let newIntegration = {};
  newIntegration[integration] = {
    status:"",
    error:""
  }
  newIntegration[integration]["status"] = "Disabled";
  return apiAction({
    url: `cases/${id}/integrations/${integration}`,
    method: "DELETE",
    onSuccess:() => setCaseIntegrations(newIntegration),
    onFailure:() => {console.log("Error occured creating case")},
    label: "DISABLE_INTEGRATION"
  });
}

export function createShowcasing (tenancyId, data) {
  return apiAction({
    url: `tenancies/${tenancyId}/showcasings`,
    method: "POST",
    data: {...data},
    onSuccess:(data) => setCases(data, false, true),
    onFailure:() => { console.log("Error occured creating case") },
    label: CREATE_SHOWCASING
  });
}

export function createCase (id) {
  return apiAction({
    url: `tenancies/${id}/cases`,
    method: "POST",
    onSuccess:(data) => setCases(data, false, true),
    onFailure:() => { console.log("Error occured creating case") },
    label: FETCH_PROPERTIES
  });
}

export function getCaseComments (caseId) {
  return apiAction({
    url: `cases/${caseId}/comments`,
    method: "GET",
    onSuccess: (data) => setCaseComments(data),
    onFailure: () => { console.log("Error occured creating case") },
    label: GET_CASE_COMMENTS
  });
}

export function deleteCaseComment (caseId, commentId) {
  return apiAction({
    url: `cases/${caseId}/comments/${commentId}`,
    method: "DELETE",
    onSuccess: () => setDeletedCaseComment(commentId),
    onFailure: () => { console.log("Error occured creating case") },
    label: DELETE_CASE_COMMENT
  });
}

function setDeletedCaseComment (data) {
  return {
    type: DELETE_CASE_COMMENT,
    payload: data
  };
}

function setCaseComments (data) {
  return {
    type: SET_CASE_COMMENTS,
    payload: data
  };
}

export function createCaseComment (caseId, comment) {
  return apiAction({
    url: `cases/${caseId}/comments`,
    data: {
      content: comment
    },
    method: "POST",
    onSuccess:(data) => setCaseComment(data),
    onFailure:() => { console.log("Error occured creating case") },
    label: CREATE_CASE_COMMENT
  });
}

function setCaseComment (data) {
  return {
    type: SET_CASE_COMMENT,
    payload: data
  };
}

export function updateCase (id, resource) {
  return apiAction({
    url: `cases/${id}`,
    data: resource,
    method: "PATCH",
    onSuccess: (data) => setCases(data, false),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_CASE
  });
}

export function addDocument (path, id, resource) {

  const getPath = () => {
    switch(path){
      case "cases": 
        return "case"
      case "properties": 
        return "property"
      case "tenancies": 
        return "tenancy";
      default:
        break;
    }
}
  const data = new FormData();   
  data.set("file", resource.file);
  data.set("type", resource.type);
    return apiAction({
      url: `${path}/${id}/documents`,
      headers:{
        "Content-Type": "multipart/form-data"
      },
      data: data,
      method: "POST",
      onSuccess: (data) => setDocument(data, getPath().toUpperCase()),
      onFailure:() => { console.log("Error occured updating case") },
      label: `ADD_DOCUMENT`
    });
}


export function deleteDocument (path, caseId, documentId) {
  const getPath = () => {
      switch(path){
        case "cases": 
          return "case"
        case "properties": 
          return "property"
        case "tenancies": 
          return "tenancy";
        default:
          break;
      }
  }
  const realPath = getPath();
  return apiAction({
    url: `${path}/${caseId}/documents/${documentId}`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    method: "DELETE",
    onSuccess: () => setDeletedDocument(realPath, documentId),
    onFailure:() => { console.log("Error occured updating case") },
    label: DELETE_CASE_DOCUMENT
  });
}

function setDeletedDocument (path, documentId) {
  return {
    type: `DELETE_${path.toUpperCase()}_DOCUMENT`,
    payload: {
      id: documentId
    }
  };
}


function setDocument (data, path) {
  return {
    type: `SET_${path.toUpperCase()}_DOCUMENT`,
    payload: {
      document:data
    }
  };
}


export function addImage (path, id, resource, order) {

  const getPath = () => {
    switch(path){
      case "cases": 
        return "case"
      case "properties": 
        return "property"
      case "tenancies": 
        return "tenancy";
      default:
        break;
    }
}

  const data = new FormData();
  data.set("alt", resource.name);
  data.set("file", resource);
  data.set("order", order);
  const fileSize = parseInt(resource.size / 1024 / 1024);
  if(fileSize > 5) { 
    return{
      type: API_ERROR,
      payload:{
        message: "Billeder må maks være 5MB.",
        response: {
          message: "Billeder må maks være 5MB.",
        }
      }
    }
  }else{
    return apiAction({
      url: `${path}/${id}/images`,
      headers:{
        "Content-Type": "multipart/form-data"
      },
      data: data,
      method: "POST",
      onSuccess: (data) => setImage(data, getPath().toUpperCase()),
      onFailure:() => { console.log("Error occured updating case") },
      label: `ADD_IMAGE`
    });
  }
}


export function updateMe (payload) {
  return apiAction({
    url: `employees/me`,
    data: payload,
    method: "PATCH",
    onSuccess: () => setMe(payload),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_ME
  });
}

function setMe (data) {
  return {
    type: UPDATE_ME,
    payload: data
  };
}

export function addProfileImage (image) {

  let data = new FormData();
  data.append('file', image);
  return apiAction({
    url: `employees/me/picture`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    data: data,
    method: "PATCH",
    onSuccess: (data) => setProfileImage(data),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_PROFILE_PICTURE
  });
}

function setProfileImage (data) {
  return {
    type: UPDATE_PROFILE_PICTURE,
    payload: data.picture
  };
}

function setImage (data, path) {
    return {
      type: `SET_${path.toUpperCase()}_IMAGE`,
      payload: {
        image:data
      }
    };
}

export function updateImage (path, pathId, imageId, resource) {
  const getPath = () => {
    switch(path){
      case "cases": 
        return "case"
      case "properties": 
        return "property"
      case "tenancies": 
        return "tenancy";
      default:
        break;
    }
}
  return apiAction({
    url: `${path}/${pathId}/images/${imageId}`,
    headers:{
      "Content-Type": "application/json"
    },
    data: resource,
    method: "PATCH",
    onSuccess: (data) => setImage(data, getPath()),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_CASE_IMAGE
  });
}

export function deleteImage (path, caseId, imageId) {
  const getPath = () => {
      switch(path){
        case "cases": 
          return "case"
        case "properties": 
          return "property"
        case "tenancies": 
          return "tenancy";
        default:
          break;
      }
  }
  const realPath = getPath();
  return apiAction({
    url: `${path}/${caseId}/images/${imageId}`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    method: "DELETE",
    onSuccess: () => setDeletedImage(realPath, imageId),
    onFailure:() => { console.log("Error occured updating case") },
    label: ADD_CASE_IMAGE
  });
}

function setDeletedImage (path, imageId) {
    return {
      type: `DELETE_${path.toUpperCase()}_IMAGE`,
      payload: {
        id: imageId
      }
    };
}

export function readMe () {
  return apiAction({
    url: "employees/me",
    method: "GET",
    onSuccess: data => setUser(data),
    onFailure:() => { console.log("Error occured loading me") },
    label: READ_ME
  });
}

function setUser (data) {
  return {
    type: READ_ME,
    payload: data
  };
}

export function readTenancyKinds (id) {
  return apiAction({
    url: `properties/${id}/tenancy-kinds`,
    method: "GET",
    onSuccess: (data) => setTenancyKinds(data),
    onFailure:() => { console.log("Error occured loading me") },
    label: FETCH_TENANCY_KINDS
  });
}

function setTenancyKinds (data) {
  return {
    type: FETCH_TENANCY_KINDS,
    payload: data
  };
}


export function fetchOrganizationIntegrations () {
  return apiAction({
    url: "integrations",
    method: "GET",
    onSuccess: setIntegrations,
    onFailure:() => { console.log("Error occured loading me") },
    label: FETCH_PROPERTIES
  });
}

function setIntegrations (data) {
  return {
    type: FETCH_ORGANIZATION_INTEGRATIONS,
    payload: data
  };
}

export function fetchOrganizationUsers () {
  return apiAction({
    url: "employees",
    method: "GET",
    onSuccess: setEmployees,
    onFailure:() => { console.log("Error occured loading me") },
    label: FETCH_PROPERTIES
  });
}


function setEmployees (data) {
  return {
    type: FETCH_ORGANIZATION_EMPLOYEES,
    payload: data
  };
}


export function queryProperties (queryData) {
  return apiAction({
    url: "properties/query",
    method: "POST",
    data: queryData,
    onSuccess: (data, headers) => setProperties(data, true, headers),
    onFailure:() => { console.log("Error occured loading  tenancies") },
    label: QUERY_PROPERTIES
  });
}

export function readProperties () {
  return apiAction({
    url: "properties",
    method: "GET",
    onSuccess:(data) => setProperties(data, true),
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_PROPERTIES
  });
}

export function readProperty (id) {
  return apiAction({
    url: `properties/${id}`,
    method: "GET",
    onSuccess: (data) => setProperties(data, false),
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_PROPERTY
  });
}

export function readPropertyTenancies (id) {
  return apiAction({
    url: `properties/${id}/tenancies`,
    method: "GET",
    onSuccess: (data) => {
      return {
        type: FETCH_PROPERTY_TENANCIES,
        payload: data
      };
    },
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_PROPERTY_TENANCIES
  });
}

export function createProperty (data) {
  return apiAction({
    url: `properties`,
    data: data,
    method: "POST",
    onSuccess:(data) => setProperties(data, false, [], true),
    onFailure:() => { console.log("Error occured loading properties") },
    label: CREATE_PROPERTY
  });
}

export function createTenancy (data) {
  return apiAction({
    url: `tenancies`,
    data: data,
    method: "POST",
    onSuccess:(data) => setTenancies(data, false, [], true),
    onFailure:() => { console.log("Error occured loading properties") },
    label: CREATE_TENANCY
  });
}

export function updateProperty (id, resource) {
  return apiAction({
    url: `properties/${id}`,
    data: resource,
    method: "PATCH",
    onSuccess: (data) => setProperties(data, false),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_PROPERTY
  });
}

function setProperties (data, multiple, headers, withLoad) {
  if(withLoad) {
    window.location.href = `/properties/${data.id}`
  }
  if(multiple) {
    return {
      type: FETCH_PROPERTIES,
      payload: {
        data: data,
        headers: headers 
      }
    };
  } else {
    return {
      type: FETCH_PROPERTY,
      payload: data
    };
  }
}

function setMatches (data) {
    return {
      type: FETCH_MATCHES,
      payload: data
    }
}

export function queryCases (queryData) {
  return apiAction({
    url: "cases/query",
    method: "POST",
    data: queryData,
    onSuccess: (data, headers) => setCases(data, true, false, headers),
    onFailure:() => { console.log("Error occured querying cases") },
    label: QUERY_CASES
  });
}

export function readCases () {
  return apiAction({
    url: "cases",
    method: "GET",
    onSuccess:(data) => setCases(data, true),
    onFailure:() => { console.log("Error occured loading cases") },
    label: FETCH_CASES
  });
}

export function readCase (id) {
  return apiAction({
    url: `cases/${id}`,
    method: "GET",
    onSuccess:(data) => setCases(data, false),
    onFailure:() => { console.log("Error occured loading cases") },
    label: FETCH_CASE
  });
}

export function readCasesStats () {
  return apiAction({
    url: `cases/stats`,
    method: "GET",
    onSuccess: setCasesStats,
    onFailure:() => { console.log("Error occured loading cases") },
    label: FETCH_CASES_STATS
  });
}

export function readPropertyStats (propertyId) {
  return apiAction({
    url: `tenancies/stats?propertyId=${propertyId}`,
    method: "GET",
    onSuccess: setPropertyStats,
    onFailure:() => { console.log("Error occured loading cases") },
    label: FETCH_PROPERTY_STATS
  });
}

function setPropertyStats(data) {
  return {
    type: FETCH_PROPERTY_STATS,
    payload: data
  };
}

export function readTenancyStats () {
  return apiAction({
    url: "tenancies/stats",
    method: "GET",
    onSuccess: setTenanciesStats,
    onFailure:() => { console.log("Error occured loading cases") },
    label: FETCH_TENANCIES_STATS
  });
}

function setTenanciesStats(data) {
  return {
    type: FETCH_TENANCIES_STATS,
    payload: data
  };
}


export function updateCaseTypes (id, operation, type) {
  return apiAction({
    url: `cases/${id}/types/${type}`,
    method: operation,
    onSuccess: () => setCaseTypes(type, operation),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_CASE
  });
}

function setCaseTypes (type, operation) {
  return {
    type: UPDATE_CASE_TYPES,
    payload: {type, operation}
  }
}


function setCasesStats(data, withLoad) {
  return {
    type: FETCH_CASES_STATS,
    payload: data
  };
}


function setCases (data, multiple, withLoad, headers) {
  if(withLoad) {
    window.location.href = `/cases/${data.id}`
  }
  if(multiple) {
    return {
      type: FETCH_CASES,
      payload: {
        data: data,
        headers: headers 
      }
    };
  } else {
    return {
      type: FETCH_CASE,
      payload: data
    };
  }
}

export function queryTenancies (queryData) {
  return apiAction({
    url: "tenancies/query",
    method: "POST",
    data: queryData,
    onSuccess: (data, headers) => setTenancies(data, true, headers),
    onFailure:() => { console.log("Error occured querying tenancies") },
    label: QUERY_TENANCIES
  });
}

export function readTenancies () {
  return apiAction({
    url: "tenancies",
    method: "GET",
    onSuccess:(data) => setTenancies(data, true),
    onFailure:() => { console.log("Error occured loading  tenancies") },
    label: FETCH_TENANCIES
  });
}

export function addExpense (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/expenses `,
    data: resource,
    method: "POST",
    onSuccess:(data) => setExpense(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `ADD_${path.toUpperCase()}_EXPENSE`
  });
}

export function updateExpense (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/expenses/${resource.id} `,
    data: resource,
    method: "PATCH",
    onSuccess:(data) => setExpense(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `UPDATE_${path.toUpperCase()}_EXPENSE`
  });
}

export function deleteExpense (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/expenses/${resource}`,
    data: resource,
    method: "DELETE",
    onSuccess:() => setDeletedExpense(path, resource),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `DELETE_${path.toUpperCase()}_EXPENSE`
  });
}
function setDeletedExpense (path, expenseId) {
  return {
    type: `DELETE_${path.toUpperCase()}_EXPENSE`,
    payload: {
      id: expenseId
    }
  };
}

function setExpense (data, path) {
  return {
    type: `SET_${path.toUpperCase()}_EXPENSE`,
    payload: data
  };
}

export function addContact (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/contacts `,
    data: resource,
    method: "POST",
    onSuccess:(data) => setContacts(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `ADD_${path.toUpperCase()}_CONTACT`
  });
}

export function updateContact (id, path, resource) {
  if(!resource.phone.number) {
    delete resource.phone;
  }
  return apiAction({
    url: `${path}/${id}/contacts/${resource.id} `,
    data: resource,
    method: "PATCH",
    onSuccess:(data) => setContacts(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `UPDATE_${path.toUpperCase()}_CONTACT`
  });
}

export function deleteContact (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/contacts/${resource}`,
    data: resource,
    method: "DELETE",
    onSuccess:() => setDeletedContact(path, resource),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `DELETE_${path.toUpperCase()}_CONTACT`
  });
}
function setDeletedContact (path, expenseId) {
  return {
    type: `DELETE_${path.toUpperCase()}_CONTACT`,
    payload: {
      id: expenseId
    }
  };
}

function setContacts (data, path) {
  return {
    type: `SET_${path.toUpperCase()}_CONTACT`,
    payload: data
  };
}

export function addAttribute (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/attributes `,
    data: resource,
    method: "POST",
    onSuccess:(data) => setAttributes(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `ADD_${path.toUpperCase()}_ATTRIBUTE`
  });
}

export function updateAttribute (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/attributes/${resource.id} `,
    data: resource,
    method: "PATCH",
    onSuccess:(data) => setAttributes(data, path),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `UPDATE_${path.toUpperCase()}_ATTRIBUTE`
  });
}

export function deleteAttribute (id, path, resource) {
  return apiAction({
    url: `${path}/${id}/attributes/${resource}`,
    data: resource,
    method: "DELETE",
    onSuccess:() => setDeletedAttribute(path, resource),
    onFailure:() => { console.log("Error occured loading properties") },
    label: `DELETE_${path.toUpperCase()}_ATTRIBUTE`
  });
}
function setDeletedAttribute (path, attributeId) {
  return {
    type: `DELETE_${path.toUpperCase()}_ATTRIBUTE`,
    payload: {
      id: attributeId
    }
  };
}

export function fetchAttributes () {
  return apiAction({
    url: `/attribute-definitions`,
    method: "GET",
    onSuccess:(data) => setAttributes(data),
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_TENANCY
  });
}

function setAttributes (data, path) {
  if(path){
   return {
     type: `SET_${path.toUpperCase()}_ATTRIBUTE`,
     payload: data
   };
  }else {
    return {
      type: FETCH_ATTRIBUTES,
      payload: data
    };
  }
 }

export function fetchContactRoles () {
  return apiAction({
    url: `/contact-roles`,
    method: "GET",
    onSuccess:(data) => setContactRoles(data),
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_CONTACT_ROLES
  });
}

function setContactRoles (data) {
    return {
      type: FETCH_CONTACT_ROLES,
      payload: data
    };
}


export function fetchExpenseTypes() {
  
  let expenseTypes = [
    "Water",
    "Heating",
    "Operating",
    "Electricity",
    "Cooling",
    "Antenna",
    "Internet",
    "ResidentRepresentation",
  ];

  let mappedExpenseTypes = expenseTypes.map((type) => {
    return {
      id: type,
      label : translateString(type),
      text: type,
    };
  });

  return {
    type: FETCH_EXPENSE_TYPES,
    payload: mappedExpenseTypes
  };
}

export function readTenancy (id) {
  return apiAction({
    url: `tenancies/${id}`,
    method: "GET",
    onSuccess:(data) => setTenancies(data, false),
    onFailure:() => { console.log("Error occured loading properties") },
    label: FETCH_TENANCY
  });
}

export function updateTenancy (id, resource) {
  return apiAction({
    url: `tenancies/${id}`,
    data: resource,
    method: "PATCH",
    onSuccess: (data) => setTenancies(data, false),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_TENANCY
  });
}

export function updateTenancyLocations (id, operation, location) {
  return apiAction({
    url: `tenancies/${id}/locations/${location}`,
    method: operation,
    onSuccess: () => setTenancyLocations(location, operation),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_TENANCY
  });
}

export function updateTenancyTypes (id, operation, type) {
  return apiAction({
    url: `tenancies/${id}/types/${type}`,
    method: operation,
    onSuccess: () => setTenancyTypes(type, operation),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_TENANCY
  });
}

function setTenancyTypes (type, operation) {
  return {
    type: UPDATE_TENANCY_TYPES,
    payload: {type, operation}
  }
}

function setTenancyLocations (location, operation) {
  return {
    type: UPDATE_TENANCY_LOCATIONS,
    payload: {location, operation}
  }
}

function setTenancies (data, multiple, headers, withLoad) {
  if(withLoad) {
    window.location.href = `/tenancies/${data.id}`
  }
  if(multiple){
    return {
      type: FETCH_TENANCIES,
      payload: {
        data: data,
        headers: headers 
      }
    };
  }else {
    return {
      type: FETCH_TENANCY,
      payload: data
    };
  }
}


export function readLatestCases (type) {
  const query = {
    "paging": {
      "offset": 0,
      "limit": 20
    },
    "sort": {
      "field": "createdAt",
      "direction": "DESC"
    },
    "filters": [{
      "type": "Equals",
      "field": "state",
      "params": {
        "value": `${type}`
      }
    }]
  };

  return apiAction({
    url: "cases/query",
    method: "POST",
    data: query,
    onSuccess: (data, headers) => {
      return {
        type: `FETCH_LATEST_${type.toUpperCase()}_CASES`,
        payload: data
      };
    },
    onFailure:() => { console.log("Error occured querying cases", query) },
    label: `FETCH_LATEST_${type.toUpperCase()}_CASES`
  });
}
export function addFloorPlan (path, id, resource, order) {
  const getPath = () => {
    switch(path){
      case "cases": 
        return "case"
      case "properties": 
        return "property"
      case "tenancies": 
        return "tenancy";
      default:
        break;
    }
  }

  const data = new FormData();
  data.set("alt", resource.name);
  data.set("file", resource);
  data.set("order", order);
  const fileSize = parseInt(resource.size / 1024 / 1024);
  if(fileSize > 5) { 
    return{
      type: API_ERROR,
      payload:{
        message: "Plantegninger må maks være 5MB.",
        response: {
          message: "Plantegninger må maks være 5MB.",
        }
      }
    }
  }else{
    return apiAction({
      url: `${path}/${id}/floor-plans`,
      headers:{
        "Content-Type": "multipart/form-data"
      },
      data: data,
      method: "POST",
      onSuccess: (data) => setFloorPlan(data, getPath().toUpperCase()),
      onFailure:() => { console.log("Error occured updating case") },
      label: `ADD_FLOOR_PLAN`
    });
  }
}

function setFloorPlan (data, path) {
    return {
      type: `SET_${path.toUpperCase()}_FLOOR_PLAN`,
      payload: {
        image:data
      }
    };
}

export function updateFloorPlan (path, pathId, imageId, resource) {
  const getPath = () => {
    switch(path){
      case "cases": 
        return "case"
      case "properties": 
        return "property"
      case "tenancies": 
        return "tenancy";
      default:
        break;
    }
  }
  return apiAction({
    url: `${path}/${pathId}/floor-plans/${imageId}`,
    headers:{
      "Content-Type": "application/json"
    },
    data: resource,
    method: "PATCH",
    onSuccess: (data) => setFloorPlan(data, getPath()),
    onFailure:() => { console.log("Error occured updating case") },
    label: UPDATE_CASE_FLOOR_PLAN
  });
}

export function deleteFloorPlan (path, caseId, imageId) {
  const getPath = () => {
      switch(path){
        case "cases": 
          return "case"
        case "properties": 
          return "property"
        case "tenancies": 
          return "tenancy";
        default:
          break;
      }
  }
  const realPath = getPath();
  return apiAction({
    url: `${path}/${caseId}/floor-plans/${imageId}`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    method: "DELETE",
    onSuccess: () => setDeletedFloorPlan(realPath, imageId),
    onFailure:() => { console.log("Error occured updating case") },
    label: ADD_CASE_FLOOR_PLAN
  });
}

function setDeletedFloorPlan (path, imageId) {
    return {
      type: `DELETE_${path.toUpperCase()}_FLOOR_PLAN`,
      payload: {
        id: imageId
      }
    };
}

export function addProspectus (id, resource) {
  const data = new FormData();
  data.set("file", resource);
  const fileSize = parseInt(resource.size / 1024 / 1024);
  if(fileSize > 20) { 
    return{
      type: API_ERROR,
      payload:{
        message: "Dokumentet må maks være 20MB.",
        response: {
          message: "Dokumentet må maks være 20MB.",
        }
      }
    }
  }else{
    return apiAction({
      url: `cases/${id}/prospectus`,
      headers:{
        "Content-Type": "multipart/form-data"
      },
      data: data,
      method: "POST",
      onSuccess: (data) => setProspectus(data),
      onFailure:() => { console.log("Error occured adding prospectus.") },
      label: `ADD_PROSPECTUS`
    });
  }
}

function setProspectus (data) {
  return {
    type: `SET_CASE_PROSPECTUS`,
    payload: {
      data: data
    }
  };
}

export function deleteProspectus (caseId) {
  return apiAction({
    url: `cases/${caseId}/prospectus/`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    method: "DELETE",
    onSuccess: () => setDeletedProspectus(),
    onFailure:() => { console.log("Error occured deleting prospectus") },
    label: DELETE_CASE_PROSPECTUS
  });
}

function setDeletedProspectus () {
    return {
      type: `DELETE_CASE_PROSPECTUS`,
      payload: {}
    };
}

export function fetchTenancyTypes () {
  let data = {
    business: [
      "Office",
      "Storage",
      "Production",
      "Restaurant",
      "Shop",
      "Hotel",
      "Clinic",
      "Café",
      "Workshop",
      "TakeAway",
      "Showroom",
    ],
    residential: [
      "Apartment",
      "House",
      "Terrace",
    ]
  }
  return {
    type: FETCH_TENANCY_TYPES,
    payload: data
  };
}