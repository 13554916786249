import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { PrimaryButton } from "components/Buttons";

const DeleteCaseModal = ({ isEnabled, onClose, deleteCase }) => {
	return (
		<FullScreenModal
			className="case__delete-case-modal"
			open={isEnabled}
			onClose={onClose}>
			<Typography variant="subtitle">Slet Udlejningssager</Typography>
			<p style={{padding: "16px 0px 16px 0px"}} className="description">
				Er du sikker på du ønsker at slette dette udlejningssager?
			</p>
			<PrimaryButton onClick={deleteCase}>Godkend</PrimaryButton>
		</FullScreenModal>
	);
};

export default DeleteCaseModal;