import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";

const CompanyFields = ({ contractData, updatePropertyContract }) => {

    const [name, setName] = useState(contractData.company.name ?? "");
    const [registrationNumber, setRegistrationNumber] = useState(contractData.company.registrationNumber ?? "");

    const [street, setStreet] = useState(contractData.company.address.street ?? "");
    const [zipCode, setZipCode] = useState(contractData.company.address.zipCode ?? "");
    const [city, setCity] = useState(contractData.company.address.city ?? "");
    const [country, setCountry] = useState(contractData.company.address.country ?? "");

    return (
        <Paper className="property-contract-details__company-bank-fields">
            <Typography variant="subtitle">Ejer informationer</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Navn</Typography>
                    <TextField
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onExit={() => setName(contractData.company.name)}
                        onFinish={() => {
                            name !== contractData.company.name && updatePropertyContract({ company: { name: name } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">CVR-nummer</Typography>
                    <TextField
                        value={registrationNumber}
                        onChange={(event) => setRegistrationNumber(event.target.value)}
                        onExit={() => setRegistrationNumber(contractData.company.registrationNumber)}
                        onFinish={() => {
                            name !== contractData.company.registrationNumber && updatePropertyContract({ company: { registrationNumber: registrationNumber } })
                        }}
                    />
                </div>
            </div>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Gade</Typography>
                    <TextField
                        value={street}
                        onChange={(event) => setStreet(event.target.value)}
                        onExit={() => setStreet(contractData.company.address.street)}
                        onFinish={() => {
                            street !== contractData.company.address.street && updatePropertyContract({ company: { address: { street: street } } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Postnummer</Typography>
                    <TextField
                        value={zipCode}
                        onChange={(event) => setZipCode(event.target.value)}
                        onExit={() => setZipCode(contractData.company.address.zipCode)}
                        onFinish={() => {
                            zipCode !== contractData.company.address.zipCode && updatePropertyContract({ company: { address: { zipCode: zipCode } } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">By</Typography>
                    <TextField
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                        onExit={() => setCity(contractData.company.address.city)}
                        onFinish={() => {
                            city !== contractData.company.address.city && updatePropertyContract({ company: { address: { city: city } } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Land</Typography>
                    <TextField
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}
                        onExit={() => setCountry(contractData.company.address.country)}
                        onFinish={() => {
                            country !== contractData.company.address.country && updatePropertyContract({ company: { address: { country: country } } })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default CompanyFields;