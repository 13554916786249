import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";

const BaseFields = props => {

    const { propertyData } = props;

    return (
        <Paper className="general-information__base-fields">
            <div className="fields">
                <div className="fields-section__field">
                    <Typography variant="label">Titel</Typography>
                    <p className="field-value">{propertyData.title}</p>
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Eksternt Ejendoms ID</Typography>
                    <p className="field-value">{propertyData.integration?.id}</p>
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Lejemål</Typography>
                    <p className="field-value">{propertyData.stats?.occupationRateStats?.total}</p>
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Ledige Lejemål</Typography>
                    <p className="field-value">{propertyData.stats?.occupationRateStats?.available}</p>
                </div>
            </div>
            <div className="graphs">
            </div>
        </Paper>
    );
};

export default BaseFields;