import React from "react";
import FileSelector from "components/FileSelector";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlined";
import "./index.scss";

const ProspectusManager = props => {

    const { data, onDelete, onUpload } = props;

    const uploadHandler = e => {
        onUpload(e[0]);
    }

    return (
        <div className="prospectus-manager-container">
            {data &&
                <div className="document">
                    <a href={data.url} target="_blank"><p className="document__name">Prospekt</p></a>
                    {onDelete &&
                        <DeleteOutlineIcon
                            className="delete-document-icon"
                            onClick={onDelete}
                        />
                    }
                </div>
            }
            {!data &&
                <FileSelector
                    acceptedFileTypes="application/pdf"
                    filesSelected={e => uploadHandler(e)}
                />
            }
        </div>
    );
};

export default ProspectusManager;