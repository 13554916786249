import React, { useEffect } from "react";
import { connect } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "helpers/theme";
import "./App.css";
import "./App.scss";
import { ReactComponent as JoratoIcon } from "assets/icons/jorato-logo.svg";

import { SET_ALL_PROPERTIES, SET_ALL_TENANCIES } from 'redux/actionTypes';
import { getAllProperties } from "helpers/api/properties";
import { getAllTenancies } from "helpers/api/tenancies";
import { fetchAttributes, fetchContactRoles, fetchExpenseTypes, fetchTenancyTypes, fetchOrganizationIntegrations, fetchOrganizationUsers, readMe } from "./redux/actions";
import OrganizationProvider from "features/organization/organization-provider";
import { CircularProgress } from "@material-ui/core";
import UserProvider from "features/user/user-provider";
import Layout from "layout";
import MainRouter from "MainRouter";
import { ToastsProvider } from "features/toasts";
import PropertiesProvider from "features/properties/properties-provider";

export function App(props) {
	const { init, setAllProperties, setAllTenancies } = props

	useEffect(() => {
		init()
	}, [init]);

	useEffect(() => {
		const getData = async () => {
			const properties = await getAllProperties();
			setAllProperties(properties);
			const tenancies = await getAllTenancies();
			setAllTenancies(tenancies);
		}
		getData()
	}, [setAllProperties, setAllTenancies]);

	if (!props.userLoaded || !props.initialDataLoaded) {
		return (
			<div className="app-loading-container">
				<CircularProgress size={50} />
				<JoratoIcon className="jorato-icon" />
			</div>
		);
	}

	return (
		<Router>
			<MuiThemeProvider theme={theme}>
				<OrganizationProvider>
					<UserProvider>
						<PropertiesProvider>
							<ToastsProvider>
								<Layout>
									<MainRouter />
								</Layout>
							</ToastsProvider>
						</PropertiesProvider>
					</UserProvider>
				</OrganizationProvider>
			</MuiThemeProvider>
		</Router>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		init: () => {
			dispatch(fetchOrganizationUsers());
			dispatch(fetchOrganizationIntegrations());
			dispatch(fetchAttributes());
			dispatch(fetchContactRoles());
			dispatch(fetchExpenseTypes());
			dispatch(fetchTenancyTypes());
			dispatch(readMe());
		},
		setAllProperties: (data) => dispatch({ type: SET_ALL_PROPERTIES, payload: data }),
		setAllTenancies: (data) => dispatch({ type: SET_ALL_TENANCIES, payload: data })
	};
};

const mapStateToProps = (state, ownProps) => {
	return {
		error: state.app.error,
		appState: state.app,
		userLoaded: state.general.loaded,
		initialDataLoaded: state.app.attributes.loaded || state.app.contactRoles.loaded || state.app.expenseTypes.loaded,
		attributes: state.app.attributes,
		contactRoles: state.app.contactRoles,
		expenseTypes: state.app.expenseTypes,
		tenancyTypes: state.app.tenancyTypes,
		properties: state.app.properties,
		tenancies: state.app.tenancies
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
