import PrimaryButton from "components/Buttons/PrimaryButton";
import { TextField } from "components/Fields";
import Typography from "components/Typography";
import { useUser } from "features/user";
import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { logout } from "helpers/auth";
import FullScreenModal from "components/FullScreenModal";
import ImageCropper from "./components/ImageCropper";
import { CircularProgress } from "@material-ui/core";
import MSAccountIntegration from "./components/MSAccountIntegration";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";
import PenneoCredentials from "./components/PenneoCredentials/PenneoCredentials";

const UserProfile = props => {

	const { isUserLoading, userData, updateUser, updateUserProfileImage, connectMicrosoftAccount, disconnectMicrosoftAccount, updatePenneoCredentials } = useUser();
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [uploadProfilePictureModal, setUploadProfilePictureModal] = useState(false);
	const [isPictureUploading, setPictureUploading] = useState(false);
	const [msConsetError, setMsConsentError] = useState(false);

	useEffect(() => {
		setName(userData.name ?? "");
		setPhoneNumber(userData.phoneNumber ?? "");
		setEmail(userData.email ?? "")
	}, [userData]);

	useEffect(() => {
		setPictureUploading(false);
	}, [userData.picture]);

	const uploadPictureHandler = (picture) => {
		setUploadProfilePictureModal(false);
		setPictureUploading(true);
		updateUserProfileImage(picture);
	}

	const clearLocalStorage = () => {
		localStorage.clear();
	}

	if (isUserLoading) {
		return (
			<></>
		);
	}

	return (
		<div className="user-profile__container">
			<Typography variant="subtitle">Rediger din profil</Typography>
			{msConsetError &&
				<div className="ms-consent-error">
					<p>Linking your Microsoft account failed. Administrator of your organization has to consent access first
						<a href={`https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MS_REDIRECT_URI}`} target="_blank"> here.</a>
					</p>
					<div className="icon-wrapper"><CloseIcon onClick={() => setMsConsentError(false)} /></div>
				</div>
			}
			<div className="content">
				<div className="user-fields">
					<Typography variant="label">Navn</Typography>
					<div className="user-field">
						<TextField
							value={name}
							onChange={(event) => setName(event.target.value)}
							onExit={() => setName(userData.name ?? "")}
							onFinish={() => {
								updateUser({ name: name })
							}}
						/>
					</div>
					<Typography variant="label">Telefonnummer</Typography>
					<div className="user-field">
						<TextField
							value={phoneNumber}
							onChange={(event) => setPhoneNumber(event.target.value)}
							onExit={() => setPhoneNumber(userData.phoneNumber ?? "")}
							onFinish={() => {
								updateUser({ phoneNumber: phoneNumber })
							}}
						/>
					</div>
					<Typography variant="label">Email</Typography>
					<div className="user-field">
						<TextField
							disabled
							value={email}
						/>
					</div>
					<MSAccountIntegration
						accountConnected={userData.outlookConnected}
						connectAccount={connectMicrosoftAccount}
						disconnectAccount={disconnectMicrosoftAccount}
						setMsConsentError={setMsConsentError}
					/>
					<PenneoCredentials
						connected={userData.penneoConnected}
						update={updatePenneoCredentials}
					/>
					{/* <PrimaryButton style={{ marginBottom: "16px"}} onClick={clearLocalStorage}>Nulstil filtre</PrimaryButton> */}
					<PrimaryButton onClick={logout}>Log ud</PrimaryButton>
				</div>
				<div className="user-profile-picture">
					<Typography variant="label">Profil billede</Typography>
					<img className="profile-picture" alt="Profile" src={userData.picture} />
					<PrimaryButton disabled={isPictureUploading} onClick={() => setUploadProfilePictureModal(true)}>
						Upload billede
						{isPictureUploading && <div className="uploading-container"><CircularProgress size={25} /></div>}
					</PrimaryButton>
				</div>
			</div>
			<FullScreenModal
				className="user-profile__upload-profile-image-modal"
				open={uploadProfilePictureModal}
				onClose={() => setUploadProfilePictureModal(false)}>
				<ImageCropper
					onSubmit={(picture) => uploadPictureHandler(picture)}
				/>
			</FullScreenModal>
		</div>
	);
};

export default UserProfile;