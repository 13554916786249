import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";

const Signer = props => {

	const { contract, updateContract } = props;

	const readonly = contract?.status !== "Negotiation";

	const [name, setName] = useState(contract.signer.name ?? "");
	const [email, setEmail] = useState(contract.signer.email ?? "");


	return (
		<Paper className="application-content__signer">
			<Typography variant="subtitle">Underskriver</Typography>
			<div className="fields">
				<div className="field-group">
					<Typography variant="label">Navn</Typography>
					<TextField
						disabled={readonly}
						value={name}
						onChange={(event) => setName(event.target.value)}
						onExit={() => setName(contract.signer.name)}
						onFinish={() => {
							updateContract({ signer: { name: name } } )
						}}
					/>
				</div>
				<div className="field-group">
					<Typography variant="label">Email</Typography>
					<TextField
						disabled={readonly}
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						onExit={() => setEmail(contract.signer.email)}
						onFinish={() => {
							updateContract({ signer: { email: email } } )
						}}
					/>
				</div>
			</div>
		</Paper>
	)
};

export default Signer;