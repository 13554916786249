import React from 'react';
import "./index.scss";

const ToggleButton = ({ value, onChange, options }) => {
	return (
		<div className="toggle_button_container">
			{
				options.map(option => (
					<div
						className={`toggle_button_option ${value === option.value ? "active" : ""}`}
						key={option.value}
						onClick={() => onChange(option.value)}
					>
						{option.label}
					</div>
				))
			}
		</div>
	);
}

export default ToggleButton;
