import React from "react";
import Typography from "components/Typography";
import { Switch } from "components/Fields";
import InheritedValueIcon from "../InheritedValueIcon";

const InternetUtility = ({ propertyDetails, tenancyDetails, updateTenancyContract }) => {

	const isLandlordResponsible = tenancyDetails.utilities?.internet?.isLandlordResponsible ?? propertyDetails.utilities?.internet?.isLandlordResponsible;
	const isLandlordReponsibleInherited = tenancyDetails.utilities?.internet?.isLandlordResponsible == null || tenancyDetails.utilities?.internet?.isLandlordResponsible == undefined;

    return (
        <>
            <Typography variant="label" className="utility-title">Internet</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Udlejer leverer {isLandlordReponsibleInherited && <InheritedValueIcon />}</Typography>
                    <Switch
                        checked={isLandlordResponsible}
                        onChange={(e) => updateTenancyContract({ utilities: { internet: { isLandlordResponsible: e.target.checked } } })}
                    />
                </div>
            </div>
        </>
    );
};

export default InternetUtility;