import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss'

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from "@material-ui/core/Grid";

import { ReactComponent as ColumnsIcon } from "assets/icons/jorato-columns.svg";
import { ReactComponent as DragIcon } from "assets/icons/jorato-drag.svg";
import {
	Checkbox,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
	modalContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	paper: {
		margin: "auto",
		borderRadius: theme.spacing(3),
		width: 400,
		backgroundColor: theme.palette.background.paper,
		outline: 0,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),

	},
	backdrop: {
		backgroundColor: "rgb(44 53 137 / 70%)"
	}
}));

const ColumnsModal = (props) => {

	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [displayColumns, setDisplayColumns] = useState([]);

	useEffect(() => {
		const activeColumns = props.possibleColumns.filter(x => props.activeColumns.includes(x.accessor)).map((ac) => {
			ac.active = true;
			return ac;
		})
		if (props.possibleColumns.length === props.activeColumns.length) {
			setDisplayColumns(activeColumns)
		} else {
			let disabledColumns = props.possibleColumns.filter((pc) => {
				return !activeColumns.some(ac => ac.accessor === pc.accessor)
			});
			disabledColumns = disabledColumns.map((dc) => {
				dc.active = false;
				return dc
			})
			setDisplayColumns([...activeColumns, ...disabledColumns]);
		}
	}, [open]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const getItemStyle = (isDragging, draggableStyle, isActive) => ({
		// styles we need to apply on draggables
		...draggableStyle,
		backgroundColor: isActive ? "#EEF1FF" : "#F7F7F7",
		margin: "8px 0px",
		color: isActive ? "#000000" : "#A8A8A8",
		borderRadius: "10px",
		...(isDragging && {
			backgroundColor: "#F7F8FD"
		})
		
	});
	

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver ? '#fff' : '#fff',
		overflow: "scroll",
		maxHeight: "80vh"
	});

	const onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(
			displayColumns,
			result.source.index,
			result.destination.index
		);
		setDisplayColumns([...items]);
		const activeColumns = items.filter(e => e.active === true);
		props.setActiveColumns(activeColumns.map(x => x.accessor));
	}

	const handleCheckBoxClick = (accessor) => {
		const temp = displayColumns;
		const index = displayColumns.findIndex(e => e.accessor === accessor)
		temp[index].active = !temp[index].active
		setDisplayColumns([...temp]);
		const activeColumns = temp.filter((e) => e.active === true)
		props.setActiveColumns(activeColumns.map(x => x.accessor));
	}

	return (
		<div className="columns__wrapper">
			<div className="columns_button__wrapper" onClick={() => { handleOpen() }}>
				<ColumnsIcon/> Kolonner
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				className={`columns__modal ${classes.modalContainer}`}
				BackdropProps={{ style: { backgroundColor: 'rgb(44 53 137 / 70%)' } }}
			>
				<Grid
					direction="column"
					className={classes.paper}
					container
					spacing={1}
				>	
					<div className='columns_modal__title'>Ændre kolonner</div>
					<Grid item>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<RootRef rootRef={provided.innerRef}>
										<ul>
											{displayColumns.map((item, index) => (
												<Draggable key={item.accessor} draggableId={item.accessor} index={index}>
													{(provided, snapshot) => (
														<li
															ref= {provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style,
																item.active
															)}
															className={`columns__item ${item.active ? "active" : ""}`}
														>
															<Checkbox
																checked={item.active}
																onChange={(e) => handleCheckBoxClick(item.accessor)}
															/>
															<div className="columns__list-item-text">
																{ item.Header }
															</div>
															<DragIcon />	
														</li>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</ul>
									</RootRef>
								)}
							</Droppable>
						</DragDropContext>
					</Grid>
				</Grid>
			</Modal>
		</div>
	);
}

ColumnsModal.propTypes = {
	possibleColumns: PropTypes.array,
	activeColumns: PropTypes.array,
	setActiveColumns: PropTypes.func,
};

ColumnsModal.defaultProps = {
	possibleColumns: [],
	activeColumns: [],
	setActiveColumns: (e) => { console.log("setActiveColumns is not defined") }
};

export default ColumnsModal;