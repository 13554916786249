import React, { useMemo } from 'react';
import './index.scss'
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

const ShowcasingsGroup = (props) => {
	
	const { group } = props;
	const history = useHistory()

	const getFormattedDayOfMonth = (input) => {
		const date = new Date(input);
		const day = `0${date.getDate()}`.slice(-2);
		return day;
	}
	const getFormatedDayOfWeek = (input) => {
		const date = new Date(input);
		const dayOfWeek = date.toLocaleString('da-dk', {  weekday: 'short' });
		return dayOfWeek;
	};

	const getTime = (input) => {
		const date = new Date(input);
		const time = date.toLocaleString('da-dk', {  hour: '2-digit', minute: '2-digit' });
		return time;
	};
	const handleClick = (id) => {
		history.push(`/showcasings/${id}`);
	}

	return (
		<div className="showcasing-group">
			<div className="title">{group.label}</div>
			{group.data.map((day, key, array) => (
				<div className={`group_container ${ array.length - 1 === key ? "last" : "" }`} key={key}>
					<div className="date_container">
						<div className="marker"></div>
						<div className="day_container">
							<div className="weekday">{getFormatedDayOfWeek(day.date)}</div>
							<div className="monthday">{getFormattedDayOfMonth(day.date)}</div>
						</div>
					</div>
					<div className="showcasings_container">
						{day.data.map((item, key) => (
							<div key={key} className="showcasing" onClick={() => handleClick(item.id)}>
								<div className="start-time">{getTime(item.schedule.startsAt)}</div>
								<div className="host">{item.hostName}</div>
								<div className="tenancy">{item.locationName}</div>
								<div className="guest">{item.guest.name}</div>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};


ShowcasingsGroup.propTypes = {
	data: PropTypes.array,
  };
  
  ShowcasingsGroup.defaultProps = {
	data: [],
};

export default ShowcasingsGroup;