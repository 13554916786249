import React from 'react'
import '../index.scss'
import LocationSection from './LocationSection'
import GuestSection from './GuestSection'
import VirtualMeeting from './VirtualMeeting'
import AlternativeMeetingLocation from './AlternativeMeetingLocation'
import { PrimaryButton } from 'components/Buttons'
import states from '../states'
import Typography from 'components/Typography'
import { ReactComponent as SuccessIcon } from 'assets/icons/jorato-showcasing-created.svg'

const Content = ({
  employeeSelectOptions,
  handleBack,
  handleCreate,
  handleUpdate,
  handleDelete,
  submitDisabled,
  state,
  dispatch,
  hostOutlookEnabled,
  isEditing,
  hasHostChanged,
  hasUpdates
}) => {
  if (state.step === states.input) {
    return (
      <div className='content'>
        <LocationSection
          state={state}
          dispatch={dispatch}
          employeeSelectOptions={employeeSelectOptions}
          isEditing={isEditing}
        />
        <div className='bottom-container'>
          <GuestSection state={state} dispatch={dispatch} />

          <div className='misc'>
            <VirtualMeeting state={state} dispatch={dispatch} />
            <AlternativeMeetingLocation state={state} dispatch={dispatch} />
            <div className='buttons'>
              {isEditing && (
                <PrimaryButton
                  onClick={() =>
                    dispatch({ type: 'SET_STEP', payload: states.confirm_delete })
                  }
                  className='no-margin danger'
                >
                  Slet
                </PrimaryButton>
              )}
              <PrimaryButton onClick={handleBack} className='no-margin'>
                Annuller
              </PrimaryButton>
              <PrimaryButton
                onClick={() =>
                  dispatch({
                    type: 'SET_STEP',
                    payload: isEditing
                      ? states.confirm_update
                      : states.confirm_creation
                  })
                }
                className='no-margin inversed'
                disabled={submitDisabled}
              >
                Fortsæt
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const showSubtitle = () => {
    if (state.guestPhoneNumber || hostOutlookEnabled || state.guestEmail) {
      return true
    }
    return false
  }
  if (state.step === states.confirm_creation) {
    return (
      <div className='confirm-creation-step'>
        <div className='container'>
          <Typography className='pb30' variant='title'>
            Klar til at oprette fremvisningen?
          </Typography>
          {showSubtitle() && (
            <p>Når du opretter fremvisningen sker følgende:</p>
          )}
          <ul>
            {state.guestPhoneNumber && (
              <li>En SMS bekræftelse sendes til deltageren</li>
            )}
            {state.guestPhoneNumber && (
              <li>
                En SMS påmindelse sendes til deltageren 3 timer før
                fremvisningen
              </li>
            )}
            {hostOutlookEnabled && (
              <li>Fremvisningen oprettes i fremvisers kalender</li>
            )}
            {state.guestEmail && (
              <li>
                Deltageren modtager en e-mail invitation til fremvisningen
              </li>
            )}
          </ul>
          <div className='buttons'>
            <PrimaryButton
              onClick={() =>
                dispatch({ type: 'SET_STEP', payload: states.input })
              }
              className='no-margin outline'
            >
              Tilbage
            </PrimaryButton>
            <PrimaryButton
              onClick={handleCreate}
              className='no-margin inversed'
            >
              Opret
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
  if (state.step === states.confirm_update) {
    return (
      <div className='confirm-update-step'>
        <div className='container'>
          <Typography className='pb30' variant='title'>
            Klar til at opdatere fremvisningen?
          </Typography>
          {showSubtitle() && (
            <p>Når du opdatere fremvisningen sker følgende:</p>
          )}
          <ul>
            {state.guestPhoneNumber && (
              <li>En SMS med opdaterede informtioner sendes til deltageren</li>
            )}
            {state.guestEmail && (
              <li>
                Deltageren modtager en opdatering til e-mail invitation til
                fremvisningen
              </li>
            )}
            {hasHostChanged && (
              <>
                <li>
                  Den oprindelig kalender invitation bliver slettet og fjernet
                  fra Xs kalender.
                </li>
                <li>Deltager modtager an annullering fra X.</li>
                <li>Deltager modtager en ny invitation fra Y.</li>
              </>
            )}
          </ul>
          <div className='buttons'>
            <PrimaryButton
              onClick={() =>
                dispatch({ type: 'SET_STEP', payload: states.input })
              }
              className='no-margin outline'
            >
              Tilbage
            </PrimaryButton>
            <PrimaryButton
              onClick={handleUpdate}
              className='no-margin inversed'
            >
              Opdater
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
  if (state.step === states.confirm_delete) {
    return (
      <div className='confirm-delete-step'>
        <div className='container'>
          <Typography className='pb30' variant='title'>
            Du er ved at slette en fremvisningen
          </Typography>
          {showSubtitle() && (
            <p>Når du sletter fremvisningen sker følgende:</p>
          )}
          <ul>
            {state.guestPhoneNumber && (
              <li>En SMS med besked om at fremvisningen er annulleret sendes til deltageren</li>
            )}
            {state.guestEmail && (
              <li>
                Deltageren modtager en anullering til e-mail invitation til
                fremvisningen
              </li>
            )}
			{state.hostOutlookEnabled && (
              <li>
                Outlook eventet slettes i fremvisers kalender
              </li>
            )}
          </ul>
          <div className='buttons'>
            <PrimaryButton
              onClick={() =>
                dispatch({ type: 'SET_STEP', payload: states.input })
              }
              className='no-margin outline'
            >
              Tilbage
            </PrimaryButton>
            <PrimaryButton
              onClick={handleDelete}
              className='no-margin danger'
            >
              Slet
            </PrimaryButton>
          </div>
        </div>
      </div>
    )
  }
  if (state.step === states.success) {
    return (
      <div className='success-step'>
        <SuccessIcon />
        <Typography variant='title'>
          Fremvisningen er {isEditing ? 'opdateret' : 'oprettet'}
        </Typography>
        <p>Du kan se detaljerne for fremvisnigen via oversigtsiden.</p>
        <PrimaryButton onClick={handleBack} className='no-margin inversed'>
          Til oversigten
        </PrimaryButton>
      </div>
    )
  }
  if (state.step === states.deleted) {
    return (
      <div className='deleted-step'>
        <SuccessIcon />
        <Typography variant='title'>Fremvisningen er slettet</Typography>
        <p>Den er fjernet når du vender tilbage til oversigtsiden.</p>
        <PrimaryButton onClick={handleBack} className='no-margin inversed'>
          Til oversigten
        </PrimaryButton>
      </div>
    )
  } else {
    return null
  }
}

export default Content
