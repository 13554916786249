import React from "react";
import "./index.scss";
import { Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { ReactComponent as ShowcasingsIcon } from "assets/icons/jorato-showcasing.svg";

const Reports = () => {

	return (
		<div className="reports-container">
			<Grid className item xs={12}>
				<Typography color="primary" variant="h1" gutterBottom>
					Reporter
				</Typography>
        	</Grid>
			<div className="reports-list-container">
				<Link to="reports/employee-showcasings">
					<div className="report-link">
						<div className="report-image">
							<ShowcasingsIcon />
						</div>
						<div className="report-description">
							<Typography className="report-title" variant="label">Fremvisninger per medarbejder</Typography>
							<div className="report-labels">
								<div className="report-label">Fremvisninger</div>
								<div className="report-label">Medarbejdere</div>
							</div>
						</div>
					</div>
				</Link>
				<Link to="reports/tenancy-showcasings">
					<div className="report-link">
						<div className="report-image">
							<ShowcasingsIcon />
						</div>
						<div className="report-description">
							<Typography className="report-title" variant="label">Fremvisninger per ejendom</Typography>
							<div className="report-labels">
								<div className="report-label">Fremvisninger</div>
								<div className="report-label">Ejendomme</div>
							</div>
						</div>
					</div>
				</Link>
				<Link to="reports/organization">
					<div className="report-link">
						<div className="report-image">
							<ShowcasingsIcon />
						</div>
						<div className="report-description">
							<Typography className="report-title" variant="label">Månedlig statusrapport</Typography>
							<div className="report-labels">
								<div className="report-label">Organisation</div>
								<div className="report-label">Ejendomme</div>
							</div>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Reports;