import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import "./index.scss";

const Descriptions = props => {

    const { data, update } = props;

    const [tenancyDescription, setTenancyDescription] = useState(data.description ?? "");

    return (
        <Paper className="general-information__descriptions">
            <Typography variant="subtitle">Beskrivelser</Typography>
            <div className="description-fields">
                <div className="fields-section__field">
                    <Typography variant="label">Lejemalsbeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={10}
                        rowsMax={10}
                        value={tenancyDescription}
                        disabled={data.deletedAt}
                        onChange={(event) => setTenancyDescription(event.target.value)}
                        onExit={() => setTenancyDescription(data.descriptions.tenancy)}
                        onFinish={() => {
                            tenancyDescription !== data.descriptions && update({ description: tenancyDescription })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default Descriptions;