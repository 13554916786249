import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import HeatingUtility from "./HeatingUtility";
import WaterUtility from "./WaterUtility";
import ElectricityUtility from "./ElectricityUtility";
import CoolingUtility from "./CoolingUtility";
import AntennaUtility from "./AntennaUtility";
import InternetUtility from "./InternetUtility";

const UtilitiesFields = ({ contractData, updatePropertyContract }) => {
    return (
        <Paper className="property-contract-details__utilities-fields">
            <Typography variant="subtitle">Forbrug</Typography>
            <HeatingUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
            <WaterUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
            <ElectricityUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
            <CoolingUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
            <AntennaUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
            <InternetUtility contractDetails={contractData} updatePropertyContract={updatePropertyContract} />
        </Paper>
    );
};

export default UtilitiesFields;