import React from 'react';
import { ReactComponent as PropertiesIcon } from "assets/icons/jorato-properties.svg";

const InheritedValueIcon = () => {
	return (
		<div className="inherited_value_icon">
			<PropertiesIcon />
			<div className="inherited_tip">Værdi er sat på ejendomsniveau.</div>
		</div>
	);
};

export default InheritedValueIcon;