import React, { useState } from "react";
import "./index.scss";
import { Link, matchPath, useLocation } from 'react-router-dom';
import GlobalSearch from "../Navigation/Components/GlobalSearch";
import { useUser } from "features/user";

export default function TopBar(props) {
	
	const location = useLocation()
	const { isUserLoading, userData } = useUser();

	return (
		<div className="top-bar-container">
			<GlobalSearch/>
			<Link to="/profile">
				{ !isUserLoading && (
					<div className="user-avatar">
						<img src={userData.picture} />
					</div>
				)}
			</Link>
			
		</div>	
	);
}
