import UserContext from './user-context';
import React, { useState, useEffect, useCallback } from "react";
import initialUserState from './user-state';
import { Auth0Client } from "helpers/auth";
import EmployeesService from 'services/api/employees';

const UserProvider = props => {

    const { children } = props;
    const [userData, setUserData] = useState(initialUserState);
    const [isUserLoading, setUserLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const result = await Auth0Client.getUser();
            const employeeResult = await EmployeesService.getCurrentEmployee();
            setUserData({ ...result, ...employeeResult.data });
            setUserLoading(false);
        })();
    }, []);

    const updateUser = useCallback(async (data) => {
        const result = await EmployeesService.updateCurrentEmployee(data);

        if (result !== null) {
            setUserData({
                ...result.data,
            });
        }
    }, [userData]);

    const updateUserProfileImage = useCallback(async (image) => {
        const result = await EmployeesService.updateCurrentEmployeeProfileImage(image);
        if (result !== null) {
            setUserData({
                ...userData,
                ...result.data,
            });
        }
    }, [userData]);

	const connectMicrosoftAccount = useCallback(async (idToken) => {
        const result = await EmployeesService.connectCurrentEmployeeADAccount(idToken);
		if (result?.status === 200) {
			setUserData({
				...userData,
				outlookConnected: true
			});
			return true;
		}

		return false;
    }, [userData]);

	const disconnectMicrosoftAccount = useCallback(async () => {
        const result = await EmployeesService.disconnectCurrentEmployeeADAccount();
		if (result.status === 200) {
			setUserData({
				...userData,
				outlookConnected: false
			});
		}
    }, [userData]);

	const updatePenneoCredentials = useCallback(async (key, secret) => {
        const result = await EmployeesService.updateEmployeePenneoCredentials(key, secret);
		if (result) {
			setUserData({
				...userData,
				penneoConnected: true
			});
			return true;
		}

		return false;
    }, [userData]);

    return (
        <UserContext.Provider
            value={{
				isUserLoading: isUserLoading,
                userData: userData,
                updateUser: updateUser,
                updateUserProfileImage: updateUserProfileImage,
				connectMicrosoftAccount: connectMicrosoftAccount,
				disconnectMicrosoftAccount: disconnectMicrosoftAccount,
				updatePenneoCredentials: updatePenneoCredentials
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;