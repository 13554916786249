import React, { useState, useEffect } from 'react'
import './index.scss'
import { usePropertiesSearch } from 'hooks/api'
import { useMemorizedTableSettings } from 'hooks/memory'
import { useHistory } from 'react-router-dom'
import Typography from 'components/Typography'
import Table from 'components/Table'
import ColumnsModal from 'components/ColumnsModal'
import HeaderContainer from 'components/HeaderContainer'
import ChipSlim from 'components/ChipSlim'
import SearchInput from 'components/SearchInput'
import FilterModal from 'components/FilterModal'
import FilterChips from 'components/FilterChips'
import columns from './columns'
import './index.scss'

const Properties = props => {
  const {
    enabledColumns,
    enabledFilters,
    enabledSorting,
    saveEnabledFilters,
    saveEnabledColumns,
    saveEnabledSorting,
    isLoading: isLoadingTableSettings
  } = useMemorizedTableSettings(`properties`)
  const {
    isPropertiesLoading,
    propertiesData,
    propertiesMetaData,
    loadProperties,
    query,
    setQuery,
    setPageIndex,
    pageIndex,
    filters,
    setFilters,
    setSorting,
    sorting,
    setColumns,
	setInitiationCompleted
  } = usePropertiesSearch()
  const history = useHistory()
  const [activeColumns, setActiveColumns] = useState([])

  const addFilter = (accessor, value) => {
    const newValue = [...filters, { accessor: accessor, value: value }]
    setFilters(newValue)
    saveEnabledFilters(newValue)
  }

  useEffect(() => {
    if (!isLoadingTableSettings) {
      setActiveColumns(
        enabledColumns.length > 0
          ? enabledColumns
          : columns.filter(x => x.isDefault).map(x => x.accessor)
      )
      setFilters(enabledFilters)
      setSorting(enabledSorting)
      setColumns(columns)
      setInitiationCompleted(true)
    }
  }, [isLoadingTableSettings])

  const handleRowClick = (e, row) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/properties/${row.original.id}`, '_blank')
    } else {
      history.push(`/properties/${row.original.id}`)
    }
  }

  const changeFilters = filters => {
    setFilters(filters)
    saveEnabledFilters(filters)
  }

  const changeActiveColumns = newColumns => {
    setActiveColumns(newColumns)
    saveEnabledColumns(newColumns)
  }

  const changeSorting = newSorting => {
    setSorting(newSorting)
    saveEnabledSorting(newSorting)
  }

  if (isPropertiesLoading) {
    return <div>Loading</div>
  }

  return (
    <div className='properties-list-container'>
      <div className='properties-list-header'>
        <HeaderContainer>
          <Typography variant='title'>Ejendomme</Typography>
          <ChipSlim label={propertiesMetaData.TotalProperties} />
        </HeaderContainer>
        <div className='table-bar-container'>
          <div className='search'>
            <SearchInput value={query} setQuery={setQuery} />
          </div>
          <div className='options'>
            <FilterModal
              addFilter={(label, accessor, type, value) =>
                addFilter(accessor, value)
              }
              possibleFilters={columns}
              data={propertiesData}
            />
            {/* <ColumnsModal
				possibleColumns={columns}
				activeColumns={activeColumns}
				setActiveColumns={changeActiveColumns}
			/> */}
          </div>
        </div>
      </div>
      <div className='filters'>
        <FilterChips
          columns={columns}
          filters={filters}
          setFilters={changeFilters}
        />
      </div>
      <div className='properties-list-table'>
        <Table
          activeColumns={activeColumns}
          columns={columns}
          data={propertiesData}
          pageLimit={propertiesMetaData.PageLimit}
          total={propertiesMetaData.TotalProperties}
          offset={propertiesMetaData.Offset}
          fetchData={loadProperties}
          onRowClick={handleRowClick}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
          setSorting={changeSorting}
          sorting={sorting}
        />
      </div>
    </div>
  )
}

export default Properties
