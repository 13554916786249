import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from "../TextField/TextField"

const MoneyField = React.forwardRef((props, ref) => {

	const { value, disabled, onChange, onFinish, onExit, placeholder, defaultValue, endAdornment, className } = props;

	return (
		<NumberFormat
			thousandSeparator="."
			decimalSeparator=","
			allowNegative={false}
			decimalScale={2}
			fixedDecimalScale={true}
			disabled={disabled}
			value={value}
			placeholder={placeholder}
			ref={ref}
			onValueChange={(values) => {
				onChange(values.floatValue)
			}}
			customInput={TextField}
			onFinish={onFinish}
			onExit={onExit}
			endAdornment={endAdornment}
			defaultValue={defaultValue}
			className={className}
		/>
	);
});

export default MoneyField;