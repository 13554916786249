import React from "react";
import Contacts from "pages/components/Contacts";
import Descriptions from "./components/Descriptions";
import Files from "pages/components/Files";
import { useOrganization } from "features/organization";
import { Facilities, Terms } from "pages/components/Attributes";
import Location from "./components/Location";
import BaseFields from "./components/BaseFields";

const GeneralInformationTab = props => {

    const { propertyData, updateProperty, uploadPropertyImages, deletePropertyImage,
        changePropertyImageOrder, uploadPropertyDocument, deletePropertyDocument,
        createPropertyContact, removePropertyContact, updatePropertyAttribute, removePropertyAttribute,
        createPropertyAttribute } = props;

    const { attributeDefinitions } = useOrganization();

    return (
        <>
            <BaseFields propertyData={propertyData} />
            <Location propertyData={propertyData} />
            <Descriptions
                propertyData={propertyData}
                updateProperty={updateProperty} />
            <Files
                images={propertyData.images}
                uploadImages={uploadPropertyImages}
                deleteImage={deletePropertyImage}
                changeImageOrder={changePropertyImageOrder}
                documents={propertyData.documents}
                uploadDocument={uploadPropertyDocument}
                deleteDocument={deletePropertyDocument} />
            <Contacts
                contacts={propertyData.contacts}
                createContact={createPropertyContact}
                removeContact={removePropertyContact} />
            <Facilities
                facilities={propertyData.attributes.filter(x => x.definition.group.text === "Facilities")}
                updateAttribute={updatePropertyAttribute}
                removeAttribute={removePropertyAttribute}
                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Facilities")}
                createAttribute={createPropertyAttribute}
            />
            <Terms
                terms={propertyData.attributes.filter(x => x.definition.group.text === "Terms")}
                updateAttribute={updatePropertyAttribute}
                removeAttribute={removePropertyAttribute}
                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Terms")}
                createAttribute={createPropertyAttribute}
            />
        </>
    )
};

export default GeneralInformationTab;