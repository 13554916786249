import { format } from "date-fns";
import { da } from 'date-fns/locale';

const formatDate = (date, dateFormat) => {
	try
	{
		return format(new Date(date), dateFormat, { locale: da });
	} catch (error) {
		console.log(date, "There's been a problem with parsing a date", date)
		return "";
	}
};

export { formatDate }