import React, { useRef, useEffect } from "react";
import Chart from 'chart.js/auto';

const StackedChart = props => {

	const canvas = useRef(null);

	const defaultColors = ['#2C3589', '#566FFF', '#8092ff', '#99a7ff', '#c7cfff'];

	useEffect(() => {
		if (!canvas.current)
			return;

		new Chart(canvas.current, {
			type: 'line',
			data: {
				labels: props.labels,
				datasets: props.datasets,
			},
			options: {
				maintainAspectRatio: false,
				responsive: true,
				plugins: {
					title: {
						display: false
					},
					legend: {
						display: !props.hideLegend
					}
				},
				scales: props.scales
			}
		});
	}, [canvas]);

	return (
		<canvas ref={canvas} ></canvas>
	);
};

export default StackedChart;