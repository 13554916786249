import { SET_USER, UPDATE_PROFILE_PICTURE, UPDATE_ME, READ_ME } from "redux/actionTypes";

const general = (
  state = {
    loaded: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case SET_USER:
      return {
        loaded: true,
        user: action.payload,
      };
    case UPDATE_ME:
      return {
        loaded: true,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case READ_ME:
      return {
        loaded: true,
        user: action.payload
      }
    case UPDATE_PROFILE_PICTURE:
      return {
        loaded: true,
        user: {
          ...state.user,
          picture: action.payload
        }
      }
    default:
      return state;
  }
};

export default general;
