import axios from "./axios";

export default class OpenhousesService {
	static async getOpenhouse(openHouseId) {
		try {
			const result = await axios.get(`open-houses/${openHouseId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getAllTenancyOpenhouses(tenancyId) {
		try {
			const result = await axios.get(`tenancies/${tenancyId}/open-houses/all`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updateOpenhouse(openHouseId, data) {
		try {
			const result = await axios.patch(`open-houses/${openHouseId}`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async deleteOpenhouse(openHouseId) {
		try {
			const result = await axios.delete(`open-houses/${openHouseId}`);
			return result;
		} catch (err) {
			return null;
		}
	}
}