import axios from "./axios";

export default class EmployeesService {
    static async getCurrentEmployee() {
        try {
            const result = await axios.get(`employees/me`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateCurrentEmployee(data) {
        try {
            const result = await axios.patch(`employees/me`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateCurrentEmployeeProfileImage(image) {
        try {
            const data = new FormData();
            data.append('file', image);

            const result = await axios.patch(`employees/me/picture`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async connectCurrentEmployeeADAccount(idToken) {
        try {
            const result = await axios.post(`employees/me/azure-directory/connect`, { idToken: idToken });
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async disconnectCurrentEmployeeADAccount() {
        try {
            const result = await axios.post(`employees/me/azure-directory/disconnect`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async updateEmployeePenneoCredentials(key, secret) {
        try {
            const result = await axios.patch(`employees/me/penneo-credentials`, { clientKey: key, secret: secret });
            return result;
        }
        catch (err) {
            return null;
        }
    }


}