import React, { useState, useEffect } from "react";
import "./index.scss";
import Tabs, { Tab } from "components/Tabs"
import useProperty from "hooks/api/useProperty";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/LoadingComponent";
import GeneralInformationTab from "./components/GeneralInformationTab/GeneralInformationTab";
import PropertyActions from "./components/PropertyActions";
import ContractDetailsTab from "./components/ContractDetailsTab/ContractDetailsTab";
import InterestListTab from "components/InterestListTab/InterestListTab";

const Property = props => {

	const [tab, setTab] = useState(0);
	const { isPropertyLoading, propertyData, propertyContractData, loadProperty, uploadPropertyImages, deletePropertyImage,
		changePropertyImageOrder, uploadPropertyDocument, deletePropertyDocument, updateProperty,
		createPropertyContact, removePropertyContact, updatePropertyAttribute, removePropertyAttribute,
		createPropertyAttribute, updatePropertyContract, loadInterestList, isInterestListLoading, interestListData } = useProperty();

	const params = useParams();

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		loadProperty(params.id);
	}, [params.id, loadProperty]);

	if (isPropertyLoading) {
		return (
			<div className="loading-container"><LoadingComponent /></div>
		);
	}

	if (!propertyData) {
		return (
			<div className="property-container">Error</div>
		);
	}

	return (
		<div className="property-container">
			<div className="property-header">
				<Tabs className="tabs-navigation" value={tab} onChange={handleTabChange} aria-label="property tabs navigation">
					<Tab label="Generel information" />
					<Tab label="Kontrakt information" />
					<Tab label="Interesseliste" />
				</Tabs>
				<PropertyActions propertyData={propertyData} />
			</div>

			<div className="tab-content">
				{tab === 0 &&
					<GeneralInformationTab
						propertyData={propertyData}
						updateProperty={updateProperty}
						uploadPropertyImages={uploadPropertyImages}
						deletePropertyImage={deletePropertyImage}
						changePropertyImageOrder={changePropertyImageOrder}
						uploadPropertyDocument={uploadPropertyDocument}
						deletePropertyDocument={deletePropertyDocument}
						createPropertyContact={createPropertyContact}
						removePropertyContact={removePropertyContact}
						updatePropertyAttribute={updatePropertyAttribute}
						removePropertyAttribute={removePropertyAttribute}
						createPropertyAttribute={createPropertyAttribute} />
				}
				{tab === 1 &&
					<ContractDetailsTab
						propertyData={propertyData}
						propertyContractData={propertyContractData}
						updatePropertyContract={updatePropertyContract}
					/>
				}
				{tab === 2 &&
					<InterestListTab
						load={loadInterestList}
						isLoading={isInterestListLoading}
						data={interestListData}
					/>
				}
			</div>
		</div>
	);
};

export default Property;