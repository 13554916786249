import axios from "./axios";

export default class ContractsService {

	static async getApplication(applicationId) {
		try {
			const result = await axios.get(`applications/${applicationId}`);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async sendApplicatonLink(name, email, caseId) {
		try {
			const result = await axios.post("applications/send-link", {
				applicantName: name,
				applicantEmail: email,
				caseId: caseId
			});
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async getContractPdf(contractId) {
		try {
			const result = await axios.get(`${process.env.REACT_APP_API_URL}/contracts/${contractId}/pdf`, {
				responseType: "blob"
			});

			return result.data;
		} catch {
			return null;
		}
	}

	static async getApplicationContract(applicationId) {
		try {
			const result = await axios.get(`applications/${applicationId}/contract`);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async selectApplication(applicationId) {
		try {
			const result = await axios.post(`applications/${applicationId}/select`);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async screenApplicant(applicationId, applicantId) {
		try {
			const result = await axios.post(`applications/${applicationId}/screen-applicant/${applicantId}`);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async rejectContract(contractId) {
		try {
			const result = await axios.post(`contracts/${contractId}/reject`);
			if (result.status >= 200 && result.status <= 299)
				return true;

			return false;
		}
		catch {
			return false;
		}
	}

	static async removeContractFromPenneo(contractId) {
		try {
			const result = await axios.post(`contracts/${contractId}/remove-from-penneo`);
			if (result.status >= 200 && result.status <= 299)
				return true;

			return false;
		}
		catch {
			return false;
		}
	}


	/*
		Sends contract with given id to Penneo 
	*/
	static async sendContract(contractId) {
		try {
			const result = await axios.post(`contracts/${contractId}/send`);
			if (result.status >= 200 && result.status <= 299)
				return { success: true, error: null };

			return { success: false, error: result.response.data };
		}
		catch (error) {
			return { success: false, error: error.response.data };
		}
	}

	static async addContractPropertyFacility(contractId, facility) {
		try {
			const result = await axios.post(`contracts/${contractId}/data/property/facilities/${facility}`);
			if (result.status !== 200)
				return false;

			return true;
		}
		catch {
			return false;
		}
	}

	static async removeContractPropertyFacility(contractId, facility) {
		try {
			const result = await axios.delete(`contracts/${contractId}/data/property/facilities/${facility}`);
			if (result.status !== 200)
				return false;

			return true;
		}
		catch {
			return false;
		}
	}

	static async addContractTenancyFacility(contractId, facility) {
		try {
			const result = await axios.post(`contracts/${contractId}/data/tenancy/facilities/${facility}`);
			if (result.status !== 200)
				return false;

			return true;
		}
		catch {
			return false;
		}
	}

	static async removeContractTenancyFacility(contractId, facility) {
		try {
			const result = await axios.delete(`contracts/${contractId}/data/tenancy/facilities/${facility}`);
			if (result.status !== 200)
				return false;

			return true;
		}
		catch {
			return false;
		}
	}

	static async getPayment(contractId) {
		try {
			const result = await axios.get(`/contracts/${contractId}/payment`);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async sendPayment(contractId) {
		try {
			const result = await axios.post(`/contracts/${contractId}/payment`);
			return result.data;
		}
		catch {
			return null;
		}
	}

	static async updateContract(contractId, data) {
		try {
			const result = await axios.patch(`contracts/${contractId}`, data);
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}

	static async updateContractData(contractId, data) {
		try {
			const result = await axios.patch(`contracts/${contractId}`, { data: data });
			if (result.status !== 200)
				return null;

			return result.data;
		}
		catch {
			return null;
		}
	}
}

export const getApplication = async (applicationId) => {
	try {
		const result = await axios.get(`applications/${applicationId}`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const getApplicationContract = async (applicationId) => {
	try {
		const result = await axios.get(`applications/${applicationId}/contract`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const selectApplication = async (applicationId) => {
	try {
		const result = await axios.post(`applications/${applicationId}/select`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const rejectContract = async (contractId) => {
	try {
		const result = await axios.post(`contracts/${contractId}/reject`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const sendContract = async (contractId) => {
	try {
		const result = await axios.post(`contracts/${contractId}/send`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const addContractPropertyFacility = async (contractId, facility) => {
	try {
		const result = await axios.post(`contracts/${contractId}/data/property/facilities/${facility}`);
		if (result.status !== 200)
			return false;

		return true;
	}
	catch {
		return false;
	}
}

export const removeContractPropertyFacility = async (contractId, facility) => {
	try {
		const result = await axios.delete(`contracts/${contractId}/data/property/facilities/${facility}`);
		if (result.status !== 200)
			return false;

		return true;
	}
	catch {
		return false;
	}
}

export const addContractTenancyFacility = async (contractId, facility) => {
	try {
		const result = await axios.post(`contracts/${contractId}/data/tenancy/facilities/${facility}`);
		if (result.status !== 200)
			return false;

		return true;
	}
	catch {
		return false;
	}
}

export const removeContractTenancyFacility = async (contractId, facility) => {
	try {
		const result = await axios.delete(`contracts/${contractId}/data/tenancy/facilities/${facility}`);
		if (result.status !== 200)
			return false;

		return true;
	}
	catch {
		return false;
	}
}

export const getPayment = async (contractId) => {
	try {
		const result = await axios.get(`/contracts/${contractId}/payment`);
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}

export const sendPayment = async (contractId) => {
	try {
		const result = await axios.post(`/contracts/${contractId}/payment`);
		return result.data;
	}
	catch {
		return null;
	}
}

export const updateContractData = async (contractId, data) => {
	try {
		const result = await axios.patch(`contracts/${contractId}`, { data: data });
		if (result.status !== 200)
			return null;

		return result.data;
	}
	catch {
		return null;
	}
}
