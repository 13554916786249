import React from "react";
import StyledPaper from "components/StyledPaper";
import Typography from "components/Typography";
import "./index.scss";
import ExtendedTable from "components/ExtendedTable";
import { useOrganization } from "features/organization";
import Columns from "./columns";

const Users = () => {

	const { employees, isLoading } = useOrganization();

	return (
		<div className="users-list__container">
			<Typography variant="title">Brugere</Typography>
			<StyledPaper>
				<ExtendedTable
					isLoading={isLoading}
					data={employees}
					columns={Columns}
					defaultSortBy={[{
						id: 'date',
						desc: true
					},
					{
						id: 'time',
						desc: false
					}]}
				/>
			</StyledPaper>
		</div>
	);
};

export default Users;