import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./index.scss";
import { PrimaryButton } from "components/Buttons";

export default function ImageCropper(props) {
	const [image, setImage] = useState(undefined);
	const [cropData, setCropData] = useState(null);
	const [cropper, setCropper] = useState(undefined);
	const inputRef = useRef(null);

	const onChange = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.readAsDataURL(files[0]);
		reader.onload = () => {
			setImage(reader.result);
		};
	};

	const dataURLtoFile = (dataurl, filename) => {
		let arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		const croppedImage = new File([u8arr], filename, { type: mime });
		return croppedImage;
	}

	function getCropData() {
		if (cropper !== "undefined") {
			setCropData(cropper.getCroppedCanvas().toDataURL());
		}
		return dataURLtoFile(cropper.getCroppedCanvas().toDataURL(), "avatar");
	};

	return (
		<div className="image-cropper-container">
			<input id="addImageInput" style={{ display: "none" }} type="file" onChange={onChange} ref={inputRef} />
			<PrimaryButton className="select-image-button" onClick={() => inputRef.current.click()}>Vælg billede</PrimaryButton>
			<Cropper
				style={{ width: "100%", maxWidth: "400px" }}
				initialAspectRatio={1}
				aspectRatio={1}
				src={image}
				viewMode={1}
				guides={true}
				minCropBoxHeight={10}
				minCropBoxWidth={10}
				background={false}
				responsive={true}
				autoCropArea={1}
				checkOrientation={false}
				onInitialized={(instance) => {
					setCropper(instance);
				}}
			/>
			{image && <PrimaryButton className="confirm-image-button" onClick={() => props.onSubmit(getCropData())}>Godkend</PrimaryButton>}
		</div>
	);
}
