import { useState, useCallback } from "react";
import CasesService from 'services/api/cases';
import produce from "immer";
import TenanciesService from "services/api/tenancies";

/*
    useCase hook handles case operations. 
    The only requirement for it is to load case first, before doing operations.
*/
const useCase = () => {

    const [isCaseLoading, setCaseLoading] = useState(true);
    const [caseData, setCaseData] = useState(null);
    const [isCaseIntegrationProcessing, setCaseIntegrationProcessing] = useState(false);

    const loadCase = useCallback(async (caseId) => {
        const result = await CasesService.getCase(caseId);
        if (result !== null) {
            const commentsResult = await CasesService.getCaseComments(caseId);
            const integrationsResult = await CasesService.getCaseIntegrations(caseId);
            const applicationsResult = await CasesService.getCaseApplications(caseId);
            setCaseData({
                ...result.data,
                comments: commentsResult?.data || [],
                integrations: integrationsResult?.data,
                applications: applicationsResult?.data || []
            });
        }
        setCaseLoading(false);
    }, []);

    const updateCase = useCallback(async (data) => {
        if (caseData == null)
            return;

        const result = await CasesService.updateCase(caseData.id, data);

        if (result !== null) {
            setCaseData({
                ...caseData,
                ...result.data,
            });
        }
    }, [caseData]);

    const deleteCase = useCallback(async (data) => {
        if (caseData == null)
            return;

        const result = await CasesService.deleteCase(caseData.id);
        const caseResult = await CasesService.getCase(caseData.id);

        if (result !== null && caseResult !== null) {
            setCaseData({
                ...caseData,
                ...caseResult.data,
            });
        }
    }, [caseData]);

    const addCaseTenancyType = useCallback(async (type) => {
        if (caseData == null)
            return;

        const result = await CasesService.addCaseTenancyType(caseData.id, type);

        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                draft.types.push(type)
            }));
        }
    }, [caseData]);

    const removeCaseTenancyType = useCallback(async (type) => {
        if (caseData == null)
            return;

        const result = await CasesService.removeCaseTenancyType(caseData.id, type);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                const index = draft.types.findIndex(x => x === type)
                if (index !== -1) draft.types.splice(index, 1)
            }));
        }
    }, [caseData]);

    const createCaseExpense = useCallback(async (expenseType, amount) => {
        if (caseData == null)
            return;

        const result = await CasesService.addCaseExpense(caseData.id, {
            type: expenseType,
            description: "",
            amount: {
                currency: "DKK",
                value: amount
            }
        });

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.expenses.push(result.data)
            }));
        }
    }, [caseData]);

	const updateCaseExpense = useCallback(async (expenseId, data) => {
        if (caseData == null)
            return;

        const result = await CasesService.updateCaseExpense(caseData.id, expenseId, data);

        if (result !== null) {
			setCaseData(produce(caseData, draft => {
                const index = draft.expenses.findIndex(x => x.id === expenseId);
                draft.expenses.splice(index, 1)
                draft.expenses.splice(index, 0, result.data);
            }));
        }
    }, [caseData]);

    const removeCaseExpense = useCallback(async (expenseId) => {
        if (caseData == null)
            return;

        const result = await CasesService.removeCaseExpense(caseData.id, expenseId);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                const index = draft.expenses.findIndex(x => x.id === expenseId)
                if (index !== -1) draft.expenses.splice(index, 1)
            }));
        }
    }, [caseData]);

    const createCaseContact = useCallback(async (role, name, email, extension, number) => {
        if (caseData == null)
            return;

        const result = await CasesService.addCaseContact(caseData.id, {
            role: role,
            title: "",
            name: name,
            email: email,
            phone: {
                extension: extension,
                number: number
            }
        });

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.contacts.push(result.data)
            }));
        }
    }, [caseData]);

    const removeCaseContact = useCallback(async (contactId) => {
        if (caseData == null)
            return;

        const result = await CasesService.removeCaseContact(caseData.id, contactId);

        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                const index = draft.contacts.findIndex(x => x.id === contactId);
                if (index !== -1) draft.contacts.splice(index, 1);
            }));
        }
    }, [caseData]);

    const createCaseAttribute = useCallback(async (definitionId, value) => {
        if (caseData == null)
            return;

        const result = await CasesService.createCaseAttribute(caseData.id, { attributeDefinition: definitionId, value: value });

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.attributes.push(result.data)
            }));
        }
    }, [caseData]);

    const updateCaseAttribute = useCallback(async (attributeId, data) => {
        if (caseData == null)
            return;

        const result = await CasesService.updateCaseAttribute(caseData.id, attributeId, data);

        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
                draft.attributes.splice(index, 0, result.data);
            }));
        }
    }, [caseData]);

    const removeCaseAttribute = useCallback(async (attributeId) => {
        if (caseData == null)
            return;

        const result = await CasesService.removeCaseAttribute(caseData.id, attributeId);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
            }));
        }
    }, [caseData]);

    // it might make sense to move comment to separate hook, just to keep this one smaller
    const createComment = useCallback(async (content) => {
        if (caseData == null)
            return;

        const result = await CasesService.createCaseComment(caseData.id, content);
        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                draft.comments.push(result.data)
            }));
        }
    }, [caseData]);

    const removeComment = useCallback(async (commentId) => {
        if (caseData == null)
            return;

        const result = await CasesService.removeCaseComment(caseData.id, commentId);
        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                draft.comments = draft.comments.filter(x => x.id !== commentId);
            }));
        }
    }, [caseData]);

    const enableCaseIntegration = useCallback(async (integrationType) => {
        if (caseData == null)
            return;

        if ((integrationType === "boligportal" || integrationType === "boligzonen" || integrationType === "lejebolig") && caseData.classification === "Business")
            return;

        if ((integrationType === "lokalebasen" || integrationType === "ejendomstorvet") && caseData.classification === "Residential")
            return;

        const result = await CasesService.enableIntegration(caseData.id, integrationType);

        if (result == null) {
            //handle error
        } else {
            const integrationsResult = await CasesService.getCaseIntegrations(caseData.id);
            setCaseData(produce(caseData, draft => {
                draft.integrations = integrationsResult.data
            }));
        }
    }, [caseData]);

    const disableCaseIntegration = useCallback(async (integrationType) => {
        if (caseData == null)
            return;

        if ((integrationType === "boligportal" || integrationType === "boligzonen" || integrationType === "lejebolig") && caseData.classification === "Business")
            return;

        if ((integrationType === "lokalebasen" || integrationType === "ejendomstorvet") && caseData.classification === "Residential")
            return;

        const result = await CasesService.disableIntegration(caseData.id, integrationType);

        if (result == null) {
            //handle error
        } else {
            const integrationsResult = await CasesService.getCaseIntegrations(caseData.id);
            setCaseData(produce(caseData, draft => {
                draft.integrations = integrationsResult.data;
            }));
        }
    }, [caseData]);

    const uploadCaseImages = useCallback(async (files) => {
        if (caseData == null)
            return;

        const o = caseData.images.map(e => e.order);
        const highestOrder = o.length > 0 ? Math.max.apply(Math, o) : 1;

        let promises = []
        files.forEach((file, ix) => {
            let data = new FormData();
            data.append("file", file);
            data.append("order", highestOrder + ix + 1)
            promises.push(CasesService.uploadImage(caseData.id, data))
        })
        const results = await Promise.all(promises);

        const images = results
            .filter(r => r.status === 200)
            .map(r => r.data);

        setCaseData(produce(caseData, draft => {
            draft.images = draft.images.concat(images)
        }));
    }, [caseData]);

    const deleteCaseImage = useCallback(async (imageId) => {
        if (caseData == null)
            return;
        
        const result = await CasesService.deleteImage(caseData.id, imageId);

        if (result == null) {
            //handle error
        } else {
            setCaseData(produce(caseData, draft => {
                draft.images = draft.images.filter(x => x.id !== imageId);
            }));
        }
    }, [caseData]);

    const changeCaseImageOrder = useCallback(async (imageId, newOrder) => {
        if (caseData == null)
            return;

        const result = await CasesService.updateImage(caseData.id, imageId, {
            order: newOrder
        });

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                const index = draft.images.findIndex(x => x.id === imageId);
                draft.images[index].order = newOrder;
            }));
        }
    }, [caseData]);

    const uploadCaseFloorPlans = useCallback(async (files) => {
        if (caseData == null)
            return;

        const o = caseData.floorPlans.map(e => e.order);
        const highestOrder = o.length > 0 ? Math.max.apply(Math, o) : 1;

        let promises = []
        files.forEach((file, ix) => {
            let data = new FormData();
            data.append("file", file);
            data.append("order", highestOrder + ix + 1)
            promises.push(CasesService.uploadFloorPlan(caseData.id, data))
        })
        const results = await Promise.all(promises);

        const floorPlans = results
            .filter(r => r.status === 200)
            .map(r => r.data);

        setCaseData(produce(caseData, draft => {
            draft.floorPlans = draft.floorPlans.concat(floorPlans)
        }));

    }, [caseData]);

    const deleteCaseFloorPlan = useCallback(async (floorPlanId) => {
        if (caseData == null)
            return;

        const result = await CasesService.deleteFloorPlan(caseData.id, floorPlanId);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.floorPlans = draft.floorPlans.filter(x => x.id !== floorPlanId);
            }));
        }
    }, [caseData]);

    const changeCaseFloorPlanOrder = useCallback(async (floorPlanId, newOrder) => {
        if (caseData == null)
            return;

        const result = await CasesService.updateFloorPlan(caseData.id, floorPlanId, {
            order: newOrder
        });

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                const index = draft.floorPlans.findIndex(x => x.id === floorPlanId);
                draft.floorPlans[index].order = newOrder;
            }));
        }
    }, [caseData]);

    const uploadCaseProspectus = useCallback(async (file) => {
        if (caseData == null)
            return;

        var data = new FormData();
        data.append("file", file);
        const result = await CasesService.uploadProspectus(caseData.id, data);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.prospectus = result.data;
            }));
        }
    }, [caseData]);

    const deleteCaseProspectus = useCallback(async () => {
        if (caseData == null)
            return;

        const result = await CasesService.deleteProspectus(caseData.id);

        if (result !== null) {
            setCaseData(produce(caseData, draft => {
                draft.prospectus = null;
            }));
        }
    }, [caseData]);


    return {
        isCaseLoading,
        caseData,
        isCaseIntegrationProcessing,
        loadCase,
        updateCase,
        addCaseTenancyType,
        removeCaseTenancyType,
        createCaseExpense,
        removeCaseExpense,
        createCaseContact,
        removeCaseContact,
        removeComment,
        createComment,
        updateCaseAttribute,
        removeCaseAttribute,
        createCaseAttribute,
        enableCaseIntegration,
        disableCaseIntegration,
        uploadCaseImages,
        deleteCaseImage,
        changeCaseImageOrder,
        uploadCaseFloorPlans,
        deleteCaseFloorPlan,
        changeCaseFloorPlanOrder,
        deleteCase,
        uploadCaseProspectus,
        deleteCaseProspectus,
		updateCaseExpense
    }
};

export default useCase;