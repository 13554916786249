import initialOrganizationState from './organization-state';
import { createContext } from 'react';

/**
 * @ignore
 */
const initialContext = {
    ...initialOrganizationState
};

const OrganizationContext = createContext(initialContext);

export default OrganizationContext;