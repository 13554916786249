import React, { useEffect, useMemo } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import ExtendedTable from "components/ExtendedTable";
import { useEvents } from "hooks/api";
import { format } from "date-fns";
import { CircularProgress } from "@material-ui/core";
import { translateString } from "helpers";
import { da } from 'date-fns/locale';

const UpcomingEvents = () => {

    const { eventsData, isEventsLoading, loadCurrentEmployeeEvents } = useEvents();

    useEffect(() => {
        loadCurrentEmployeeEvents();
    }, [loadCurrentEmployeeEvents]);

    const mapEvents = () => eventsData.map(x => {
        return {
            id: x.id,
            type: translateString(x.type),
            address: `${x.address.street} ${x.address.zipCode} ${x.address.city}`,
            linkPath: x.type === "OpenHouse" ? `/openhouses/${x.id}` : `/showcasings/${x.id}`,
            date: format(new Date(x.date), "d MMM yyyy HH:mm", { locale: da })
        }
    });

    const mappedEvents = useMemo(() => mapEvents(), [eventsData]);

    const columns = [
        {
            Header: 'Adresse',
            accessor: 'address',
            canGroupBy: false
        },
        {
            Header: 'Type',
            accessor: 'type',
            canGroupBy: false
        },
        {
            Header: 'Dato',
            accessor: 'date',
            canGroupBy: false
        }];

    let content = (
        <div className="loading-container">
            <CircularProgress />
        </div>
    )

    if (!isEventsLoading) {
        content = (
            <ExtendedTable
                isLoading={isEventsLoading}
                data={mappedEvents}
                columns={columns}
                linkPath="/cases/"
            />
        );
    }

    return (
        <Paper className="upcoming-events">
            <Typography variant="subtitle">Kommende begivenheder ({mappedEvents.length})</Typography>
            {content}
        </Paper>
    )
};

export default UpcomingEvents;