import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./index.css";

function LoadingComponent(props) {
  if (props.variant === "image") {
    return (
      <div className="imageLoaderContainer">
        <CircularProgress className="imageLoader" />
        <p>Uploader billede</p>
      </div>
    );
  }
  if (props.variant === "simple") {
    return (
      <div className="simpleLoaderContainer">
        <CircularProgress className="simpleLoader" />
        <p>Lige et sekund...</p>
      </div>
    );
  }
  if (props.variant === "spinner") {
    return (
      <CircularProgress className="simpleLoader" />
    );
  }
  return <CircularProgress className="loader" />;
}

export default LoadingComponent;
