import React from "react";
import PropTypes from 'prop-types';
import { Select, Switch, TextField } from "components/Fields";
import { MenuItem } from "@material-ui/core";

const AttributeField = props => {

    const { onChange, readOnly } = props;

    if (props.type === "Boolean")
        return (
            <Switch
                checked={props.value}
                disabled={readOnly}
                onChange={(e) => {
                    onChange(e.target.checked);
                }}
            />
        );

    if (props.type === "String")
        return (
            <TextField 
                value={props.value}
                disabled={readOnly}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            />
        );

    if (props.type === "Options")
        return (
            <Select
                value={props.optionId}
                disabled={readOnly}
                onChange={(e) => {
                    onChange(e.target.value);
                }}>
                {props.options.map((option, ix) => (
                    <MenuItem key={ix} value={option.id}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        )

    return null;
};

AttributeField.propTypes = {
    type: PropTypes.oneOf(["Boolean", "String", "Options"])
};

export default AttributeField;