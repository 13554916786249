import React from "react";
import CompanyFields from "./components/CompanyFields";
import BankFields from "./components/BankFields";
import OtherFields from "./components/OtherFields";
import UtilitiesFields from "./components/UtilitiesFields";
import SignerFields from "./components/SignerFields";
import RentControlFields from "./components/RentControlFields";
import RentIncreaseFields from "./components/RentIncreaseFields";
import TenancyFields from "./components/TenancyFields";

const ContractDetailsTab = ({ updatePropertyContract, propertyContractData }) => {

	console.log(propertyContractData)

	return (
		<div className="property-contract-details">
			<TenancyFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<CompanyFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<SignerFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<BankFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<UtilitiesFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<RentIncreaseFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<RentControlFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
			<OtherFields
				contractData={propertyContractData}
				updatePropertyContract={updatePropertyContract} />
		</div>
	);

}

export default ContractDetailsTab;
