import React, { useEffect, useState, useMemo } from 'react'
import './index.scss'

import Typography from 'components/Typography'
import ChipSlim from 'components/ChipSlim'
import SearchInput from 'components/SearchInput'
import { useCasesSearch } from 'hooks/api'
import { useHistory } from 'react-router-dom'
import { useMemorizedTableSettings } from 'hooks/memory'
import { useOrganization } from 'features/organization'
import { ToggleButton } from 'components/Buttons'
import { translateString } from 'helpers/index'
import Table from 'components/Table'
import getColumns from './columns'
import ColumnsModal from 'components/ColumnsModal'
import FilterModal from 'components/FilterModal'
import FilterChips from 'components/FilterChips'
import useMemorizedToggleSettings from './useMemorizedToggleSettings'

import HeaderContainer from 'components/HeaderContainer'

const Cases = props => {
  const {
    enabledColumns,
    enabledFilters,
    enabledSorting,
    saveEnabledFilters,
    saveEnabledColumns,
    saveEnabledSorting,
    isLoading: isLoadingTableSettings
  } = useMemorizedTableSettings(`${props.caseState}_cases_v2`)
  const {
    isCasesLoading,
    casesData,
    casesMetaData,
    query,
    setQuery,
    setPageIndex,
    pageIndex,
    filters,
    setFilters,
    setSorting,
    sorting,
    classification,
    setClassification,
    setColumns,
	setInitiationCompleted
  } = useCasesSearch({ state: props.caseState })
  const { employees, blockedEmployees } = useOrganization()

  const history = useHistory()
  const [activeColumns, setActiveColumns] = useState([])
  const { activeToggle, saveActiveToggle, isLoading: isLoadingToggleSettings } = useMemorizedToggleSettings();

  const columns = useMemo(
    () => getColumns(props.caseState, [...employees, ...blockedEmployees]),
    [employees, blockedEmployees]
  )

  useEffect(() => {
    setColumns(columns)
  }, [columns])
useEffect(() => {
	if (!isLoadingTableSettings && !isLoadingToggleSettings) {
		setActiveColumns(enabledColumns.length > 0 ? enabledColumns : columns.filter(x => x.isDefault).map(x => x.accessor));
		setFilters(enabledFilters);
		setSorting(enabledSorting);
		setClassification(activeToggle);
		setInitiationCompleted(true);
	}
}, [isLoadingTableSettings, isLoadingToggleSettings, enabledFilters, enabledSorting, enabledColumns, activeToggle]);


  const addFilter = (accessor, value) => {
    const newValue = [...filters, { accessor: accessor, value: value }]
    setFilters(newValue)
    saveEnabledFilters(newValue)
  }
	const handleRowClick = (e, row) => {
		if (e.ctrlKey || e.metaKey ) {
            window.open(`/cases/${row.original.id}`, "_blank");
        } else {
			history.push(`/cases/${row.original.id}`);
		}
	};

  const changeFilters = filters => {
    setFilters(filters)
    saveEnabledFilters(filters)
  }

  const changeActiveColumns = newColumns => {
    setActiveColumns(newColumns)
    saveEnabledColumns(newColumns)
  }

  const changeSorting = newSorting => {
    setSorting(newSorting)
    saveEnabledSorting(newSorting)
  }

  if (isCasesLoading) {
    return <div>Loading</div>
  }

  return (
    <div className='cases-list-container'>
      <div className='cases-list-header'>
        <HeaderContainer>
          <Typography variant='title'>Udlejningssager</Typography>
          <ChipSlim label={props.title} number={casesMetaData.TotalCases} />
        </HeaderContainer>
        <div className='table-bar-container'>
          <div className='search'>
            <div className='search-container'>
              <ToggleButton
                value={classification}
                onChange={value => setClassification(value)}
                options={[
                  {
                    label: 'Alle',
                    value: 'all'
                  },
                  {
                    label: translateString('residential'),
                    value: 'residential'
                  },
                  {
                    label: translateString('business'),
                    value: 'business'
                  }
                ]}
              />
              <SearchInput value={query} setQuery={setQuery} />
            </div>
          </div>
          <div className='options'>
            <FilterModal
              addFilter={(label, accessor, type, value) =>
                addFilter(accessor, value)
              }
              possibleFilters={columns}
              data={casesData}
			  style={{ marginRight: '16px' }}
            />
            <ColumnsModal
              possibleColumns={columns}
              activeColumns={activeColumns}
              setActiveColumns={changeActiveColumns}
            />
          </div>
        </div>
      </div>
      <div className='filters'>
        <FilterChips
          columns={columns}
          filters={filters}
          setFilters={changeFilters}
        />
      </div>
      <div className='cases-list-table'>
		<Table
			activeColumns={activeColumns}
			columns={columns}
			data={casesData}
			pageLimit={casesMetaData.PageLimit}
			total={casesMetaData.TotalCases}
			offset={casesMetaData.Offset}
			onRowClick={handleRowClick}
			setPageIndex={setPageIndex}
			pageIndex={pageIndex}
			setSorting={changeSorting}
			sorting={sorting}
		/>
      </div>
    </div>
  )
}

export default Cases
