import React, { useRef, useEffect } from 'react'
import '../index.scss'
import Typography from 'components/Typography'
import StyledAutocomplete from 'components/StyledAutocomplete2'
import StyledSelect from 'components/StyledSelect2'
import StyledDatepicker from 'components/StyledDatepicker'
import TimePicker from 'components/TimePicker'
import { useCasesSearch } from 'hooks/api'
import ShowcasingDurations from 'helpers/showcasing-durations'

const LocationSection = ({
  state,
  dispatch,
  employeeSelectOptions,
  isEditing
}) => {
  const { casesData, setQuery, setInitiationCompleted } = useCasesSearch({
    state: 'all'
  })
  const inputRef = useRef(null)
  
  useEffect(() => {
	setInitiationCompleted(true)
  }, [setInitiationCompleted])

  return (
    <div className='section location'>
      <div className='field tenancy'>
        <Typography className='pb15' variant='subtitle'>
          Lejemål
        </Typography>
        <StyledAutocomplete
          ref={inputRef}
          inputProps={{ 
			placeholder: 'Søg efter adresse',
			...(isEditing && { disabled: true })
		}}
          items={casesData.length > 0 ? casesData : []}
          getItemValue={item => item.addressStreet}
          renderItem={(item, highlighted) => (
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
            >
              {item.addressStreet}
            </div>
          )}
          value={state.caseQuery}
          onChange={e => {
			console.log('setting query')
            dispatch({ type: 'SET_CASE_QUERY', payload: e.target.value })
            setQuery(e.target.value)
          }}
          onSelect={(value, item) => {
			dispatch({ type: 'SET_CASE_QUERY', payload: value })
			dispatch({ type: 'SET_CASE_ID', payload: item.id })
            inputRef.current.blur()
          }}
		  disabled={isEditing}
        />
      </div>
      <div className='field date'>
        <Typography className='pb15' variant='subtitle'>
          Dato
        </Typography>
        <StyledDatepicker value={state.startDate} onChange={(value) => dispatch({ type: 'SET_START_DATE', payload: value })} />
      </div>
      <div className='field time'>
        <Typography className='pb15' variant='subtitle'>
          Tid
        </Typography>
        <TimePicker value={state.startTime} onChange={(value) => dispatch({ type: 'SET_START_TIME', payload: value })} />
      </div>
	  <div className='field time'>
        <Typography className='pb15' variant='subtitle'>
          Varighed
        </Typography>
        <StyledSelect
          options={ShowcasingDurations}
          value={state.duration}
          onChange={value => dispatch({ type: "SET_DURATION", payload: value })}
        />
      </div>
      <div className='field showcaser'>
        <Typography className='pb15' variant='subtitle'>
          Fremviser
        </Typography>
        <StyledSelect
          options={employeeSelectOptions}
          value={state.hostId}
          onChange={value => dispatch({ type: "SET_HOST_ID", payload: value })}
        />
      </div>
    </div>
  )
}

export default LocationSection
