import React from "react";
import Typography from "components/Typography";
import { Switch } from "components/Fields";

const AntennaUtility = ({ contractDetails, updatePropertyContract }) => {
	return (
		<>
			<Typography variant="label" className="utility-title">Fællesantenne</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer leverer</Typography>
					<Switch
						checked={contractDetails.utilities.antenna.isLandlordResponsible}
						onChange={(e) => updatePropertyContract({ utilities: { antenna: { isLandlordResponsible: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Lejerforening leverer</Typography>
					<Switch
						checked={contractDetails.utilities.antenna.isUnionSupplied}
						onChange={(e) => updatePropertyContract({ utilities: { antenna: { isUnionSupplied: e.target.checked } } })}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default AntennaUtility;