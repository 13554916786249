import { useState, useCallback } from "react";
import ReportsService from 'services/api/reports';
import produce from "immer";

/*
    useReports hook handles reports operations. 
*/
const useReports = () => {

    const [isReportLoading, setReportLoading] = useState(true);
    const [reportData, setReportData] = useState({});

    const loadEmployeeShowcasingReport = useCallback(async (from, to, interval) => {
		setReportLoading(true);
		
        const result = await ReportsService.getEmployeeShowcasingsReport(from, to, interval);
        if (result !== null) {
			setReportData(result.data);
        }

        setReportLoading(false);
    }, []);

	const loadTenancyShowcasingReport = useCallback(async (from, to, interval) => {
		setReportLoading(true);

        const result = await ReportsService.getTenancyShowcasingsReport(from, to, interval);
        if (result !== null) {
			setReportData(result.data);
        }

        setReportLoading(false);
    }, []);

	const loadOrganizationReport = useCallback(async (date) => {
		setReportLoading(true);
		
        const result = await ReportsService.getOrganizationReport(date);
        if (result !== null) {
			console.log(result.data)
			setReportData(result.data);
        }

        setReportLoading(false);
    }, []);

    return {
		isReportLoading,
		reportData,
		loadEmployeeShowcasingReport,
		loadTenancyShowcasingReport,
		loadOrganizationReport
    }
}

export default useReports;