import React, { useState } from "react";
import LogEntry from "./LogEntry";
import PropTypes from "prop-types";
import { PrimaryButton } from "components/Buttons";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import { getStateLogLabel } from "helpers";

const Log = props => {

    const { createComment, data, removeComment, viewOnly } = props;

    const [createCommentModal, setCreateCommentModal] = useState(false);
    const [newCommentValue, setNewCommentValue] = useState("");

    const handleCreateComment = () => {
        createComment(newCommentValue);
        setCreateCommentModal(false);
        setNewCommentValue("");
    }

    const modifiedStateLogs = data.stateLogs.map(e => {
        return {
            title: getStateLogLabel(e.state),
            createdAt: e.createdAt,
            icon: e.state
        }; 
    })
    const modifiedComments = data.comments.map(e => {
        return {
            title: `${e.author?.name ?? "-"} kommenterede`,
            createdAt: e.createdAt,
            content: e.content,
            icon: "Comment",
            id: e.id
        }
    })
    const logData = modifiedComments.concat(modifiedStateLogs);

    return (
        <div className="log__container">
            <PrimaryButton disabled={viewOnly} onClick={() => setCreateCommentModal(true)}>Tilføj Kommentar</PrimaryButton>
            <FullScreenModal 
                className="log__create-modal"
                open={createCommentModal}
                onClose={() => setCreateCommentModal(false)}>
                <Typography variant="subtitle">Tilføj Kommentar</Typography>
                <TextField
                    multiline
                    rows={5}
                    rowsMax={5}
                    value={newCommentValue}
                    onChange={(e) => setNewCommentValue(e.target.value)} />
                <PrimaryButton onClick={handleCreateComment}>Tilføj Kommentar</PrimaryButton>
            </FullScreenModal>
            <div className="log__list">
            {logData
                .slice()
                .sort((a, b) => b.createdAt > a.createdAt ? 1: -1)
                .map((comment, ix) => <LogEntry index={ix} key={ix} data={comment} removeComment={removeComment} />)
            }
            </div>
        </div>
    );
};

Log.propTypes = {
    data: PropTypes.object.isRequired
};  

export default Log;