import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import LoadingComponent from "components/LoadingComponent";

const CreatingContractModal = ({ isEnabled }) => {
	return (
		<FullScreenModal
			className="application-container__generating-contract-modal"
			open={isEnabled}>
			<Typography variant="subtitle">Opretter kontrakt</Typography>
			<div className="contract-generating-loading-container"><LoadingComponent /></div>
		</FullScreenModal>
	);
};

export default CreatingContractModal;