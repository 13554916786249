import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

export const getInterestLists = async () => { 
    try {
        const result = await axios.get(`/interestlists`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const getInterestListByTenancy = async (tenancyId) => { 
    try {
        const result = await axios.get(`/tenancies/${tenancyId}/interestlists`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const createInterestList = async (listObject) => { 
    try {
        const result = await axios.post(`/interestlists`, listObject);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const getInterestList = async (listId) => { 
    try {
        const result = await axios.get(`/interestlists/${listId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const updateInterestList = async (listId, listObject) => { 
    try {
        const result = await axios.patch(`/interestlists/${listId}`, listObject);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const deleteInterestList = async (listId) => { 
    try {
        const result = await axios.delete(`/interestlists/${listId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const createInterestListComment = async (listId, comment) => { 
    const data = {
        body: comment
    }
    try {
        const result = await axios.post(`/interestlists/${listId}/comments`, data);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const getInterestListComments = async (listId) => { 
    try {
        const result = await axios.get(`/interestlists/${listId}/comments`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const updateInterestListComment = async (listId, commentId, comment) => { 
    const data = {
        body: comment
    }
    try {
        const result = await axios.patch(`/interestlists/${listId}/comments/${commentId}`, data);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const updateInterestListContact = async (listId, owner) => { 

    try {
        const result = await axios.patch(`/interestlists/${listId}/owners/${owner.id}`, owner);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const createInterestListContact = async (listId, owner) => { 

    try {
        const result = await axios.post(`/interestlists/${listId}/owners`, owner);
        return result.data
    } catch (err) {
        console.error(err);
    }
};


export const deleteInterestListComment = async (listId, commentId) => { 
    try {
        const result = await axios.delete(`/interestlists/${listId}/comments/${commentId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const createInterestListInterest = async (listId, interest) => { 
    try {
        console.log(interest);
        const result = await axios.post(`/interestlists/${listId}/interests`, interest);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const getInterestListInterests = async (listId) => { 
    try {
        const result = await axios.get(`/interestlists/${listId}/interests`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const deleteInterestListInterests = async (listId, interestId) => { 
    try {
        const result = await axios.delete(`/interestlists/${listId}/interests/${interestId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const addInterestListOwner = async (listId, ownerObject) => { 
    try {
        const result = await axios.post(`/interestlists/${listId}/owners`, ownerObject);
        return result.data
    } catch (err) {
        console.error(err);
    }
};