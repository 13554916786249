import React from 'react';

const StateChanges = ({ data }) => {
	return (
		<div className="data-container state-changes">
			<p className="title">Statusændringer</p>
			<div className="state-container">
				<div className="state">
					<div className="name">Oprettede</div>
					<div className="value">{data.draft.selectedMonth}</div>
				</div>
				<div className="state">
					<div className="name">Aktiveverede</div>
					<div className="value">{data.active.selectedMonth}</div>
				</div>
				<div className="state">
					<div className="name">Reserverede</div>
					<div className="value">{data.reserved.selectedMonth}</div>
				</div>
				<div className="state">
					<div className="name">Udlejde</div>
					<div className="value">{data.completed.selectedMonth}</div>
				</div>
			</div>
		</div>
	);
};

export default StateChanges;