import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { PrimaryButton } from "components/Buttons";

const DeleteTenancyModal = ({ isEnabled, onClose, deleteTenancy }) => {
	return (
		<FullScreenModal
			className="tenancy__delete-tenancy-modal"
			open={isEnabled}
			onClose={onClose}>
			<Typography variant="subtitle">Slet lejemål</Typography>
			<p style={{padding: "16px 0px 16px 0px"}} className="description">
				Er du sikker på du ønsker at slette dette lejemål?
			</p>
			<PrimaryButton onClick={deleteTenancy}>Godkend</PrimaryButton>
		</FullScreenModal>
	);
};

export default DeleteTenancyModal;