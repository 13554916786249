import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { translateString } from "helpers";
import { PrimaryButton } from "components/Buttons";

const SendContractModal = ({ isEnabled, onClose, documents, sendContract }) => {
	return (
		<FullScreenModal
			className="application-container__send-contract-modal"
			open={isEnabled}
			onClose={onClose}>
			<Typography variant="subtitle">Send kontrakt til Penneo</Typography>
			<p className="description">
				Check at kontrakten er korrekt inden afsendelse. 

				Når du bekræfter, sendes kontrakten til Penneo og underskrivere notificeres.

				Det vil ikke være muligt at ændre i kontrakten efterfølgende.
				{documents.length > 0 ? " Følgende dokumenter vil blive tilføjet til kontrakten:" : null}
			</p>
			<ul>
				{documents.map(document => (
					<li key={document.id}>
						{translateString(document.type)}
						{document.type == "DataPolicy" || document.type == "Paragraph11" ? " (Underskrives)" : null}
					</li>
				))}
			</ul>
			<PrimaryButton onClick={sendContract}>Godkend</PrimaryButton>
		</FullScreenModal>
	);
};

export default SendContractModal;