import React, { useEffect, useMemo } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import ExtendedTable from "components/ExtendedTable";
import useCases from "hooks/api/useCases";
import { CircularProgress } from "@material-ui/core";
import { getUnixTime, format } from "date-fns";
import { da } from 'date-fns/locale';

const MyCases = () => {

    const { isCasesLoading, casesData, loadCurrentEmployeeActiveCases } = useCases();

    const calculateActiveDays = (caseData) => {
        const activeLog = caseData.stateLogs.filter(x => x.state === "Active").sort((a, b) => b.createdAt - a.createdAt)[0];
        if (!activeLog)
            return "-";

        const activeDate = new Date(activeLog.createdAt);
        const now = new Date();

        return ((getUnixTime(now) - getUnixTime(activeDate)) / (60 * 60 * 24)).toFixed();
    }

    const mapCases = () => casesData.map(x => {
        return {
            id: x.id,
            address: `${x.address.street} ${x.address.zipCode} ${x.address.city}`,
            activeDays: calculateActiveDays(x),
            availableFrom: format(new Date(x.availableFrom), 'd MMM yyyy', { locale: da })
        }
    });

    const mappedCases = useMemo(() => mapCases(), [casesData]);
    const columns = [
        {
            Header: 'Adresse',
            accessor: 'address',
            canGroupBy: false
        },
        {
            Header: 'Aktive dage',
            accessor: 'activeDays',
            canGroupBy: false
        },
        {
            Header: 'Ledig pr.',
            accessor: 'availableFrom',
            canGroupBy: false
        }];

    useEffect(() => {
        loadCurrentEmployeeActiveCases();
    }, [loadCurrentEmployeeActiveCases]);

    let content = (
        <div className="loading-container">
            <CircularProgress />
        </div>
    )

    if (!isCasesLoading) {
        content = (
            <ExtendedTable
                isLoading={isCasesLoading}
                data={mappedCases}
                columns={columns}
                linkPath="/cases/"
            />
        );
    }

    return (
        <Paper className="user-cases">
            <Typography variant="subtitle">Mine Udlejningssager ({mappedCases.length})</Typography>
            {content}
        </Paper>
    )
};

export default MyCases;