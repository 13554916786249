import React, { useState, useMemo, useEffect } from "react";
import "./index.scss";
import { useTable, useGroupBy, useExpanded } from 'react-table';
import getAllCasesNotCompleted from "helpers/reports";
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GroupIcon from '@material-ui/icons/Group';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Typography from "@material-ui/core/Typography";

import { translateString, formatNumber } from "helpers";
import LoadingComponent from "components/LoadingComponent";
import { formatDate } from "helpers";
// import ReactExport from "react-export-excel";


export default function Reports(props) {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(()=>{
    getAllCasesNotCompleted().then(result => {
      const translatedData = result.map(e => {
        e.state = translateString(e.state);
        e.addressStreet = e.address.street;
        e.addressCity = e.address.city;
        e.size = e.size.value
        e.classification = translateString(e.classification);
        e.monthlyRent = e.monthlyRent.value > 0 ? Math.round(e.monthlyRent.value) : 0;
        e.createdAt = formatDate(e.createdAt);
        e.daysEmpthy = calculateDaysEmpthy(e.availableFrom)
        e.lossToDate = Math.round(e.daysEmpthy * (e.monthlyRent/30));
        return e;
      });
      setData(translatedData);
      setIsLoading(false);
    });
  }, [])

  const calculateDaysEmpthy = (availableDate) => {
    if (availableDate === null) {return 0}
    var differenceMs = new Date().getTime() - new Date(availableDate).getTime();
    const differenceDays = Math.round(differenceMs/(1000*60*60*24))
    return differenceDays > 0 ? differenceDays : 0;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Adresse",
        accessor: "addressStreet",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`
      },
      {
        Header: "By",
        accessor: "addressCity",
        aggregate: "unique",
        Aggregated: ({ value }) => `${value}`        
      },
      {
        Header: "Status",
        accessor: "state",
        Aggregated: ({ value }) => `-`
      },
      {
        Header: "Ejendom",
        accessor: "propertyTitle",
        Aggregated: ({ value }) => `-`
      },
      {
        Header: "Type",
        accessor: "classification",
        Aggregated: ({ value }) => `-`
      },
      {
        Header: "Størrelse",
        accessor: "size",
        aggregate: "sum",
        Aggregated: ({ value }) => value === 0 ? "-" : `${Math.round(value)} m2`,
        disableGroupBy: true,
        Cell: ({ value }) => value === 0 ? "-" : `${value}`
      },
      {
        Header: "Værelser",
        accessor: "rooms",
        aggregate: "sum",
        Aggregated: ({ value }) => `-`,
        disableGroupBy: true,
        Cell: ({ value }) => value === 0 ? "-" : `${value}`
      },
      {
        Header: "Ledig fra",
        accessor: "availableFrom",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        Cell: ({ value }) => value === null ? "-" : `${value}`
      },
      {
        Header: "Tilgængelig fra",
        accessor: "accessibleFrom",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        Cell: ({ value }) => value === null ? "-" : `${value}`
      },
      {
        Header: "Oprettet",
        accessor: "createdAt",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`
      },
      {
        Header: "Dage tom",
        accessor: "daysEmpthy",
        disableGroupBy: true,
        aggregate: "sum",
        Aggregated: ({ value }) => value === 0 ? "-" : `${value}`,
        Cell: ({ value }) => value === 0 ? "-" : `${value}`
      },
      {
        Header: "Månedlig leje",
        accessor: "monthlyRent",
        aggregate: "sum",
        Aggregated: ({ value }) => value === 0 ? "-" : `${formatNumber(Math.round(value), "financial")}`,
        disableGroupBy: true,
        Cell: ({ value }) => value === 0 ? "-" : `${formatNumber(Math.round(value), "financial")}`
      },
      {
        Header: "Tab til dato",
        accessor: "lossToDate",
        aggregate: "sum", 
        Aggregated: ({ value }) => value === 0 ? "-" : `${formatNumber(Math.round(value), "financial")}`,
        disableGroupBy: true,
        Cell: ({ value }) => value === 0 ? "-" : `${formatNumber(Math.round(value), "financial")}`
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
    columns: columns,
    data: data,
    initialState: {
      groupBy: [
        "propertyTitle"
        ]
      }
    },
    useGroupBy,
    useExpanded
  )

  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  // const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="container">
      <div className="headerContainer">
        <Typography color="primary" variant="h1" >
          Tomgangsliste
        </Typography>
        {/* <ExcelFile filename={"Tomgangsliste_" + today} element={<Button variant="contained" disableElevation>Eksporter til Excel</Button>}>
                <ExcelSheet data={data} name="Tomgangsliste">
                    <ExcelColumn label="Adresse" value="addressStreet"/>
                    <ExcelColumn label="By" value="addressCity"/>
                    <ExcelColumn label="Status" value="state"/>
                    <ExcelColumn label="Ejendom" value="propertyTitle"/>
                    <ExcelColumn label="Type" value="classification"/>
                    <ExcelColumn label="Størrelse" value="size"/>
                    <ExcelColumn label="Værelser" value="rooms"/>
                    <ExcelColumn label="Ledig fra" value="availableFrom"/>
                    <ExcelColumn label="Tilgængelig fra" value="accessibleFrom"/>
                    <ExcelColumn label="Oprettet fra" value="createdAt"/>
                    <ExcelColumn label="Dage tom" value="daysEmpthy"/>
                    <ExcelColumn label="Månedlig leje" value="monthlyRent"/>
                    <ExcelColumn label="Tab til dato" value="lossToDate"/>
                    
                </ExcelSheet>
            </ExcelFile> */}
      </div>
      <div className="reportTableContainer">
        <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  <span className="flexedNotJustified">
                    {column.canGroupBy ? (
                        <span className="groupByToggle" {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? <GroupIcon/> : <GroupOutlinedIcon/>}
                        </span>
                      ) : null}
                      <span>{column.render('Header')}</span>
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} >
                      {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <span className="flexedNotJustified">
                            <span className="expandToggle" {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                            </span>{' '}
                            <span>{cell.render('Cell')} ({row.subRows.length})</span>
                          </span>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
      </div>
    </div>
  )
 
}
