import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { translateString } from "helpers";
import Icon from "components/Icon";

const Documents = props => {

	const { documents } = props;

	return (
		<Paper className="application-content__applicants">
			<Typography variant="subtitle">Dokumenter</Typography>
			<div className="documents-container">
				{documents.map(document => (
					<div key={document.id} className="document">
						<a href={document.url} target="_blank"><p className="document__name">{translateString(document.type)}</p></a>
						{document.type === "DataPolicy" || document.type === "Paragraph11" ? <Icon type="signable" /> : null}
					</div>
				))}
			</div>
		</Paper>
	)
};

export default Documents;