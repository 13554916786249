import { FETCH_PROPERTIES, SET_PROPERTY_DOCUMENT, DELETE_PROPERTY_DOCUMENT, FETCH_PROPERTY, FETCH_PROPERTY_TENANCIES, SET_PROPERTY_IMAGE, FETCH_PROPERTY_STATS, DELETE_PROPERTY_IMAGE, SET_PROPERTIES_ATTRIBUTE, SET_PROPERTIES_CONTACT, DELETE_PROPERTIES_ATTRIBUTE, DELETE_PROPERTIES_CONTACT } from "redux/actionTypes";

const properties = (
  state = {
    list: {
      data:[],
      loaded: false
    },
    selected : {
      data: [],
      loaded: false,
      tenancies: {
        loaded: false,
        data: []
      },
      stats: {
        loaded:false,
        data:[]
      }
    },
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PROPERTY_STATS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          stats: {
            loaded: true,
            data: action.payload
          }
        }
      }
    }
    case SET_PROPERTY_DOCUMENT: {
      const alreadyExisting = state.selected.data.documents.find((document) => {
        return document.id === action.payload.document.id;
      });
      let newDocuments = [];
      if (alreadyExisting) {
        newDocuments = state.selected.data.documents
          .map((image) =>
            image.id === action.payload.document.id ? action.payload.document : document
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newDocuments = [...state.selected.data.documents, action.payload.document];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    
    case DELETE_PROPERTY_DOCUMENT: {
      const newDocuments = state.selected.data.documents.filter(
        (document) => document.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_PROPERTY_IMAGE: {
      const newImages = state.selected.data.images.filter(image => image.id !== action.payload.id);
      state.selected.data = {
        ...state.selected.data,
        images: newImages
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            images: newImages
          }
        }
      }
    }
    case SET_PROPERTIES_ATTRIBUTE: {
      const alreadyExisting = state.selected.data.attributes.find(attribute => {
        return attribute.definition.id === action.payload.definition.id 
      })
      let newAttributes= [];
      if(!!alreadyExisting) {
        newAttributes = state.selected.data.attributes.map(attribute => attribute.definition.id === action.payload.definition.id ? action.payload : attribute).sort((a, b) => a.order < b.order ? -1 : 1);
      }else {
        newAttributes = [...state.selected.data.attributes, action.payload ]
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            attributes: newAttributes
          }
        }
      }
    }
    case SET_PROPERTY_IMAGE: {
      const alreadyExisting = state.selected.data.images.find(image => {
        return image.id === action.payload.image.id 
      })
      let newImages = [];
      if(alreadyExisting) {
        newImages = state.selected.data.images.map(image => image.id === action.payload.image.id ? action.payload.image : image).sort((a, b) => a.order < b.order ? -1 : 1);
      }else {
        newImages = [...state.selected.data.images, action.payload.image ]
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            images: newImages
          }
        }
      }
    }
    case FETCH_PROPERTIES:
      return {
        ...state,
        list: {
          data: action.payload.data,
          resHeaders: action.payload.headers,
          loaded: true
        }
      };
    case FETCH_PROPERTY:
      return {
        ...state,
        selected: {
          ...state.selected,
          loaded: true,
          data:  action.payload,
        }
      };
    case FETCH_PROPERTY_TENANCIES: 
      return {
        ...state,
        selected: {
          ...state.selected,
          tenancies: {
            loaded: true,
            data: action.payload
          }
        }
      }
    case SET_PROPERTIES_CONTACT: {
      const alreadyExisting = state.selected.data.contacts.find(contact => {
        return contact.id === action.payload.id 
      })
      let newContacts= [];
      if(!!alreadyExisting) {
        newContacts = state.selected.data.contacts.map(contact => contact.id === action.payload.id ? action.payload : contact).sort((a, b) => a.order < b.order ? -1 : 1);
      }else {
        newContacts = [...state.selected.data.contacts, action.payload ]
      }
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            contacts: newContacts
          }
        }
      }
    }
    case DELETE_PROPERTIES_ATTRIBUTE: {
      const newAttributes = state.selected.data.attributes.filter(attribute => attribute.definition.id !== action.payload.id);
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            attributes: newAttributes
          }
        }
      }
    }
    case DELETE_PROPERTIES_CONTACT: {
      const newContacts = state.selected.data.contacts.filter(contact => contact.id !== action.payload.id);
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            contacts: newContacts
          }
        }
      }
    }
    default:
      return state;
  }
  
};

export default properties;
