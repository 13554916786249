import { useState, useCallback, useEffect, useMemo } from "react";
import CasesService from 'services/api/cases';
import TenanciesService from "services/api/tenancies";

/*
	useCasesSearch hook handles cases paging, filtering, searching and sorting. 
*/
const useCasesSearch = ({ state }) => {

	const pageLimit = 35;

	const [isCasesLoading, setCasesLoading] = useState(true);
	const [initiationCompleted, setInitiationCompleted] = useState(false);
	const [casesData, setCasesData] = useState([]);
	const [casesMetaData, setCasesMetaData] = useState({});

	const [classification, setClassification] = useState("all");
	const [query, setQuery] = useState("");
	const [pageIndex, setPageIndex] = useState(0);
	const [filters, setFilters] = useState([]);
	const [sorting, setSorting] = useState([]);
	const [columns, setColumns] = useState([]);

	const parseFilters = (state, filters, columns) => {
		let s = "";
		if (state === "deleted") {
			s = `deleted_at > 1`
		} else if (state === 'all') {
			s = `deleted_at = 0`
		} else {
			s = `state = ${state} AND deleted_at = 0`;
		}

		if (classification === "residential") {
			s = `${s} AND classification = residential`
		} else if (classification === "business") {
			s = `${s} AND classification = business`
		}

		//on default show only cases that aren't postponed
		if (!filters.some(x => x.accessor === "postponedUntil")) {
			filters = [...filters, {accessor: 'postponedUntil', value: 'Nej'}];
		}
		
		for (let filter of filters) {
			const column = columns.find(x => x.accessor === filter.accessor);
			if (!column) {
				continue;
			}
			
			if (filter.accessor === "postponedUntil") {
				if (filter.value === "Ja") {
					s = `${s} AND ${filter.accessor} > 0`
				} else {
					s = `${s} AND ${filter.accessor} = 0`
				}
			} else if (column.filter === "equal") {
				s = `${s} AND ${filter.accessor} = '${filter.value}'`
			} else if (column.filter === "between") {
				s = `${s} AND ${filter.accessor} >= ${filter.value.min} AND ${filter.accessor} <= ${filter.value.max}`
			}
		}

		return s;
	};

	const memorizedFilters = useMemo(() => parseFilters(state, filters, columns), [state, filters, classification, columns]);

	const parseSorting = (sorting) => {
		return sorting.map(x => {
			if (x.desc) {
				return `${x.id}:desc`
			} else {
				return `${x.id}:asc`
			}
		});
	}

	const memorizedSorting = useMemo(() => parseSorting(sorting), [sorting]);

	const loadCases = useCallback(async () => {
		const result = await CasesService.searchCases({
			paging: {
				offset: pageIndex * pageLimit,
				limit: pageLimit
			},
			query: query && query.length > 0 ? query : null,
			filter: memorizedFilters,
			sort: memorizedSorting
		});

		setCasesMetaData({
			PageLimit: result.headers["x-limit"],
			Offset: result.headers["x-offset"],
			RemainingCases: result.headers["x-remaining"],
			TotalCases: result.headers["x-total"]
		})
		setCasesData(result.data);
		setCasesLoading(false);
	}, [pageIndex, query, memorizedFilters, memorizedSorting]);

	useEffect(() => {
		setPageIndex(0);
	}, [memorizedFilters, memorizedSorting, query]);

	useEffect(() => {
		if (initiationCompleted) {
			loadCases();
		}
	}, [pageIndex, query, memorizedFilters, memorizedSorting, initiationCompleted]);

	return {
		isCasesLoading,
		initiationCompleted,
		setInitiationCompleted,
		casesData,
		casesMetaData,
		loadCases,
		query,
		setQuery,
		setPageIndex,
		pageIndex,
		filters,
		setFilters,
		sorting,
		setSorting,
		classification,
		setClassification,
		setColumns
	};
};

export default useCasesSearch;