import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import InheritedValueIcon from "./InheritedValueIcon";

const Other = ({ tenancyContractData, propertyContractData, updateTenancyContract }) => {

	const paragraph11InitialValue = tenancyContractData.paragraph11 ?? propertyContractData.paragraph11;
	const isParagraph11Inherited = tenancyContractData.paragraph11 === null || tenancyContractData.paragraph11 === "";
	const [paragraph11, setParagraph11] = useState(paragraph11InitialValue ?? "");

	const leaseAdditionalDescriptionInitialValue = tenancyContractData.leaseAdditionalDescription ?? propertyContractData.leaseAdditionalDescription;
	const isLeaseDescriptionInherited = tenancyContractData.leaseAdditionalDescription === null || tenancyContractData.leaseAdditionalDescription === "";
	const [leaseAdditionalDescription, setLeaseAdditionalDescription] = useState(leaseAdditionalDescriptionInitialValue ?? "");

	const usageDescriptionInitialValue = tenancyContractData.usageDescription ?? propertyContractData.usageDescription;
	const isUsageDescriptionInherited = tenancyContractData.usageDescription === null || tenancyContractData.usageDescription === "";
	const [usageDescription, setUsageDescription] = useState(usageDescriptionInitialValue ?? "");

	const petsSpecialConditionsInitialValue = tenancyContractData.petsSpecialConditions ?? propertyContractData.petsSpecialConditions;
	const isPetsSpecialConditionsInherited = tenancyContractData.petsSpecialConditions === null || tenancyContractData.petsSpecialConditions === "";
	const [petsSpecialConditions, setPetsSpecialConditions] = useState(petsSpecialConditionsInitialValue ?? "");

	const petsAllowedValue = tenancyContractData.petsAllowed ?? propertyContractData.petsAllowed;
	const isPetsAllowedInherited = tenancyContractData.petsAllowed === null || tenancyContractData.petsAllowed === "";

	return (
		<Paper className="tenancy-contract-details__other-fields">
			<Typography variant="subtitle">Andet</Typography>
			<div>
				<div className="fields-section__field">
					<Typography variant="label">Benyttelse {isUsageDescriptionInherited && <InheritedValueIcon />}</Typography>
					<TextField
						value={usageDescription}
						onChange={(event) => setUsageDescription(event.target.value)}
						onExit={() => setUsageDescription(usageDescriptionInitialValue)}
						onFinish={() => {
							usageDescription !== usageDescriptionInitialValue && updateTenancyContract({ usageDescription })
						}}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Husdyr {isPetsAllowedInherited && <InheritedValueIcon />}</Typography>
					<div className="switch-container">
						<Switch
							checked={petsAllowedValue}
							onChange={(e) => updateTenancyContract({ petsAllowed: e.target.checked })}
						/>
					</div>
				</div>
				{propertyContractData.petsAllowed && (
					<div>
						<div className="fields-section__field">
							<Typography variant="label">Særlige vilkår for husdyrtilladelsen {isPetsSpecialConditionsInherited && <InheritedValueIcon />}</Typography>
							<TextField
								value={petsSpecialConditions}
								multiline
								rows={5}
								rowsMax={5}
								onChange={(event) => setPetsSpecialConditions(event.target.value)}
								onExit={() => setPetsSpecialConditions(petsSpecialConditionsInitialValue)}
								onFinish={() => {
									petsSpecialConditions !== petsSpecialConditionsInitialValue && updateTenancyContract({ petsSpecialConditions })
								}}
							/>
						</div>
					</div>
				)}
				<div>
					<div className="fields-section__field">
						<Typography variant="label">§ 10. Øvrige oplysninger om det lejede {isLeaseDescriptionInherited && <InheritedValueIcon />}</Typography>
						<TextField
							value={leaseAdditionalDescription}
							multiline
							rows={5}
							rowsMax={20}
							onChange={(event) => setLeaseAdditionalDescription(event.target.value)}
							onExit={() => setLeaseAdditionalDescription(leaseAdditionalDescriptionInitialValue)}
							onFinish={() => {
								leaseAdditionalDescription !== leaseAdditionalDescriptionInitialValue && updateTenancyContract({ leaseAdditionalDescription })
							}}
						/>
					</div>
				</div>
				<div>
					<Typography variant="label">§ 11. Særlige vilkår {isParagraph11Inherited && <InheritedValueIcon />}</Typography>
					<TextField
						multiline
						rows={10}
						rowsMax={20}
						value={paragraph11}
						onChange={(event) => setParagraph11(event.target.value)}
						onExit={() => setParagraph11(paragraph11InitialValue ?? "")}
						onFinish={() => {
							updateTenancyContract({ paragraph11: paragraph11 })
						}}
					/>
				</div>
			</div>
		</Paper>
	);
}

export default Other;