import PropertiesContext from './properties-context';
import React, { useState, useEffect, useCallback } from "react";
import PropertiesService from 'services/api/properties';

const PropertiesProvider = props => {

    const { children } = props;
    const [propertiesData, setPropertiesData] = useState([]);
    const [isPropertiesLoading, setPropertiesLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const propertiesResult = await PropertiesService.getAllProperties();
            setPropertiesData(propertiesResult);
			setPropertiesLoading(false);
        })();
    }, []);

    return (
        <PropertiesContext.Provider
            value={{
                propertiesData: propertiesData,
				isPropertiesLoading: isPropertiesLoading
            }}
        >
            {children}
        </PropertiesContext.Provider>
    );
};

export default PropertiesProvider;