 
 import React from "react";
 import TextField from '@material-ui/core/TextField';
 import Select from '@material-ui/core/Select';
 import MenuItem from '@material-ui/core/MenuItem';

 // Define a default UI for filtering
 export default function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    // const count = preFilteredRows.length

    return (
      <TextField className="ExtendedTable_Filter"
        variant="outlined"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Søg ...`}
      />
    )
  }

  // This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <Select
      className={"select " + (filterValue === "all" || filterValue === undefined ? "placeholder" : "")}
      variant="outlined"
      value={filterValue ? filterValue : "all"}
      onChange={e => {
        e.target.value === "all" ? setFilter(undefined) : setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value="all">Alle</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  )
}
  
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <TextField 
        className="ExtendedTable_Filter"
        variant="outlined"
        value={filterValue[0] || ''}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : null, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      <div>til</div>
      <TextField
        className="ExtendedTable_Filter"
        variant="outlined"
        value={filterValue[1] || ''}
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : null])
        }}
        placeholder={`Maks (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
      
    </div>
  )
}