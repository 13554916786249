import React from 'react';

const ReportFeature = ({ title, selectedMonth, previousMonth }) => {

	const getDelta = () => {
		if (selectedMonth == 0 && previousMonth == 0) {
			return "Samme som forrige måned";
		}

		if (selectedMonth == 0) {
			return `${previousMonth} færre end forrige måned`;
		}

		if (previousMonth == 0) {
			return `${selectedMonth} mere end forrige måned`;
		}

		const delta = Math.abs(((previousMonth - selectedMonth) / previousMonth) * 100);

		if (previousMonth > selectedMonth) {
			return `-${delta.toFixed(0)}% ift. forhænværende måned`;
	
		} else {
			return `+${delta.toFixed(0)}% ift. forhænværende måned`;
		}
	};

	return (
		<div className="report-feature data-container">
			<p className="title">{title}</p>
			<p className="amount">{selectedMonth}</p>
			<p className="delta">{getDelta()}</p>
		</div>
	);
};

export default ReportFeature;