import React, { useState } from 'react';
import './index.scss'

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";


import { Switch } from "components/Fields";
import StyledButton from "components/StyledButton";
import StyledAutocomplete from "components/StyledAutocomplete";
import StyledTextField from "components/StyledTextField";
import { ReactComponent as FilterIcon } from "assets/icons/jorato-filters.svg";

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		left: "50%",
		top: "50%",
		transform: "translateX(-50%) translateY(-50%);",
		borderRadius: theme.spacing(3),
		width: 600,
		backgroundColor: theme.palette.background.paper,
		outline: 0,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
}));


export default function FilterModal(props) {

	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [filter, setFilter] = useState("");
	const [filterValue, setFilterValue] = useState("");

	const handleClose = () => {
		setOpen(false);
		setFilter("");
	};

	const handleSelectFilter = (filter) => {
		if (filter === null) {
			setFilter("")
		} else {
			setFilter(filter);
		}
	}

	const handleAddFilter = () => {
		props.addFilter(filter.Header, filter.accessor, filter.filter, filterValue);
		setOpen(false);
		setFilter("");
		setFilterValue("");
	}

	const handleSetFilterValue = (event) => {
		setFilterValue(event.target.value)
	}

	const handleSetFilterValueRange = (event, type) => {
		if (filterValue === "") {
			if (type === "min") {
				setFilterValue({ min: event.target.value, max: "" })
			} else if (type === "max") {
				setFilterValue({ min: "", max: event.target.value })
			} else if (type === "noValue") {
				setFilterValue("noValue")
			}
		} else {
			if (type === "min") {
				setFilterValue({ min: event.target.value, max: filterValue.max })
			} else if (type === "max") {
				setFilterValue({ min: filterValue.min, max: event.target.value })
			} else if (type === "noValue") {
				setFilterValue("");
			}

		}
	}
	const getValueFromAccessor = (object, accessor) => {
		let value = null;
		if (accessor.includes(".")) {
			const accessors = accessor.split(".")
			if (accessors.length === 2) {
				value = object[accessors[0]] ? object[accessors[0]][accessors[1]] : null
			} else if (accessors.length === 3) {
				value = object[accessors[0]][accessors[1]] ? object[accessors[0]][accessors[1]][accessors[2]] : null
			} else if (accessors.length === 4) {
				value = object[accessors[0]][accessors[1]][accessors[2]] ? object[accessors[0]][accessors[1]][accessors[2]][accessors[3]] : null
			}
		} else (
			value = object[accessor]
		)
		return value
	}
	const getFilter = () => {
		if (filter === "") {
			return
		} else {
			switch (filter.filter) {
				case "between":
					return (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<TextField
								variant="outlined"
								onChange={e => handleSetFilterValueRange(e, "min")}
								placeholder={`Min. værdi`}
								style={{
									marginRight: '0.5rem',
								}}
								type="number"
							/>
							<div>til</div>
							<TextField
								variant="outlined"
								onChange={e => handleSetFilterValueRange(e, "max")}
								placeholder={`Maks. værdi`}
								style={{
									marginLeft: '0.5rem',
								}}
								type="number"
							/>
						</div>
					)
				case "date-range":
					return (
						<>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<TextField
									variant="outlined"
									disabled={filterValue === "noValue"}
									onChange={e => handleSetFilterValueRange(e, "min")}
									type="date"
									placeholder={`Min. værdi`}
									style={{
										marginRight: '0.5rem',
									}}
								/>
								<div>til</div>
								<TextField
									variant="outlined"
									disabled={filterValue === "noValue"}
									onChange={e => handleSetFilterValueRange(e, "max")}
									type="date"
									placeholder={`Maks. værdi`}
									style={{
										marginLeft: '0.5rem',
									}}
								/>
							</div>
							<div style={{
								paddingTop: "8px"
							}}>
								<Switch
									onChange={e => handleSetFilterValueRange(e, "noValue")}
								/>
								Uden værdi
							</div>
						</>
					)
				case "boolean":
					let o = ["Ja", "Nej"];
					return (
						<Select
							variant="outlined"
							displayEmpty
							value={filterValue}
							onChange={handleSetFilterValue}
							style={{ width: "100%", borderRadius: "30px" }}
						>
							<MenuItem disabled key={999} value="">Vælg en værdi</MenuItem>
							{o.map((option, i) => (
								<MenuItem key={i} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					)
				case "exact":
					let options = [];
					if (filter.accessor === "responsiblePerson.name") {
						options = props.employees.data.map(e => e.name)
					} else if (filter.accessor === "visibility") {
						options = [...new Set(props.data.map(item => item[filter.accessor]))];
						options.push("Alle publicerede")
					} else if (filter.accessor === "activeCase") {
						options = ["Ja", "Nej"];
					} else {
						console.log(props.data);
						options = [...new Set(props.data.map(item => getValueFromAccessor(item, filter.accessor)))];
						console.log(options);
					}
					return (
						<Select
							variant="outlined"
							displayEmpty
							value={filterValue}
							onChange={handleSetFilterValue}
							style={{ width: "100%", borderRadius: "30px" }}
						>
							<MenuItem disabled key={999} value="">Vælg en værdi</MenuItem>
							{options.map((option, i) => (
								<MenuItem key={i} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					)
				case "equal":
					if (filter.options && filter.options.length > 0) {
						return (
							<Select
								variant="outlined"
								displayEmpty
								value={filterValue}
								onChange={handleSetFilterValue}
								style={{ width: "100%", borderRadius: "30px" }}
							>
								<MenuItem disabled key={999} value="">Vælg en værdi</MenuItem>
								{filter.options.map((option, i) => (
									<MenuItem key={i} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						);
					} else {
						return (
							<TextField
								variant="outlined"
								placeholder={`Tilføj tekst feltet skal indeholde`}
								onChange={handleSetFilterValue}
							/>
						);
					}
				default:
					return (
						<TextField
							variant="outlined"
							placeholder={`Tilføj tekst feltet skal indeholde`}
							onChange={handleSetFilterValue}
						/>
					)
			}
		}
	}

	return (
		<>
			<div className="filters_button__wrapper" style={props.style}  onClick={() => { setOpen(true); }}>
				<FilterIcon /> Filtre
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				BackdropProps={{ style: { backgroundColor: 'rgb(44 53 137 / 70%)' } }}
			>
				<Grid
					direction="column"
					className={classes.paper}
					container
					spacing={1}
				>
					<Grid item>
						<Typography color="secondary" variant="h3" gutterBottom>
							Tilføj filter
						</Typography>
					</Grid>
					<Grid item>
						<StyledAutocomplete
							options={props.possibleFilters.filter(x => !x.disableFilter)}

							getOptionLabel={(option) => option.Header}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									placeholder="Vælg filter"
									variant="outlined"
								/>
							)}
							onChange={e => handleSelectFilter(e)}
						/>
					</Grid>
					<Grid item>
						{getFilter()}
					</Grid>
					<Grid item container direction="row">
						<Grid item xs={6}>
							<StyledButton style={{ marginTop: '4px' }} elevation={0} onClick={() => setOpen(false)} variant="outlined">
								Anuller
							</StyledButton>
						</Grid>
						<Grid item container direction="row" justify="flex-end" xs={6}>
							<StyledButton style={{ marginTop: '4px' }} elevation={0} onClick={() => handleAddFilter()} variant="contained" color="primary">
								Tilføj
							</StyledButton>
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		</>
	);
}
