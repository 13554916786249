import { useState, useCallback } from "react";
import ShowcasingsService from 'services/api/showcasings';

/*
	useShowcasing hook handles showcasing creation. 
*/
const useCreateShowcasing = () => {

	const [isShowcasingCreating, setIsShowcasingCreating] = useState(false);
	const [showcasingCreated, setShowcasingCreated] = useState(false);

	const createShowcasing = async (tenancyId, guestEmail, guestName, guestPhoneExtension, guestPhoneNumber, hostId, duration, startsAt, isVirtual, alternativeMeetingLocation, communicationLanguage) => {
		setShowcasingCreated(false);
		const payload = {
			tenancyId: tenancyId,
			guest: {
				email: guestEmail,
				name: guestName,
				phone: {
					extension: guestPhoneExtension.toString(),
					number: guestPhoneNumber
				}
			},
			hostId: hostId,
			schedule: {
				duration: duration,
				startsAt: startsAt
			},
			isOnline: isVirtual,
			meetingLocation: alternativeMeetingLocation
				? alternativeMeetingLocation
				: '',
			communicationLanguage: communicationLanguage
		}
		setIsShowcasingCreating(true);
		const result = await ShowcasingsService.createShowcasing(tenancyId, payload);
		if (result !== null) {
			return true;
			setShowcasingCreated(true);
		} else {
			return false;
		}
		setIsShowcasingCreating(false);
	};

	return {
		createShowcasing,
		isShowcasingCreating,
		showcasingCreated,
	}
};

export default useCreateShowcasing;
