import React, { useRef } from 'react';
import Picker from 'react-month-picker'
import "react-month-picker/css/month-picker.css";
import { PrimaryButton } from 'components/Buttons';
import "./index.scss";

const YearMonthPicker = ({value, onChange}) => {

	const refPicker = useRef();

	const pickerLang = {
		months: ['jan', 'febr', 'marts', 'april', 'maj', 'juni', 'juli', 'aug', 'sept', 'okt', 'nov', 'dec'],
		from: 'Fra', to: 'Til',
	}

	const render = m => {
		if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
		return '?'
	}

	const handleClickRangeBox = (e) => {
		refPicker.current.show()
	}

	const handleClickRangeDateChange = (year, month, range) => {
		if (range == 0) {
			onChange({ from: { year: year, month, month }, to: value.to })
		} else {
			onChange({ from: value.from, to: { year: year, month, month } })
		}
	}

	const handleClickSingleDateChange = (year, month) => {
		onChange({ year: year, month, month })
	}

	return (
		<Picker
			ref={refPicker}
			years={{}}
			value={value}
			lang={pickerLang}
			theme="light"
			onChange={value.from || value.to ? handleClickRangeDateChange : handleClickSingleDateChange}
		>
			{value.from || value.to 
				? <PrimaryButton onClick={handleClickRangeBox}>{render(value.from) + ' - ' + render(value.to)}</PrimaryButton>
				: <PrimaryButton onClick={handleClickRangeBox}>{render(value)}</PrimaryButton>
			}
		</Picker>
	);
};

export default YearMonthPicker;