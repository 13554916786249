import React from 'react';
import "./index.scss";
import DatePicker from 'react-date-picker';
import { ReactComponent as CalendarIcon } from "assets/icons/jorato-calendar.svg";

const StyledDatepicker = ({ value, onChange  }) => {

	return <DatePicker 
		className="styled_datepicker" 
		clearIcon={null} 
		onChange={onChange} 
		value={value} 
		calendarIcon={<CalendarIcon />}
		locale="da-DK"
	/>;
}
export default StyledDatepicker;