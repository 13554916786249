import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { Select } from "components/Fields";
import { MenuItem } from "@material-ui/core";
import AttributeField from "./AttributeField";
import { ActionButton } from "components/Buttons";
import Icon from "components/Icon";

const NewAttribute = props => {

    const { existingAttributes, attributeDefinitions, createAttribute } = props;

    const [attributeDefinition, setAttributeDefinition] = useState("");
    const [attributeValue, setAttributeValue] = useState("");

    const existingAttributesDefinitions = existingAttributes.map(x => x.definition.id);
    const allowedAttributeDefinitions = attributeDefinitions ? attributeDefinitions.filter(x => !existingAttributesDefinitions.includes(x.id)) : [];

    const selectAttributeDefinitionHandler = (value) => {
        if (value.kind === "Boolean") setAttributeValue(false);
        else setAttributeValue("");

        setAttributeDefinition(value);
    };

    const createAttributeHandler = () => {
        createAttribute(attributeDefinition.id, attributeValue);
        setAttributeDefinition("");
        setAttributeValue("");
    };

    return (
        <TableRow>
            <TableCell>
                <Select
                    value={attributeDefinition}
                    onChange={(e) => selectAttributeDefinitionHandler(e.target.value)}>
                    {allowedAttributeDefinitions.map((definition, ix) => (
                        <MenuItem key={ix} value={definition}>
                            {definition.description}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                {attributeDefinition
                    ? <AttributeField
                        type={attributeDefinition.kind}
                        value={attributeValue}
                        options={attributeDefinition.options}
                        optionId={attributeValue}
                        onChange={(data) => setAttributeValue(data)} />
                    : null
                }
            </TableCell>
            <TableCell>
                {attributeDefinition
                    ? <ActionButton onClick={createAttributeHandler}>
                        <Icon type="plus" />
                    </ActionButton>
                    : null
                }
            </TableCell>
        </TableRow>
    );
};

export default NewAttribute;