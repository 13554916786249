import React from 'react';
import LoadingComponent from "components/LoadingComponent";
import StyledPaper from "components/StyledPaper";
import ReportFeature from './ReportFeature';
import StateChanges from './StateChanges';
import StateTimeline from './StateTimeline';
import EmployeesTable from './EmployeesTable';

const ReportContent = ({ isLoading, data }) => {

	if (isLoading) {
		return (
			<div className="loading-container"><LoadingComponent /></div>
		);
	}

	return (
		<>
			<div className="report-features-container report-grid">
				<ReportFeature
					title="Fremvisninger"
					selectedMonth={data.showcasings.selectedMonth}
					previousMonth={data.showcasings.previousMonth}
				/>
				<ReportFeature
					title="Åbent hus"
					selectedMonth={data.openHouses.selectedMonth}
					previousMonth={data.openHouses.previousMonth}
				/>
				<ReportFeature
					title="Reservationer"
					selectedMonth={data.uniqueStateChanges.reserved.selectedMonth}
					previousMonth={data.uniqueStateChanges.reserved.previousMonth}
				/>
				<ReportFeature
					title="Udlejninger"
					selectedMonth={data.uniqueStateChanges.completed.selectedMonth}
					previousMonth={data.uniqueStateChanges.completed.previousMonth}
				/>
			</div>
			<div className="state-report report-grid">
				<StateTimeline data={data.stateTimeline} />
				<StateChanges data={data.stateChanges} />
			</div>
			<EmployeesTable data={data.employeesSummary}/>
		</>
	);
};

export default ReportContent;