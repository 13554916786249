import {FETCH_MATCHES} from "redux/actionTypes";
  

const matches = (
    state = {
      data: {},
      loaded: false,
    },
    action
  ) => {
    switch (action.type) {
      case FETCH_MATCHES:
        return {
          ...state,
          data: action.payload,
          loaded: true,
        };
      default:
        return state;
    }
  };
  
  export default matches;
  