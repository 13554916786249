import { TableCell, TableRow } from "@material-ui/core";
import ResourceTable from "components/ResourceTable";
import Typography from "components/Typography";
import React from "react";
import Paper from "components/StyledPaper";
import AttributeField from "./AttributeField";
import NewAttributeRow from "./NewAttributeRow";
import Icon from "components/Icon";

const Terms = props => {

    const { terms, createAttribute, removeAttribute, updateAttribute, attributeDefinitions, readOnly } = props;

    const closeFormHandler = () => {
    }

    const termForm = (
        <NewAttributeRow
            existingAttributes={terms}
            attributeDefinitions={attributeDefinitions}
            createAttribute={createAttribute} />
    );

    return (
        <Paper className="general-information__facilities">
            <ResourceTable
                title={<Typography variant="subtitle">Vilkår</Typography>}
                readOnly={readOnly}
                headers={["Type", "Værdi", ""]}
                sizes={["40%", "40%", "20%"]}
                form={termForm}
                onFormClose={closeFormHandler}>
                {terms.map((term, ix) => (
                    <TableRow key={ix}>
                        <TableCell>{term.definition.description}</TableCell>
                        <TableCell>
                            <AttributeField
                                type={term.definition.kind}
                                value={term.value}
                                readOnly={readOnly}
                                options={term.definition.options}
                                optionId={term.optionId}
                                onChange={(data) => updateAttribute(term.definition.id, { value: data })} />
                        </TableCell>
                        <TableCell className="actions-cell">
                            <Icon onClick={() => removeAttribute(term.definition.id)} type="bin" />
                        </TableCell>
                    </TableRow>
                ))}
            </ResourceTable>
        </Paper>
    );
};

export default Terms;