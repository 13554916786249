import React from "react";
import { styled } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Icon from "components/Icon";

const CustomModal = (props) => {
    return (
        <Modal {...props}>
            <div className="fullscreen-modal__content">
                <Icon className="close-icon" onClick={props.onClose} type="close" />
                {props.children}
            </div>
        </Modal>
    )
}  

const FullScreenModal = styled(CustomModal)({
    backgroundColor: "rgba(44, 53, 137, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .fullscreen-modal__content": {
        backgroundColor: "white",
        borderRadius: "39px",
        padding: "63px",
        position: "relative",
        "&:focus": {
            outline: 0
        },
        "& .close-icon": {
            position: "absolute",
            top: "26px",
            right: "26px",
            cursor: "pointer"
        }
    }
});

export default FullScreenModal;