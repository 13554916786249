import React from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const DragHandle = SortableHandle(({ className }) => (
    <DragIndicatorIcon className={`dragIcon ${className}`} />
));

const Item = SortableElement(props => {

    const { item, editable, variant, onItemDelete } = props;

    return (
        <div className={`gridItemContainer ${variant}Style`}>
            {editable && <DragHandle className={`${variant}Style`} />}
            <div
                onClick={() => window.open(item.original ? item.original : item.url, '_blank')}
                className="gridImage"
                style={{ background: `url(${item?.alternatives?.find(x => true)?.url || item.original || item.url})` }}
            ></div>
            {editable && (
                <DeleteOutlineIcon
                    onClick={() => onItemDelete(item.id)}
                    className={`deleteIcon ${variant}Style`}
                />
            )}
        </div>
    );
});

export default Item;
