import OpenHouses from "./OpenHouses";
import { connect } from "react-redux";
import { readOpenHouses } from "redux/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.general.user,
    openhouses: state.openhouses,
    employees: state.app.employees,
    properties: state.app.properties,
    tenancies: state.app.tenancies
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    readOpenHouses: () => dispatch(readOpenHouses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenHouses);
