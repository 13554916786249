import { useState, useCallback, useEffect } from "react";

/*
    useMemorizedTableSettings hook handles saved data like enabled columns, filters, sorting options. 
*/
const useMemorizedTableSettings = (id) => {

	const [isLoading, setLoading] = useState(true);
    const [enabledColumns, setEnabledColumns] = useState([]);
    const [enabledFilters, setEnabledFilters] = useState([]);
    const [enabledSorting, setEnabledSorting] = useState([]);

    const loadData = useCallback(() => {
		setEnabledFilters(JSON.parse(localStorage.getItem(`${id}_filters`)) ?? []);
		setEnabledSorting(JSON.parse(localStorage.getItem(`${id}_sorting`)) ?? []);
		setEnabledColumns(JSON.parse(localStorage.getItem(`${id}_columns`)) ?? []);
		setLoading(false);
    }, [id]);

	const saveEnabledFilters = (filters) => {
		localStorage.setItem(`${id}_filters`, JSON.stringify(filters));
		setEnabledFilters(filters);
	};

	const saveEnabledColumns = (columns) => {
		localStorage.setItem(`${id}_columns`, JSON.stringify(columns));
		setEnabledColumns(columns);
	};

	const saveEnabledSorting = (sorting) => {
		localStorage.setItem(`${id}_sorting`, JSON.stringify(sorting));
		setEnabledSorting(sorting);
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	return {
		enabledColumns,
		enabledFilters,
		enabledSorting,
		saveEnabledFilters,
		saveEnabledColumns,
		saveEnabledSorting,
		isLoading
	};
}

export default useMemorizedTableSettings;