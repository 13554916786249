import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import "./index.scss";

const Descriptions = props => {

    const { data, update } = props;

    const [title, setTitle] = useState(data.title ?? "");
    const [areaDescription, setAreaDescription] = useState(data.descriptions.area ?? "");
    const [propertyDescription, setPropertyDescription] = useState(data.descriptions.property ?? "");
    const [tenancyDescription, setTenancyDescription] = useState(data.descriptions.tenancy ?? "");

    return (
        <Paper className="general-information__descriptions">
            <Typography variant="subtitle">Beskrivelser</Typography>
            <div className="fields-section__field title-field">
                <Typography variant="label">Titel</Typography>
                <TextField
                    value={title}
                    disabled={data.deletedAt}
                    onChange={(event) => setTitle(event.target.value)}
                    onExit={() => setTitle(data.title)}
                    onFinish={() => {
                        title !== data.title && update({ title: title })
                    }}
                />
            </div>
            <div className="description-fields">
                <div className="fields-section__field">
                    <Typography variant="label">Ejendomsbeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={10}
                        rowsMax={10}
                        value={propertyDescription}
                        disabled={data.deletedAt}
                        onChange={(event) => setPropertyDescription(event.target.value)}
                        onExit={() => setPropertyDescription(data.descriptions.property)}
                        onFinish={() => {
                            propertyDescription !== data.descriptions.property && update({ descriptions: { property: propertyDescription } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Lejemalsbeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={10}
                        rowsMax={10}
                        value={tenancyDescription}
                        disabled={data.deletedAt}
                        onChange={(event) => setTenancyDescription(event.target.value)}
                        onExit={() => setTenancyDescription(data.descriptions.tenancy)}
                        onFinish={() => {
                            tenancyDescription !== data.descriptions.tenancy && update({ descriptions: { tenancy: tenancyDescription } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Områdebeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={10}
                        rowsMax={10}
                        value={areaDescription}
                        disabled={data.deletedAt}
                        onChange={(event) => setAreaDescription(event.target.value)}
                        onExit={() => setAreaDescription(data.descriptions.area)}
                        onFinish={() => {
                            areaDescription !== data.descriptions.area && update({ descriptions: { area: areaDescription } })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default Descriptions;