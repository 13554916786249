import {
    FETCH_OPENHOUSES,
    FETCH_TENANCY_KINDS
} from "redux/actionTypes";

const openhouses = (
    state = {
        data: [],
        loaded: false,
        tenancyKinds: {
            data: [],
            loaded:false,
        }
    },
    action
) => {
    switch (action.type) {
        case FETCH_OPENHOUSES:
            return {
                ...state,
                data: action.payload,
                loaded: true,
            };
            case FETCH_TENANCY_KINDS:
                return {
                    ...state,
                    tenancyKinds: {
                        data: action.payload,
                        loaded: true
                    }
                };
        default:
            return state;
    }
};

export default openhouses;
