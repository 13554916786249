import React from 'react';
import { styled } from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";

const ControlledAutoComplete = (props) => {
  let hasValue = true;
  if (props.value === "" || props.value === undefined || props.value?.length === 0) {
    hasValue = false;
  }
  return <Autocomplete 
    {...props}
    value={props.value}
    className={`${props.className} ${hasValue ? "hasValue" : ""} ${props.disabled ? "disabled" : ""}`}
    onChange={(event, newValue)=> {
      props.onChange(newValue)
    }}
  />
}

export default styled(ControlledAutoComplete)({
  '&': {
    borderRadius: 30,
  },
  '&.top .MuiTextField-root': {
    borderRadius: "0px 0px 30px 30px",
  },
  '&.hasValue .MuiTextField-root': {
    border: "1px solid #566FFF",
  },
  '&.hasValue .MuiTextField-root input': {
    color: "#566FFF"
  },
  '& MuiAutocomplete-inputRoot': {
    color: "#566FFF"
  },
  '&.disabled .MuiTextField-root input':{
    color: "rgba(0, 0, 0, 0.87)",
  },
  '&.disabled .MuiTextField-root': {
    border: "1px solid rgba(0,0,0,0.50)",
  },
  '&[aria-expanded=true] .MuiTextField-root': {
    border: "1px solid #566FFF",
  },
  "& .MuiInputBase-root input": {
    border: "none",
    fontFamily: "Montserrat",
    padding: "14px 14px 14px 30px" ,
    fontSize: "16px !important"
  },
  "& .MuiInputBase-root input::placeholder": {
    color: "rgba(0,0,0,0.87)"
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    paddingLeft: "21px"
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: "5px 9px"
  },
  '& .MuiAutocomplete-option[data-focus="true"]': {
    backgroundColor: "#F7F8FD !important"
  },
  '& .MuiAutocomplete-option': {
    color: "#A8A8A8",
    fontSize: "16px"
  },
  '.MuiAutocomplete-paper': {
    margin: "0"
  },
  '.MuiAutocomplete-listbox': {
    maxHeight: "18vh"
  }
});