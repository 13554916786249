import Showcasings from "./Showcasings";
import { connect } from "react-redux";
import { readShowcasings } from "redux/actions";

const mapStateToProps = (state, ownProps) => {
    
  return {
    user: state.general.user,
    showcasings: state.showcasings.showcasings,
    employees: state.app.employees,
    properties: state.app.properties,
    tenancies: state.app.tenancies
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    readShowcasings: () => dispatch(readShowcasings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Showcasings);
