import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const WaterUtility = ({ contractDetails, updatePropertyContract }) => {

    const [accountingStartDate, setAccountingStartDate] = useState(contractDetails.utilities.water.accountingStartDate ?? "");

    return (
        <>
            <Typography variant="label" className="utility-title">Vand</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Udlejer ansvarlig</Typography>
                    <Switch
                        checked={contractDetails.utilities.water.isLandlordResponsible}
                        onChange={(e) => updatePropertyContract({ utilities: { water: { isLandlordResponsible: e.target.checked } } })}
                    />
                </div>
				<div className="fields-section__field">
                    <Typography variant="label">Lejemålet har individuelle målere</Typography>
                    <Switch
                        checked={contractDetails.utilities.water.hasIndividualMeters}
                        onChange={(e) => updatePropertyContract({ utilities: { water: { hasIndividualMeters: e.target.checked } } })}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Vandregnskabsåret begynder</Typography>
                    <TextField
                        value={accountingStartDate ?? ""}
                        type="date"
                        onChange={(event) => setAccountingStartDate(event.target.value)}
                        onExit={() => setAccountingStartDate(contractDetails.utilities.water.accountingStartDate)}
                        onFinish={() => {
                            accountingStartDate !== contractDetails.utilities.water.accountingStartDate && updatePropertyContract({ utilities: { water: { accountingStartDate } } })
                        }}
                    />
                </div>
            </div>
            <hr/>
        </>
    );
};

export default WaterUtility;