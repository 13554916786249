import axios from "./axios";

export default class TenanciesService {

    static async getTenancy(tenancyId) {
        try {
            const result = await axios.get(`/tenancies/${tenancyId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async queryTenancies(ids) {
        try {
            const result = await axios.post(`tenancies/query`, {
				includeOnlyIds: ids
			});
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async searchTenancies(data) {
        try {
            const result = await axios.post(`tenancies/search`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async getTenanciesByIds(tenancyIds) {
		try {
			const body = {
				includeOnlyIds: [...tenancyIds]
			}
			const result = await axios.post(`tenancies/query`, body);
			return result;
		}
		catch (err) {
			return null;
		}
	}

    static async createTenancy(data) {
        try {
            const result = await axios.post(`/tenancies`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async createTenancyBasedCase(tenancyId) {
        try {
            const result = await axios.post(`/tenancies/${tenancyId}/cases`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async deleteTenancy(tenancyId) {
        try {
            const result = await axios.delete(`/tenancies/${tenancyId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }


    static async getTenancyInterestLists(tenancyId) {
        try {
            const result = await axios.get(`/tenancies/${tenancyId}/interestlists`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateTenancy(tenancyId, data) {
        try {
            const result = await axios.patch(`tenancies/${tenancyId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addTenancyType(tenancyId, type) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/types/${type}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeTenancyType(tenancyId, type) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/types/${type}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addTenancyLocationPoint(tenancyId, location) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/locations/${location}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeTenancyLocationPoint(tenancyId, location) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/locations/${location}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addTenancyExpense(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/expenses`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async updateTenancyExpense(tenancyId, expenseId, data) {
        try {
            const result = await axios.patch(`tenancies/${tenancyId}/expenses/${expenseId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeTenancyExpense(tenancyId, expenseId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/expenses/${expenseId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addTenancyContact(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/contacts`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeTenancyContact(tenancyId, contactId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/contacts/${contactId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async createTenancyAttribute(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/attributes`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateTenancyAttribute(tenancyId, attributeId, data) {
        try {
            const result = await axios.patch(`tenancies/${tenancyId}/attributes/${attributeId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeTenancyAttribute(tenancyId, attributeId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/attributes/${attributeId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadImage(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/images`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteImage(tenancyId, imageId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/images/${imageId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateImage(tenancyId, imageId, data) {
        try {
            const result = await axios.patch(`tenancies/${tenancyId}/images/${imageId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadFloorPlan(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/floor-plans`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteFloorPlan(tenancyId, floorPlanId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/floor-plans/${floorPlanId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateFloorPlan(tenancyId, floorPlanId, data) {
        try {
            const result = await axios.patch(`tenancies/${tenancyId}/floor-plans/${floorPlanId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadDocument(tenancyId, data) {
        try {
            const result = await axios.post(`tenancies/${tenancyId}/documents`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteDocument(tenancyId, documentId) {
        try {
            const result = await axios.delete(`tenancies/${tenancyId}/documents/${documentId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async getTenancyAnalytics(tenancyId) {
        try {
            const result = await axios.get(`/tenancies/${tenancyId}/analytics`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async getTenancyContractData(tenancyId) {
		try {
			const result = await axios.get(`tenancies/${tenancyId}/contract-data`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updateTenancyContractData(tenancyId, data) {
		try {
			const result = await axios.patch(`tenancies/${tenancyId}/contract-data`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}
}