import {
  FETCH_ATTRIBUTES,
  FETCH_CONTACT_ROLES,
  FETCH_EXPENSE_TYPES,
  API_ERROR,
  FETCH_TENANCY_TYPES,
  SET_TENANCY_IMAGE,
  SET_PROPERTY_IMAGE,
  SET_CASE_IMAGE,
  FETCH_ORGANIZATION_INTEGRATIONS,
  FETCH_ORGANIZATION_EMPLOYEES,
  SET_ALL_PROPERTIES,
  SET_ALL_TENANCIES,
} from "redux/actionTypes";

const app = (
  state = {
    attributes: {
      loaded: false,
      data: {},
    },
    contactRoles: {
      loaded: false,
      data: [],
    },
    expenseTypes: {
      loaded: false,
      data: [],
    },
    employees:{
      loaded: false,
      data: []
    },
    tenancyTypes: {
      loaded: false,
      data: {},
    },
    integrations:{
      loaded: false,
      data: {}
    },
    properties:{
      loaded: false,
      data: {}
    },
    tenancies:{
      loaded: false,
      data: {}
    },
    location:"/dashboard",
    uploadingImage: false,
  },
  action
) => {
  switch (action.type) {
    case "ADD_IMAGE":
      return {
        ...state,
        uploadingImage: true,
      };
    case SET_TENANCY_IMAGE:
    case SET_PROPERTY_IMAGE:
    case SET_CASE_IMAGE:
      return {
        ...state,
        uploadingImage: false,
      };
    case FETCH_ATTRIBUTES:
      return {
        ...state,
        attributes: {
          loaded: true,
          data: action.payload,
        },
      };
    case FETCH_ORGANIZATION_EMPLOYEES:
      return{
        ...state,
        employees:{
          loaded: true,
          data: action.payload
        }
      };
    case FETCH_ORGANIZATION_INTEGRATIONS:
      return{
        ...state,
        integrations:{
          loaded: true,
          data: action.payload
        }
      };
    case FETCH_CONTACT_ROLES:
      return {
        ...state,
        contactRoles: {
          loaded: true,
          data: action.payload,
        },
      };
    case FETCH_EXPENSE_TYPES:
      // return {...state}
      return {
        ...state,
        expenseTypes: {
          loaded: true,
          data: action.payload,
        },
      };
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_TENANCY_TYPES:
      return {
        ...state,
        tenancyTypes: {
          loaded: true,
          data: action.payload,
        },
      };
      case SET_ALL_PROPERTIES:
        return {
          ...state,
          properties: {
            loaded: true,
            data: action.payload,
          },
        };
      case SET_ALL_TENANCIES:
        return {
          ...state,
          tenancies: {
            loaded: true,
            data: action.payload,
          },
        };
    default:
      return state;
  }
};

export default app;
