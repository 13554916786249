import initialPropertiesState from './properties-state';
import { createContext } from 'react';

/**
 * @ignore
 */
const stub = () => {
    throw new Error('You forgot to wrap your component in <PropertiesProvider>.');
};

/**
 * @ignore
 */
const initialContext = {
    ...initialPropertiesState
};

const PropertiesContext = createContext(initialContext);

export default PropertiesContext;