import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import styles from "assets/mapStyles";
import MapMarketIcon from "assets/icons/mapMarker.svg";
import './index.scss';

const GoogleMaps = (props) => {

	const { latitude, longitude } = props;

	return (
		<Map
			google={props.google}
			zoom={14}
			styles={styles}
			initialCenter={{
				lat: latitude,
				lng: longitude,
			}}
			disableDefaultUI={true}
			scrollwheel={false}>
			<Marker icon={MapMarketIcon} />
		</Map>
	);
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMaps);