const BusinessTenancyTypes = [
    "Office",
    "Storage",
    "Production",
    "Restaurant",
    "Shop",
    "Hotel",
    "Clinic",
    "Café",
    "Workshop",
    "TakeAway",
    "Showroom"
];

const ResidentialTenancyTypes = [
    "Apartment",
    "House",
    "Terrace"
];

export {
    BusinessTenancyTypes, ResidentialTenancyTypes
};