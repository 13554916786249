import CreateInterestList from "./CreateInterestList";
import { connect } from "react-redux";
import { readPropertyTenancies, readTenancy, createShowcasing } from "redux/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    readPropertyTenancies: (propertyId) => dispatch(readPropertyTenancies(propertyId)),
    readTenancy: (id) => dispatch(readTenancy(id)),
    createShowcasing:(tenancyId, data) => dispatch(createShowcasing(tenancyId, data))
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.general.user,
    employees: state.app.employees,
    properties: state.app.properties,
    tenancies: state.app.tenancies
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInterestList);