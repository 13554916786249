import React, { useState } from "react";
import Typography from "components/Typography";
import "./index.scss";
import StyledPaper from "components/StyledPaper";
import { TextField } from "components/Fields";
import { ActionButton } from "components/Buttons";
import ApplicantScreeningService from "services/api/applicant-screening";
import Icon from "components/Icon";
import LoadingComponent from "components/LoadingComponent";

const ScreeningPage = () => {

	const [cpr, setCpr] = useState("");
	const [screeningResult, setScreeningResult] = useState(null);
	const [validationError, setValidationError] = useState("");
	const [isResultLoading, setResultLoading] = useState(false);

	const getResultHandler = async () => {
		if (cpr.length != 10 || !cpr.match("[0-9]+")) {
			setValidationError("CPR is invalid")
			return;
		}

		setResultLoading(true);
		const result = await ApplicantScreeningService.GetCprScreening(cpr);
		if (result.status === 200) {
			setScreeningResult(result.data);
			setCpr("");
		} else {
			setValidationError(result.data.message);
		}
		setResultLoading(false);
	};

	const ValidationError = (
		<div className="validation-error">
			<p className="text">{validationError}</p>
			<Icon className="close-icon" onClick={() => setValidationError("")} type="close" />
		</div>
	);

	const Loader = (
		<div className="loader-container">
			<LoadingComponent />
		</div>
	);

	const Form = (
		<>
			{isResultLoading && Loader}
			{validationError != "" && ValidationError}
			<Typography variant="subtitle">RKI tjek</Typography>
			<p className="description">RKI er Danmarks største register over "dårlige betalere". Gennem opslag på CPR nummer kan du lave opslag direkte i registret og derved forsøge at undgå lejere der ikke betaler.</p>
			<div className="input-group">
				<Typography variant="label">CPR nummer</Typography>
				<TextField
					value={cpr}
					onChange={(event) => setCpr(event.target.value)}
				/>
			</div>
			<ActionButton onClick={getResultHandler}>Slä op i RKI</ActionButton>
		</>
	);

	const Result = (
		<>
			<Typography variant="subtitle">RKI tjek</Typography>
			<div className="result-container">
				<div className="left">
					<Typography variant="label">Persondata</Typography>
					<p className="result-data">
						{screeningResult?.name} <br />
						{screeningResult?.street} {screeningResult?.houseNumber} <br />
						{screeningResult?.zipCode} {screeningResult?.city}
					</p>
				</div>
				<div className="right">
					<Typography variant="label">Ikke registreret i RKI</Typography>
					<p className="result-data">
						{screeningResult?.HasPaymentRemarks ? "Found" : "Not found"}
					</p>
				</div>
			</div>
			<ActionButton onClick={() => setScreeningResult(null)}>Nyt opslag</ActionButton>
		</>
	);

	return (
		<div className="screening-page__container">
			<Typography variant="title">Kreditvurdering</Typography>
			<StyledPaper>
				<div className="internal-container">
					{screeningResult != null ? Result : Form}
				</div>
			</StyledPaper>
		</div>
	);
};

export default ScreeningPage;