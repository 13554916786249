const states = { 
	input: 'input',
	confirm_creation: 'confirm_creation',
	confirm_update: 'confirm_update',
	confirm_delete: 'confirm_delete',
	success: 'success',
	deleted: 'deleted',
	error: 'error'
}

export default states;