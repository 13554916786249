import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import { useCasesSearch } from "hooks/api"
import { useTenanciesSearch } from "hooks/api";
import { usePropertiesSearch } from "hooks/api";
import { translateString } from "helpers";
import { useHistory } from "react-router-dom";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";
import { ReactComponent as SearchIcon } from "assets/icons/jorato-search.svg";

export default function GlobalSearch(props) {
	const [typedQuery, setTypedQuery] = useState('');
	const [isFocused, setIsFocused] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { isCasesLoading, casesData, casesMetaData, setQuery: setCasesQuery, setInitiationCompleted: setCasesInitiationCompleted } = useCasesSearch({ state: 'all' });
	const { isTenanciesLoading, tenanciesData, tenanciesMetaData, setQuery: setTenanciesQuery, setInitiationCompleted: setTenanciesInitiationCompleted } = useTenanciesSearch();
	const { isPropertiesLoading, propertiesData, propertiesMetaData, setQuery: setPropertiesQuery, setInitiationCompleted: setPropertiesInitiationCompleted  } = usePropertiesSearch();

	const history = useHistory();
	const searchInput = useRef(null);

	useEffect(() => {
		setCasesInitiationCompleted(true);
		setTenanciesInitiationCompleted(true);
		setPropertiesInitiationCompleted(true);
	}, []);

	useEffect(() => {
		if (isFocused) {
			searchInput.current.focus();
		}
	}, [isFocused]);

	useEffect(() => {
		if (isLoading === true && !isTenanciesLoading && !isPropertiesLoading && !isCasesLoading && typedQuery.length > 2) {
			setIsLoading(false);
		}
	}, [isTenanciesLoading, isPropertiesLoading, isCasesLoading, casesData, tenanciesData, propertiesData]);

	const handleChange = (e) => {
		setTypedQuery(e.target.value);
		if (e.target.value.length > 2) {;
			setIsLoading(true);
			setCasesQuery(e.target.value);
			setTenanciesQuery(e.target.value);
			setPropertiesQuery(e.target.value);
		}
	}

	const handleCaseClick = (id) => {
		history.push(`/cases/${id}`);
		closePopup();
	}
	const handleTenancyClick = (id) => {
		history.push(`/tenancies/${id}`);
		closePopup();
	}
	const handlePropertyClick = (id) => {
		history.push(`/properties/${id}`);
		closePopup();
	}

	const handleOnKeyDown = (event) => {
        if (event.key === "Escape") {
			closePopup()
        }
    };

	const handleCloseClick = () => {
		closePopup()
	}

	const closePopup = () => {
		setTypedQuery('');
		setIsFocused(false);
		searchInput.current.blur();
	}

	const handleIconClick = () => {
		setIsFocused(true);
	}

	let displayed;

	if (casesData.length > 0 || tenanciesData.length > 0 || propertiesData.length > 0) {
		let totalResults = 0
		let shownResults = 0;
		if (casesMetaData && casesMetaData.TotalCases > 0) {
			totalResults = parseInt(casesMetaData.TotalCases);
			if (casesMetaData.TotalCases > 5) {
				shownResults = 5;
			} else {
				shownResults = parseInt(casesMetaData.TotalCases);
			}
		}
		if (tenanciesMetaData && tenanciesMetaData.TotalTenancies > 0) {
			totalResults += parseInt(tenanciesMetaData.TotalTenancies);
			if (tenanciesMetaData.TotalTenancies > 5) {
				shownResults += 5;
			} else {
				shownResults += parseInt(tenanciesMetaData.TotalTenancies);
			}
		}
		if (propertiesMetaData && propertiesMetaData.TotalProperties > 0) {
			totalResults += parseInt(propertiesMetaData.TotalProperties);
			if (propertiesMetaData.TotalProperties > 5) {
				shownResults += 5;
			} else {
				shownResults += parseInt(propertiesMetaData.TotalProperties);
			}
		}
		displayed = (
			<div className="search-results-info">Viser {shownResults} ud af {totalResults} resultater for "{typedQuery}"</div>
		);
	}	

	return (
		<div onKeyDown={(e) => handleOnKeyDown(e)} className={`global-search-wrapper ${isFocused ? "active" : ""}`}>
			<div className="global-search-container">
				{!isFocused && (
					<div className="initial-display-element">
						<SearchIcon onClick={handleIconClick} />
					</div>
				)}
				<div className={`global-search-input-wrapper ${isFocused ? 'active' : ''}`}>
					<input 
						type="text" 
						placeholder="Søg..."
						value={typedQuery}
						onChange={handleChange}
						onFocus={() => setIsFocused(true)}
						ref={searchInput}
					/>
					<div onClick={handleCloseClick} className="icon-wrapper"><CloseIcon /></div>
				</div>
				{isFocused && typedQuery.length <= 2 && (
					<div className="search-results-wrapper">
						<div className="search-results-info">Skriv mindst tre tegn for at søge. Der vises maks 5 resultater for hver type.</div>
					</div>
				)}
				{isFocused && typedQuery.length > 2 && casesMetaData.TotalCases === "0" && tenanciesMetaData.TotalTenancies === "0" && propertiesMetaData.TotalProperties === "0" && (
					<div className="search-results-wrapper">
						<div className="search-results-info">Din søgning gav ikke noget resultat.</div>
					</div>
				)}
				{isFocused && typedQuery.length > 2 && (
					<div className="search-results-wrapper">
						{isLoading && (
							<div className="loading">
								Loading
							</div>
						)}
						{!isLoading && (
							<div className="search-results-container">
								{displayed}
								{casesData.length > 0 && (
									<div className="search-results-title">Udlejningssager</div>
								)}
								{casesData.slice(0, 5).length > 0 && casesData.slice(0, 5).map((item, index) => {
									return (
										<div className="search-results-item" key={index} onClick={() => handleCaseClick(item.id)}>
											<div className="search-results-item-title">{item.addressStreet}</div>
											<div className="chip__wrapper">
												<div className="chip__label">{translateString(item.state)}</div>
											</div>
										</div>
									)
								})}
								{tenanciesData.length > 0 && (
									<div className="search-results-title">Lejemål</div>
								)}
								{tenanciesData.length > 0 && tenanciesData.slice(0, 5).map((item, index) => {
									return (
										<div className="search-results-item" key={index} onClick={() => handleTenancyClick(item.id)}>
											{item.addressStreet}
										</div>
									)
								})}
								{propertiesData.length > 0 && (
									<div className="search-results-title">Ejendomme</div>
								)}
								{propertiesData.length > 0 && propertiesData.map((item, index) => {
									return (
										<div className="search-results-item" key={index} onClick={() => handlePropertyClick(item.id)}>
											{item.title}
										</div>
									)
								})}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}