import axios from "axios";
import { API } from "redux/actionTypes";
import { accessDenied, apiError, apiStart, apiEnd } from "./actions";
import { getToken } from "helpers/auth";


const apiMiddleware = ({ dispatch }) => next => action => {
  action && next(action);
  if (!action || action.type !== API) return;

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers
  } = action.payload;
  
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
  
  
  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  
  axios.interceptors.request.use(async function (config) {
    const token = await getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  
 
  if (label) {
    if(label === "ADD_IMAGE"){
      dispatch({type: label})
    }
    dispatch(apiStart(label));
  }
  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data
    })
    .then(({ data, headers}) => {
      dispatch(onSuccess(data, headers));
    })
    .catch(error => {
      // dispatch(apiError(error));
      dispatch(onFailure(error));

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
   .finally(() => {
      if (label) {
        dispatch(apiEnd(label));
      }
   });
};

export default apiMiddleware;