import {
    FETCH_CASE_APPLICATION,
    FETCH_APPLICATION_CONTRACT
} from "redux/actionTypes";

const applications = (
    state = {
        data: {},
        loaded: false,
        contract:{
            data:{},
            loaded: false,
        },
        payment: {
            data: null,
            loaded: false
        },
    },
    action
) => {
    switch (action.type) {
        case FETCH_CASE_APPLICATION:
            return {
                ...state,
                data: action.payload,
                loaded: true
            };
        case FETCH_APPLICATION_CONTRACT:
            return {
                ...state,
                contract: {
                    data: action.payload.status === 404 ? undefined : action.payload ,
                    loaded: true
                }
            };
        default:
            return state;
    }
};

export default applications;
