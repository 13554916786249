import React from "react";
import PropTypes from 'prop-types';

const ExternalLink = (props) => {
	const redirectToLink = () => {
		const urlPrefixList = [
			{
				organization: "Kereby",
				domain: "kereby.dk",
				baseUrl: "https://kerebyudlejning.dk/",
				businessPrefix: "erhverv/",
				resendetialPrefix: "bolig/"
			},
			{
				organization: "Wesoft",
				domain: "wesoft.dk",
				baseUrl: "https://wesoft.dk/",
				businessPrefix: "erhverv/",
				resendetialPrefix: "bolig/"
			},
			{
				organization: "Norse PM",
				domain: "norsepm.dk",
				baseUrl: "https://norsepm.dk/",
				businessPrefix: "",
				resendetialPrefix: "bolig/"
			}
		]

		const urlPrefix = urlPrefixList.find(e => e.organization === props.organizationName)
		var link = "";
		switch (props.caseType) {
			case "Residential":
				if (props.userEmail.includes("norse")) {
					link = urlPrefix.baseUrl + urlPrefix.resendetialPrefix + props.tenancyId;
				} else {
					link = urlPrefix.baseUrl + urlPrefix.resendetialPrefix + props.caseId;
				}
				break;
			case "Business":
				link = urlPrefix.baseUrl + urlPrefix.businessPrefix + props.link;
				break;
			default:
				link = urlPrefix.baseUrl + props.link;
				break;
		}
		if (props.mailto) {
			window.location.href = "mailto:?subject=" + encodeURIComponent(props.caseAddress) + "&body=%0D%0A" + encodeURIComponent(link);
		}
		else {
			window.open(link, '_blank');
		}

	}

	return (
		<div onClick={() => redirectToLink()}>
			{props.children}
		</div>
	)
}

ExternalLink.propTypes = {
	link: PropTypes.string,
	mailto: PropTypes.bool
};

ExternalLink.defaultProps = {
	link: "https://google.com",
	mailto: false
};

export default ExternalLink;