import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { MoneyField, TextField } from "components/Fields";
import AdditionalExpensesTable from "./AdditionalExpensesTable";
import Icon from "components/Icon";
import "./index.scss";

const Expenses = props => {

    const { data, createExpense, removeExpense, update, updateExpense } = props;
    const [rent, setRent] = useState(data.monthlyRent.value);
    const [prepaidRentPeriod, setPrepaidRentPeriod] = useState(data.prepaidRent.period);
    const [prepaidRentAmount, setPrepaidRentAmount] = useState(data.prepaidRent.amount.value);
    const [depositPeriod, setDepositPeriod] = useState(data.deposit.period);
    const [depositAmount, setDepositAmount] = useState(data.deposit.amount.value);

    const calculatePrepaidRentAmount = () => {
        const amount = rent * prepaidRentPeriod;
        setPrepaidRentAmount(amount);
        update({ prepaidRent: { amount: { value: amount } } });
    }

    const calculateDepositAmount = () => {
        const amount = rent * depositPeriod;
        setDepositAmount(amount);
        update({ deposit: { amount: { value: amount } } });
    }

    return (
        <Paper className="general-information__expenses">
            <Typography variant="subtitle">Omkostninger</Typography>
            <div className="wrapper">
                <div className="fields">
                    <div className="fields-section__field">
                        <Typography variant="label">Leje</Typography>
                        <MoneyField
                            value={rent}
                            disabled={data.deletedAt}
                            onChange={setRent}
                            onExit={() => setRent(data.monthlyRent.value)}
                            onFinish={() => {
                                rent !== data.monthlyRent.value && update({ monthlyRent: { value: rent } })
                            }}
                            endAdornment={<>kr./md.</>}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Forudbetalt leje periode</Typography>
                        <TextField
                            value={prepaidRentPeriod}
                            disabled={data.deletedAt}
                            type="number"
                            onChange={(event) => setPrepaidRentPeriod(event.target.value)}
                            onExit={() => setPrepaidRentPeriod(data.monthlyRent.value)}
                            onFinish={() => {
                                rent !== data.prepaidRent.period && update({ prepaidRent: { period: prepaidRentPeriod } })
                            }}
                            endAdornment={<>md.</>}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Forudbetalt leje</Typography>
                        <MoneyField
                            value={prepaidRentAmount}
                            disabled={data.deletedAt}
                            type="number"
                            onChange={setPrepaidRentAmount}
                            onExit={() => setPrepaidRentAmount(data.prepaidRent.amount.value)}
                            onFinish={() => {
                                rent !== data.prepaidRent.amount.value && update({ prepaidRent: { amount: { value: prepaidRentAmount } } })
                            }}
                            endAdornment={<div className="calc-wrapper">kr. <Icon onClick={calculatePrepaidRentAmount} type="calc" /></div>}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Depositum periode</Typography>
                        <TextField
                            value={depositPeriod}
                            disabled={data.deletedAt}
                            type="number"
                            onChange={(event) => setDepositPeriod(event.target.value)}
                            onExit={() => setDepositPeriod(data.deposit.period)}
                            onFinish={() => {
                                rent !== data.deposit.period && update({ deposit: { period: depositPeriod } })
                            }}
                            endAdornment={<>md.</>}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Depositum</Typography>
                        <MoneyField
                            value={depositAmount}
                            disabled={data.deletedAt}
                            type="number"
                            onChange={setDepositAmount}
                            onExit={() => setDepositAmount(data.deposit.amount.value)}
                            onFinish={() => {
                                rent !== data.deposit.amount.value && update({ deposit: { amount: { value: depositAmount } } })
                            }}
                            endAdornment={<div className="calc-wrapper">kr. <Icon onClick={calculateDepositAmount} type="calc" /></div>}
                        />
                    </div>
                </div>
                <AdditionalExpensesTable
                    expenses={data.expenses}
                    disabled={data.deletedAt}
					updateExpense={updateExpense}
                    createExpense={createExpense}
                    removeExpense={removeExpense} />
            </div>
        </Paper>
    )
}

export default Expenses;