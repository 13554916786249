import React from "react";
import Properties from "pages/Properties";
import Property from "pages/Property";
import Tenancies from "pages/Tenancies";
import Tenancy from "pages/Tenancy";
import Cases from "pages/Cases";
import Case from "pages/Case";
import Users from "pages/Users";
import Application from "pages/Application"
import Dashboard from "pages/Dashboard";
import NoMatch from "pages/NoMatch";
import Reports from "pages/Reports";
import Showcasings from "pages/Showcasings";
import Showcasing from "pages/Showcasings/pages/Showcasing";
import OpenHouses from "pages/OpenHouses";
import CreateOpenHouse from "pages/OpenHouses/pages/CreateOpenHouse";
import EditOpenHouse from "pages/OpenHouses/pages/EditOpenHouse";
import InterestLists from "pages/InterestLists";
import CreateInterestList from "pages/InterestLists/pages/CreateInterestList";
import EditInterestList from "pages/InterestLists/pages/EditInterestList";
import ReportAvailableList from "pages/Reports/Pages/ReportAvailableList";
import InterestListByTenancy from "pages/InterestLists/pages/InterestListByTenancy";
import { Switch, Redirect, Route } from "react-router-dom";
import UserProfile from "pages/UserProfile";
import EmployeeShowcasingsReport from "pages/Reports/Pages/EmployeeShowcasingsReport";
import Screening from "pages/Screening";
import TenancyShowcasingsReport from "pages/Reports/Pages/TenancyShowcasingsReport";
import OrganizationReport from "pages/Reports/Pages/OrganizationReport";

const MainRouter = () => {
    return (
        <Switch>
            <Route path="/properties/:id">
                <Property />
            </Route>
            <Route path="/properties">
                <Properties />
            </Route>
            <Route path="/cases/draft">
                <Cases caseState="draft" title="Kladder" />
            </Route>
            <Route path="/cases/active">
                <Cases caseState="active" title="Aktive" />
            </Route>
            <Route path="/cases/reserved">
                <Cases caseState="reserved" title="Reserverede" />
            </Route>
            <Route path="/cases/completed">
                <Cases caseState="completed" title="Afsluttede" />
            </Route>
            <Route path="/cases/deleted">
                <Cases caseState="deleted" title="Slettede" />
            </Route>
            <Route path="/cases/:id">
                <Case />
            </Route>
			<Route path="/reports/organization">
                <OrganizationReport />
            </Route>
            <Route path="/reports/available-list">
                <ReportAvailableList />
            </Route>
			<Route path="/reports/employee-showcasings">
                <EmployeeShowcasingsReport />
            </Route>
			<Route path="/reports/tenancy-showcasings">
                <TenancyShowcasingsReport />
            </Route>
            <Route path="/reports">
                <Reports />
            </Route>
            <Route path="/tenancies/:id/interestlists">
                <InterestListByTenancy />
            </Route>
            <Route path="/tenancies/:id">
                <Tenancy />
            </Route>
            <Route path="/tenancies">
                <Tenancies />
            </Route>
            <Route path="/application/:id">
                <Application />
            </Route>
            <Route path="/dashboard">
                <Dashboard />
            </Route>
			<Route path="/showcasings/create">
                <Showcasing />
            </Route>
            <Route path="/showcasings/:id">
                <Showcasing />
            </Route>
            <Route path="/showcasings">
                <Showcasings />
            </Route>
            <Route path="/openhouses/create">
                <CreateOpenHouse />
            </Route>
            <Route path="/openhouses/:id">
                <EditOpenHouse />
            </Route>
            <Route path="/openhouses">
                <OpenHouses />
            </Route>
			<Route path="/screening">
                <Screening />
            </Route>
            <Route path="/interestlists">
                <InterestLists />
            </Route>
            <Route path="/interestlist/create">
                <CreateInterestList />
            </Route>
            <Route path="/interestlist/:id">
                <EditInterestList />
            </Route>
            <Route path="/profile">
                <UserProfile />
            </Route>
			<Route path="/users">
				<Users />
			</Route>
			<Route exact path="/">
				<Redirect to={"/dashboard"} />
			</Route>
			<Route path="*">
				<NoMatch />
			</Route>
		</Switch>
	);
};

export default MainRouter;