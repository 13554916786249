import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";

const BaseFields = props => {

    const { tenancyData, propertyData } = props;

    return (
        <Paper>
            <div className="general-information__tenancy-address">
                <h1 className="street">{tenancyData.address.street}</h1>
                <Typography variant="label">{tenancyData.address.zipCode} {tenancyData.address.city}</Typography>
            </div>
            <div className="general-information__fields">
                <div className="fields-section__field">
                    <Typography variant="label">Lejemåls ID</Typography>
                    <p className="field-value">{tenancyData.integration?.id}</p>
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Ejendom</Typography>
                    <p className="field-value">{propertyData?.title}</p>
                </div>
            </div>
        </Paper>

    );
};

export default BaseFields;