import { ActionButton, PrimaryButton } from "components/Buttons";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import React, { useState, useMemo } from "react";
import { translateString } from "helpers";
import { Select, TextField } from "components/Fields";
import { CircularProgress, MenuItem } from "@material-ui/core";
import TenanciesService from "services/api/tenancies";
import { useHistory } from "react-router-dom";

const PropertyActions = props => {

    const { propertyData } = props;

    const [isModalEnabled, setModalEnabled] = useState(false);

    const [newTenancyType, setNewTenancyType] = useState("");
    const [newTenancyRooms, setNewTenancyRooms] = useState("");
    const [newTenancyStreet, setNewTenancyStreet] = useState("");
    const [newTenancyZipCode, setNewTenancyZipCode] = useState("");
    const [newTenancyCity, setNewTenancyCity] = useState("");

    const [isTenancyCreating, setTenancyCreating] = useState(false);
    const [createdTenancyId, setCreatedTenancyId] = useState(null);
    const [tenancyCreationSuccess, setTenancyCreationSuccess] = useState(null);

    const history = useHistory();

    const createTenancyHandler = async () => {
        setTenancyCreating(true);
        const result = await TenanciesService.createTenancy({
            classification: newTenancyType,
            rooms: newTenancyRooms,
            address: {
                street: newTenancyStreet,
                zipCode: newTenancyZipCode,
                city: newTenancyCity
            },
            propertyId: propertyData.id,
            organizationId: propertyData.id
        });

        if (result?.status === 200) {
            setTenancyCreationSuccess(true);
            setCreatedTenancyId(result.data.id)
        } else {
            setTenancyCreationSuccess(false);
        }
    };

    const modalCloseHandler = () => {
        if (isTenancyCreating && tenancyCreationSuccess === null) {
            return;
        }
        setModalEnabled(false);
        setNewTenancyType("");
        setNewTenancyRooms("");
        setNewTenancyStreet("");
        setNewTenancyZipCode("");
        setNewTenancyCity("");
        setTenancyCreating(false);
        setCreatedTenancyId(null);
        setTenancyCreationSuccess(null)
    }

    const isTenancyCreationAllowed = useMemo(() => {
        if (newTenancyType === "" || newTenancyRooms === "" || newTenancyStreet === "" || newTenancyZipCode === "" || newTenancyCity === "")
            return true;

        return false;
    }, [newTenancyType, newTenancyRooms, newTenancyStreet, newTenancyZipCode, newTenancyCity]);


    return (
        <>
            <div className="property-actions">
                <ActionButton onClick={() => setModalEnabled(true)}>Opret lejemål</ActionButton>
            </div>
            <FullScreenModal
                className="property-actions__create-tenancy-action"
                open={isModalEnabled}
                onClose={modalCloseHandler}>
                <Typography variant="subtitle">Opret lejemål</Typography>
                <div className="fields-group">
                    <Select
                        value={newTenancyType}
                        onChange={(e) => {
                            setNewTenancyType(e.target.value)
                        }}>
                        <MenuItem value="" disabled>
                            Vælg lejemålstype
                            </MenuItem>
                        <MenuItem value="Residential">
                            {translateString("Residential")}
                        </MenuItem>
                        <MenuItem value="Business">
                            {translateString("Business")}
                        </MenuItem>
                    </Select>
                    <TextField
                        placeholder="Antal værelser"
                        value={newTenancyRooms}
                        onChange={(event) => setNewTenancyRooms(event.target.value)}
                        number
                    />
                </div>
                <div className="fields-group">
                    <TextField
                        placeholder="Gadenavn"
                        value={newTenancyStreet}
                        onChange={(event) => setNewTenancyStreet(event.target.value)}
                    />
                    <TextField
                        placeholder="Postnummer"
                        value={newTenancyZipCode}
                        onChange={(event) => setNewTenancyZipCode(event.target.value)}
                    />
                    <TextField
                        placeholder="By"
                        value={newTenancyCity}
                        onChange={(event) => setNewTenancyCity(event.target.value)}
                    />
                </div>
                <PrimaryButton
                    disabled={isTenancyCreationAllowed}
                    onClick={createTenancyHandler}>
                    Opret
                </PrimaryButton>
                {isTenancyCreating &&
                    <div className="loading-curtain">
                        {tenancyCreationSuccess === null && <CircularProgress size={50} />}
                        {tenancyCreationSuccess === true &&
                            <div>
                                <Typography variant="subtitle">Lejemål oprettet.</Typography>
                                <PrimaryButton onClick={() => history.push(`/tenancies/${createdTenancyId}`)}>Gå til lejemål</PrimaryButton>
                            </div>
                        }
                        {tenancyCreationSuccess === false &&
                            <div>
                                <Typography variant="subtitle">Oprettelse af lejemål fejlede.</Typography>
                                <PrimaryButton onClick={modalCloseHandler}>Luk</PrimaryButton>
                            </div>
                        }
                    </div>
                }
            </FullScreenModal>
        </>
    );
};

export default PropertyActions;