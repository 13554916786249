import axios from "./axios";

export default class ShowcasingsService {

	static async createShowcasing(tenancyId, data) {
		try {
			const result = await axios.post(`tenancies/${tenancyId}/showcasings`, {...data});
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getShowcasing(showcasingId) {
		try {
			const result = await axios.get(`showcasings/${showcasingId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getShowcasings() {
		try {
			const result = await axios.get("showcasings");
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getAllTenancyShowcasings(tenancyId) {
		try {
			const result = await axios.get(`tenancies/${tenancyId}/showcasings/all`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updateShowcasing(showcasingId, data) {
		try {
			const result = await axios.patch(`showcasings/${showcasingId}`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async deleteShowcasing(showcasingId) {
		try {
			const result = await axios.delete(`showcasings/${showcasingId}`);
			return result;
		} catch (err) {
			return null;
		}
	}
}