import React from 'react'
import '../index.scss'
import Typography from 'components/Typography'
import Toggle from 'components/Toggle2'

const VirtualMeeting = ({ state, dispatch }) => {
  return (
    <div className='section virtual'>
      <div className="text-container">
        <Typography className='pb15' variant='label'>
          Online fremvisning
        </Typography>
        <p className='subtext'>
          Mødeindkaldelse indeholder et link til et Teams møde
        </p>
      </div>
      <Toggle
	  	className={'toggle'}
        checked={state.isVirtual}
        onChange={value => dispatch({ type: 'SET_IS_VIRTUAL', payload: value })}
      />
    </div>
  )
}

export default VirtualMeeting
