import React, { useMemo } from 'react';
import './index.scss'
import PropTypes from "prop-types";
import ShowcasingsGroup from './ShowcasingsGroup';
import LoadingComponent from 'components/LoadingComponent';
import { PrimaryButton } from "components/Buttons";
import { ReactComponent as PlusIcon } from "assets/icons/jorato-plus.svg";

const ShowcasingsList = (props) => {
	
	const { isLoading, data, handleCreate } = props;

	const groupData = (data) => {
		const filteredData = data.filter((item) => {
			return item.cancelledAt === null;
		});
		const groupedData = filteredData.reduce((acc, item) => {
			const date = new Date(item.schedule.startsAt);
			const dateKey = date.toISOString().slice(0, 10);
			const monthKey = ('0' + (date.getMonth()+1)).slice(-2)
			const yearKey = date.getFullYear();
			const monthLabel = date.toLocaleString('default', { month: 'long' });
			const yearMonthKey = `${yearKey}-${monthKey}`;
			const yearMonthGroup = acc.find((g) => g.yearMonth === yearMonthKey);
			if (yearMonthGroup) {
				const dateGroup = yearMonthGroup.data.find((g) => g.date === dateKey);
				if (dateGroup) {
					dateGroup.data.push(item);
				} else {
					yearMonthGroup.data.push({
						date: dateKey,
						data: [item],
					});
				};
			} else {
				acc.push({
					yearMonth: yearMonthKey,
					label: monthLabel,
					data: [{
						date: dateKey,
						data: [item],
					}],
				});
			}
			return acc;
		}, []);
		groupedData.sort((a, b) => {
			return new Date(a.yearMonth) - new Date(b.yearMonth);
		});
		groupedData.map((group) => {
			const r = group.data.sort((a, b) => { 
				return new Date(a.date) - new Date(b.date);
			});
			const r2 = r.map((item) => {
				return item.data.sort((a, b) => {
					return new Date(a.schedule.startsAt) - new Date(b.schedule.startsAt);
				})
			})
			return r2;	
		});
		return groupedData;
	};
	const memorizedDataGroupedByDate = useMemo(() => groupData(data), [data]);

	return (
		<div className="showcasings-list">
			{isLoading && (
				<LoadingComponent />
			)}
			{memorizedDataGroupedByDate.length === 0 && !isLoading && (
				<div className="empty-state">
					<p>Ingen fremvisninger er planlagt. Hvad med at oprette en?</p>
					<PrimaryButton onClick={handleCreate} className="no-margin has-icon inversed">
						<PlusIcon />
						Opret fremvisning
					</PrimaryButton>
				</div>
			)}
			{!isLoading && memorizedDataGroupedByDate.map((group, key) => (
				<ShowcasingsGroup key={key} group={group} />
			))}
			<div>
				{ }
			</div>
		</div>
	);
};


ShowcasingsList.propTypes = {
	isLoading: PropTypes.bool,
	data: PropTypes.array,
  };
  
ShowcasingsList.defaultProps = {
	isLoading: true,
	data: [],
};

export default ShowcasingsList;