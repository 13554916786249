import LoadingComponent from "components/LoadingComponent";
import React, { useEffect } from "react";
import Table from 'components/Table'
import columns from "./columns";
import { PrimaryButton } from "components/Buttons";

const InterestListTab = ({ data, isLoading, load }) => {

	useEffect(() => {
		load();
	}, [load]);

	if (isLoading) {
		return (
			<LoadingComponent />
		);
	}

	const EmailAll = () => {
		const emails = data.map(x => x.email);
		const bccEmails = emails.join(',');
		const mailtoLink = `mailto:?bcc=${bccEmails}`;
		window.open(mailtoLink);
	}

	return (
		<div className="interest-list-container">
			<div style={{ marginBottom: '32px'}} className='table-bar-container'>
				<PrimaryButton onClick={EmailAll}>E-mail alle</PrimaryButton>
			</div>
			<Table
				activeColumns={columns.map(x => x.accessor)}
				columns={columns}
				data={data}
				pageLimit={data.length}
				total={data.length}
			/>
		</div>
	);
};

export default InterestListTab;