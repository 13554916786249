import React, { useState, useRef, useEffect } from 'react'
import './index.scss'
import StyledAutocomplete from 'components/StyledAutocomplete2'
import PhoneExtensions from 'helpers/all-phone-extensions'

const PhoneExtensionPicker = ({ value, onChange, className }) => {
  const [query, setQuery] = useState('')
  const inputRef = useRef(null)

  const handleSelect = (value, item) => {
    setQuery(value);
	onChange(value);
	inputRef.current.blur();
  }

  useEffect(() => {
	if (value !== query) {
		setQuery(value);
	}
  }, [value])

  const shouldItemRender = item => {
    const t = item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    return t
  }

  return (
    <StyledAutocomplete
      ref={inputRef}
      items={PhoneExtensions}
      getItemValue={item => item.value}
      renderItem={(item, highlighted) => (
        <div
          key={item.value}
          style={{ backgroundColor: highlighted ? '#eee' : 'transparent' }}
        >
          {item.label}
        </div>
      )}
      value={query}
      onChange={e => {
        setQuery(e.target.value)
      }}
      onSelect={(value, item) => handleSelect(value, item)}
      shouldItemRender={shouldItemRender}
      inputProps={{
        onFocus: () => setQuery(''),
		tabIndex: "-1"
      }}
	  className={className}
    />
  )
}
export default PhoneExtensionPicker
