import { useState, useCallback, useEffect } from "react";
import ShowcasingsService from "services/api/showcasings";
import { useUser } from "features/user";
import TenanciesService from 'services/api/tenancies';

/*
    useShowcasingsSearch hook handles showcasings loading and filtering. 
*/
const useShowcasingsSearch = (users) => {

    const [isShowcasingsLoading, setIsShowcasingsLoading] = useState(true);
    const [showcasingsData, setShowcasingsData] = useState([]);
	const [filter, setFilter] = useState(JSON.parse(localStorage.getItem(`showcasings_filter`)) ?? ['all']);
	const [filteredData, setFilteredData] = useState([]);
	const [isFilteredDataLoading, setFilteredDataLoading] = useState(true);
	const { isUserLoading, userData } = useUser();

	useEffect(() => {
		if (users.loaded){
			loadAllShowcasings();
		}	
	}, [users]);

	useEffect(() => {
		if (!isShowcasingsLoading && !isUserLoading && filter !== null) {
			filterShowcasings();
		}
	}, [filter, isShowcasingsLoading, isUserLoading, showcasingsData]);


	const filterShowcasings = () => {
		if (filter === "residential") {
			const filtered = showcasingsData.filter((showcasings) => {
				return showcasings.classification === "Residential";
			});
			setFilteredData(filtered);
		}
		else if (filter === "business") {
			const filtered = showcasingsData.filter((showcasings) => {
				return showcasings.classification === "Business";
			});
			setFilteredData(filtered);
		}
		else if (filter === "private") {
			const filtered = showcasingsData.filter((showcasings) => {
				return showcasings.hostId === userData.sub;
			});
			setFilteredData(filtered);
		}
		else {
			setFilteredData(showcasingsData);
		}
		setFilteredDataLoading(false);
		localStorage.setItem(`showcasings_filter`, JSON.stringify(filter));
	}	

	const loadAllShowcasings = useCallback(async () => {
		const result = await ShowcasingsService.getShowcasings();
		if (result !== null) {
			const tenancyIds = result.data.map(showcasing => showcasing.tenancyId);
			const tenancies = await TenanciesService.getTenanciesByIds(tenancyIds);
			const enhancedShowcasings = result.data.map(showcasing => {
				const tenancy = tenancies.data.find(t => t.id === showcasing.tenancyId)
				showcasing.locationName = tenancy.address.street;
				showcasing.classification = tenancy.classification;
				const host = users.data.find(u => u.employeeId === showcasing.hostId);
				showcasing.hostName = host.name;
				return showcasing;
			});
			setShowcasingsData(enhancedShowcasings);
			setIsShowcasingsLoading(false);
		}
	}, [users]);

	return {
		isShowcasingsLoading,
		showcasingsData,
		loadAllShowcasings,
		filter,
		setFilter,
		filteredData,
		isFilteredDataLoading,
    }
};

export default useShowcasingsSearch;
