import { useState, useCallback } from "react";
import produce from "immer";
import TenanciesService from "services/api/tenancies";

/*
    useTenancy hook handles tenancy operations. 
    The only requirement for it is to load tenancy first, before doing operations.
*/
const useTenancy = () => {

    const [isTenancyLoading, setTenancyLoading] = useState(true);
    const [tenancyData, setTenancyData] = useState(null);
	const [tenancyContractData, setTenancyContractData] = useState(null);

    const loadTenancy = useCallback(async (tenancyId) => {
        const result = await TenanciesService.getTenancy(tenancyId);
        if (result !== null) {
            setTenancyData({
                ...result.data,
            });

			const tenancyContractData = await TenanciesService.getTenancyContractData(tenancyId);
			setTenancyContractData(tenancyContractData.data);
        }

        setTenancyLoading(false);
    }, []);

    const updateTenancy = useCallback(async (data) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateTenancy(tenancyData.id, data);

        if (result !== null) {
            setTenancyData({
                ...tenancyData,
                ...result.data,
            });
        }
    }, [tenancyData]);

	const updateTenancyContract = useCallback(async (data) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateTenancyContractData(tenancyData.id, data);

        if (result !== null) {
            setTenancyContractData(result.data);
        }
    }, [tenancyData]);

	const deleteTenancy = useCallback(async (data) => {
        if (tenancyData == null)
            return;

        await TenanciesService.deleteTenancy(tenancyData.id);
    }, [tenancyData]);


    const addTenancyType = useCallback(async (type) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.addTenancyType(tenancyData.id, type);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.types.push(type)
            }));
        }
    }, [tenancyData]);

    const removeTenancyType = useCallback(async (type) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.removeTenancyType(tenancyData.id, type);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.types.findIndex(x => x === type)
                if (index !== -1) draft.types.splice(index, 1)
            }));
        }
    }, [tenancyData]);

    const addTenancyLocationPoint = useCallback(async (location) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.addTenancyLocationPoint(tenancyData.id, location);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.locations.push(location)
            }));
        }
    }, [tenancyData]);

    const removeTenancyLocationPoint = useCallback(async (location) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.removeTenancyLocationPoint(tenancyData.id, location);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.locations.findIndex(x => x === location)
                if (index !== -1) draft.locations.splice(index, 1)
            }));
        }
    }, [tenancyData]);


    const createTenancyExpense = useCallback(async (expenseType, amount) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.addTenancyExpense(tenancyData.id, {
            type: expenseType,
            description: "",
            amount: {
                currency: "DKK",
                value: amount
            }
        });

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.expenses.push(result.data)
            }));
        }
    }, [tenancyData]);

	const updateTenancyExpense = useCallback(async (expenseId, data) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateTenancyExpense(tenancyData.id, expenseId, data);

        if (result !== null) {
			setTenancyData(produce(tenancyData, draft => {
                const index = draft.expenses.findIndex(x => x.id === expenseId);
                draft.expenses.splice(index, 1)
                draft.expenses.splice(index, 0, result.data);
            }));
        }
    }, [tenancyData]);

    const removeTenancyExpense = useCallback(async (expenseId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.removeTenancyExpense(tenancyData.id, expenseId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.expenses.findIndex(x => x.id === expenseId)
                if (index !== -1) draft.expenses.splice(index, 1)
            }));
        }
    }, [tenancyData]);

    const createTenancyContact = useCallback(async (role, name, email, extension, number) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.addTenancyContact(tenancyData.id, {
            role: role,
            title: "",
            name: name,
            email: email,
            phone: {
                extension: extension,
                number: number
            }
        });

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.contacts.push(result.data)
            }));
        }
    }, [tenancyData]);

    const removeTenancyContact = useCallback(async (contactId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.removeTenancyContact(tenancyData.id, contactId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.contacts.findIndex(x => x.id === contactId);
                if (index !== -1) draft.contacts.splice(index, 1);
            }));
        }
    }, [tenancyData]);

    const createTenancyAttribute = useCallback(async (definitionId, value) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.createTenancyAttribute(tenancyData.id, { attributeDefinition: definitionId, value: value });

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.attributes.push(result.data)
            }));
        }
    }, [tenancyData]);

    const updateTenancyAttribute = useCallback(async (attributeId, data) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateTenancyAttribute(tenancyData.id, attributeId, data);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
                draft.attributes.splice(index, 0, result.data);
            }));
        }
    }, [tenancyData]);

    const removeTenancyAttribute = useCallback(async (attributeId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.removeTenancyAttribute(tenancyData.id, attributeId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
            }));
        }
    }, [tenancyData]);

    const uploadTenancyImages = useCallback(async (files) => {
        if (tenancyData == null)
            return;

        const o = tenancyData.images.map(e => e.order);
        const highestOrder = o.length > 0 ? Math.max.apply(Math, o) : 1;

        let promises = []
        files.forEach((file, ix) => {
            let data = new FormData();
            data.append("file", file);
            data.append("order", highestOrder + ix + 1)
            promises.push(TenanciesService.uploadImage(tenancyData.id, data))
        })
        const results = await Promise.all(promises);
        const images = results.filter(r => r.status === 200).map(r => r.data); 
        setTenancyData(produce(tenancyData, draft => {
            draft.images = draft.images.concat(images)
        }));
    }, [tenancyData]);

    const deleteTenancyImage = useCallback(async (imageId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.deleteImage(tenancyData.id, imageId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.images = draft.images.filter(x => x.id !== imageId);
            }));
        }
    }, [tenancyData]);

    const changeTenancyImageOrder = useCallback(async (imageId, newOrder) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateImage(tenancyData.id, imageId, {
            order: newOrder
        });

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.images.findIndex(x => x.id === imageId);
                draft.images[index].order = newOrder;
            }));
        }
    }, [tenancyData]);

    const uploadTenancyFloorPlans = useCallback(async (files) => {
        if (tenancyData == null)
            return;

        const o = tenancyData.images.map(e => e.order);
        const highestOrder = o.length > 0 ? Math.max.apply(Math, o) : 1;
    
        let promises = []
        files.forEach((file, ix) => {
            let data = new FormData();
            data.append("file", file);
            data.append("order", highestOrder + ix + 1)
            promises.push(TenanciesService.uploadFloorPlan(tenancyData.id, data))
        })
        const results = await Promise.all(promises);

        const floorPlans = results
            .filter(r => r.status === 200)
            .map(r => r.data);

            setTenancyData(produce(tenancyData, draft => {
            draft.floorPlans = draft.floorPlans.concat(floorPlans)
        }));

    }, [tenancyData]);

    const deleteTenancyFloorPlan = useCallback(async (floorPlanId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.deleteFloorPlan(tenancyData.id, floorPlanId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.floorPlans = draft.floorPlans.filter(x => x.id !== floorPlanId);
            }));
        }
    }, [tenancyData]);

    const changeTenancyFloorPlanOrder = useCallback(async (floorPlanId, newOrder) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.updateFloorPlan(tenancyData.id, floorPlanId, {
            order: newOrder
        });

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                const index = draft.floorPlans.findIndex(x => x.id === floorPlanId);
                draft.floorPlans[index].order = newOrder;
            }));
        }
    }, [tenancyData]);

    const uploadTenancyDocument = useCallback(async (file, type) => {
        if (tenancyData == null)
            return;

        var data = new FormData();
        data.append("file", file);
        data.append("type", type);
        const result = await TenanciesService.uploadDocument(tenancyData.id, data);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.documents.push(result.data)
            }));
        }
    }, [tenancyData]);

    const deleteTenancyDocument = useCallback(async (documentId) => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.deleteDocument(tenancyData.id, documentId);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.documents = draft.documents.filter(x => x.id !== documentId);
            }));
        }
    }, [tenancyData]);

    const createTenancyBasedCase = useCallback(async () => {
        if (tenancyData == null)
            return;

        const result = await TenanciesService.createTenancyBasedCase(tenancyData.id);

        if (result !== null) {
            setTenancyData(produce(tenancyData, draft => {
                draft.currentCase = result.data.id;
            }));
        }
    }, [tenancyData]);

    return {
        isTenancyLoading,
        tenancyData,
		tenancyContractData,
        loadTenancy,
        updateTenancy,
		deleteTenancy,
        addTenancyType,
        removeTenancyType,
        createTenancyExpense,
        removeTenancyExpense,
        createTenancyContact,
        removeTenancyContact,
        updateTenancyAttribute,
        removeTenancyAttribute,
        createTenancyAttribute,
        uploadTenancyImages,
        deleteTenancyImage,
        changeTenancyImageOrder,
        uploadTenancyFloorPlans,
        deleteTenancyFloorPlan,
        changeTenancyFloorPlanOrder,
        uploadTenancyDocument,
        deleteTenancyDocument,
        addTenancyLocationPoint,
        removeTenancyLocationPoint,
        createTenancyBasedCase,
		updateTenancyExpense,
		updateTenancyContract
    }
};

export default useTenancy;