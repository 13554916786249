import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const Typography = props => {

    switch(props.variant) {
        case "title":
            return <h1 className="typography__page--title" {...props}>{props.children}</h1>
		case "subtitle":
				return <p {...props} className={`typography__subtitle ${props.className ? props.className : ""}`}>{props.children}</p>
        case "label":
            return <p {...props} className={`typography__label ${props.className ? props.className : ""}`} >{props.children}</p>
        default:
            return <p {...props}>{props.children}</p>
    }
};

Typography.propTypes = {
  variant: PropTypes.string
};

export default Typography;