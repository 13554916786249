import { translateString } from "helpers/index";

const columns = [
	{
		Header: "Gade",
		accessor: "addressStreet",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		defaultCanFilter: true,
		filter: "contains",
		isDefault: true
	},
	{
		Header: "Externt id",
		accessor: "integrationId",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		defaultCanFilter: true,
		filter: "contains",
		isDefault: true
	},
	{
		Header: "By",
		accessor: "addressCity",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		defaultCanFilter: true,
		filter: "contains",
		isDefault: true
	},
	{
		Header: "Postnummer",
		accessor: "addressZipCode",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		defaultCanFilter: true,
		filter: "contains",
		isDefault: true
	},
	{
		Header: "Type",
		accessor: "classification",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		filter: "equal",
		isDefault: true,
		options: [
			{
				label: translateString("residential"),
				value: "residential"
			},
			{
				label: translateString("business"),
				value: "business"
			},
			{
				label: translateString("other"),
				value: "other"
			}
		],
		Cell: ({ value }) => translateString(value),
	},
	{
		Header: "Størrelse",
		accessor: "sizeValue",
		aggregate: "sum",
		Aggregated: ({ value }) => value === 0 ? "-" : `${Math.round(value)} m2`,
		filter: 'between',
		disableGroupBy: true,
		disableSortBy: true,
		isDefault: true
	},
	{
		Header: "Værelser",
		accessor: "rooms",
		aggregate: "sum",
		Aggregated: ({ value }) => `-`,
		filter: 'between',
		disableGroupBy: true,
		disableSortBy: true,
		isDefault: true
	},
];

export default columns;