import React, { useEffect, useMemo, useState } from "react";
import Typography from "components/Typography";
import useCases from "hooks/api/useCases";
import { isSameMonth, getUnixTime, format } from "date-fns";
import { CircularProgress } from "@material-ui/core";
import { da } from 'date-fns/locale';
import { formatNumber } from "helpers";
import Paper from "components/StyledPaper";
import { getLatestCasesByType } from "helpers/api/cases";

const CompletedCasesSummary = ({ tenancies, properties, casesData }) => {

    const [latestDraftCasesNumber, setLatestDraftCasesNumber] = useState(0);

    useEffect(() => {
        const getData = async () => {
            const drafts = await getLatestCasesByType("Draft");
            setLatestDraftCasesNumber(drafts.length);
        }
        getData();
    }, []);

    const getCasesFromCurrentMonth = () => casesData.filter(x => {
        const now = new Date();
        const completedAt = x.stateLogs.filter(x => x.state === "Completed").sort((a, b) => a.createdAt - b.createdAt)[0];
        if (!completedAt)
            return false;

        const completedDate = new Date(completedAt.createdAt);

        if (!isSameMonth(now, completedDate))
            return false;

        return true;
    });

    const currentMonthCases = useMemo(() => getCasesFromCurrentMonth(), [casesData]);

    const getAverageClosingTime = () => {
        const completedInDays = currentMonthCases.map(x => {
            const completedAt = x.stateLogs.filter(x => x.state === "Completed").sort((a, b) => a.createdAt - b.createdAt)[0];
            const activatedAt = x.stateLogs.filter(x => x.state === "Active").sort((a, b) => a.createdAt - b.createdAt)[0];
            if (activatedAt == null || completedAt == null)
                return 0;

            const completedDate = new Date(completedAt.createdAt);
            const activatedDate = new Date(activatedAt.createdAt);

            const difference = getUnixTime(completedDate) - getUnixTime(activatedDate);
            return Math.round(difference / 86400);
        });

        const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
        return Math.round(average(completedInDays));
    }

    const averageClosingTime = useMemo(() => getAverageClosingTime(), [currentMonthCases]);

    const getAverageRent = () => {
        if (!currentMonthCases || currentMonthCases.length === 0)
            return 0;

        const rents = currentMonthCases.map(x => x.size.value === 0 ? 0 : x.monthlyRent.value / x.size.value);
        const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

        return Math.round(average(rents));
    }

    const averageRent = useMemo(() => getAverageRent(), [currentMonthCases]);

    return (
        <div className="summary-grid">
            <Paper className="summary-item">
                <Typography variant="subtitle" className="summary-item__value">DKK {formatNumber(averageRent, "financial")},00</Typography>
                <Typography variant="label">Gns. leje per m2 i {format(new Date(), 'LLLL', { locale: da })}</Typography>
            </Paper>
            <Paper className="summary-item">
                <Typography variant="subtitle" className="summary-item__value">{latestDraftCasesNumber}</Typography>
                <Typography variant="label">Antal kladder</Typography>
            </Paper>
            <Paper className="summary-item">
                <Typography variant="subtitle" className="summary-item__value">{tenancies.loaded ? tenancies.data.length : 0}</Typography>
                <Typography variant="label">Antal lejemål</Typography>
            </Paper>
            <Paper className="summary-item">
                <Typography variant="subtitle" className="summary-item__value">{properties.loaded ? properties.data.length : 0}</Typography>
                <Typography variant="label">Antal ejendomme</Typography>
            </Paper>
        </div>
    );
}

export default CompletedCasesSummary;