import React, {useState, useEffect, useCallback} from 'react';
import { styled } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import TextField from "@material-ui/core/TextField";

const DebouncedTextField = (props) => {
  const {onChange} = props
  const [value, setValue] = useState(props.value ? props.value : "");
  const delayedCall = useCallback(debounce(() => props.onChange ? props.onChange(value) : null, 500), [value]);

  useEffect(() => {
    if(onChange) {
      delayedCall();
      return delayedCall.cancel;
    }
  }, [value, delayedCall, onChange]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  let hasValue = true;
  if (props.value === "" || props.value === undefined) {
    hasValue = false;
  }


  return <TextField 
      {...props}
      value={value}
      variant="outlined"
      className={`${props.className} ${hasValue && !props.disabled ? "hasValue" : ""}`}
      onChange={(event)=> {
        setValue(event.target.value)
      }}
  />
}

export default styled(DebouncedTextField)({
  '&': {
    borderRadius: 30,
    border: "1px solid #A8A8A8",
    margin: "8px 0px"
  },
  "& .MuiInputBase-root input": {
    color: "rgba(0, 0, 0, 0.87)",
    border: "none",
    fontFamily: "Montserrat",
    padding: "14.5px 14px 14.5px 30px",
    fontSize: "16px !important"
  },
  "& .Mui-disabled input": {
    color: "rgba(0, 0, 0, 0.38)",
    cursor: "not-allowed"
  },
  "& .MuiInputBase-root input[type=datetime-local]": {
    color: "rgba(0, 0, 0, 0.5)",
  },
  "& .MuiInputBase-root textarea": {
    border: "none",
    fontFamily: "Montserrat",
    padding: "24.5px 14px 14.5px 30px !important",
    fontSize: "16px !important"
  },
  '&.hasValue': {
    border: "1px solid #566FFF",
  },
  '&.hasValue input, &.hasValue input[type=datetime-local]': {
    color: "#566FFF",
  },
  '&.hasValue textarea': {
    color: "#566FFF",
  },
  "& fieldset":{
    border: "none !important"
  }
});