import React from "react";
import Typography from "components/Typography";
import { Switch } from "components/Fields";

const InternetUtility = ({ contractDetails, updatePropertyContract }) => {
    return (
        <>
            <Typography variant="label" className="utility-title">Internet</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Udlejer leverer</Typography>
                    <Switch
                        checked={contractDetails.utilities.internet.isLandlordResponsible}
                        onChange={(e) => updatePropertyContract({ utilities: { internet: { isLandlordResponsible: e.target.checked } } })}
                    />
                </div>
            </div>
        </>
    );
};

export default InternetUtility;