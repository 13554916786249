import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";

const ApplicationDetails = props => {

    const { caseData, applicationData } = props;

    return (
        <Paper className="application-content__details">
            <Typography variant="subtitle">Ansøgsøgningsdetaljer</Typography>
            <div className="fields">
                <div className="field-group">
                    <Typography variant="label">Ansøgningsstatus</Typography>
                    <p className="field-value">{applicationData.status}</p>
                </div>
                <div className="field-group">
                    <Typography variant="label">Lejemålsadresse</Typography>
                    <p className="field-value">{caseData.address?.street} <br /> {caseData.address?.zipCode} {caseData.address?.city}</p>
                </div>
                <div className="field-group">
                    <Typography variant="label">Ansvarlig</Typography>
                    <p className="field-value">{caseData.responsiblePerson?.name ? caseData.responsiblePerson?.name : '-'}</p>
                </div>
            </div>
        </Paper>
    )
};

export default ApplicationDetails;