import {
  FETCH_CASES,
  FETCH_CASE,
  SET_CASE_IMAGE,
  SET_CASE_FLOOR_PLAN,
  DELETE_CASE_FLOOR_PLAN,
  DELETE_CASE_PROSPECTUS,
  SET_CASE_PROSPECTUS,
  DELETE_CASE_IMAGE,
  SET_CASES_EXPENSE,
  DELETE_CASES_EXPENSE,
  DELETE_CASES_CONTACT,
  SET_CASES_ATTRIBUTE,
  SET_CASES_CONTACT,
  UPDATE_CASE_TYPES,
  DELETE_CASES_ATTRIBUTE,
  FETCH_CASE_INTEGRATIONS,
  FETCH_CASE_APPLICATIONS,
  SET_CASE_DOCUMENT,
  DELETE_CASE_DOCUMENT,
  SET_CASE_COMMENTS,
  SET_CASE_COMMENT,
  DELETE_CASE_COMMENT
} from "redux/actionTypes";

import { translateString } from "helpers";

const cases = (
  state = {
    list: {
      data: [],
      loaded: false,
    },
    selected: {
      data: [],
      comments: {
        list: [],
        loaded: false,
      },
      integrations: {
        list: [],
        loaded: false,
      },
      applications: {
        list: [],
        loaded: false,
      },
      loaded: false,
    },
    stats: {
      loaded: false,
      data: {},
    },
    error: undefined,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_CASE_TYPES: {
      let newTypes = [...state.selected.data.types];
      if (action.payload.operation === "POST") {
        newTypes.push(action.payload.type);
      } else if (action.payload.operation === "DELETE") {
        newTypes = newTypes.filter(
          (location) => location !== action.payload.type
        );
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            types: newTypes,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASE_COMMENT: {
      const newComments = state.selected.comments.list.filter(
        (comment) => comment.id !== action.payload
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          comments: {
            loaded: true,
            list: newComments
          }
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_CASE_COMMENT : {
      return {
        ...state,
        selected: {
          ...state.selected,
          comments: {
            list: [
              ...state.selected.comments.list,
              action.payload,
            ],
            loaded: true,
          },
        },
      };
    }
    case SET_CASE_COMMENTS : {
      return {
        ...state,
        selected: {
          ...state.selected,
          comments: {
            list: [
              ...action.payload,
            ],
            loaded: true,
          },
        },
      };
    }
    case SET_CASE_IMAGE: {
      const alreadyExisting = state.selected.data.images.find((image) => {
        return image.id === action.payload.image.id;
      });
      let newImages = [];
      if (alreadyExisting) {
        newImages = state.selected.data.images
          .map((image) =>
            image.id === action.payload.image.id ? action.payload.image : image
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newImages = [...state.selected.data.images, action.payload.image];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            images: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }


    case SET_CASE_FLOOR_PLAN: {
      const alreadyExisting = state.selected.data.floorPlans.find((image) => {
        return image.id === action.payload.image.id;
      });
      let newImages = [];
      if (alreadyExisting) {
        newImages = state.selected.data.floorPlans
          .map((image) =>
            image.id === action.payload.image.id ? action.payload.image : image
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newImages = [...state.selected.data.floorPlans, action.payload.image];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            floorPlans: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_CASE_PROSPECTUS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            prospectus: action.payload.data,
          },
        },
      };
    }
    case SET_CASES_ATTRIBUTE: {
      const alreadyExisting = state.selected.data.attributes.find(
        (attribute) => {
          return attribute.definition.id === action.payload.definition.id;
        }
      );
      let newAttributes = [];
      if (!!alreadyExisting) {
        newAttributes = state.selected.data.attributes
          .map((attribute) =>
            attribute.definition.id === action.payload.definition.id
              ? action.payload
              : attribute
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newAttributes = [...state.selected.data.attributes, action.payload];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            attributes: newAttributes,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_CASES_CONTACT: {
      const alreadyExisting = state.selected.data.contacts.find((contact) => {
        return contact.id === action.payload.id;
      });
      let newContacts = [];
      if (!!alreadyExisting) {
        newContacts = state.selected.data.contacts
          .map((contact) =>
            contact.id === action.payload.id ? action.payload : contact
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newContacts = [...state.selected.data.contacts, action.payload];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            contacts: newContacts,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_CASES_EXPENSE: {
      const alreadyExisting = state.selected.data.expenses.find((expense) => {
        return expense.id === action.payload.id;
      });
      let newExpenses = [];
      if (!!alreadyExisting) {
        newExpenses = state.selected.data.expenses
          .map((expense) =>
            expense.id === action.payload.id ? action.payload : expense
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newExpenses = [...state.selected.data.expenses, action.payload];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            expenses: newExpenses,
            images: [...state.selected.data.images],
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }

    case SET_CASE_DOCUMENT: {
      const alreadyExisting = state.selected.data.documents.find((document) => {
        return document.id === action.payload.document.id;
      });
      let newDocuments = [];
      if (alreadyExisting) {
        newDocuments = state.selected.data.documents
          .map((image) =>
            image.id === action.payload.document.id ? action.payload.document : document
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newDocuments = [...state.selected.data.documents, action.payload.document];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    
    case DELETE_CASE_DOCUMENT: {
      const newDocuments = state.selected.data.documents.filter(
        (document) => document.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASE_IMAGE: {
      const newImages = state.selected.data.images.filter(
        (image) => image.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            images: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASE_FLOOR_PLAN: {
      const newImages = state.selected.data.floorPlans.filter(
        (image) => image.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            floorPlans: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASE_PROSPECTUS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            prospectus: null,
          },
        },
      };
    }

    case DELETE_CASES_ATTRIBUTE: {
      const newAttributes = state.selected.data.attributes.filter(
        (attribute) => attribute.definition.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            attributes: newAttributes,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASES_EXPENSE: {
      const newExpenses = state.selected.data.expenses.filter(
        (expense) => expense.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            expenses: newExpenses,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case DELETE_CASES_CONTACT: {
      const newContacts = state.selected.data.contacts.filter(
        (contact) => contact.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            contacts: newContacts,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }

    case FETCH_CASE_INTEGRATIONS:
      return {
        ...state,
        selected: {
          ...state.selected,
          integrations: {
            list: {
              ...state.selected.integrations.list,
              ...action.payload,
            },
            loaded: true,
          },
        },
      };
    
    case FETCH_CASE_APPLICATIONS:
      return {
        ...state,
        selected: {
          ...state.selected,
          applications: {
            list: [
              ...action.payload,
            ],
            loaded: true,
          },
        },
      };

    case FETCH_CASES:
      return {
        list: {
          data: action.payload.data,
          resHeaders: action.payload.headers,
          loaded: true,
        },
        selected: {
          loaded: false,
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    case FETCH_CASE:
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...action.payload,
            locations: action.payload.locations.sort((a, b) =>
              translateString(a) > translateString(b) ? 1 : -1
            ),
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    default:
      return state;
  }
};

export default cases;
