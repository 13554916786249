import React, { useState, useMemo } from 'react';
import { TextField } from "components/Fields";
import Typography from "components/Typography";
import { PrimaryButton } from 'components/Buttons';
import { ReactComponent as SavedIcon } from "assets/icons/jorato-checkmark.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/jorato-error.svg";
import LoadingComponent from 'components/LoadingComponent';

const UpdatePenneoCredentials = ({ update }) => {

	const [key, setKey] = useState("");
	const [secret, setSecret] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isUpdated, setUpdated] = useState(false);
	const [isErrorOccured, setErrorOccured] = useState(false);

	const DefaultConnectedCred = "***********";

	const credsChanged = useMemo(() => {
		return key.length > 0 && secret.length > 0;
	}, [key, secret]);

	const handleUpdate = async (key, secret) => {
		setLoading(true);
		const result = await update(key, secret);
		if (result) {
			setLoading(false);
			setUpdated(true);
		} else {
			setLoading(false);
			setErrorOccured(true);
		}
	}

	return (
		<div className="penneo-creds-container">
			<Typography className="title">Penneo informationer</Typography>
			<Typography className="description">Du kan finde key og secret på hos Penneo under profil indstilling på fanen <a href="https://app.penneo.com/settings/profile/user/integration" target="_blank"> "API Keys"</a></Typography>
			<div className="fields">
				<div className="field">
					<Typography variant="label">Client Key</Typography>
					<TextField
						value={key}
						placeholder={DefaultConnectedCred}
						onChange={(event) => setKey(event.target.value)}
					/>
				</div>
				<div className="field">
					<Typography variant="label">Secret</Typography>
					<TextField
						value={secret}
						placeholder={DefaultConnectedCred}
						onChange={(event) => setSecret(event.target.value)}
					/>
				</div>
			</div>
			<PrimaryButton disabled={!credsChanged} onClick={() => handleUpdate(key, secret)} className="save-button">
				Gem
			</PrimaryButton>
			{isLoading &&
				<div className="info-container">
					<LoadingComponent />
					Gemmer...
				</div>
			}
			{isUpdated &&
				<div className="info-container">
					<SavedIcon className="icon" />
					Penneo informationer er blevet gemt!
				</div>
			}
			{isErrorOccured &&
				<div className="info-container">
					<ErrorIcon className="icon" />
					Penneo informationer er ikke gyldige.
					<a className="close" onClick={() => setErrorOccured(false)}>Prøv igen</a>
				</div>
			}
		</div>
	);
};

export default UpdatePenneoCredentials;