import React, { useState, useEffect } from 'react';
import Typography from "components/Typography";
import "./index.scss";
import { useReports } from 'hooks/api';
import { formatDate } from 'helpers/date';
import YearMonthPicker from 'components/YearMonthPicker';
import ReportContent from './ReportContent';

const OrganizationReport = () => {

	const [date, setDate] = useState();

	const { isReportLoading, reportData, loadOrganizationReport } = useReports();

	const getDefaultDate = () => {
		const now = new Date();
		return { year: now.getFullYear(), month: now.getMonth() };
	};

	const getFormattedDate = () => {
		if (!date) {
			return "";
		}

		const d = new Date(date.year, date.month - 1, 1);
		return formatDate(d, "LLLL yyyy")
	}

	useEffect(() => {
		if (!date) {
			setDate(getDefaultDate());
		}
	}, []);

	useEffect(() => {
		if (!date)
			return;

		const d = new Date(date.year, date.month - 1, 1);

		loadOrganizationReport(formatDate(d, "MM/yyyy"));
	}, [date]);

	return (
		<div className="organization-report__container">
			<div className="header">
				<Typography variant="title">Månedsrapport - {getFormattedDate()}</Typography>
				<div className="actions">
					<YearMonthPicker value={date ?? getDefaultDate()} onChange={setDate} />
				</div>
			</div>
			<ReportContent isLoading={isReportLoading} data={reportData} />
		</div>
	);
};

export default OrganizationReport;