import React from 'react';
import { styled } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

const ControlledSelect = (props) => {
  return (
    <Select
      {...props}
      displayEmpty
      onChange={props.onChange}
      value={props.value}
      variant="outlined"
      className={`${props.className} ${props.value !== "" ? "hasValue" : ""}`}
    >
      {props.children}
    </Select>
  );
};

export default styled(ControlledSelect)({
  "&": {
    borderRadius: 30,
    border: "1px solid #A8A8A8",
    margin: "8px 0px",
    width: "100%",
  },
  "&.hasValue": {
    border: "1px solid #566FFF",
    color: "#566FFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    border: "none",
    fontFamily: "Montserrat",
    padding: "14.5px 14px 14.5px 30px",
    fontSize: "16px !important",
  },
  "&.Mui-disabled": {
	borderColor: "#A8A8A8",
	cursor: "not-allowed",
	color: "#A8A8A8",
	"& div, input": {
		cursor: "not-allowed",
	}
  },
});
