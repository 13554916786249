import React from "react";
import { ReactComponent as BinIcon } from "assets/icons/jorato-delete.svg";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";
import { ReactComponent as PlusIcon } from "assets/icons/jorato-plus.svg";
import { ReactComponent as CalcIcon } from "assets/icons/jorato-calc.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/jorato-error.svg";
import { ReactComponent as SignableIcon } from "assets/icons/jorato-signable-icon.svg";
import { ReactComponent as InfoIcon } from "assets/icons/jorato-info.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/jorato-document.svg";
import { ReactComponent as EditIcon } from "assets/icons/jorato-edit.svg";
import { ReactComponent as CheckmarkIcon } from "assets/icons/jorato-checkmark.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/jorato-remove.svg";

const Icon = React.forwardRef((props, ref) => {

	switch (props.type) {
		case "bin":
			return <BinIcon {...props} ref={ref} />
		case "close":
			return <CloseIcon {...props} ref={ref} />
		case "plus":
			return <PlusIcon {...props} ref={ref} />
		case "calc":
			return <CalcIcon {...props} ref={ref} />
		case "error":
			return <ErrorIcon {...props} ref={ref} />
		case "signable":
			return <SignableIcon {...props} ref={ref} />
		case "info":
			return <InfoIcon {...props} ref={ref} />
		case "document":
			return <DocumentIcon {...props} ref={ref} />
		case "edit":
			return <EditIcon {...props} ref={ref} />
		case "checkmark":
			return <CheckmarkIcon {...props} ref={ref} />
		case "remove":
			return <RemoveIcon {...props} ref={ref} />
		default:
			return null;
	}
});

export default Icon;