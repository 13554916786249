import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { translateString } from "helpers";
import { PrimaryButton } from "components/Buttons";

const PenneoNotConnectedModal = ({ isEnabled, onClose }) => {
	return (
		<FullScreenModal
			className="application-container__send-contract-modal"
			open={isEnabled}
			onClose={onClose}>
			<Typography variant="subtitle">Penneo opsætning mangler</Typography>
			<p className="description">
				Gå til min side og følg instruktionerne for at forbinde din Penneo konto.
			</p>
		</FullScreenModal>
	);
};

export default PenneoNotConnectedModal;