import { TableHead } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export default styled(TableHead)({
    '& .MuiTableCell-root.MuiTableCell-head': {
        color: "#566FFF",
        fontSize: "18px",
        fontWeight: "500",
        borderBottom: 0,
        padding: "16px 10px"
    }
});