import { useState, useCallback, useEffect, useMemo } from "react";
import TenanciesService from 'services/api/tenancies';

/*
	useTenanciesSearch hook handles tenancies paging, filtering, searching and sorting. 
*/
const useTenanciesSearch = () => {

	const pageLimit = 35;

	const [isTenanciesLoading, setTenanciesLoading] = useState(true);
	const [tenanciesData, setTenanciesData] = useState([]);
	const [tenanciesMetaData, setTenanciesMetaData] = useState({});
	const [initiationCompleted, setInitiationCompleted] = useState(false);

	const [query, setQuery] = useState("");
	const [pageIndex, setPageIndex] = useState(0);
	const [filters, setFilters] = useState([]);
	const [sorting, setSorting] = useState([]);
	const [columns, setColumns] = useState([]);

	const parseFilters = (filters, columns) => {
		let s = "";

		for (let filter of filters) {
			const column = columns.find(x => x.accessor === filter.accessor);
			if (!column) {
				continue;
			}

			if (column.filter === "equal") {
				s = s.length > 0 ? `${s} AND ${filter.accessor} = ${filter.value}` : `${filter.accessor} = ${filter.value}`
			} else if (column.filter === "between") {
				s = s.length > 0 ? `${s} AND ${filter.accessor} >= ${filter.value.min} AND ${filter.accessor} <= ${filter.value.max}` : `${filter.accessor} >= ${filter.value.min} AND ${filter.accessor} <= ${filter.value.max}`
			}
		}

		return s;
	};

	const memorizedFilters = useMemo(() => parseFilters(filters, columns), [columns, filters]);

	const parseSorting = (sorting) => {
		return sorting.map(x => {
			if (x.desc) {
				return `${x.id}:desc`
			} else {
				return `${x.id}:asc`
			}
		});
	}

	const memorizedSorting = useMemo(() => parseSorting(sorting), [sorting]);

	const loadTenancies = useCallback(async () => {
		const result = await TenanciesService.searchTenancies({
			paging: {
				offset: pageIndex * pageLimit,
				limit: pageLimit
			},
			query: query && query.length > 0 ? query : null,
			filter: memorizedFilters,
			sort: memorizedSorting
		});

		setTenanciesMetaData({
			PageLimit: result.headers["x-limit"],
			Offset: result.headers["x-offset"],
			RemainingTenancies: result.headers["x-remaining"],
			TotalTenancies: result.headers["x-total"]
		})
		setTenanciesData(result.data);
		setTenanciesLoading(false);
	}, [pageIndex, query, memorizedFilters, memorizedSorting]);

	useEffect(() => {
		setPageIndex(0);
	}, [query, filters, sorting]);

	useEffect(() => {
		if (initiationCompleted) {
			loadTenancies();
		}
	}, [pageIndex, query, memorizedFilters, memorizedSorting]);

	return {
		isTenanciesLoading,
		initiationCompleted,
		setInitiationCompleted,
		tenanciesData,
		tenanciesMetaData,
		loadTenancies,
		query,
		setQuery,
		setPageIndex,
		pageIndex,
		filters,
		setFilters,
		sorting,
		setSorting,
		setColumns
	};

}

export default useTenanciesSearch;