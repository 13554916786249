import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";

export default withStyles(() => ({
    tooltip: {
        backgroundColor: "white",
        borderRadius: "39px",
        color: "black",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.2)"
    }
}))(Tooltip);