import React, { useEffect, useState } from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import { ReactComponent as SearchIcon } from "assets/icons/jorato-search.svg";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";

const SearchInput = props => {
  const { value, setQuery } = props
  
  return (
  	<div className="search-input-container">
		<SearchIcon className="search-input-container__icon" />
		<input 
			value={value}
			onChange={e => setQuery(e.target.value)}
			className='search-input-container__input' placeholder='Søg' 
		/>
		{ value.toString().length > 0 && (
			<CloseIcon className='search-input-container__close-icon' onClick={() => setQuery('')} />
		)}
  	</div>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  setQuery: PropTypes.func,
}

SearchInput.defaultProps = {
  value: 'Value not defined',
  setQuery: () => console.log('setQuery not defined'),
}

export default SearchInput
