import { useState, useCallback, useEffect, useMemo } from "react";
import PropertiesService from 'services/api/properties';

/*
	usePropertiesSearch hook handles properties paging, filtering, searching and sorting. 
*/
const usePropertiesSearch = () => {

	const pageLimit = 35;

	const [isPropertiesLoading, setPropertiesLoading] = useState(true);
	const [initiationCompleted, setInitiationCompleted] = useState(false);
	const [propertiesData, setPropertiesData] = useState([]);
	const [propertiesMetaData, setPropertiesMetaData] = useState({});

	const [query, setQuery] = useState("");
	const [pageIndex, setPageIndex] = useState(0);
	const [filters, setFilters] = useState([]);
	const [sorting, setSorting] = useState([]);
	const [columns, setColumns] = useState([]);

	const parseFilters = (filters, columns) => {
		let s = "";

		for (let filter of filters) {
			const column = columns.find(x => x.accessor === filter.accessor);
			if (!column) {
				continue;
			}

			if (column.filter === "equal") {
				s = s.length > 0 ? `${s} AND ${filter.accessor} = ${filter.value}` : `${filter.accessor} = ${filter.value}`
			} else if (column.filter === "between") {
				s = s.length > 0 ? `${s} AND ${filter.accessor} >= ${filter.value.min} AND ${filter.accessor} <= ${filter.value.max}` : `${filter.accessor} >= ${filter.value.min} AND ${filter.accessor} <= ${filter.value.max}`
			}
		}

		return s;
	};

	const memorizedFilters = useMemo(() => parseFilters(filters, columns), [filters, columns]);

	const parseSorting = (sorting) => {
		return sorting.map(x => {
			if (x.desc) {
				return `${x.id}:desc`
			} else {
				return `${x.id}:asc`
			}
		});
	}

	const memorizedSorting = useMemo(() => parseSorting(sorting), [sorting]);

	const loadProperties = useCallback(async () => {
		const result = await PropertiesService.searchProperties({
			paging: {
				offset: pageIndex * pageLimit,
				limit: pageLimit
			},
			query: query && query.length > 0 ? query : null,
			filter: memorizedFilters,
			sort: memorizedSorting
		});

		setPropertiesMetaData({
			PageLimit: result.headers["x-limit"],
			Offset: result.headers["x-offset"],
			RemainingProperties: result.headers["x-remaining"],
			TotalProperties: result.headers["x-total"]
		})
		setPropertiesData(result.data);
		setPropertiesLoading(false);
	}, [pageIndex, query, memorizedFilters, memorizedSorting]);

	useEffect(() => {
		setPageIndex(0);
	}, [query, filters, sorting]);

	useEffect(() => {
		if (initiationCompleted) {
			loadProperties();
		}
	}, [pageIndex, query, memorizedFilters, memorizedSorting, initiationCompleted]);

	return {
		isPropertiesLoading,
		initiationCompleted,
		setInitiationCompleted,
		propertiesData,
		propertiesMetaData,
		loadProperties,
		query,
		setQuery,
		setPageIndex,
		pageIndex,
		filters,
		setFilters,
		sorting,
		setSorting,
		setColumns
	};

}

export default usePropertiesSearch;