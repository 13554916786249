import React from "react";
import Navigation from "./components/Navigation";
import TopBar from "./components/TopBar";
import "./index.scss";

const Layout = props => {
    return (
        <div className="app-container">
            <Navigation />
            <div className="page-wrapper">
                <TopBar />
                <div className="page-container">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default Layout;