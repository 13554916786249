const ExpenseTypes = [
    "Water",
    "Heating",
    "Operating",
    "Electricity",
    "Cooling",
    "Antenna",
    "Internet",
    "ResidentRepresentation"
];

export default ExpenseTypes;