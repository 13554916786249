//Module imports
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import Typography  from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ExtendedTable from "components/ExtendedTable";
import HeaderContainer from "components/HeaderContainer";
import NewTypography from "components/Typography";
import ChipSlim from "components/ChipSlim";

import Columns from "components/ExtendedTable/config/interestListsColumns.js";
import StyledPaper from "components/StyledPaper";
import StyledButton from "components/StyledButton";
import { getInterestLists } from "helpers/api/interestLists";
import SearchField from "components/SearchField";
import LoadingComponent from "components/LoadingComponent";

export default function InterestLists(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedData, setSearchedData] = useState([]);
  const [searchColumns, setSearchColumns] = useState(["name", "email", "phone"]);

  useEffect(() => {
    const getData = async (filters) => {
      const interestList = await getInterestLists();
      const interestListEnhanced = interestList.map((e) => {
        e.name = e.owners[0].name
        e.email = e.owners[0].email
        e.phone = e.owners[0].phone.number
        if (e.owners[1]) {
          e.name = e.name + "\n " +  e.owners[1].name
          e.email = e.email + "\n " +  e.owners[1].email
          e.phone = e.phone + "\n " +  e.owners[1].phone.number
        }
        return e; 
      })
      setData(interestListEnhanced);
      setIsLoading(false);
    }
    getData()  
  }, []);

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <Grid container alignItems="center" style={{ marginBottom: "16px" }} direction="row" spacing={2}>
        <Grid item>
			<HeaderContainer>
				<NewTypography variant='title'>Interesseliste</NewTypography>
				<ChipSlim label={data.length} />
			</HeaderContainer>
        </Grid>
      </Grid>
      <Grid item container style={{ marginBottom: "16px" }} spacing={2}>
        <Grid item xs={12} md={4} lg={2} xl={2}>
          <SearchField 
            data={data}
            setSearchedData={setSearchedData}
            searchColumns={searchColumns}
          />
        </Grid>
		<Grid item>
          <Link to="/interestlist/create">
            <StyledButton disableElevation={true} className="" variant="contained">Opret interessant</StyledButton>
          </Link>
        </Grid>
      </Grid>
        <StyledPaper>
          <ExtendedTable
            isLoading={isLoading}
            data={searchedData}
            columns={Columns}
            filters={""}
            linkPath="/interestlist/" 
            pagination={""}
          />
        </StyledPaper>
    </div>
  );
}
