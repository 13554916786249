import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { Fragment } from "react";
import { ActionButton } from "components/Buttons";
import LoadingComponent from "components/LoadingComponent";

const ScreeningAction = ({ applicant, screenApplicant }) => {

	const [isLoading, setLoading] = useState(false);

	const handleScreenApplicant = async () => {
		setLoading(true);
		await screenApplicant(applicant.id)
		setLoading(false);
	}

	if (isLoading) {
		return (
			<div className="field-group screen-button-container">
				<ActionButton><LoadingComponent /></ActionButton>
			</div>
		);
	}

	if (applicant.screeningResult != null) {
		return (
			<div className="field-group">
				<Typography variant="label">RKI status</Typography>
				<p className="field-value">{applicant.screeningResult.hasPaymentRemarks ? "Bemærkninger fundet" : "Ingen bemærkninger fundet"}</p>
			</div>
		);
	}

	if (applicant.cpr != "") {
		return (
			<div className="field-group screen-button-container">
				<ActionButton onClick={handleScreenApplicant}>RKI opslag</ActionButton>
			</div>
		);
	}

	return null;
};

const ScreeningDetails = ({ screeningResult }) => {

	const [isOpen, setOpen] = useState(false);

	if (!screeningResult) {
		return null;
	}

	return (
		<div className="screening-details-container">
			{isOpen &&
				<div className="screening-details-data">
					<p className="title">Detaljer fora RKI opslag</p>
					<div className="fields">
						<div className="field-group">
							<Typography variant="label">Navn</Typography>
							<p className="field-value">{screeningResult.name}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">Gade</Typography>
							<p className="field-value">{screeningResult.street}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">Postnummber</Typography>
							<p className="field-value">{screeningResult.zipCode}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">By</Typography>
							<p className="field-value">{screeningResult.city}</p>
						</div>
					</div>
				</div>
			}
			<button className="toggle-button" onClick={() => setOpen(!isOpen)}>
				{!isOpen ? "Vis detaljer fra RKI opslag" : "Skjul detaljer fra RKI opslag"}
			</button>
		</div>
	);
};

const Applicants = props => {

	const { applicants, screenApplicant } = props;

	return (
		<Paper className="application-content__applicants">
			<Typography variant="subtitle">Ansøger(e)</Typography>
			{applicants.map((applicant, ix) =>
				<Fragment key={ix}>
					{ix > 0 && <hr />}
					<div className="fields">
						<div className="field-group">
							<Typography variant="label">Navn</Typography>
							<p className="field-value">{applicant.name}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">Telefon</Typography>
							<p className="field-value">{applicant.phone?.extension} {applicant.phone?.number}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">E-mail</Typography>
							<p className="field-value">{applicant.email}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">Adresse</Typography>
							<p className="field-value">{applicant.address?.street} <br /> {applicant.address?.zipCode} {applicant.address?.city} <br /> {applicant.address?.country}</p>
						</div>
						<div className="field-group">
							<Typography variant="label">ID-bekræftelse</Typography>
							<p className="field-value">{applicant.idVerificationStatus === "approved" ? "Ja" : "Nej"}</p>
						</div>
						<ScreeningAction applicant={applicant} screenApplicant={screenApplicant} />
					</div>
					<ScreeningDetails screeningResult={applicant.screeningResult} />
				</Fragment>
			)}
		</Paper>
	)
};

export default Applicants;