import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

export const getTenancy = async (tenancyId) => { 
    try {
        const result = await axios.get(`tenancies/${tenancyId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const getAllTenancies = async () => {
    const url = "/tenancies";
    const firstRequest = await axios.get(url, {});
    let tenancies = firstRequest.data;
    if (firstRequest.headers["x-total"] > 250) {
        const promises = [];
        let offset = 250;
        const total =  firstRequest.headers["x-total"]; 
        while (offset < total) {
            const offsetUrl = url + "?offset=" + offset;
            await sleep(50);
            promises.push(axios.get(offsetUrl, {}));
            offset += 250;
        }
        const resolvedPromises = await Promise.all(promises);
        const data = resolvedPromises.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);
        tenancies = [...tenancies, ...data];
        return tenancies;
    } else {
        return tenancies;
    }
}

export const getTenanciesStats = async (tenancyId) => { 
    try {
        const result = await axios.get(`tenancies/stats`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }
