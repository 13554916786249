import { useState, useCallback } from "react";
import ShowcasingsService from "services/api/showcasings";

/*
    useShowcasings hook handles showcasings loading. 
*/
const useShowcasings = () => {

    const [isShowcasingsLoading, setShowcasingsLoading] = useState(true);
    const [showcasingsData, setShowcasingsData] = useState([]);

    const loadAllTenancyShowcasings = useCallback(async (tenancyId) => {
        const result = await ShowcasingsService.getAllTenancyShowcasings(tenancyId);
        if (result !== null) {
            setShowcasingsData(result.data);
        }
        setShowcasingsLoading(false);
    }, []);

	const loadAllShowcasings = useCallback(async () => {
		const result = await ShowcasingsService.getShowcasings();
		if (result !== null) {
			setShowcasingsData(result.data);
		}
		setShowcasingsLoading(false);
	}, []);
	

	return {
		isShowcasingsLoading,
		showcasingsData,
		loadAllTenancyShowcasings,
		loadAllShowcasings,
    }
};

export default useShowcasings;
