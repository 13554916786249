import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const ChipSlim = props => {

	const { label, number, fontSmall } = props;

	console.log(label, number)
	
	return (
		<div className="chip-slim__wrapper">
			<div className={`chip-slim__label ${fontSmall ? 'fontSmall' : ''}`}>{label ? label : number}</div>
			{number !== null && label !== null && (
				<div className="chip-slim__number">({number})</div>
			)}
		</div>
	);
};

ChipSlim.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
  fontSmall: PropTypes.bool,
};

ChipSlim.defaultProps = {
	label: null,
  	number: null,
	fontSmall: false,
  };
  
export default ChipSlim;