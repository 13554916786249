import React, { useEffect } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { useTenancyAnalytics } from "hooks/api";
import WebStatsChart from "./components/WebStatsChart";
import "./index.scss"
import LoadingComponent from "components/LoadingComponent";
import OpenHousesList from "./components/OpenHousesList";
import ShowcasingsList from "./components/ShowcasingsList";

const AnalyticsTab = props => {

	const { tenancyData } = props;
	const { isAnalyticsLoading, analyticsData, loadTenancyAnalytics } = useTenancyAnalytics();

	useEffect(() => {
		loadTenancyAnalytics(tenancyData.id);
	}, [tenancyData.id, loadTenancyAnalytics]);

	if (isAnalyticsLoading) {
		return (
			<Paper className="analytics-tab">
				<div className="loading-container"><LoadingComponent /></div>
			</Paper>
		);
	}

	console.log(analyticsData);

	return (
		<Paper className="analytics-tab">
			<div className="fields-section">
				{analyticsData.web.error == null && (
					<div className="field">
						<Typography variant="label">Google Analytics</Typography>
						<WebStatsChart data={analyticsData.web.data} />
					</div>
				)}
				<div className="field"></div>
			</div>
			<div className="fields-section">
				<div className="field">
					<Typography variant="label">Open houses in the past 30 days</Typography>
					<OpenHousesList tenancyId={tenancyData.id} />
				</div>
				<div className="field">
					<Typography variant="label">Showcasings in the past 30 days</Typography>
					<ShowcasingsList tenancyId={tenancyData.id} />
				</div>
			</div>
		</Paper>
	);
};

export default AnalyticsTab;