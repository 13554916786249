import React, { useEffect, useMemo } from "react";
import ExtendedTable from "components/ExtendedTable";
import { useShowcasings } from "hooks/api";
import { CircularProgress } from "@material-ui/core";
import { useOrganization } from "features/organization";
import { formatDate } from 'helpers/date';

const ShowcasingsList = props => {

	const { tenancyId } = props;

    const { isShowcasingsLoading, showcasingsData, loadAllTenancyShowcasings } = useShowcasings();
	const { employees } = useOrganization();

    useEffect(() => {
        loadAllTenancyShowcasings(tenancyId);
    }, [loadAllTenancyShowcasings, tenancyId]);

	const mapShowcasings = () => showcasingsData.map(x => {
        return {
            linkPath: `/showcasings/${x.id}`,
            date: formatDate(x.schedule.startsAt, "d MMM yyyy HH:mm"),
			host: employees.find(e => e.employeeId == x.hostId) ? employees.find(e => e.employeeId == x.hostId).name : ""
        }
    });

    const mappedShowcasings = useMemo(() => mapShowcasings(), [showcasingsData]);

    const columns = [
        {
            Header: 'Dato',
            accessor: 'date',
            canGroupBy: false
        },
		{
            Header: 'Host',
            accessor: 'host',
            canGroupBy: false
        }];

    let content = (
        <div className="loading-container">
            <CircularProgress />
        </div>
    )

    if (!isShowcasingsLoading) {
        content = (
            <ExtendedTable
                isLoading={isShowcasingsLoading}
                data={mappedShowcasings}
                columns={columns}
                linkPath="/showcasings/"
            />
        );
    }

    return content;
};

export default ShowcasingsList;