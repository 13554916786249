import { TableBody } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export default styled(TableBody)({
    '& .MuiTableRow-root:nth-child(odd)': {
        backgroundColor: "#F7F8FD"
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
        color: "#2C3589",
        fontSize: "16px",
        fontWeight: "400",
        borderBottom: 0,
        borderRadius: 0,
        padding: "16px 10px"
    }
});