import React, { useState } from 'react';
import { TableCell, TableRow } from "@material-ui/core";
import { translateString } from "helpers/index";
import Icon from "components/Icon";
import { MoneyField } from "components/Fields";

const AdditionalExpense = ({ type, amount, id, disabled, removeExpense, updateExpense }) => {

    const [value, setValue] = useState(amount.value);

	return (
		<TableRow>
			<TableCell>{translateString(type)}</TableCell>
			<TableCell>
				<MoneyField
					value={value}
					disabled={disabled}
					type="number"
					onChange={setValue}
					onExit={() => setValue(amount.value)}
					onFinish={() => {
						updateExpense(id, { amount: { value: value } })
					}}
				/>
			</TableCell>
			<TableCell className="actions-cell">
				<Icon disabled={disabled} onClick={() => removeExpense(id)} type="bin" />
			</TableCell>
		</TableRow>
	);
};

export default AdditionalExpense;