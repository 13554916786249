import axios from "./axios";

export default class PropertiesService {
	static async getProperty(propertyId) {
		try {
			const result = await axios.get(`properties/${propertyId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async queryProperties(ids) {
        try {
            const result = await axios.post(`properties/query`, {
				includeOnlyIds: ids
			});
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async searchProperties(data) {
        try {
            const result = await axios.post(`properties/search`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async getAllProperties() {
		const url = "/properties";
		const firstRequest = await axios.get(url, {});
		let properties = firstRequest.data;

		if (firstRequest.headers["x-total"] > 250) {
			const promises = [];
			let offset = 250;
			const total = firstRequest.headers["x-total"];

			while (offset < total) {
				const offsetUrl = url + "?offset=" + offset;
				promises.push(axios.get(offsetUrl, {}));
				offset += 250;
			}

			const resolvedPromises = await Promise.all(promises);
			const data = resolvedPromises.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);
			properties = [...properties, ...data];
			return properties;
		} else {
			return properties;
		}
	}
	
	static async getPropertyTenancies(propertyId) {
		try {
			const result = await axios.get(`properties/${propertyId}/tenancies`);
			return result;
		}
		catch (err) {
			return null;
		}
	}
	static async getPropertyStats(propertyId) {
		try {
			const result = await axios.get(`tenancies/stats?propertyId=${propertyId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updateProperty(propertyId, data) {
		try {
			const result = await axios.patch(`properties/${propertyId}`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async addPropertyContact(propertyId, data) {
		try {
			const result = await axios.post(`properties/${propertyId}/contacts`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async removePropertyContact(propertyId, contactId) {
		try {
			const result = await axios.delete(`properties/${propertyId}/contacts/${contactId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async createPropertyAttribute(propertyId, data) {
		try {
			const result = await axios.post(`properties/${propertyId}/attributes`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updatePropertyAttribute(propertyId, attributeId, data) {
		try {
			const result = await axios.patch(`properties/${propertyId}/attributes/${attributeId}`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async removePropertyAttribute(propertyId, attributeId) {
		try {
			const result = await axios.delete(`properties/${propertyId}/attributes/${attributeId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async uploadImage(propertyId, data) {
		try {
			const result = await axios.post(`properties/${propertyId}/images`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async deleteImage(propertyId, imageId) {
		try {
			const result = await axios.delete(`properties/${propertyId}/images/${imageId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updateImage(propertyId, imageId, data) {
		try {
			const result = await axios.patch(`properties/${propertyId}/images/${imageId}`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async uploadDocument(propertyId, data) {
		try {
			const result = await axios.post(`properties/${propertyId}/documents`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async deleteDocument(propertyId, documentId) {
		try {
			const result = await axios.delete(`properties/${propertyId}/documents/${documentId}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getPropertyInterestList(propertyId) {
		try {
			const result = await axios.get(`properties/${propertyId}/interest-list`);
			return result;
		}
		catch (err) {
			return null;
		}
	}
	
	static async getPropertyContractData(propertyId) {
		try {
			const result = await axios.get(`properties/${propertyId}/contract-data`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async updatePropertyContractData(propertyId, data) {
		try {
			const result = await axios.patch(`properties/${propertyId}/contract-data`, data);
			return result;
		}
		catch (err) {
			return null;
		}
	}
}