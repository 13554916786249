import React from "react";
import "./index.scss";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import Log from "./components/Log";
import BaseFields from "./components/BaseFields";
import Location from "./components/Location";
import LeaseInformation from "pages/components/LeaseInformation";
import Expenses from "pages/components/Expenses";
import Contacts from "pages/components/Contacts";
import Integrations from "./components/Integrations";
import { useOrganization } from "features/organization";
import Files from "pages/components/Files";
import { Facilities, Terms } from "pages/components/Attributes";
import IntegrationData from "./components/IntegrationData";
import Descriptions from "./components/Descriptions";
import EnergyInformation from "pages/components/EnergyInformation";

const GeneralInformationTab = props => {

    const { caseData, tenancyData, updateCase, updateTenancy, removeComment, createComment, addCaseTenancyType,
        removeCaseTenancyType, createCaseExpense, removeCaseExpense, createCaseContact,
        removeCaseContact, updateCaseAttribute, removeCaseAttribute, createCaseAttribute,
        isCaseIntegrationProcessing, enableCaseIntegration, disableCaseIntegration, uploadCaseImages,
        deleteCaseImage, changeCaseImageOrder, uploadCaseFloorPlans, deleteCaseFloorPlan,
        changeCaseFloorPlanOrder, integrationId, integrationData,
        uploadProspectus, deleteProspectus, updateCaseExpense } = props;

    const { attributeDefinitions } = useOrganization();

    return (
        <>
            <Paper>
                <div className="general-information__tenancy-address">
                    <h1 className="street">{caseData.address.street}</h1>
                    <Typography variant="label">{caseData.address.zipCode} {caseData.address.city}</Typography>
                </div>
                <div className="general-information__sections">
                    <BaseFields 
                        caseData={caseData} 
                        updateCase={updateCase} 
                        integrationId={integrationId} 
                    />
                    <Log data={caseData} viewOnly={!!caseData.deletedAt} removeComment={removeComment} createComment={createComment} />
                </div>
            </Paper>
            <Integrations
                caseData={caseData}
                isProcessing={isCaseIntegrationProcessing}
                enableIntegration={enableCaseIntegration}
                disableIntegration={disableCaseIntegration}
            />
            <LeaseInformation
                data={caseData}
				tenancyData={tenancyData}
                update={updateCase}
                addTenancyType={addCaseTenancyType}
                removeTenancyType={removeCaseTenancyType} />
            {/* <UnstructuredInformation
                data={caseData}
                update={updateCase} /> */}
            <Location caseData={caseData} updateCase={updateCase} />
			<EnergyInformation tenancyData={tenancyData} updateTenancy={updateTenancy} />
            <Descriptions data={caseData} update={updateCase} />
            <Expenses
                data={caseData}
				updateExpense={updateCaseExpense}
                createExpense={createCaseExpense}
                removeExpense={removeCaseExpense}
                update={updateCase} />
            <Files
                images={caseData.images}
                readOnly={caseData.deletedAt ? true : false}
                floorPlans={caseData.floorPlans}
                uploadImages={uploadCaseImages}
                deleteImage={deleteCaseImage}
                changeImageOrder={changeCaseImageOrder}
                uploadFloorPlans={uploadCaseFloorPlans}
                deleteFloorPlan={deleteCaseFloorPlan}
                changeFloorPlanOrder={changeCaseFloorPlanOrder}
                prospectusEnabled={caseData.classification === "Business"}
                prospectus={caseData.prospectus}
                uploadProspectus={uploadProspectus}
                deleteProspectus={deleteProspectus}
				virtualTour={caseData.virtualTour?.url}
				updateVirtualTour={(value) => updateCase({virtualTour: {url: value}})}
            />
            <Contacts
                contacts={caseData.contacts}
                readOnly={caseData.deletedAt ? true : false}
                createContact={createCaseContact}
                removeContact={removeCaseContact}
            />
            <Facilities
                facilities={caseData.attributes.filter(x => x.definition.group.text === "Facilities")}
                readOnly={caseData.deletedAt ? true : false}
                updateAttribute={updateCaseAttribute}
                removeAttribute={removeCaseAttribute}
                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Facilities")}
                createAttribute={createCaseAttribute}
            />
            <Terms
                terms={caseData.attributes.filter(x => x.definition.group.text === "Terms")}
                readOnly={caseData.deletedAt ? true : false}
                updateAttribute={updateCaseAttribute}
                removeAttribute={removeCaseAttribute}
                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Terms")}
                createAttribute={createCaseAttribute}
            />
            <IntegrationData
                integrationData={integrationData}
            />
        </>
    );
}

export default GeneralInformationTab;