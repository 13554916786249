import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { getIntegrationDataObject } from "helpers";
import { formatDate } from 'helpers/date';

const IntegrationData = props => {

    const { integrationData } = props;

    if (!integrationData) {
      return null;  
    }
    
    let list = []


    for (const [key, value] of Object.entries(integrationData)) {
        const o = getIntegrationDataObject(key);
        const displayLabel = o.label;
        var displayValue = "-";
        if (value) {
            displayValue = o.type === "date" ? formatDate(value, "dd/MM/yyyy") : value;
        }
        const displaySpan = o.span ? o.span : 1;
        const sortOrder = o.sortOrder ? o.sortOrder : 1; 
        list.push({
            label: displayLabel,
            value: displayValue,
            span: displaySpan,
            sortOrder: sortOrder
        })
    }
    list.sort((a, b) => (a.sortOrder < b.sortOrder) ? 1 : -1)

    return (
        <Paper className="general-information__integration-data">
            <div className="wrapper">
                <Typography variant="subtitle">Unik data</Typography>
                <div className="fields points">
                    {list.map((object, ix) => {
                        return (
                            <div key={ix} className={`fields-section__field span${object.span}`}>
                                <Typography variant="label">{ object.label }</Typography>
                                <p className="field-value">{ object.value }</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Paper>
    );
};

export default IntegrationData;