const getInitialStartTime = () => {
	let r = {}
	const d = new Date()
	let hour = d.getHours()
	if (7 < hour && hour < 17) {
		const twoDigitHour = ('0' + (hour + 1)).slice(-2)
		r.hour = `${twoDigitHour}:00`
		r.date = d
	} else {
		r.hour = '08:00'
		const dayOfWeek = d.getDay()
		if (dayOfWeek === 5) {
			d.setDate(d.getDate() + 3)
		} else if (dayOfWeek === 6) {
			d.setDate(d.getDate() + 2)
		} else {
			d.setDate(d.getDate() + 1)
		}
		r.date = d
	}
	return r
}
export default getInitialStartTime;