import { useEffect, useState } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { connect } from "react-redux";
import { SET_USER } from "redux/actionTypes";
import { history } from 'redux/configureStore';
import Cookies from "js-cookie";

const scope = "openid profile email cases:read cases:write interestlists:read contracts:read contracts:write interestlists:write showcasings:read showcasings:write open-houses:read open-houses:write properties:read tenancies:read properties:write tenancies:write employees:read";

export let Auth0Client = null;

export const getToken = async (options = { audience: "https://wesoft/rental", scope: scope, cacheLocation: "localstorage" }, client = Auth0Client) => {
	try {
		const token = await client.getTokenSilently(options);
		return token;
	} catch (err) {
		if (err.error !== "consent_required") {
			return console.log(err);
		}
		return client.getTokenWithPopup(options);
	}
};

export const logout = () => {
	Cookies.remove('authToken');
	Cookies.remove('auth0.is.authenticated');
	return Auth0Client.logout()
}

const Auth0Provider = props => {

	const { children, setUser } = props;
	const [isLoading, setLoading] = useState(true);

	const onRedirectCallback = appState => {
		history.push(
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname
		);
	};

	useEffect(() => {
		const initAuth0 = async () => {

			Auth0Client = await createAuth0Client({
				domain: process.env.REACT_APP_AUTH0_DOMAIN,
				audience: process.env.REACT_APP_AUTH0_AUDIENCE,
				client_id: process.env.REACT_APP_AUTH0_API_IDENTIFIER,
				redirect_uri: window.location.origin,
				scope: scope,
				onRedirectCallback: onRedirectCallback,
				advancedOptions: {
					scope: scope,
					defaultScope: scope
				}
			});

			if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
				const { appState } = await Auth0Client.handleRedirectCallback();
				onRedirectCallback(appState);
			}

			const isAuthenticated = await Auth0Client.isAuthenticated();

			if (isAuthenticated) {
				setLoading(false);
			} else {
				Auth0Client.loginWithRedirect({
					appState: {
						targetUrl: window.location.pathname
					},
					scope: scope
				});
			}
		};
		initAuth0();
	}, [setUser]);

	if (isLoading)
		return null;

	return children;
};

const mapStateToProps = state => ({
	general: state.general
});

const mapDispatchToProps = dispatch => ({
	setUser: (user) => {
		dispatch({ type: SET_USER, payload: user });
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth0Provider);