import { useState, useCallback } from "react";
import ContractsService from 'services/api/contracts';
import produce from "immer";

/*
	useApplication hook handles application operations. 
	The only requirement for it is to load application first, before doing operations.
*/
const useApplication = () => {

	const [isApplicationLoading, setApplicationLoading] = useState(true);
	const [isContractGenerating, setContractGenerating] = useState(false);
	const [isContractCreating, setContractCreating] = useState(false);
	const [applicationData, setApplicationData] = useState(null);

	const loadApplication = useCallback(async (applicationId) => {
		const result = await ContractsService.getApplication(applicationId);
		if (result !== null) {
			const contract = await ContractsService.getApplicationContract(applicationId);

			setApplicationData({
				...result,
				contract: contract
			});
		}

		setApplicationLoading(false);
	}, []);

	const selectApplication = useCallback(async () => {
		if (!applicationData)
			return;

		setContractCreating(true);
		const result = await ContractsService.selectApplication(applicationData.id);
		if (result) {
			setApplicationData({
				...applicationData,
				contract: result
			});
		}
		setContractCreating(false);
	}, [applicationData]);

	const updateContract = useCallback(async (data) => {
		if (!applicationData)
			return;

		const result = await ContractsService.updateContract(applicationData.contract.id, data);
		if (result) {
			setApplicationData({
				...applicationData,
				contract: result
			});
		}
	}, [applicationData]);

	const updateContractData = useCallback(async (data) => {
		if (!applicationData)
			return;

		const result = await ContractsService.updateContractData(applicationData.contract.id, data);
		if (result) {
			setApplicationData({
				...applicationData,
				contract: result
			});
		}
	}, [applicationData]);

	const rejectContract = useCallback(async () => {
		if (!applicationData)
			return;

		const result = await ContractsService.rejectContract(applicationData.contract.id);
		if (result) {
			const contract = await ContractsService.getApplicationContract(applicationData.id);

			setApplicationData({
				...applicationData,
				contract: contract
			});
		}
	}, [applicationData]);

	const removeContractFromPenneo = useCallback(async () => {
		if (!applicationData)
			return;

		const result = await ContractsService.removeContractFromPenneo(applicationData.contract.id);
		if (result) {
			const contract = await ContractsService.getApplicationContract(applicationData.id);

			setApplicationData({
				...applicationData,
				contract: contract
			});
		}
	}, [applicationData]);

	const sendContract = useCallback(async () => {
		if (!applicationData)
			return;

		const { success, error } = await ContractsService.sendContract(applicationData.contract.id);
		if (success) {
			const contract = await ContractsService.getApplicationContract(applicationData.id);

			setApplicationData({
				...applicationData,
				contract: contract
			});

			return null;
		}
		else {
			return error;
		}
	}, [applicationData]);

	const openContract = useCallback(async () => {
		if (!applicationData?.contract)
			return;

		if (isContractGenerating)
			return;

		setContractGenerating(true);
		const result = await ContractsService.getContractPdf(applicationData.contract.id);
		setContractGenerating(false);
		const data = window.URL.createObjectURL(result);
		window.open(data);

	}, [applicationData]);

	const toggleContractPropertyFacility = useCallback(async (facility) => {
		if (!applicationData)
			return;

		let result = false;

		if (applicationData.contract.data.property.facilities.predefined.includes(facility)) {
			result = await ContractsService.removeContractPropertyFacility(applicationData.contract.id, facility);
		} else {
			result = await ContractsService.addContractPropertyFacility(applicationData.contract.id, facility);
		}

		if (result) {
			const contract = await ContractsService.getApplicationContract(applicationData.id);

			setApplicationData({
				...applicationData,
				contract: contract
			});
		}
	}, [applicationData]);

	const toggleContractTenancyFacility = useCallback(async (facility) => {
		if (!applicationData)
			return;

		let result = false;

		if (applicationData.contract.data.tenancy.facilities.predefined.includes(facility)) {
			result = await ContractsService.removeContractTenancyFacility(applicationData.contract.id, facility);
		} else {
			result = await ContractsService.addContractTenancyFacility(applicationData.contract.id, facility);
		}

		if (result) {
			const contract = await ContractsService.getApplicationContract(applicationData.id);

			setApplicationData({
				...applicationData,
				contract: contract
			});
		}
	}, [applicationData]);

	const screenApplicant = useCallback(async (applicantId) => {
		if (!applicationData)
			return;

		const result = await ContractsService.screenApplicant(applicationData.id, applicantId);
		console.log(result)
		if (result) {
			setApplicationData({
				...result,
				contract: applicationData.contract
			});
		}
	}, [applicationData]);

	return {
		isApplicationLoading,
		applicationData,
		loadApplication,
		selectApplication,
		rejectContract,
		removeContractFromPenneo,
		sendContract,
		openContract,
		updateContract,
		updateContractData,
		toggleContractPropertyFacility,
		toggleContractTenancyFacility,
		screenApplicant,
		isContractGenerating,
		isContractCreating
	};
};

export default useApplication;