import OrganizationContext from './organization-context';
import React, { useState, useEffect } from "react";
import initialOrganizationState from './organization-state';
import OrganizationService from "services/api/organization";

const OrganizationProvider = props => {

    const { children } = props;
    const [organizationName, setOrganizationName] = useState(initialOrganizationState.name);
    const [isOrganizationLoading, setOrganizationLoading] = useState(initialOrganizationState.isLoading);
    const [contactRoles, setContactRoles] = useState(initialOrganizationState.contactRoles);
    const [employees, setEmployees] = useState(initialOrganizationState.employees);
	const [blockedEmployees, setBlockedEmployees] = useState(initialOrganizationState.blockedEmployees);
    const [integrations, setIntegrations] = useState(initialOrganizationState.integrations);
    const [attributeDefinitions, setAttributeDefinitions] = useState(initialOrganizationState.attributeDefinitions);

    useEffect(() => {
        (async () => {
            const contractRolesResult = await OrganizationService.getContactRoles();
            if (contractRolesResult !== null) {
                setContactRoles(contractRolesResult.data);
            }

            const employeesResult = await OrganizationService.getEmployees();
            if (employeesResult !== null) {
                setEmployees(employeesResult.data.filter(x => !x.blocked));
				setBlockedEmployees(employeesResult.data.filter(x => x.blocked));
            }

            const integrationsResult = await OrganizationService.getIntegrations();
            if (integrationsResult !== null) {
                setIntegrations(integrationsResult.data);
            }

            const attributeDefinitionsResult = await OrganizationService.getAttributeDefinitions();
            if (attributeDefinitionsResult !== null) {
                setAttributeDefinitions(attributeDefinitionsResult.data);
            }

            const organizationResult = await OrganizationService.getOrganization();
            if (organizationResult !== null) {
                setOrganizationName(organizationResult.data.name);
            }
            
            setOrganizationLoading(false);
        })();
    }, []);

    return (
        <OrganizationContext.Provider
            value={{
                organizationName,
                isOrganizationLoading,
                contactRoles,
                employees,
                integrations,
                attributeDefinitions,
				blockedEmployees
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};

export default OrganizationProvider;