import MaterialTabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const Tabs = withStyles({
    root: {
        borderBottom: '1px solid #EAEAEA',
    },
    indicator: {
        backgroundColor: '#566FFF',
    },
})(MaterialTabs);

export default Tabs;