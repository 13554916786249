import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import HeatingUtility from "./utilities/HeatingUtility";
import WaterUtility from "./utilities/WaterUtility";
import ElectricityUtility from "./utilities/ElectricityUtility";
import CoolingUtility from "./utilities/CoolingUtility";
import AntennaUtility from "./utilities/AntennaUtility";
import InternetUtility from "./utilities/InternetUtility";

const UtilitiesFields = ({ tenancyContractData, propertyContractData, updateTenancyContract }) => {
    return (
        <Paper className="property-contract-details__utilities-fields">
            <Typography variant="subtitle">Forbrug</Typography>
            <HeatingUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
			<WaterUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
            <ElectricityUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
            <CoolingUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
            <AntennaUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
            <InternetUtility propertyDetails={propertyContractData} tenancyDetails={tenancyContractData} updateTenancyContract={updateTenancyContract} />
        </Paper>
    );
};

export default UtilitiesFields;