import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { Select } from "components/Fields";
import { translateString } from "helpers";
import { MenuItem } from "@material-ui/core";

const TenancyFields = ({ contractData, updatePropertyContract }) => {

	const tenancyTypes = [
		"single_room",
		"housing_cooperative",
		"apartment",
		"condominium",
		"town_house"
	];

	return (
		<Paper className="property-contract-details__company-bank-fields">
			<Typography variant="subtitle">Tenancy informationer</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Det lejede er</Typography>
					<Select
						value={contractData.tenancyType}
						onChange={(e) => {
							updatePropertyContract({ tenancyType: e.target.value } )
						}}>
						{tenancyTypes.map((type, ix) => {
							return (
								<MenuItem key={ix} value={type}>
									{translateString(type)}
								</MenuItem>
							);
						})}
					</Select>
				</div>
			</div>
		</Paper>
	);
};

export default TenancyFields;