import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import { Select } from "components/Fields";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const HeatingUtility = props => {

	const { contractDetails, updateProperty } = props;

	const [source, setSource] = useState(contractDetails.utilities.heating.source ?? []);
	const [accountingStartDate, setAccountingStartDate] = useState(contractDetails.utilities.heating.accountingStartDate ?? "");

	const handleChange = (event) => {
		const value = event.target.value;
		setSource(value);
		updateProperty({ contractDetails: { utilities: { heating: { source: value } } } })
	};

	const options = [
		"electricity",
		"gas",
		"oil",
		"district_heating"
	];

	return (
		<>
			<Typography variant="label" className="utility-title">Varme</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer ansvarlig</Typography>
					<Switch
						checked={contractDetails.utilities.heating.isLandlordResponsible}
						onChange={(e) => updateProperty({ contractDetails: { utilities: { heating: { isLandlordResponsible: e.target.checked } } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Udgift til varme inkluderet i husleje</Typography>
					<Switch
						checked={contractDetails.utilities.heating.isHeatingIncludedInRent}
						onChange={(e) => updateProperty({ contractDetails: { utilities: { heating: { isHeatingIncludedInRent: e.target.checked } } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Opvarmes ved</Typography>
					<Select
						multiple
						value={source}
						onChange={handleChange}
						renderValue={(source) => source.join(", ")}
					>
						{options.map((option) => (
							<MenuItem key={option} value={option}>
								<ListItemIcon>
									<Checkbox checked={source.indexOf(option) > -1} />
								</ListItemIcon>
								<ListItemText primary={option} />
							</MenuItem>
						))}
					</Select>
					{/* <TextField
                    value={source}
                    onChange={(event) => setName(event.target.value)}
                    onExit={() => setName(propertyData.contractDetails.company.name)}
                    onFinish={() => {
                        name !== propertyData.contractDetails.company.name && updateProperty({ contractDetails: { company: { name: name } } })
                    }}
                /> */}
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Varmeregnskabsåret begynder</Typography>
					<TextField
						value={accountingStartDate ?? ""}
						type="date"
						onChange={(event) => setAccountingStartDate(event.target.value)}
						onExit={() => setAccountingStartDate(contractDetails.utilities.heating.accountingStartDate)}
						onFinish={() => {
							accountingStartDate !== contractDetails.utilities.heating.accountingStartDate && updateProperty({ contractDetails: { utilities: { heating: { accountingStartDate } } } })
						}}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default HeatingUtility;