import React, { useMemo } from "react";
import LineChart from "components/LineChart";
import { isSameMonth, sub, isSameYear, format, getUnixTime } from "date-fns";
import { da } from 'date-fns/locale';
import Paper from "components/StyledPaper";

const CompletedCasesChart = ({ casesData }) => {

    const numberOfMonths = 5;

    const getMapWithKeys = () => {
        const now = new Date();

        const results = new Map();

        for (let i = numberOfMonths - 1; i >= 0; i--) {
            results.set(sub(now, { months: i }), []);
        }

        return results;
    }

    const getKey = (map, item) => {
        const keys = map.keys();
        const completedAt = item.stateLogs.filter(x => x.state === "Completed").sort((a, b) => a.createdAt - b.createdAt)[0];
        if (!completedAt)
            return null;

        const completedDate = new Date(completedAt.createdAt);

        let key = keys.next().value;

        while (key) {
            if (isSameMonth(completedDate, key) && isSameYear(completedDate, key)) {
                return key;
            }

            key = keys.next().value;
        }

    }

    const getCompletedCasesByMonth = () => {
        const results = getMapWithKeys();

        casesData.forEach((item) => {
            const key = getKey(results, item);
            if (key) {
                const collection = results.get(key);
                if (collection) {
                    results.set(key, [...collection, item]);
                }
            }
        });

        return results;
    }

    const completedCasesByMonth = useMemo(() => getCompletedCasesByMonth(), [casesData]);

    const getClosingTime = (item) => {
        const completedAt = item.stateLogs.filter(x => x.state === "Completed").sort((a, b) => a.createdAt - b.createdAt)[0];
        const completedDate = new Date(completedAt.createdAt);
        const activatedAt = item.stateLogs.filter(x => x.state === "Active").sort((a, b) => a.createdAt - b.createdAt)[0];
        if (activatedAt == null)
            return 0;

        const activatedDate = new Date(activatedAt.createdAt);

        const difference = getUnixTime(completedDate) - getUnixTime(activatedDate);
        return Math.round(difference / 86400);
    }

    const getAverageClosingTimeByMonth = () => {
        const results = getMapWithKeys();

        casesData.forEach((item) => {
            const key = getKey(results, item);
            if (key) {
                const collection = results.get(key);
                if (collection) {
                    results.set(key, [...collection, getClosingTime(item)]);
                }
            }
        });

        const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

        [...results].forEach(x => {
            const averageClosingTime = Math.round(average(x[1]));
            results.set(x[0], !averageClosingTime ? 0 : averageClosingTime);
        });

        return results;
    }

    const averageClosingTimeByMonth = useMemo(() => getAverageClosingTimeByMonth(), [casesData]);


    return (
        <Paper className="chart-container">
            <LineChart
                labels={[...completedCasesByMonth.keys()].map(x => format(x, 'MMMM', { locale: da }))}
                datasets={[
                    {
                        label: "Afsluttede sager",
                        data: [...completedCasesByMonth].map(x => x[1].length),
                        borderColor: '#2C3589',
                        backgroundColor: 'rgba(44, 53, 137, 0.5)',
                        yAxisID: 'y'
                    },
                    {
                        label: "Gns. afslutningstid (dage)",
                        data: [...averageClosingTimeByMonth].map(x => x[1]),
                        borderColor: '#566FFF',
                        backgroundColor: 'rgba(86, 111, 255, 0.5)',
                        yAxisID: 'y1'
                    }]}
                scales={{
                    x: {
                        grid: {
                            display: true
                        }
                    },
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true
                        },
                        ticks: {
                            stepSize: 1
                        }
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            display: true,
                            drawOnChartArea: false
                        },
                        ticks: {
                            stepSize: 1
                        }
                    }
                }}
            />
        </Paper>
    );
};
export default CompletedCasesChart;