import React, { useRef, useEffect } from "react";
import Chart from 'chart.js/auto';

const DoughnutChart = props => {

    const canvas = useRef(null);

    const defaultColors = ['#2C3589', '#566FFF', '#8092ff', '#99a7ff', '#c7cfff'];

    useEffect(() => {
        if (!canvas.current)
            return;

        new Chart(canvas.current, {
            type: 'doughnut',
            data: {
                labels: props.labels,
                datasets: [{
                    data: props.data,
                    backgroundColor: props.backgroundColors ?? defaultColors
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: !props.hideLegend,
                    }
                }
            }
        });
    }, [canvas]);

    return (
        <canvas ref={canvas} ></canvas>
    );
};

export default DoughnutChart;