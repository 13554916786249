import React from "react";
import "./index.scss";
import { ReactComponent as CloseIcon } from "assets/icons/jorato-close.svg";

const Toast = props => {
    return (
        <div className="toast">
            <p>{props.children}</p>
            <div className="icon-wrapper"><CloseIcon onClick={props.onRemove}/></div>
        </div>
    );
};

export default Toast;