import axios from "./axios";

export default class CasesService {
    static async getCase(caseId) {
        try {
            const result = await axios.get(`cases/${caseId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async searchCases(data) {
        try {
            const result = await axios.post(`cases/search`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }


    static async getEmployeeCases() {
        try {
            const result = await axios.get(`employees/me/cases`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getEmployeeActiveCases() {
        try {
            const result = await axios.get(`employees/me/cases/active`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getEmployeeCompletedCases() {
        try {
            const result = await axios.get(`employees/me/cases/completed`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getCaseIntegrations(caseId) {
        try {
            const result = await axios.get(`cases/${caseId}/integrations`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateCase(caseId, data) {
        try {
            const result = await axios.patch(`cases/${caseId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addCaseTenancyType(caseId, type) {
        try {
            const result = await axios.post(`cases/${caseId}/types/${type}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeCaseTenancyType(caseId, type) {
        try {
            const result = await axios.delete(`cases/${caseId}/types/${type}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addCaseExpense(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/expenses`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

	static async updateCaseExpense(caseId, expenseId, data) {
        try {
            const result = await axios.patch(`cases/${caseId}/expenses/${expenseId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeCaseExpense(caseId, expenseId) {
        try {
            const result = await axios.delete(`cases/${caseId}/expenses/${expenseId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async addCaseContact(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/contacts`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeCaseContact(caseId, contactId) {
        try {
            const result = await axios.delete(`cases/${caseId}/contacts/${contactId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async createCaseAttribute(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/attributes`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateCaseAttribute(caseId, attributeId, data) {
        try {
            const result = await axios.patch(`cases/${caseId}/attributes/${attributeId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeCaseAttribute(caseId, attributeId) {
        try {
            const result = await axios.delete(`cases/${caseId}/attributes/${attributeId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }


    static async getCaseComments(caseId) {
        try {
            const result = await axios.get(`cases/${caseId}/comments`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getCaseApplications(caseId) {
        try {
            const result = await axios.get(`cases/${caseId}/applications`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async createCaseComment(caseId, content) {
        try {
            const result = await axios.post(`cases/${caseId}/comments/`, {
                content: content
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async removeCaseComment(caseId, commentId) {
        try {
            const result = await axios.delete(`cases/${caseId}/comments/${commentId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async enableIntegration(caseId, integration) {
        try {
            const result = await axios.post(`cases/${caseId}/integrations/${integration}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async disableIntegration(caseId, integration) {
        try {
            const result = await axios.delete(`cases/${caseId}/integrations/${integration}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadImage(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/images`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteImage(caseId, imageId) {
        try {
            const result = await axios.delete(`cases/${caseId}/images/${imageId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateImage(caseId, imageId, data) {
        try {
            const result = await axios.patch(`cases/${caseId}/images/${imageId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadFloorPlan(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/floor-plans`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteFloorPlan(caseId, floorPlanId) {
        try {
            const result = await axios.delete(`cases/${caseId}/floor-plans/${floorPlanId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async updateFloorPlan(caseId, floorPlanId, data) {
        try {
            const result = await axios.patch(`cases/${caseId}/floor-plans/${floorPlanId}`, data);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadDocument(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/documents`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteDocument(caseId, documentId) {
        try {
            const result = await axios.delete(`cases/${caseId}/documents/${documentId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async uploadProspectus(caseId, data) {
        try {
            const result = await axios.post(`cases/${caseId}/prospectus`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteProspectus(caseId) {
        try {
            const result = await axios.delete(`cases/${caseId}/prospectus`);
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async deleteCase(caseId) {
        try {
            const result = await axios.delete(`cases/${caseId}`);
            return result;
        }
        catch (err) {
            return null;
        }
    }
}