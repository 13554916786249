import { useState, useCallback } from "react";
import OpenhousesService from 'services/api/openhouses';

/*
    useOpenhouse hook handles openhouse operations. 
    The only requirement for it is to load openhouse first, before doing operations
*/
const useOpenHouse = () => {

    const [isOpenhouseLoading, setOpenhouseLoading] = useState(true);
    const [openhouseData, setOpenhouseData] = useState(null);

    const loadOpenhouse = useCallback(async (openhouseId) => {
        const result = await OpenhousesService.getOpenhouse(openhouseId);
        if (result !== null) {
            setOpenhouseData({
                ...result.data
            });
        }

        setOpenhouseLoading(false);
    }, []);

    const updateOpenhouse = useCallback(async (data) => {
        if (openhouseData == null)
            return;

        const result = await OpenhousesService.updateOpenhouse(openhouseData.id, data);

        if (result !== null) {
            setOpenhouseData({
                ...openhouseData,
                ...result.data,
            });
        }
    }, [openhouseData]);

	const deleteOpenhouse = useCallback(async () => {
        if (openhouseData == null)
            return;

        await OpenhousesService.deleteOpenhouse(openhouseData.id);
    }, [openhouseData]);

	return {
		isOpenhouseLoading,
		openhouseData,
		loadOpenhouse,
		updateOpenhouse,
		deleteOpenhouse
    }
};

export default useOpenHouse;
