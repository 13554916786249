import axios from "./axios";

export default class EventsService {
    static async getEmployeeEvents() {
        try {
            const result = await axios.get(`employees/me/events`);
            return result;
        }
        catch (err) {
            return null;
        }
    }
}