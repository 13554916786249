import { useState, useCallback } from "react";
import EventsService from 'services/api/events';

/*
    useEvents hook handles events loading. 
*/
const useEvents = () => {

    const [isEventsLoading, setEventsLoading] = useState(true);
    const [eventsData, setEventsData] = useState([]);

    const loadCurrentEmployeeEvents = useCallback(async () => {
        const result = await EventsService.getEmployeeEvents();
		if (result.data !== "") {
			setEventsData(result.data);
		}
        setEventsLoading(false);
    }, []);

    return {
        eventsData,
        isEventsLoading,
        loadCurrentEmployeeEvents
    };
};

export default useEvents;