import axios from "./axios";

export default class OrganizationService
{
    static async getOrganization()
    {
        try {
            const result = await axios.get(`organizations`);    
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getContactRoles()
    {
        try {
            const result = await axios.get(`contact-roles`);    
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getAttributeDefinitions()
    {
        try {
            const result = await axios.get(`attribute-definitions`);    
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getIntegrations()
    {
        try {
            const result = await axios.get(`integrations`);    
            return result;
        }
        catch (err) {
            return null;
        }
    }

    static async getEmployees()
    {
        try {
            const result = await axios.get(`employees`);    
            return result;
        }
        catch (err) {
            return null;
        }
    }
}