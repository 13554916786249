import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";

const SignerFields = ({ contractData, updatePropertyContract }) => {

    const [name, setName] = useState(contractData.company.signer.name ?? "");
    const [email, setEmail] = useState(contractData.company.signer.email ?? "");

    return (
        <Paper className="property-contract-details__company-bank-fields">
            <Typography variant="subtitle">Underskriver</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Navn</Typography>
                    <TextField
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onExit={() => setName(contractData.company.signer.name)}
                        onFinish={() => {
                            name !== contractData.company.signer.name && updatePropertyContract({ company: { signer: { name: name } } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Email</Typography>
                    <TextField
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onExit={() => setEmail(contractData.company.signer.email)}
                        onFinish={() => {
                            email !== contractData.company.signer.email && updatePropertyContract({ company: { signer: { email: email } } })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default SignerFields;