import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles({
    buttonGroup: {
        color: "#2C3589",
        border: 0,
        cursor: "pointer",
        height: "48px",
        display: "flex",
        padding: "0px 0px 0px 22px",
        minWidth: "72px",
        alignAtems: "center",
        fontWeight: 500,
        borderRadius: "50px",
        justifyContent: "center",
        backgroundColor: "#E2E6FB",
        boxShadow: "none",
        marginLeft: "14px",
    },
    button: {
        background: "transparent",
        "fontSize": "13.3333px",
        "&:hover": {
            backgroundColor: "transparent",
            color: "currentColor",
            stroke: "none",
            boxShadow: "none"
        }
    },
    toggle: {
        background: "transparent",
        "fontSize": "13.3333px",
        "color": "currentColor",
        "&:hover": {
            backgroundColor: "transparent",
            color: "currentColor",
            stroke: "none",
            boxShadow: "none",
            "& span svg path": {
                stroke: "blue"
            }
        },  
        "borderRadius": "0px 30px 30px 0px"
    },
    list: {
        borderRadius: "30px",
        backgroundColor: "transparent",
        color: "currentColor",
        boxShadow: "none",
        "& li": {
            borderRadius: "30px",
            color: "#2C3589",
            margin: "10px 0px",
            padding: "0px 22px",
            height: "48px",
            backgroundColor: "#E2E6FB",
            fontSize: "13.3333px"
        },
        
    },
    paper: {
        backgroundColor: "transparent"
    }
});

export default function SplitButton(props) {

    const { primary, options } = props;
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup className={classes.buttonGroup} variant="contained" ref={anchorRef} aria-label="split button">
                <Button className={classes.button} onClick={() => primary.action()}>{primary.label}</Button>
                <Button 
                    className={classes.toggle}
                    color="primary"
                    size="small"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper className={classes.paper} elevation={0}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList className={classes.list} id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={`${option.label}_${index}`}
                                            onClick={() => option.action()}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
