import React, { useState, useEffect } from 'react';
import Typography from "components/Typography";
import StyledPaper from "components/StyledPaper";
import YearMonthPicker from 'components/YearMonthPicker';
import { sub } from 'date-fns'
import IntervalPicker from './IntervalPicker';
import { useReports } from 'hooks/api';
import ReportContent from './ReportContent';
import "./index.scss";
import { formatDate } from 'helpers/date';

const EmployeeShowcasingsReport = () => {

	const [interval, setInterval] = useState("weekly");
	const [dates, setDates] = useState();

	const { isReportLoading, reportData, loadEmployeeShowcasingReport } = useReports();

	const getDefaultDate = () => {
		const now = new Date();
		const prevMonth = sub(now, {
			months: 1,
		});

		return { from: { year: prevMonth.getFullYear(), month: prevMonth.getMonth() + 1 }, to: { year: now.getFullYear(), month: now.getMonth() + 1 } };
	};

	useEffect(() => {
		if (!dates) {
			setDates(getDefaultDate());
		}
	}, []);

	useEffect(() => {
		if (!dates) 
			return;

		const fromDate = new Date(dates.from.year, dates.from.month - 1, 1);
		const toDate = new Date(dates.to.year, dates.to.month - 1, 1);

		loadEmployeeShowcasingReport(formatDate(fromDate, "MM/yyyy"), formatDate(toDate, "MM/yyyy"), interval);
	}, [interval, dates]);

	return (
		<div className="employee-showcasings-report__container">
			<div className="header">
				<Typography variant="title">Fremvisningsrapport</Typography>
				<div className="actions">
					<IntervalPicker value={interval} onChange={setInterval} />
					<YearMonthPicker value={dates ?? getDefaultDate()} onChange={setDates} />
				</div>
			</div>
			<StyledPaper>
				<ReportContent isLoading={isReportLoading} data={reportData} />
			</StyledPaper>
		</div>
	);
};

export default EmployeeShowcasingsReport;