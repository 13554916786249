import React from "react";
import Typography from "components/Typography";
import { Switch } from "components/Fields";
import InheritedValueIcon from "../InheritedValueIcon";

const AntennaUtility = ({ propertyDetails, tenancyDetails, updateTenancyContract }) => {

	const isLandlordResponsible = tenancyDetails.utilities?.antenna?.isLandlordResponsible ?? propertyDetails.utilities?.antenna?.isLandlordResponsible;
	const isLandlordReponsibleInherited = tenancyDetails.utilities?.antenna?.isLandlordResponsible == null || tenancyDetails.utilities?.antenna?.isLandlordResponsible == undefined;

	const isUnionSupplied = tenancyDetails.utilities?.antenna?.isLandlordResponsible ?? propertyDetails.utilities?.antenna?.isLandlordResponsible;
	const isUnionSuppliedInherited = tenancyDetails.utilities?.antenna?.isLandlordResponsible == null || tenancyDetails.utilities?.antenna?.isLandlordResponsible == undefined;

	return (
		<>
			<Typography variant="label" className="utility-title">Fællesantenne</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer leverer {isLandlordReponsibleInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={isLandlordResponsible}
						onChange={(e) => updateTenancyContract({ utilities: { antenna: { isLandlordResponsible: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Lejerforening leverer {isUnionSuppliedInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={isUnionSupplied}
						onChange={(e) => updateTenancyContract({ utilities: { antenna: { isUnionSupplied: e.target.checked } } })}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default AntennaUtility;