import Toast from "components/Toasts/Toast";
import React, { useCallback, useEffect, useState, createContext } from "react";
import produce from "immer";

const ToastsContext = createContext();

const ToastsProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        if (toasts.length > 0) {
            const timer = setTimeout(
                () => setToasts((toasts) => toasts.slice(1)),
                50000
            );
            return () => clearTimeout(timer);
        }
    }, [toasts]);

    const addToast = useCallback((toast) => {
        setToasts((toasts) => [...toasts, toast]);
    }, [toasts, setToasts]);

    const removeToast = useCallback((index) => {
        console.log(index);
        setToasts(produce(toasts, draft => {
            draft.splice(index, 1)
        }));
    }, [toasts, setToasts]);

    return (
        <ToastsContext.Provider value={addToast}>
            {children}
            <div className="toasts-container">
                {toasts.map((toast, ix) => (
                    <Toast key={ix} onRemove={() => removeToast(ix)}>{toast}</Toast>
                ))}
            </div>
        </ToastsContext.Provider>
    );
}

export default ToastsProvider;
export { ToastsContext };