import React from "react";
import "./index.scss";

export default function NoMatch() {
  return (
    <div className="noMatchContainer">
      Uh oh, wrong place, go back to <a href="/">home</a>
    </div>
  );
}
