import React from 'react';
import "./index.scss";
import Toggle from 'react-toggle'
import "react-toggle/style.css" 

const StyledToggle = ({ checked, onChange, className}) => {

	return <Toggle 
		checked={checked}
		onChange={(e) => onChange(e.target.checked)}
		icons={false}
		className={`styled_toggle ${className}`}
	/>
}

export default StyledToggle;