import { translateString } from "helpers/index";

const columns = [
	{
		Header: "Navn",
		accessor: "title",
		disableGroupBy: true,
		Aggregated: ({ value }) => `-`,
		defaultCanFilter: true,
		filter: "contains",
		isDefault: true
	},
	{
        Header: "Externt id",
        accessor: "integrationId",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        defaultCanFilter: true,
        filter: "contains",
		isDefault: true
    },
	{
        Header: "By",
        accessor: "city",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        defaultCanFilter: true,
        filter: "contains",
		isDefault: true
    },
    {
        Header: "Postnummer",
        accessor: "zipCode",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        defaultCanFilter: true,
        filter: "contains",
		isDefault: true
    },
];

export default columns;