import React from 'react';
import { styled } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";

const customPaper = (props) => {
  return <Paper 
    {...props}
    elevation={0}
  />
}

const StyledPaper = styled(customPaper)({
  overflow: "auto",
  padding: "56px 40px",
  width: "100%",
  // minWidth: "fit-content",
  margin: "0p 12px",
  boxSizing:"border-box",
  borderRadius: "39px"
});

export default StyledPaper;