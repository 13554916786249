import React, { forwardRef } from 'react'
import Autocomplete from 'react-autocomplete'
import './index.scss'

const StyledAutocomplete2 = forwardRef((props, ref) => {
  return (
    <Autocomplete
	  ref={ref}
      {...props}
      wrapperProps={{ className: `styled_autocomplete_2 ${props.className}` }}
      menuStyle={{
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '2px 0',
        fontSize: '90%',
        position: 'fixed',
        overflow: 'auto',
        maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
		zIndex: '10'
      }}
    />
  )
})

export default StyledAutocomplete2
