import React, { useEffect, useState } from "react";
import { ActionButton } from "components/Buttons";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendContractModal from "./SendContractModal";
import { useUser } from "features/user";
import PenneoNotConnectedModal from "./PenneoNotConnectedModal";
import { useToasts } from "features/toasts";
import GeneratingContractModal from "./GeneratingContractModal";
import CreatingContractModal from "./CreatingContractModal";

const ApplicationActions = ({ applicationData, selectApplication, rejectContract, sendContract, openContract, documents, isContractGenerating, isContractCreating, removeContractFromPenneo }) => {

	const { userData } = useUser();
	const contract = applicationData.contract;
	const history = useHistory();
	const [isProcessing, setProcessing] = useState(false);
	const [isModalEnabled, setModalEnabled] = useState(false);
	const [isPenneoNotConnectedModalEnabled, setPenneoNotConnectedModal] = useState(false);
	const addToast = useToasts();

	const handleOpenCurrentCase = () => {
		history.push(`/cases/${applicationData.caseId}`);
	}

	const handleRejectContract = async () => {
		setProcessing(true);
		await rejectContract();
		setProcessing(false);
		addToast("Contract has been rejected.")
	}

	const handleRemoveContractFromPenneo = async () => {
		setProcessing(true);
		await removeContractFromPenneo();
		setProcessing(false);
		addToast("Contract has been removed from Penneo.")
	}

	const handleConfirmContract = () => {
		if (!userData.penneoConnected) {
			setPenneoNotConnectedModal(true);
			return;
		}
		setModalEnabled(true);
	}

	const handleSendContract = async () => {
		setProcessing(true);
		setModalEnabled(false);
		var error = await sendContract();
		if (error) {
			addToast(error.message);
		}
		setProcessing(false);
	}

	// useEffect(() => {
	// 	setProcessing(false);
	// }, [contract]);

	if (isProcessing)
		return (
			<div className="application-actions">
				<CircularProgress size={30} />
			</div>
		)

	if (applicationData.status != "Valid")
		return null;

	if (!contract)
		return (
			<>
				<div className="application-actions">
					<ActionButton onClick={handleOpenCurrentCase}>Se udlejningssag</ActionButton>
					<ActionButton onClick={() => selectApplication()} disabled={isContractCreating}>Generer kontrakt</ActionButton>
				</div>
				<CreatingContractModal isEnabled={isContractCreating} />
			</>
		);

	if (contract.status === "Negotiation")
		return (
			<>
				<div className="application-actions">
					<ActionButton onClick={handleOpenCurrentCase}>Se udlejningssag</ActionButton>
					<ActionButton onClick={openContract} disabled={isContractGenerating}>Åben kontrakt</ActionButton>
					<ActionButton onClick={handleRejectContract}>Afvis kontrakt</ActionButton>
					<ActionButton onClick={handleConfirmContract}>Send til Penneo</ActionButton>
				</div>
				<SendContractModal
					isEnabled={isModalEnabled}
					onClose={() => setModalEnabled(false)}
					documents={documents}
					sendContract={handleSendContract} />
				<PenneoNotConnectedModal
					isEnabled={isPenneoNotConnectedModalEnabled}
					onClose={() => setPenneoNotConnectedModal(false)}
				/>
				<GeneratingContractModal isEnabled={isContractGenerating} />
			</>
		);

	if (contract.status === "Sent")
		return (
			<div className="application-actions">
				<p className="application-info">Kontrakt er sendt til Penneo</p>
				<ActionButton onClick={handleRemoveContractFromPenneo}>Remove from Penneo</ActionButton>
			</div>
		);

	if (contract.status === "Rejected")
		return (
			<div className="application-actions">
				<p className="application-info">Kontrakt er afvist</p>
			</div>
		);

	return (
		<div className="application-actions">
			<p className="application-info">Kontract er underskrevet</p>
		</div>
	);
};

export default ApplicationActions;