import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { Switch, Select, TextField } from "components/Fields";
import { MenuItem } from "@material-ui/core";

const RentIncreaseFields = ({ contractData, updatePropertyContract }) => {

	const [date, setDate] = useState(contractData.rentIncrease.date ?? "");
    const [startsFrom, setStartsFrom] = useState(contractData.rentIncrease.startsFrom ?? "");

	const months = [
		{ value: 1, label: "januar" },
		{ value: 2, label: "februar" },
		{ value: 3, label: "marts" },
		{ value: 4, label: "april" },
		{ value: 5, label: "maj" },
		{ value: 6, label: "juni" },
		{ value: 7, label: "juli" },
		{ value: 8, label: "august" },
		{ value: 9, label: "september" },
		{ value: 10, label: "oktober" },
		{ value: 11, label: "november" },
		{ value: 12, label: "december" }
	];

	return (
		<Paper className="property-contract-details__other-fields">
			<Typography variant="subtitle">Lejeregulering</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Aktiveret</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.rentIncrease.enabled}
							onChange={(e) => updatePropertyContract({ rentIncrease: { enabled: e.target.checked } } )}
						/>
					</div>
				</div>
				<div className="fields-section__field">
                    <Typography variant="label">Date</Typography>
                    <TextField
                        value={date ?? ""}
                        type="date"
                        onChange={(event) => setDate(event.target.value)}
                        onExit={() => setDate(contractData.rentIncrease.date)}
                        onFinish={() => {
                            date !== contractData.rentIncrease.date && updatePropertyContract({ rentIncrease: { date: date } })
                        }}
                    />
                </div>
				<div className="fields-section__field">
					<Typography variant="label">Fra måned</Typography>
					<Select
						value={contractData.rentIncrease.fromMonth ?? ""}
						onChange={(e) => {
							updatePropertyContract({ rentIncrease: { fromMonth: e.target.value } })
						}}>
						{months.map(month => (
							<MenuItem key={month.value} value={month.value}>
								{month.label}
							</MenuItem>
						))}
					</Select>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Til måned</Typography>
					<Select
						value={contractData.rentIncrease.toMonth ?? ""}
						onChange={(e) => {
							updatePropertyContract({ rentIncrease: { toMonth: e.target.value } })
						}}>
						{months.map(month => (
							<MenuItem key={month.value} value={month.value}>
								{month.label}
							</MenuItem>
						))}
					</Select>
				</div>
				<div className="fields-section__field">
                    <Typography variant="label">Starts From</Typography>
                    <TextField
                        value={startsFrom ?? ""}
                        type="date"
                        onChange={(event) => setStartsFrom(event.target.value)}
                        onExit={() => setStartsFrom(contractData.rentIncrease.startsFrom)}
                        onFinish={() => {
                            date !== contractData.rentIncrease.startsFrom && updatePropertyContract({ rentIncrease: { startsFrom: startsFrom } })
                        }}
                    />
                </div>
			</div>
		</Paper>
	);
}

export default RentIncreaseFields;