import React, { useEffect, useMemo } from 'react';
import './index.scss'
import { useTable, useGroupBy, useExpanded, useFilters, useAsyncDebounce, useSortBy, usePagination } from 'react-table';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from "@material-ui/core/Typography";
import "./index.scss";
import { PrimaryButton } from 'components/Buttons';
import { ReactComponent as NoResultsIcon } from "assets/icons/jorato-no-results.svg";

const Table = ({ activeColumns, columns, data, pageLimit, total, onRowClick, setPageIndex, pageIndex, setSorting, sorting }) => {

	const mappedActiveColumns = useMemo(() => {
		return activeColumns.map(activeColumn => {
			return columns.find(column => column.accessor === activeColumn);
		});
	}, [activeColumns, columns]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		pageOptions,
		pageCount,
		state
	} = useTable({
		columns: mappedActiveColumns,
		data,
		initialState: {
			pageSize: pageLimit,
			pageIndex: pageIndex ?? 0,
			sortBy: sorting ?? []
		},
		manualPagination: true,
		pageCount: Math.ceil(total / pageLimit),
		manualSortBy: true
	},
		useFilters,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination
	);

	useEffect(() => {
		if (!setPageIndex) return;
		setPageIndex(state.pageIndex);
	}, [state.pageIndex]);

	useEffect(() => {
		if (!setSorting) return;
		setSorting(state.sortBy ?? []);
	}, [state.sortBy]);

	return (
		<div className="data-table">
			{ total == 0 && (
				<div className="no-data">
					<NoResultsIcon />
					Ingen resultater til dine kriterier.
				</div>
			)}
			{ total > 0 && ( 
				<>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps()}>
											<span className="column">
												<span className="header" {...column.getSortByToggleProps()}>
													<Typography className="title" color="primary" variant="h3">
														{column.render('Header')}
													</Typography>
													{column.isSorted
														? column.isSortedDesc
															? <ArrowDropDownIcon />
															: <ArrowDropUpIcon />
														: ""}
												</span>
											</span>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map(row => {
								prepareRow(row)
								return (
									<tr className={onRowClick ? "clickable" : ""} onClick={(e) => onRowClick(e, row)} {...row.getRowProps()}>
										{row.cells.map(cell => {
											return (
												<td {...cell.getCellProps()} >
													{cell.isGrouped ? (
														<span className="flexedNotJustified">
															<span className="expandToggle" {...row.getToggleRowExpandedProps()}>
																{row.isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
															</span>{' '}
															<span>{cell.render('Cell')} ({row.subRows.length})</span>
														</span>
													) : cell.isAggregated ? (
														cell.render('Aggregated')
													) : cell.isPlaceholder ? null : (cell.render('Cell')
													)}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="pagination">
						<div className="previous-container">
							{canPreviousPage && (
								<PrimaryButton onClick={() => previousPage()} disabled={!canPreviousPage}>Forrige</PrimaryButton>
							)}
						</div>
						<span className="page-counter">{pageCount > 0 ? state.pageIndex + 1 : 0} ud af {pageCount}</span>
						<div className="next-container">
							{canNextPage && (
								<PrimaryButton className="no-margin" onClick={() => nextPage()} disabled={!canNextPage}>Næste</PrimaryButton>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Table;