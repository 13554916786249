import React, { useMemo } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import GoogleMaps from "components/GoogleMaps";


const Location = props => {

	const { propertyData } = props;

	const addresses = useMemo(() => {
		if (!propertyData.address?.streets || propertyData.address?.streets.length === 0) {
			return "";
		} else {
			var groupedStreets = propertyData.address?.streets
				.filter(x => !x.match(/P-plads/i))
				.filter(x => !x.match(/Kælderrum/i))
				.map(x => {
					return {
						street: x.replace(/[\d-].*/, '').trim(),
						number: x.replace(/^(\D*)/, '').trim()
					}
				})
				.filter(x => x.number.length !== 0)
				.reduce((acc, obj) => {
					const key = obj['street'];
					if (!acc[key]) {
						acc[key] = [];
					}
					acc[key].push(obj.number);
					return acc;
				}, {});

			if (!groupedStreets) {
				return null;
			}
			
			return Object.entries(groupedStreets).map(x => {

				return (
					<li className="street" key={x[0]}><div className="street-name">{`${x[0]}:`}</div> <div className="street-numbers">{`${x[1].join(", ")}`}</div></li>
				)
			});
		}
	}, [propertyData.address]);

	return (
		<Paper className="general-information__location">
			<div className="wrapper">
				<Typography variant="subtitle">Lokation</Typography>
				<div className="fields addresses">
					<div className="fields-section__field">
						<Typography variant="label">Adresse</Typography>
						<ul className="addresses-list">{addresses}</ul>
					</div>
				</div>
				<div className="fields">
					<div className="fields-section__field">
						<Typography variant="label">Postnummer</Typography>
						<p className="field-value">{propertyData.address?.zipCode ?? ""}</p>
					</div>
					<div className="fields-section__field">
						<Typography variant="label">By</Typography>
						<p className="field-value">{propertyData.address?.city ?? ""}</p>
					</div>
				</div>
				<hr />
				<div className="fields">
					<div className="fields-section__field">
						<Typography variant="label">Breddegrad</Typography>
						<p className="field-value">{propertyData.address?.coordinates?.latitude}</p>
					</div>
					<div className="fields-section__field">
						<Typography variant="label">Længdegrad</Typography>
						<p className="field-value">{propertyData.address?.coordinates?.longitude}</p>
					</div>
				</div>
			</div>
			<div className="map">
				<GoogleMaps
					latitude={propertyData.address?.coordinates?.latitude}
					longitude={propertyData.address?.coordinates?.longitude} />
			</div>
		</Paper>
	);
};

export default Location;