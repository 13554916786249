import React from "react";
import "./index.scss";

const PrimaryButton = props => {
    return (
        <button {...props} className={`primary-button ${props.className && props.className}`}>
            {props.children}
        </button>
    )
};

export default PrimaryButton;