import React from "react";
import Item from "./Item";
import Grid from "@material-ui/core/Grid";
import { SortableContainer } from "react-sortable-hoc";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as NewImageIcon } from "assets/icons/jorato-new-image.svg";

const List = props => {

    const { data } = props;

    const addImage = (
        <div
            style={{ cursor: "pointer" }}
            onClick={() => {
                document.getElementById(props.loaderId).click();
            }}
            className="gridItemContainer"
        >
            <input
                multiple
                onChange={(e) => {
                    const fileArray = [...e.target.files];
                    // const highestOrderValue = props.data.length > 0 ? props.data[props.data.length - 1].order : 0;
                    // function addImageWithOrderValue(item, index) {
                    //     const order = highestOrderValue + index + 1
                    //     props.withAdd(item, order);
                    // }
                    props.withAdd(fileArray)
                    // fileArray.forEach(addImageWithOrderValue);
                }}
                accept="image/png, image/jpeg, image/jpg"
                id={props.loaderId}
                style={{ display: "none" }}
                type="file"
            />
            {props.isUploadingImage ? (
                <CircularProgress size={30} />
            ) : (
                <div className="gridImage">
                    <NewImageIcon className="add-image-icon" />
                </div>
            )}
        </div>
    );

    return (
        <Grid style={props.style} item container xs={12}>
            {
                data.map((value, index) => {
                    return (
                        <Item
                            selected={props.selected === index}
                            onItemClick={props.onItemClick}
                            onItemDelete={props.onItemDelete}
                            editable={props.editable}
                            variant={props.variant}
                            key={value.id}
                            index={index}
                            place={index}
                            item={{...value}}
                        />
                    );
                })}
            {props.withAdd && !props.readOnly && addImage}
        </Grid>
    );
};

export default SortableContainer(List);