import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Fuse from 'fuse.js';

const SearchField = (props) => {
	const { data, setSearchedData, searchColumns } = props;
	const [searchString, setSearchString] = useState("");

	useEffect(() => {
		if (searchString === "") {
			setSearchedData(data);
		} else {
			const fuseOptions = {
				keys: searchColumns.map(e => e.accessor ? e.accessor : e),
				threshold: 0.2
			}
			const fuse = new Fuse(data, fuseOptions);
			const searchedData = fuse.search(searchString);
			const newData = searchedData.map((e) => e.item)
			setSearchedData(newData)
		}
	}, [data, searchString, setSearchedData, searchColumns])

	return (
		<>
			<TextField
				placeholder="Søg"
				onChange={(e) => setSearchString(e.target.value)}
				variant="outlined"
			/>
		</>
	);
}

export default SearchField;