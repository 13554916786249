import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import { Select } from "components/Fields";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InheritedValueIcon from "../InheritedValueIcon";

const HeatingUtility = ({ propertyDetails, tenancyDetails, updateTenancyContract }) => {

	const isLandlordResponsible = tenancyDetails.utilities?.heating?.isLandlordResponsible ?? propertyDetails.utilities?.heating?.isLandlordResponsible;
	const isLandlordReponsibleInherited = tenancyDetails.utilities?.heating?.isLandlordResponsible == null || tenancyDetails.utilities?.heating?.isLandlordResponsible == undefined;

	const isHeatingIncludedInRent = tenancyDetails.utilities?.heating?.isHeatingIncludedInRent ?? propertyDetails.utilities?.heating?.isHeatingIncludedInRent;
	const isHeatingIncludedInRentInherited = tenancyDetails.utilities?.heating?.isHeatingIncludedInRent == null || tenancyDetails.utilities?.heating?.isHeatingIncludedInRent == undefined;

	const sourceInitialValue = tenancyDetails.utilities?.heating?.source ?? propertyDetails.utilities?.heating?.source ?? [];
	const isSourceInherited = !tenancyDetails.utilities?.heating?.source;
	const [source, setSource] = useState(sourceInitialValue);

	const accountingStartDateInitialValue = tenancyDetails.utilities?.heating?.accountingStartDate ?? propertyDetails.utilities?.heating?.accountingStartDate ?? "";
	const isAccountingStartDateInherited = !tenancyDetails.utilities?.heating?.accountingStartDate;
	const [accountingStartDate, setAccountingStartDate] = useState(accountingStartDateInitialValue);

	const handleChange = (event) => {
		const value = event.target.value;
		setSource(value);
		updateTenancyContract({ contractDetails: { utilities: { heating: { source: value } } } })
	};

	const options = [
		"Electricity",
		"Gas",
		"Oil",
		"DistrictHeating"
	];

	return (
		<>
			<Typography variant="label" className="utility-title">Varme</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer ansvarlig {isLandlordReponsibleInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={isLandlordResponsible}
						onChange={(e) => updateTenancyContract({ utilities: { heating: { isLandlordResponsible: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Udgift til varme inkluderet i husleje {isHeatingIncludedInRentInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={isHeatingIncludedInRent}
						onChange={(e) => updateTenancyContract({ utilities: { heating: { isHeatingIncludedInRent: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Opvarmes ved {isSourceInherited && <InheritedValueIcon />}</Typography>
					<Select
						multiple
						value={source}
						onChange={handleChange}
						renderValue={(source) => source.join(", ")}
					>
						{options.map((option) => (
							<MenuItem key={option} value={option}>
								<ListItemIcon>
									<Checkbox checked={source.indexOf(option) > -1} />
								</ListItemIcon>
								<ListItemText primary={option} />
							</MenuItem>
						))}
					</Select>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Varmeregnskabsåret begynder {isAccountingStartDateInherited && <InheritedValueIcon />}</Typography>
					<TextField
						value={accountingStartDate ?? ""}
						type="date"
						onChange={(event) => setAccountingStartDate(event.target.value)}
						onExit={() => setAccountingStartDate(accountingStartDateInitialValue)}
						onFinish={() => {
							accountingStartDate !== accountingStartDateInitialValue && updateTenancyContract({ utilities: { heating: { accountingStartDate } } })
						}}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default HeatingUtility;