
export const FETCH_TENANCIES = "FETCH_TENANCIES";
export const FETCH_TENANCY = "FETCH_TENANCY";
export const ADD_TENANCY_IMAGE = "ADD_TENANCY_IMAGE";
export const SET_TENANCY_IMAGE = "SET_TENANCY_IMAGE";
export const UPDATE_TENANCY = "UPDATE_TENANCY";
export const QUERY_TENANCIES = "QUERY_TENANCIES";
export const DELETE_TENANCY_IMAGE = "DELETE_TENANCY_IMAGE";
export const UPDATE_TENANCY_IMAGE = "UPDATE_TENANCY_IMAGE";
export const SET_TENANCIES_EXPENSE = "SET_TENANCIES_EXPENSE";
export const DELETE_TENANCIES_EXPENSE = "DELETE_TENANCIES_EXPENSE";
export const SET_TENANCIES_CONTACT = "SET_TENANCIES_CONTACT";
export const DELETE_TENANCIES_CONTACT = "DELETE_TENANCIES_CONTACT";
export const SET_TENANCIES_ATTRIBUTE = "SET_TENANCIES_ATTRIBUTE";
export const DELETE_TENANCIES_ATTRIBUTE = "DELETE_TENANCIES_ATTRIBUTE";
export const UPDATE_TENANCY_LOCATIONS = "UPDATE_TENANCY_LOCATIONS";
export const DELETE_TENANCY_FLOOR_PLAN = "DELETE_TENANCY_FLOOR_PLAN";
export const SET_TENANCY_FLOOR_PLAN = "SET_TENANCY_FLOOR_PLAN";
export const UPDATE_TENANCY_TYPES = "UPDATE_TENANCY_TYPES";
export const FETCH_TENANCIES_STATS = "FETCH_TENANCIES_STATS";
export const CREATE_TENANCY = "CREATE_TENANCY";
export const DELETE_TENANCY_DOCUMENT = "DELETE_TENANCY_DOCUMENT";
export const SET_TENANCY_DOCUMENT = "SET_TENANCY_DOCUMENT";

export const FETCH_TENANCY_KINDS = "FETCH_TENANCY_KINDS";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const READ_ME = "READ_ME";

export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const CREATE_PROPERTY = "CREATE_PROPERTY";
export const FETCH_PROPERTIES = "FETCH_PROPERTIES";
export const FETCH_PROPERTY = "FETCH_PROPERTY";
export const FETCH_PROPERTY_TENANCIES = "FETCH_PROPERTY_TENANCIES";
export const ADD_PROPERTY_IMAGE = "ADD_PROPERTY_IMAGE";
export const SET_PROPERTY_IMAGE = "SET_PROPERTY_IMAGE";
export const QUERY_PROPERTIES = "QUERY_PROPERTIES";
export const DELETE_PROPERTY_IMAGE = "DELETE_PROPERTY_IMAGE";
export const UPDATE_PROPERTY_IMAGE = "UPDATE_PROPERTY_IMAGE";
export const SET_PROPERTIES_EXPENSE = "SET_PROPERTIES_EXPENSE";
export const DELETE_PROPERTIES_EXPENSE = "DELETE_PROPERTIES_EXPENSE";
export const SET_PROPERTIES_CONTACT = "SET_PROPERTIES_CONTACT";
export const DELETE_PROPERTIES_CONTACT = "DELETE_PROPERTIES_CONTACT";
export const SET_PROPERTIES_ATTRIBUTE = "SET_PROPERTIES_ATTRIBUTE";
export const DELETE_PROPERTIES_ATTRIBUTE = "DELETE_PROPERTIES_ATTRIBUTE";
export const FETCH_PROPERTY_STATS = "FETCH_PROPERTY_STATS";
export const DELETE_PROPERTY_DOCUMENT = "DELETE_PROPERTY_DOCUMENT";
export const SET_PROPERTY_DOCUMENT = "SET_PROPERTY_DOCUMENT";

export const CREATE_SHOWCASING = "CREATE_SHOWCASING";
export const FETCH_SHOWCASINGS = "FETCH_SHOWCASINGS";
export const FETCH_OPENHOUSES = "FETCH_OPENHOUSES";

export const FETCH_CASES = "FETCH_CASES";
export const FETCH_CASE = "FETCH_CASE";
export const UPDATE_CASE = "UPDATE_CASE";
export const QUERY_CASES = "QUERY_CASES";
export const ADD_CASE_IMAGE = "ADD_CASE_IMAGE";
export const SET_CASE_IMAGE = "SET_CASE_IMAGE";
export const DELETE_CASE_IMAGE = "DELETE_CASE_IMAGE";
export const UPDATE_CASE_IMAGE = "UPDATE_CASE_IMAGE";
export const SET_CASES_EXPENSE = "SET_CASES_EXPENSE";
export const DELETE_CASES_EXPENSE = "DELETE_CASES_EXPENSE";
export const SET_CASES_CONTACT = "SET_CASES_CONTACT";
export const DELETE_CASES_CONTACT = "DELETE_CASES_CONTACT";
export const SET_CASES_ATTRIBUTE = "SET_CASES_ATTRIBUTE";
export const DELETE_CASES_ATTRIBUTE = "DELETE_CASES_ATTRIBUTE";
export const UPDATE_CASE_FLOOR_PLAN = "UPDATE_CASE_FLOOR_PLAN";
export const ADD_CASE_FLOOR_PLAN = "ADD_CASE_FLOOR_PLAN";
export const DELETE_CASE_FLOOR_PLAN = "DELETE_CASE_FLOOR_PLAN";
export const SET_CASE_FLOOR_PLAN = "SET_CASE_FLOOR_PLAN";
export const UPDATE_CASE_TYPES = "UPDATE_CASE_TYPES";
export const DELETE_CASE_PROSPECTUS = "DELETE_CASE_PROSPECTUS";
export const SET_CASE_PROSPECTUS = "SET_CASE_PROSPECTUS";
export const FETCH_CASE_INTEGRATIONS = "FETCH_CASE_INTEGRATIONS";
export const FETCH_CASE_APPLICATIONS = "FETCH_CASE_APPLICATIONS";
export const FETCH_CASE_APPLICATION = "FETCH_CASE_APPLICATION";
export const SET_CASE_DOCUMENT = "SET_CASE_DOCUMENT";
export const DELETE_CASE_DOCUMENT = "DELETE_CASE_DOCUMENT";
export const SET_CASE_COMMENT = "SET_CASE_COMMENT";
export const CREATE_CASE_COMMENT = "CREATE_CASE_COMMENT";
export const GET_CASE_COMMENTS = "GET_CASE_COMMENTS";
export const SET_CASE_COMMENTS = "SET_CASE_COMMENTS";
export const DELETE_CASE_COMMENT = "DELETE_CASE_COMMENT";

export const FETCH_CASES_STATS = "FETCH_CASES_STATS";
export const FETCH_LATEST_DRAFT_CASES = "FETCH_LATEST_DRAFT_CASES";
export const FETCH_LATEST_ACTIVE_CASES = "FETCH_LATEST_ACTIVE_CASES";
export const UPDATE_ME = "UPDATE_ME";

export const SET_APP_LOADING = "SET_APP_LOADING";
export const SET_USER = "SET_USER";
export const FETCH_ATTRIBUTES = "FETCH_ATTRIBUTES";
export const FETCH_CONTACT_ROLES = "FETCH_CONTACT_ROLES";
export const FETCH_EXPENSE_TYPES = "FETCH_EXPENSE_TYPES";
export const FETCH_TENANCY_TYPES = "FETCH_TENANCY_TYPES";

export const FETCH_APPLICATION_CONTRACT = "FETCH_APPLICATION_CONTRACT";

export const FINISH_CONTRACT_PAYMENT_LOADING = "FINISH_CONTRACT_PAYMENT_LOADING";

export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const FETCH_MATCHES = "FETCH_MATCHES";
export const FETCH_ORGANIZATION_INTEGRATIONS = "FETCH_ORGANIZATION_INTEGRATIONS";
export const FETCH_ORGANIZATION_EMPLOYEES = "FETCH_ORGANIZATION_EMPLOYEES";

export const SET_ALL_PROPERTIES = "SET_ALL_PROPERTIES";
export const SET_ALL_TENANCIES = "SET_ALL_TENANCIES";