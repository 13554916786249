import { createMuiTheme } from "@material-ui/core/styles";

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#566FFF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2C3589",
      contrastText: "#fff",
    },
    neutral: {
      main: "#ffffff"
    },
    accent: {
      main: "#2C3589"
    }
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "sans-serif"
    ].join(','),
    h1: {
      fontWeight: 600,
      fontSize: "26px"
    },
    h2: {
      fontWeight: 500,
      fontSize: "22px"
    },
    h3: {
      fontWeight: 600,
      fontSize: "16px"
    }
  },
});

const theme = createMuiTheme(
  {
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          color: "#DEDEDE",
          "&$checked": {
            color: globalTheme.palette.accent.main
          }
        }
      },
      MuiSelect: {
        root: {
          padding: "14.5px 14px 14.5px 30px",
          fontSize: "16px !important"
        }
      },
      MuiTextField: {
        root: {
          "& .MuiInputBase-root input": {
            border: "none",
            fontFamily: "Montserrat",
            padding: "14.5px 14px 14.5px 30px",
            fontSize: "16px !important",
            borderRadius: "30px"
          },
          "& .MuiInputBase-root fieldset": {
            borderRadius: "30px"
          }
        }
      },
      MuiButton: {
        root: {
          textTransform: "initial",
          fontSize: "16px",
        },
        contained: {
          color: globalTheme.palette.secondary.main,
          backgroundColor: globalTheme.palette.neutral.main,
          '&:hover': {
            backgroundColor: globalTheme.palette.primary.main,
            color: globalTheme.palette.primary.contrastText,
            stroke: "white",
            "& svg path": {
              stroke: "white"
            },
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: globalTheme.palette.primary.main,
            },
          }
        },
        outlined: {
          border: "1px solid #DEDEDE"
        }
      },
      MuiChip: {
        root: {
          borderRadius: "30px",
          lineHeight: "1.75",
          height: "auto",
          fontSize: "16px",
          padding: "10px 26px",
          color: globalTheme.palette.secondary.main,
          backgroundColor: globalTheme.palette.neutral.main
        },
        label: {
          paddingLeft: "0px"
        }
      },
      MuiBackdrop: {
        backgroundColor: globalTheme.palette.secondary.main,
        root: {
          backgroundColor: "purple"
        }
      }
    },
  },
  globalTheme
)

export default theme;