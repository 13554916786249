import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";

const Descriptions = props => {

    const { propertyData, updateProperty } = props;

    const [title, setTitle] = useState(propertyData.title ?? "");
    const [areaDescription, setAreaDescription] = useState(propertyData.descriptions.area ?? "");
    const [propertyDescription, setPropertyDescription] = useState(propertyData.descriptions.property ?? "");
    const [tenancyTemplateDescription, setTenancyTemplateDescription] = useState(propertyData.descriptions.tenancyTemplate ?? "");

    return (
        <Paper className="general-information__descriptions">
            <Typography variant="subtitle">Beskrivelser</Typography>
            <div className="fields-section__field title-field">
                <Typography variant="label">Titel</Typography>
                <TextField
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    onExit={() => setTitle(propertyData.title)}
                    onFinish={() => {
                        title !== propertyData.title && updateProperty({ title: title })
                    }}
                />
            </div>
            <div className="description-fields">
                <div className="fields-section__field">
                    <Typography variant="label">Ejendomsbeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={5}
                        rowsMax={5}
                        value={propertyDescription}
                        onChange={(event) => setPropertyDescription(event.target.value)}
                        onExit={() => setPropertyDescription(propertyData.descriptions.property)}
                        onFinish={() => {
                            propertyDescription !== propertyData.descriptions.property && updateProperty({ descriptions: { property: propertyDescription } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Lejemålsbeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={5}
                        rowsMax={5}
                        value={tenancyTemplateDescription}
                        onChange={(event) => setTenancyTemplateDescription(event.target.value)}
                        onExit={() => setTenancyTemplateDescription(propertyData.descriptions.tenancy)}
                        onFinish={() => {
                            tenancyTemplateDescription !== propertyData.descriptions.tenancyTemplate && updateProperty({ descriptions: { tenancyTemplate: tenancyTemplateDescription } })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Områdebeskrivelse</Typography>
                    <TextField
                        multiline
                        rows={5}
                        rowsMax={5}
                        value={areaDescription}
                        onChange={(event) => setAreaDescription(event.target.value)}
                        onExit={() => setAreaDescription(propertyData.descriptions.area)}
                        onFinish={() => {
                            areaDescription !== propertyData.descriptions.area && updateProperty({ descriptions: { area: areaDescription } })
                        }}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default Descriptions;