import React, { useEffect, useMemo } from "react";
import ExtendedTable from "components/ExtendedTable";
import { useOpenhouses } from "hooks/api";
import { CircularProgress } from "@material-ui/core";
import { useOrganization } from "features/organization";
import { formatDate } from 'helpers/date';

const OpenHousesList = props => {

	const { tenancyId } = props;

    const { isOpenHousesLoading, openHousesData, loadAllTenancyOpenhouses } = useOpenhouses();
	const { employees } = useOrganization();

    useEffect(() => {
        loadAllTenancyOpenhouses(tenancyId);
    }, [loadAllTenancyOpenhouses, tenancyId]);

	const mapOpenHouses = () => openHousesData.map(x => {
        return {
            linkPath: `/openhouses/${x.id}`,
            date: formatDate(x.scheduled.startsAt, "d MMM yyyy HH:mm"),
			host: x.hosts.length > 0 && employees.find(e => e.employeeId == x.hosts[0]) ? employees.find(e => e.employeeId == x.hosts[0]).name : ""
        }
    });

    const mappedOpenhouses = useMemo(() => mapOpenHouses(), [openHousesData]);

    const columns = [
        {
            Header: 'Dato',
            accessor: 'date',
            canGroupBy: false
        },
		{
            Header: 'Host',
            accessor: 'host',
            canGroupBy: false
        }];

    let content = (
        <div className="loading-container">
            <CircularProgress />
        </div>
    )

    if (!isOpenHousesLoading) {
        content = (
            <ExtendedTable
                isLoading={isOpenHousesLoading}
                data={mappedOpenhouses}
                columns={columns}
                linkPath="/openhouses/"
            />
        );
    }

    return content;
};

export default OpenHousesList;