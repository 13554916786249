import React, { useState } from "react";
import { ActionButton } from "components/Buttons";
import SplitButton from "components/Buttons/SplitButton";
import { useToasts } from "features/toasts";
import { useUser } from "features/user";
import TenanciesService from "services/api/tenancies";
import ExternalLink from "./ExternalLink";
import ChangeStateAction from "./ChangeStateAction";
import { useOrganization } from "features/organization";
import CreateShowcasingAction from "./CreateShowcasingAction";
import DeleteCaseModal from "./DeleteCaseModal";
import SendApplicationLinkModal from "./SendApplicationLinkModal";

const CaseActions = props => {

	const { caseData, tenancyData, propertyData, updateCase, createComment, deleteCase } = props;
	const { userData } = useUser();
	const { organizationName } = useOrganization();
	const addToast = useToasts();
	const [isDeleteModalEnabled, setDeleteModalEnabled] = useState(false);
	const [isSendApplicationLinkModalEnabled, setSendApplicationLinkModalEnabled] = useState(false);

	const updateState = (state) => {
		updateCase({ state: state });
	};

	const sendMailToJosephine = async () => {
		const recipient = `joh@kereby.dk`;
		const header = `Fotomateriale til ${caseData.address.street}`;
		const tenancy = await TenanciesService.getTenancy(caseData.tenancyId);
		const body = `Hej\n\nDu bedes bestille fotomateriale på ${caseData.address.street}. \n\n Lejemålet er ${caseData.size.value} m2 og har lejemålsnummer ${tenancy.data.integration.id}. \n\n Der skal bestilles ${caseData.classification === "Residential" ? "Boligpakke" : "Erhvevspakke"}.`;
		window.location.href = "mailto:" + recipient + "?subject=" + encodeURIComponent(header) + "&body=%0D%0A" + encodeURIComponent(body);
		createComment("Email om fotomateriale sendt til Josephine")
	}

	const copyApplicationLinkToClipboard = () => {
		var link = `https://jorato.com/application/${caseData.id}`;
		navigator.clipboard.writeText(link).then(function () {
			console.log('Async: Copying to clipboard was successful!');
		}, function (err) {
			console.error('Async: Could not copy text: ', err);
		});

		addToast("Ansøgningslink er blevet kopieret");
	}

	const openSharepointLink = async () => {
		const tenancyExternalId = tenancyData.integration.id;
		const propertyExternalId = propertyData.integration.id;
		let address = caseData.address.street;
		if (address[address.length - 1] === ".") {
			address = address.slice(0, -1);
		}
		const link = "https://kereby.Sharepoint.com/sites/" + propertyExternalId + "/Delte dokumenter/" + tenancyExternalId + " " + address + "/Lease Up";
		window.open(link, '_blank');
	}

	const openSharepointPropertyLink = async () => {
		const tenancyExternalId = tenancyData.integration.id;
		const propertyExternalId = propertyData.integration.id;
		let address = caseData.address.street;
		if (address[address.length - 1] === ".") {
			address = address.slice(0, -1);
		}
		const link = "https://kereby.Sharepoint.com/sites/" + propertyExternalId;
		window.open(link, '_blank');
	}

	const openSharepointSearchLink = async () => {
		const tenancyExternalId = tenancyData.integration.id;
		const propertyExternalId = propertyData.integration.id;
		let address = caseData.address.street;
		if (address[address.length - 1] === ".") {
			address = address.slice(0, -1);
		}
		const link = "https://kereby.Sharepoint.com/_layouts/15/search.aspx/?q=" + tenancyExternalId;
		window.open(link, '_blank');
	}

	const actions = (
		<>
			<ChangeStateAction currentState={caseData.state} updateState={(state) => updateState(state)} />
			{caseData.visibility !== "Invisible" && <ExternalLink userEmail={userData.email} organizationName={organizationName} link={caseData.slug} caseType={caseData.classification} caseId={caseData.id} tenancyId={caseData.tenancyId}><ActionButton>Se på hjemmeside</ActionButton></ExternalLink>}
			{caseData.visibility !== "Invisible" && <ExternalLink userEmail={userData.email} organizationName={organizationName} link={caseData.slug} caseType={caseData.classification} mailto={true} caseAddress={caseData.address.street}><ActionButton>Opret email</ActionButton></ExternalLink>}
			{!caseData.deletedAt && (
				<>
					<ActionButton onClick={() => setDeleteModalEnabled(true)}>Slet sag</ActionButton>
					<DeleteCaseModal
						isEnabled={isDeleteModalEnabled}
						onClose={() => setDeleteModalEnabled(false)}
						deleteCase={deleteCase} />
				</>
			)}
			<CreateShowcasingAction caseId={caseData.id} />
		</>
	);

	const activeActions = (
		<>
			{caseData.classification == "Residential"
				? (
					<>
						<SplitButton
							primary={{
								label: "Ansøgsningslink",
								action: copyApplicationLinkToClipboard
							}}
							options={[
								{
									label: "Send Application Link",
									action: () => setSendApplicationLinkModalEnabled(true)
								},
							]}
						/>
						<SendApplicationLinkModal
							isEnabled={isSendApplicationLinkModalEnabled}
							caseId={caseData.id}
							onClose={() => setSendApplicationLinkModalEnabled(false)}
						/>
					</>
				)
				: null
			}
		</>
	);

	const kerebySpecificActionsDraft = (
		<>
			<ActionButton onClick={() => sendMailToJosephine()}>Mail til Josephine</ActionButton>
		</>
	);
	const kerebySpecificActionsActive = (
		<>
			<ActionButton onClick={() => sendMailToJosephine()}>Mail til Josephine</ActionButton>
		</>
	);

	const kerebySpecificActions = (
		<>
			<SplitButton
				primary={{
					label: "Sharepoint",
					action: openSharepointLink
				}}
				options={[
					{
						label: "Ejendom",
						action: openSharepointPropertyLink
					},
					{
						label: "Lejemåls søgning",
						action: openSharepointSearchLink
					},
				]}
			/>
		</>
	);

	if (caseData.deletedAt)
		return (
			<div className="case-deleted">
				<div>
					Denne sag er slettet og kan derfor ikke redigeres
				</div>
			</div>
		);

	return (
		<div className="case-actions">
			{actions}
			{userData.email.includes("kereby") && kerebySpecificActions}
			{caseData.state === "Active" && activeActions}
			{caseData.state === "Draft" && userData.email.includes("kereby") && kerebySpecificActionsDraft}
			{caseData.state === "Active" && userData.email.includes("kereby") && kerebySpecificActionsActive}
		</div>
	);
}

export default CaseActions;
