import axios from "./axios";

export default class ApplicantScreeningService {

	static async GetCprScreening(cpr) {
		try {
			const result = await axios.get(`applicant-screening/cpr?cpr=${cpr}`);
			return result;
		}
		catch (err) {
			return err.response;
		}
	}
}