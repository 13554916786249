import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const RentControlFields = ({ contractData, updatePropertyContract }) => {

	const [uncontrolledRentJustification, setUncontrolledRentJustification] = useState(contractData.rentControl.uncontrolledRentJustification ?? "");

	return (
		<Paper className="property-contract-details__other-fields">
			<Typography variant="subtitle">Lejefastsættelse</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Lejeforholdet er omfattet af reglerne om fri leje</Typography>
					<div className="switch-container">
						<Switch
							checked={!contractData.rentControl.enabled}
							onChange={(e) => updatePropertyContract({ rentControl: { enabled: !e.target.checked } })}
						/>
					</div>
				</div>
			</div>
			{!contractData.rentControl.enabled && (
				<div className="fields-section__container">
					<div className="fields-section__field">
						<Typography variant="label">Grundlag for fri leje</Typography>
						<TextField
							value={uncontrolledRentJustification}
							multiline
							rows={5}
							rowsMax={20}
							onChange={(event) => setUncontrolledRentJustification(event.target.value)}
							onExit={() => setUncontrolledRentJustification(contractData.rentControl.uncontrolledRentJustification)}
							onFinish={() => {
								uncontrolledRentJustification !== contractData.rentControl.uncontrolledRentJustification && updatePropertyContract({ rentControl: { uncontrolledRentJustification: uncontrolledRentJustification } })
							}}
						/>
					</div>
				</div>
			)}
		</Paper>
	);
}

export default RentControlFields;