import React, { useState } from "react";
import ResourceTable from "components/ResourceTable";
import { TableCell, TableRow } from "@material-ui/core";
import { MoneyField } from "components/Fields";
import ActionButton from "components/Buttons/ActionButton";
import Icon from "components/Icon";
import expenseTypes from "helpers/expense-types";
import { translateString } from "helpers/index";
import { MenuItem } from "@material-ui/core";
import { Select } from "components/Fields";
import AdditionalExpense from "./AdditionalExpense";

const AdditionalExpensesTable = props => {

    const { expenses, createExpense, removeExpense, updateExpense, disabled } = props;
    const [expenseType, setExpenseType] = useState("");
    const [expenseAmount, setExpenseAmount] = useState();

    const closeFormHandler = () => {
        setExpenseType("");
        setExpenseAmount("");
    }

    const createExpenseHandler = () => {
        if (!expenseType || !expenseAmount)
            return;

        createExpense(expenseType, expenseAmount);
        setExpenseType("");
        setExpenseAmount("");
    }

    const expenseForm = (
        <TableRow>
            <TableCell>
                <Select
                    value={expenseType}
                    disabled={disabled}
                    onChange={(e) => {
                        setExpenseType(e.target.value)
                    }}
                    displayEmpty
                    renderValue={(v) => v.length > 0 ? translateString(v) : "Vælg omkostningstype"}
                    >
                    {expenseTypes.filter(x => !expenses.map(y => y.type).includes(x)).map((expenseType, ix) => (
                        <MenuItem key={ix} value={expenseType}>
                            {translateString(expenseType)}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                <MoneyField
                    value={expenseAmount}
                    type="number"
                    placeholder="Angiv beløb"
                    onChange={setExpenseAmount}
                />
            </TableCell>
            <TableCell>
                <ActionButton disabled={disabled} onClick={createExpenseHandler}>
                    <Icon type="plus" />
                </ActionButton>
            </TableCell>
        </TableRow>
    );

    return (
        <div className="additional-expenses-table">
            <ResourceTable
                title={<p className="table-title">Andre Omkostninger</p>}
                readOnly={disabled}
                headers={["Type", "Beløb", ""]}
                sizes={["40%", "40%", "20%"]}
                form={expenseForm}
                onFormClose={closeFormHandler}>
                {expenses.map((expense, ix) => (
                    <AdditionalExpense 
						key={ix}
						type={expense.type}
						amount={expense.amount}
						id={expense.id}
						updateExpense={updateExpense}
						removeExpense={removeExpense}
						disabled={disabled}
					/>
                ))}
            </ResourceTable>
        </div>
    )
}

export default AdditionalExpensesTable;