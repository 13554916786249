import InterestListByTenancy from "./InterestListByTenancy";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
    
  return {
    user: state.general.user,
    showcasings: state.showcasings.showcasings,
    employees: state.app.employees
  };
};

export default connect(mapStateToProps)(InterestListByTenancy);
