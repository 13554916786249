import { useState, useCallback } from "react";
import PropertiesService from 'services/api/properties';
import produce from "immer";

/*
    useProperty hook handles property operations. 
    The only requirement for it is to load property first, before doing operations.
*/
const useProperty = () => {

    const [isPropertyLoading, setPropertyLoading] = useState(true);
    const [propertyData, setPropertyData] = useState(null);
	const [propertyContractData, setPropertyContractData] = useState(null);

	const [isInterestListLoading, setInterestListLoading] = useState(true);
    const [interestListData, setInterestListData] = useState([]);

    const loadProperty = useCallback(async (propertyId) => {
        const result = await PropertiesService.getProperty(propertyId);
        if (result !== null) {
            const propertyStatsResult = await PropertiesService.getPropertyStats(propertyId);
            setPropertyData({
                ...result.data,
                stats: propertyStatsResult?.data
            });

			const propertyContractData = await PropertiesService.getPropertyContractData(propertyId);
			setPropertyContractData(propertyContractData.data);
        }

        setPropertyLoading(false);
    }, []);

    const updateProperty = useCallback(async (data) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.updateProperty(propertyData.id, data);

        if (result !== null) {
            setPropertyData({
                ...propertyData,
                ...result.data,
            });
        }
    }, [propertyData]);

	const updatePropertyContract = useCallback(async (data) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.updatePropertyContractData(propertyData.id, data);

        if (result !== null) {
            setPropertyContractData(result.data);
        }
    }, [propertyData]);

    const uploadPropertyImage = useCallback(async (file) => {
        if (propertyData == null)
            return;

        var data = new FormData();
        data.append("file", file);
        const result = await PropertiesService.uploadImage(propertyData.id, data);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.images.push(result.data)
            }));
        }
    }, [propertyData]);

    const uploadPropertyImages = useCallback(async (files) => {
        if (propertyData == null)
            return;

        const o = propertyData.images.map(e => e.order);
        const highestOrder = o.length > 0 ? Math.max.apply(Math, o) : 1;

        let promises = []
        files.forEach((file, ix) => {
            let data = new FormData();
            data.append("file", file);
            data.append("order", highestOrder + ix + 1);
            promises.push(PropertiesService.uploadImage(propertyData.id, data))
        })
        const results = await Promise.all(promises);
        const images = results.filter(r => r.status === 200).map(r => r.data); 
        setPropertyData(produce(propertyData, draft => {
            draft.images = draft.images.concat(images)
        }));
    }, [propertyData]);

    const deletePropertyImage = useCallback(async (imageId) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.deleteImage(propertyData.id, imageId);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.images = draft.images.filter(x => x.id !== imageId);
            }));
        }
    }, [propertyData]);

    const changePropertyImageOrder = useCallback(async (imageId, newOrder) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.updateImage(propertyData.id, imageId, {
            order: newOrder
        });

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                const index = draft.images.findIndex(x => x.id === imageId);
                draft.images[index].order = newOrder;
            }));
        }
    }, [propertyData]);

    const uploadPropertyDocument = useCallback(async (file, type) => {
        if (propertyData == null)
            return;

        var data = new FormData();
        data.append("file", file);
        data.append("type", type);
        const result = await PropertiesService.uploadDocument(propertyData.id, data);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.documents.push(result.data)
            }));
        }
    }, [propertyData]);

    const deletePropertyDocument = useCallback(async (documentId) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.deleteDocument(propertyData.id, documentId);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.documents = draft.documents.filter(x => x.id !== documentId);
            }));
        }
    }, [propertyData]);

    const createPropertyContact = useCallback(async (role, name, email, extension, number) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.addPropertyContact(propertyData.id, {
            role: role,
            title: "",
            name: name,
            email: email,
            phone: {
                extension: extension,
                number: number
            }
        });

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.contacts.push(result.data)
            }));
        }
    }, [propertyData]);

    const removePropertyContact = useCallback(async (contactId) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.removePropertyContact(propertyData.id, contactId);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                const index = draft.contacts.findIndex(x => x.id === contactId);
                if (index !== -1) draft.contacts.splice(index, 1);
            }));
        }
    }, [propertyData]);

    const createPropertyAttribute = useCallback(async (definitionId, value) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.createPropertyAttribute(propertyData.id, { attributeDefinition: definitionId, value: value });

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                draft.attributes.push(result.data)
            }));
        }
    }, [propertyData]);

    const updatePropertyAttribute = useCallback(async (attributeId, data) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.updatePropertyAttribute(propertyData.id, attributeId, data);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
                draft.attributes.splice(index, 0, result.data);
            }));
        }
    }, [propertyData]);

    const removePropertyAttribute = useCallback(async (attributeId) => {
        if (propertyData == null)
            return;

        const result = await PropertiesService.removePropertyAttribute(propertyData.id, attributeId);

        if (result !== null) {
            setPropertyData(produce(propertyData, draft => {
                const index = draft.attributes.findIndex(x => x.definition.id === attributeId);
                draft.attributes.splice(index, 1)
            }));
        }
    }, [propertyData]);

	const loadInterestList = useCallback(async () => {
        const result = await PropertiesService.getPropertyInterestList(propertyData.id);
        if (result !== null) {
            setInterestListData(result.data);
        }
        setInterestListLoading(false);
    }, [propertyData?.id]);

    return {
        isPropertyLoading,
        propertyData,
		propertyContractData,
        updateProperty,
        loadProperty,
        uploadPropertyImage,
        uploadPropertyImages,
        deletePropertyImage,
        changePropertyImageOrder,
        uploadPropertyDocument,
        deletePropertyDocument,
        createPropertyContact,
        removePropertyContact,
        createPropertyAttribute,
        updatePropertyAttribute,
        removePropertyAttribute,
		loadInterestList,
		isInterestListLoading,
		interestListData,
		updatePropertyContract
    }
}

export default useProperty;