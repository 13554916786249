import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Select } from "components/Fields";
import { MenuItem, Tooltip } from "@material-ui/core";
import { BusinessTenancyTypes, ResidentialTenancyTypes } from "helpers/tenancy-types";
import { translateString } from "helpers";
import "./index.scss";
import Icon from "components/Icon";

const getTenancyTypes = (classification) => {
    if (classification === "Residential") {
        return ResidentialTenancyTypes;
    }
    if (classification === "Business") {
        return BusinessTenancyTypes;
    }
    return [];
}

const LeaseInformation = props => {

    const { data, tenancyData, update, addTenancyType, removeTenancyType } = props;

    const [rooms, setRooms] = useState(data.rooms ?? "");
    const [size, setSize] = useState(data.size.value ?? "");

    const types = getTenancyTypes(data.classification);

    const handleTypesChange = (e) => {
        const selected = e.target.value.find(x => !data.types.includes(x));
        const deselected = data.types.find(x => !e.target.value.includes(x));
        if (selected) {
            addTenancyType(selected);
        }
        if (deselected) {
            removeTenancyType(deselected);
        }
    }

    return (
        <Paper className="general-information__lease-information">
            <Typography variant="subtitle">Lejemålsinfo</Typography>
            <div className="fields">
                <div className="fields-section__field">
                    <Typography variant="label">Værelser</Typography>
                    <TextField
                        value={rooms}
                        disabled={data.deletedAt}
                        type="number"
                        onChange={(event) => setRooms(event.target.value)}
                        onExit={() => setRooms(data.rooms)}
                        onFinish={() => {
                            rooms !== data.rooms && update({ rooms: rooms })
                        }}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Størrelse</Typography>
                    <TextField
                        value={size}
                        disabled={data.deletedAt}
                        type="number"
                        onChange={(event) => setSize(event.target.value)}
                        onExit={() => setSize(data.size.value)}
                        onFinish={() => {
                            size !== data.size.value && update({ size: { value: size} })
                        }}
                        endAdornment={<>m<sup>2</sup></>}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Klassifikation</Typography>
                    <Select
                        value={data.classification}
                        disabled={data.deletedAt}
                        onChange={(e) => {
                            e.target.value !== data.classification && update({ classification: e.target.value })
                        }}>
                        <MenuItem value="Residential">
                            Bolig
                        </MenuItem>
                        <MenuItem value="Business">
                            Erhverv
                        </MenuItem>
                        <MenuItem value="Other">
                            Andet
                        </MenuItem>
                    </Select>
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Anvendelse</Typography>
                    <Select
                        value={data.types}
                        disabled={data.deletedAt}
                        multiple
                        displayEmpty
                        renderValue={(v) => v.length > 0 ? v.map(e => translateString(e)).join(', ') : "Ikke defineret"}
                        onChange={(e) => handleTypesChange(e)}>
                        {types.map((type, ix) => (
                            <MenuItem key={ix} value={type}>
                                {translateString(type)}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </Paper>
    );
};

export default LeaseInformation;