import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { useParams } from "react-router-dom";

import Typography from "components/Typography";
import StyledPaper from "components/StyledPaper";
import StyledTextField from "components/StyledTextField";
import StyledAutocomplete from "components/StyledAutocomplete";
import StyledSelect from "components/StyledSelect";
import StyledButton from "components/StyledButton";
import LoadingComponent from "components/LoadingComponent";
import { TextField } from "components/Fields";

import { ReactComponent as GeneralIcon } from "assets/icons/Generelt.svg";
import { ReactComponent as KeyIcon } from "assets/icons/KeyIcon.svg";
import { ReactComponent as TimeIcon } from "assets/icons/Tidspunkt.svg";

import { deleteOpenHouse } from "helpers/api/openhouses";
import { updateOpenHouse, addOpenHouseTenancy, deleteOpenHouseTenancy, addOpenHouseHost, deleteOpenHouseHost } from "helpers/api/properties";
import "./index.scss";
import useOpenHouse from "hooks/api/useOpenHouse";
import { formatInTimeZone } from "date-fns-tz";
import PropertiesService from "services/api/properties";
import { useOrganization } from "features/organization";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const EditOpenHouse = (props) => {
	const params = useParams();
	const openHouse = useOpenHouse();
	const organization = useOrganization();

	const [isLoading, setLoading] = useState(true);
	const [description, setDescription] = useState(null);
	const [startsAt, setStartsAt] = useState("");
	const [duration, setDuration] = useState("");
	const [hosts, setHosts] = useState([]);
	const [selectedProperty, setSelectedProperty] = useState("");
	const [selectedPropertyTenancies, setSelectedPropertyTenancies] = useState([]);
	const [selectedTenancies, setSelectedTenancies] = useState([]);
	const [meetingLocation, setMeetingLocation] = useState("");
	const [announceForAllTenancies, setAnnounceForAllTenancies] = useState(false);

	const timeValues = [
		{ value: 15, label: "15 minutter" },
		{ value: 30, label: "30 minutter" },
		{ value: 45, label: "45 minutter" },
		{ value: 60, label: "60 minutter" },
	];

	useEffect(() => {
		openHouse.loadOpenhouse(params.id);
	}, [params.id]);

	useEffect(() => {
		const loadData = async () => {
			const propertyResponse = await PropertiesService.getProperty(openHouse.openhouseData.property);
			setSelectedProperty(propertyResponse.data);

			const tenanciesResponse = await PropertiesService.getPropertyTenancies(openHouse.openhouseData.property);
			setSelectedPropertyTenancies(tenanciesResponse.data);

			setDescription(openHouse.openhouseData.description);
			setStartsAt(formatInTimeZone(new Date(openHouse.openhouseData.scheduled.startsAt), 'Europe/Berlin', "yyyy-MM-dd'T'HH:mm:ss.SSS"));
			setDuration(openHouse.openhouseData.scheduled.duration);
			setHosts(organization.employees.filter(x => openHouse.openhouseData.hosts.includes(x.employeeId)));
			setSelectedTenancies(tenanciesResponse.data.filter(x => openHouse.openhouseData.tenancies.includes(x.id)));
			setMeetingLocation(openHouse.openhouseData.meetingLocation);
			setAnnounceForAllTenancies(openHouse.openhouseData.announceForAllTenancies)

			setLoading(false);
		};

		if (openHouse.openhouseData && !organization.isOrganizationLoading) {
			loadData();
		} else {
			setLoading(true);
		}
	}, [openHouse.openhouseData, organization.isOrganizationLoading]);

	useEffect(() => {
	}, [selectedProperty]);

	const onDelete = async () => {
		await deleteOpenHouse(params.id);
		window.location.href = "/openhouses"
	};

	if (isLoading)
		return <LoadingComponent />;

	return (
		<Grid container className="openhousesContainer" spacing={2}>
			<Grid className="header" item xs={12}>
				<Typography variant="title">
					Åbent hus
				</Typography>
				<StyledButton
					style={{ marginLeft: "16px" }}
					onClick={() => onDelete()}
					disableElevation={true}
					variant="contained"
				>
					Slet åbent hus
				</StyledButton>
			</Grid>
			<Grid container item xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container spacing={2}>
						<Grid className="headerSection" item xs={12}>
							<KeyIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
								className="titleIcon"
							/>
							<Typography variant="subtitle">
								Lejemål til åbent hus
							</Typography>
						</Grid>
						<Grid item xs={12} lg={6}>
							<div className="selectSection">
								<Typography variant="label">
									Ejendom
								</Typography>
								<StyledAutocomplete
									disabled
									options={[selectedProperty]}
									getOptionLabel={(option) => option ? option.title : ""}
									renderInput={(params) => (
										<StyledTextField {...params} variant="outlined" placeholder="Vælg ejendom" />
									)}
									onChange={(value) => {
										setSelectedProperty(value ? value : "");
									}}
									getOptionSelected={(option, value) => {
										if (value === "" || value.id === option.id) {
											return true;
										}
									}}
									value={selectedProperty ? selectedProperty : ""}
								/>
							</div>
						</Grid>
						<Grid item xs={12} lg={6}>
							<div className="selectSection">
								<Typography variant="label">
									Mødested
								</Typography>
								<TextField
									value={meetingLocation}
									onChange={(event) => setMeetingLocation(event.target.value)}
									onExit={() => setMeetingLocation(meetingLocation)}
									onFinish={() => {
										updateOpenHouse(params.id, { meetingLocation: meetingLocation })
									}}
								/>
							</div>
						</Grid>
						<Grid className="tenancies-selector" item xs={12} lg={12}>
							<Typography variant="label">
								Vis på alle lejemål
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										style={{ color: "#566FFF" }}
										checked={announceForAllTenancies}
										onChange={() => {
											const newValue = !announceForAllTenancies;
											setAnnounceForAllTenancies(newValue)
											updateOpenHouse(params.id, { announceForAllTenancies: newValue })
										}}
										name="checkedA"
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={12}>
							<div className="selectSection possible_tenancies">
								<Typography variant="label">
									Lejemål
								</Typography>
								<StyledAutocomplete
									fullWidth
									multiple
									options={selectedPropertyTenancies}
									disabled={
										selectedProperty === "" ||
										selectedPropertyTenancies.length === 0
									}
									getOptionLabel={(option) => {
										return option ?
											selectedPropertyTenancies.find((e) => e.id === option.id).address.street :
											"";
									}
									}
									renderOption={(option) => <>{option.address.street}</>}
									getOptionSelected={(option, value) => {
										if (value.length === 0 || value.id === option.id) {
											return true;
										}
									}}
									renderInput={(params) => (
										<StyledTextField
											{...params}
											placeholder={
												selectedProperty === ""
													? "Vælg lejemål"
													: selectedPropertyTenancies.length === 0
														? "Ejendommen har ingen lejemål"
														: "Vælg lejemål"
											}
											variant="outlined"
										/>
									)}
									noOptionsText={"Ejendommen har ingen lejemål"}
									value={selectedTenancies ? selectedTenancies : ""}
									onChange={(value) => {
										if (selectedTenancies.length < value.length) {
											addOpenHouseTenancy(params.id, value[value.length - 1].id)
										} else if (selectedTenancies.length > value.length) {
											const selectedTenanciesIds = selectedTenancies.map(tenancy => tenancy.id);
											const valuesIds = value.map(tenancy => tenancy.id);
											const deletedId = selectedTenanciesIds.find(tenancy => !valuesIds.includes(tenancy));
											if (deletedId) {
												deleteOpenHouseTenancy(params.id, deletedId)
											}
										}
										setSelectedTenancies(value ? value : []);
									}}
								/>
							</div>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
			<Grid item container xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container spacing={2}>
						<Grid className="headerSection" item xs={12}>
							<GeneralIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
								className="titleIcon"
							/>
							<Typography variant="subtitle">
								Yderligere information
							</Typography>
						</Grid>
						<Grid item xs={12} lg={12}>
							<Typography variant="label">
								Beskrivelse (valgfri)
							</Typography>
							<TextField
								multiline
								rows={5}
								rowsMax={5}
								value={description}
								className="descriptionTextField"
								placeholder="Tilføj en eventuel beskrivelse"
								onChange={(event) => setDescription(event.target.value)}
								onExit={() => setDescription(description)}
								onFinish={() => {
									updateOpenHouse(params.id, { description: description })
								}}
							/>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
			<Grid item container xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container spacing={2}>
						<Grid item className="headerSection" xs={12}>
							<TimeIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
							/>
							<Typography variant="subtitle">
								Tidspunkt og Fremviser
							</Typography>
						</Grid>
						<Grid item xs={12} lg={6}>
							<div className="selectSection">
								<Typography variant="label">
									Starttidspunkt
								</Typography>
								<TextField
									value={startsAt ?? ""}
									type="datetime-local"
									onChange={(event) => setStartsAt(event.target.value)}
									onExit={() => setStartsAt(startsAt)}
									onFinish={() => {
										updateOpenHouse(params.id, { scheduled: { startsAt: new Date(startsAt + "Z"), duration: duration } })
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Typography variant="label">
								Varighed
							</Typography>
							<StyledSelect
								displayEmpty
								onChange={(e) => {
									setDuration(e.target.value)
									updateOpenHouse(params.id, { scheduled: { startsAt: new Date(startsAt), duration: e.target.value } })
								}}
								renderValue={(option) => `${timeValues.find((e) => option === e.value).label}`}
								value={duration}
								variant="outlined"
							>
								{timeValues.map((e, index) => (
									<MenuItem key={index} value={e.value}>
										{`${e.label}`}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
						<Grid item xs={12} lg={12}>
							<div className="selectSection">
								<Typography variant="label">
									Fremviser
								</Typography>
								<StyledAutocomplete
									multiple
									options={organization.employees}
									value={hosts}
									getOptionLabel={(option) => option.name}
									renderInput={(params) => (
										<StyledTextField {...params} variant="outlined" placeholder="Vælg fremviser" />
									)}
									onChange={(value) => {
										if (hosts.length < value.length) {
											addOpenHouseHost(params.id, value[value.length - 1].employeeId)
										} else if (hosts.length > value.length) {
											const selectedHostsIds = hosts.map(host => host.employeeId);
											const valuesIds = value.map(host => host.id);
											const deletedId = selectedHostsIds.find(host => !valuesIds.includes(host));
											if (deletedId) {
												deleteOpenHouseHost(params.id, deletedId)
											}
										}
										setHosts(value ? value : [])
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={12} lg={6}>
							<div className="selectSection">

							</div>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
		</Grid>
	);
}

export default EditOpenHouse;