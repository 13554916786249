import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

export const getPropertyTenancies = async (propertyId) => { 
    try {
        const result = await axios.get(`properties/${propertyId}/tenancies`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const addOpenHouseHostLocation = async (openHouseId, hostId) => {
    try {
        const result = await axios.post(`/open-houses/${openHouseId}/locations/${hostId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const deleteOpenHouseHostLocation = async (openHouseId, deletedId) => {
    try {
        const result = await axios.delete(`/open-houses/${openHouseId}/locations/${deletedId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const addOpenHouseKind = async (openHouseId, kind) => {
    try {
        const result = await axios.post(`/open-houses/${openHouseId}/relevant/kinds/${kind}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const deleteOpenHouseKind = async (openHouseId, deletedId) => {
    try {
        const result = await axios.delete(`/open-houses/${openHouseId}/relevant/kinds/${deletedId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const addOpenHouseHost = async (openHouseId, hostId) => {
    try {
        const result = await axios.post(`/open-houses/${openHouseId}/hosts/${hostId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const deleteOpenHouseHost = async (openHouseId, deletedId) => {
    try {
        const result = await axios.delete(`/open-houses/${openHouseId}/hosts/${deletedId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const addOpenHouseTenancy = async (openHouseId, tenancyId) => {
    try {
        const result = await axios.post(`/open-houses/${openHouseId}/tenancies/${tenancyId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const deleteOpenHouseTenancy = async (openHouseId, deletedId) => {
    try {
        const result = await axios.delete(`/open-houses/${openHouseId}/tenancies/${deletedId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const addOpenHouseRelatedTenancy = async (openHouseId, tenancyId) => {
    try {
        const result = await axios.post(`/open-houses/${openHouseId}/relevant/tenancies/${tenancyId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const deleteOpenHouseRelatedTenancy = async (openHouseId, deletedId) => {
    try {
        const result = await axios.delete(`/open-houses/${openHouseId}/relevant/tenancies/${deletedId}`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const updateOpenHouse = async (openHouseId, payload) => {
    try {
        const result = await axios.patch(`/open-houses/${openHouseId}`, payload);
        return result.data
    } catch (err) {
        console.error(err);
    }
}


export const getPropertyKinds = async (propertyId) => {  
    try {
        const result = await axios.get(`properties/${propertyId}/tenancy-kinds`);
        return result.data
    } catch (err) {
        console.error(err);
    }
}

export const getAllProperties = async () => {
    const url = "/properties";
    const firstRequest = await axios.get(url, {});
    let properties = firstRequest.data;

    if (firstRequest.headers["x-total"] > 250) {
        const promises = [];
        let offset = 250;
        const total =  firstRequest.headers["x-total"]; 

        while (offset < total) {
            const offsetUrl = url + "?offset=" + offset;
            promises.push(axios.get(offsetUrl, {}));
            offset += 250;
        }

        const resolvedPromises = await Promise.all(promises);
        const data = resolvedPromises.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);
        properties = [...properties, ...data];
        return properties;
    } else {
        return properties;
    }
}

export const createProperty = async (title) => {  
    try {
        const result = await axios.post(`properties/`, { "title": title});
        return result.data
    } catch (err) {
        console.error(err);
    }
}

