import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import InheritedValueIcon from "../InheritedValueIcon";

const CoolingUtility = ({ propertyDetails, tenancyDetails, updateTenancyContract }) => {
	
	const isLandlordResponsible = tenancyDetails.utilities?.cooling?.isLandlordResponsible ?? propertyDetails.utilities?.cooling?.isLandlordResponsible;
	const isLandlordReponsibleInherited = tenancyDetails.utilities?.cooling?.isLandlordResponsible == null || tenancyDetails.utilities?.cooling?.isLandlordResponsible == undefined;

	const hasIndividualMeters = tenancyDetails.utilities?.cooling?.hasIndividualMeters ?? propertyDetails.utilities?.cooling?.hasIndividualMeters;
	const isHasIndividualMetersInherited = tenancyDetails.utilities?.cooling?.hasIndividualMeters == null || tenancyDetails.utilities?.cooling?.hasIndividualMeters == undefined;

	const accountingStartDateInitialValue = tenancyDetails.utilities?.cooling?.accountingStartDate ?? propertyDetails.utilities?.cooling?.accountingStartDate ?? "";
	const isAccountingStartDateInherited = !tenancyDetails.utilities?.cooling?.accountingStartDate;
	const [accountingStartDate, setAccountingStartDate] = useState(accountingStartDateInitialValue);

    return (
        <>
            <Typography variant="label" className="utility-title">Køling</Typography>
            <div className="fields-section__container">
                <div className="fields-section__field">
                    <Typography variant="label">Udlejer ansvarlig {isLandlordReponsibleInherited && <InheritedValueIcon />}</Typography>
                    <Switch
                        checked={isLandlordResponsible}
                        onChange={(e) => updateTenancyContract({ utilities: { cooling: { isLandlordResponsible: e.target.checked } } })}
                    />
                </div>
				<div className="fields-section__field">
                    <Typography variant="label">Lejemålet har individuelle målere {isHasIndividualMetersInherited && <InheritedValueIcon />}</Typography>
                    <Switch
                        checked={hasIndividualMeters}
                        onChange={(e) => updateTenancyContract({ utilities: { cooling: { hasIndividualMeters: e.target.checked } } })}
                    />
                </div>
                <div className="fields-section__field">
                    <Typography variant="label">Kølingsregnskabsåret begynder {isAccountingStartDateInherited && <InheritedValueIcon />}</Typography>
					<TextField
						value={accountingStartDate ?? ""}
						type="date"
						onChange={(event) => setAccountingStartDate(event.target.value)}
						onExit={() => setAccountingStartDate(accountingStartDateInitialValue)}
						onFinish={() => {
							accountingStartDate !== accountingStartDateInitialValue && updateTenancyContract({ utilities: { cooling: { accountingStartDate } } })
						}}
					/>
                </div>
            </div>
            <hr/>
        </>
    );
};

export default CoolingUtility;