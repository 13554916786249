import React, { useMemo } from 'react'
import './index.scss'

import useShowcasingsSearch from 'hooks/api/useShowcasingsSearch';
import HeaderContainer from 'components/HeaderContainer';
import ShowcasingsList from './Components/ShowcasingsList';
import Typography from 'components/Typography';
import ChipSlim from 'components/ChipSlim';
import { ToggleButton } from 'components/Buttons';
import { translateString } from 'helpers';
import { PrimaryButton } from "components/Buttons";
import { ReactComponent as PlusIcon } from "assets/icons/jorato-plus.svg";
import { useHistory } from "react-router-dom";

export default function Showcasings (props) {
	const { filter, setFilter, filteredData, isFilteredDataLoading } = useShowcasingsSearch(props.employees, props.tenancies);
	const history = useHistory();

	const handleCreate = () => {
		history.push(`/showcasings/create`);
	}

	const cleanedData = useMemo(() => {
		const cleanedData = filteredData.filter((item) => {
			return item.cancelledAt === null;
		})
		return cleanedData; 
	}, [filteredData]);

  	return (
		<div className='showcasings-list-container'>
			<HeaderContainer>
				<Typography variant='title'>Fremvisninger</Typography>
				<ChipSlim number={cleanedData ? cleanedData.length : 0} />
			</HeaderContainer>
			<div className='controls'>
				<ToggleButton
					value={filter}
					onChange={value => setFilter(value)}
					options={[
					{
						label: 'Alle',
						value: 'all'
					},
					{
						label: translateString('residential'),
						value: 'residential'
					},
					{
						label: translateString('business'),
						value: 'business'
					},
					{
						label: 'Mine fremvisninger',
						value: 'private'
					}
					]}
				/>
				<div className='right-aligned'>
					<PrimaryButton onClick={handleCreate} className="no-margin has-icon">
						<PlusIcon />
						Opret fremvisning
					</PrimaryButton>
				</div>
			</div>
			<div className='content'>
				<ShowcasingsList isLoading={isFilteredDataLoading} data={filteredData} handleCreate={handleCreate} />
			</div>
		</div>
  )
}
