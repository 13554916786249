import React, { useMemo } from "react";
import SplitButton from "components/Buttons/SplitButton";

const ChangeStateAction = props => {

	const { currentState, updateState } = props;

	const states = [
		"Draft", "Active", "Reserved", "Completed"
	];

	const getNextStateIndex = (state) => {
		const max = states.length;
		const next = states.indexOf(currentState) + 1;

		if (next >= max) {
			return 0;
		}

		return next;
	}

	const translateState = (state) => {
		switch (state) {
			case "Draft":
				return "Kladde";
			case "Active":
				return "Aktiver";
			case "Reserved":
				return "Reserver";
			case "Completed":
				return "Afslut";
			default:
				return "-";
		}
	}

	const nextState = useMemo(() => states[getNextStateIndex(currentState)], [currentState]);

	return (
		<SplitButton
			primary={{
				label: translateState(nextState),
				action: () => updateState(nextState)
			}}
			options={states.filter(x => x != nextState && x != currentState).map(x => {
				return {
					label: translateState(x),
					action: () => updateState(x)
				}
			})}
		/>
	);
};

export default ChangeStateAction;