import { CircularProgress } from "@material-ui/core";
import { ActionButton } from "components/Buttons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DeleteTenancyModal from "./DeleteTenancyModal";

const TenancyActions = props => {

	const { tenancyData, createTenancyBasedCase, deleteTenancy } = props;
	const history = useHistory();
	const [isCaseCreating, setCaseCreating] = useState(false);
	const [isModalEnabled, setModalEnabled] = useState(false);

	const handleCreateCase = async () => {
		setCaseCreating(true);
		await createTenancyBasedCase();
		setCaseCreating(false);
	};

	const handleOpenCurrentCase = () => {
		history.push(`/cases/${tenancyData.currentCase}`);
	}

	const handleDeleteTenancy = async () => {
		await deleteTenancy();
		history.push(`/tenancies`);
	}

	return (
		<div className="tenancy-actions">
			{tenancyData.currentCase === null &&
				<ActionButton onClick={handleCreateCase} className="create-case-action">
					Opret udlejningssag
					{isCaseCreating && <div className="create-case-action-loading"><CircularProgress size={20} /></div>}
				</ActionButton>
			}
			{tenancyData.currentCase !== null && <ActionButton onClick={handleOpenCurrentCase}>Se udlejningssag</ActionButton>}
			<ActionButton onClick={() => setModalEnabled(true)} className="delete-tenancy-action">
				Slet lejemål
			</ActionButton>
			<DeleteTenancyModal
				isEnabled={isModalEnabled}
				onClose={() => setModalEnabled(false)}
				deleteTenancy={handleDeleteTenancy} />
		</div>
	);
};

export default TenancyActions;