import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import InheritedValueIcon from "./InheritedValueIcon";

const RentControlFields = ({ tenancyContractData, propertyContractData, updateTenancyContract }) => {

	const rentJustificationInitialValue = tenancyContractData.rentControl?.uncontrolledRentJustification ?? propertyContractData.rentControl.uncontrolledRentJustification;
	const isRentJustificationInherited = !tenancyContractData.rentControl?.uncontrolledRentJustification || tenancyContractData.rentControl?.uncontrolledRentJustification === "";
	const [uncontrolledRentJustification, setUncontrolledRentJustification] = useState(rentJustificationInitialValue ?? "");

	const enabledValue = tenancyContractData.rentControl?.enabled ?? propertyContractData.rentControl.enabled;
	const isEnabledInherited = !tenancyContractData.rentControl || tenancyContractData.rentControl?.enabled == null;

	return (
		<Paper className="property-contract-details__other-fields">
			<Typography variant="subtitle">Lejefastsættelse</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Lejeforholdet er omfattet af reglerne om fri leje {isEnabledInherited && <InheritedValueIcon />}</Typography>
					<div className="switch-container">
						<Switch
							checked={!enabledValue}
							onChange={(e) => updateTenancyContract({ rentControl: { enabled: !e.target.checked } })}
						/>
					</div>
				</div>
			</div>
			{!propertyContractData.rentControl.enabled && (
				<div className="fields-section__container">
					<div className="fields-section__field">
						<Typography variant="label">Grundlag for fri leje {isRentJustificationInherited && <InheritedValueIcon />}</Typography>
						<TextField
							value={uncontrolledRentJustification}
							multiline
							rows={5}
							rowsMax={20}
							onChange={(event) => setUncontrolledRentJustification(event.target.value)}
							onExit={() => setUncontrolledRentJustification(rentJustificationInitialValue)}
							onFinish={() => {
								uncontrolledRentJustification !== rentJustificationInitialValue && updateTenancyContract({ rentControl: { uncontrolledRentJustification: uncontrolledRentJustification } })
							}}
						/>
					</div>
				</div>
			)}
		</Paper>
	);
}

export default RentControlFields;