import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

export const getLatestCasesByType = async (type) => { 
    const query = {
        "paging": {
          "offset": 0,
          "limit": 20
        },
        "sort": {
          "field": "createdAt",
          "direction": "DESC"
        },
        "filters": [{
          "type": "Equals",
          "field": "state",
          "params": {
            "value": `${type}`
          }
        }]
      };
    try {
        const result = await axios.post(`/cases/query`, query);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const getCasesStats = async () => { 
    try {
        const result = await axios.get(`cases/stats`);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const addCaseComment = async (caseId, comment) => { 
  const content = {
    content: comment
  }
  try {
      const result = await axios.post(`cases/${caseId}/comments`, content);
      return result.data
  } catch (err) {
      console.error(err);
  }
};

