import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ExtendedTable from "components/ExtendedTable";
import { getOpenHouses } from "helpers/api/openhouses";
import Columns from "components/ExtendedTable/config/openHousesColumns.js";
import "./index.scss";
import StyledPaper from "components/StyledPaper";
import StyledButton from "components/StyledButton";
import { formatDate } from 'helpers/date';


const OpenHouses = (props) => {
  const [data, setData] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(true);

  useEffect(() => {
    const getData = async (filters) => {
      const openHouses = await getOpenHouses(filters);
      const openHousesEnhanced = openHouses.data.filter(x => x.cancelledAt == null).map(openHouse => {
        const locationNames = openHouse.tenancies.map(location => {
            const tenancy = props.tenancies.data.find(t => t.id === location)
            return tenancy.address.street;
        })
        openHouse.locationNames = locationNames;
        let employeeNames = "";
        openHouse.hosts.forEach((host) => {
          const employee = props.employees.data.find(e => {
            return e.employeeId === host
          })
          if (employee) {
            employeeNames = employeeNames + " " + employee.name
          }
        })
        openHouse.hostNames = employeeNames;
        const property = props.properties.data.find((p) => p.id === openHouse.property);
        openHouse.propertyName = property.title;
		openHouse.date = formatDate(openHouse.scheduled.startsAt, "dd/MM/yyyy");
        openHouse.time = formatDate(openHouse.scheduled.startsAt, "HH:mm");
        return openHouse; 
      })
      setData(openHousesEnhanced);
      setDataIsLoading(false);
    }
    if (props.employees.loaded && props.properties.loaded && props.tenancies.loaded){
      getData();  
    }
    }, [props.employees, props.properties, props.tenancies]);

    const history = useHistory();
    
  return (
    <div>
      <Grid container alignItems="center"  style={{ marginBottom: "16px" }} direction="row" spacing={2}>
        <Grid item>
          <Typography color="primary" variant="h1" >
            Åbent hus
          </Typography>          
        </Grid>
        <Grid item>
          <StyledButton disableElevation={true} className="" onClick={()=> history.push("/openhouses/create")} variant="contained">
            Opret åbent hus
            </StyledButton>
        </Grid>
      </Grid>
      <StyledPaper>
        <ExtendedTable
          isLoading={dataIsLoading}
          data={data}
          linkPath="/openhouses/"
          columns={Columns}
        />
      </StyledPaper>
    </div>
  );
}

export default OpenHouses;