import React from "react";
import { TenancyFields, Maintenance, Other, RentControlFields } from "./components";
import "./index.scss";
import RentIncreaseFields from "./components/RentIncreaseFields";
import UtilitiesFields from "./components/UtilitiesFields";

const ContractDetailsTab = ({ tenancyContractData, propertyContractData, updateTenancyContract }) => (
	<div className="tenancy-contract-details">
		<TenancyFields
			tenancyContractData={tenancyContractData}
			propertyContractData={propertyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
		<Maintenance
			tenancyContractData={tenancyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
		<UtilitiesFields
			tenancyContractData={tenancyContractData}
			propertyContractData={propertyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
		<RentIncreaseFields
			tenancyContractData={tenancyContractData}
			propertyContractData={propertyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
		<RentControlFields
			tenancyContractData={tenancyContractData}
			propertyContractData={propertyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
		<Other
			tenancyContractData={tenancyContractData}
			propertyContractData={propertyContractData}
			updateTenancyContract={updateTenancyContract}
		/>
	</div>
);

export default ContractDetailsTab;
