import { useState, useCallback } from "react";
import CasesService from 'services/api/cases';

/*
    useCases hook handles cases loading. 
*/
const useCases = () => {

    const [isCasesLoading, setCasesLoading] = useState(true);
    const [casesData, setCasesData] = useState([]);

    const loadCurrentEmployeeCases = useCallback(async () => {
        const result = await CasesService.getEmployeeCases();
        setCasesData(result.data);
        setCasesLoading(false);
    }, []);

    const loadCurrentEmployeeActiveCases = useCallback(async () => {
        const result = await CasesService.getEmployeeActiveCases();
        setCasesData(result.data);
        setCasesLoading(false);
    }, []);

    const loadCurrentEmployeeCompletedCases = useCallback(async () => {
        const result = await CasesService.getEmployeeCompletedCases();
        setCasesData(result.data);
        setCasesLoading(false);
    }, []);

    return {
        isCasesLoading,
        casesData,
        loadCurrentEmployeeCases,
        loadCurrentEmployeeActiveCases,
        loadCurrentEmployeeCompletedCases
    };
};

export default useCases;