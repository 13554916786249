import React, { useRef, useEffect } from "react";
import Chart from 'chart.js/auto';

const LineChart = props => {

    const canvas = useRef(null);

    const defaultColors = ['#2C3589', '#566FFF', '#8092ff', '#99a7ff', '#c7cfff'];

    useEffect(() => {
        if (!canvas.current)
            return;

        new Chart(canvas.current, {
            type: 'line',
            data: {
                labels: props.labels,
                datasets: props.datasets,    
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: !props.hideLegend
                    }
                },
                scales: props.scales,
                tension: 0.3
            }
        });
    }, [canvas]);

    return (
        <canvas ref={canvas} ></canvas>
    );
};

export default LineChart;