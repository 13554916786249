import React, { useState, useEffect } from "react";
import "./index.scss";
import Tabs, { Tab } from "components/Tabs"
import { useParams } from "react-router-dom";
import LoadingComponent from "components/LoadingComponent";
import useTenancy from "hooks/api/useTenancy";
import useProperty from "hooks/api/useProperty";
import InterestListTab from "components/InterestListTab/InterestListTab";
import GeneralInformationTab from "./components/GeneralInformationTab";
import TenancyActions from "./components/TenancyActions/TenancyActions";
import ContractDetailsTab from "./components/ContractDetailsTab";

const Tenancy = () => {

	const [tab, setTab] = useState(0);
	const { isTenancyLoading, tenancyData, tenancyContractData, updateTenancy, loadTenancy, addTenancyType, removeTenancyType,
		createTenancyExpense, removeTenancyExpense, createTenancyContact, removeTenancyContact,
		updateTenancyAttribute, removeTenancyAttribute, createTenancyAttribute, uploadTenancyImages,
		deleteTenancyImage, changeTenancyImageOrder, uploadTenancyFloorPlans, deleteTenancyFloorPlan,
		changeTenancyFloorPlanOrder, uploadTenancyDocument, deleteTenancyDocument, addTenancyLocationPoint,
		removeTenancyLocationPoint, createTenancyBasedCase, deleteTenancy, updateTenancyExpense, updateTenancyContract } = useTenancy();

	const { isPropertyLoading, propertyData, propertyContractData, loadProperty, loadInterestList, isInterestListLoading, interestListData } = useProperty();

	const params = useParams();

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		loadTenancy(params.id);
	}, [params.id, loadTenancy]);

	useEffect(() => {
		if (!tenancyData?.propertyId) {
			return;
		}

		loadProperty(tenancyData?.propertyId);
	}, [tenancyData, loadProperty]);

	if (isTenancyLoading || isPropertyLoading) {
		return (
			<div className="loading-container"><LoadingComponent /></div>
		);
	}

	if (!tenancyData) {
		return (
			<div className="tenancy-container">Error</div>
		);
	}

	return (
		<div className="tenancy-container">
			<div className="tenancy-header">
				<Tabs className="tabs-navigation" value={tab} onChange={handleTabChange} aria-label="tenancy tabs navigation">
					<Tab label="Generel information" />
					<Tab label="Interesseliste" />
					<Tab label="Kontrakt information" />
				</Tabs>
				<TenancyActions
					tenancyData={tenancyData}
					deleteTenancy={deleteTenancy}
					createTenancyBasedCase={createTenancyBasedCase} />
			</div>

			<div className="tab-content">
				{tab === 0 &&
					<GeneralInformationTab
						tenancyData={tenancyData}
						propertyData={propertyData}
						updateTenancy={updateTenancy}
						addTenancyType={addTenancyType}
						removeTenancyType={removeTenancyType}
						createTenancyExpense={createTenancyExpense}
						removeTenancyExpense={removeTenancyExpense}
						updateTenancyExpense={updateTenancyExpense}
						createTenancyContact={createTenancyContact}
						removeTenancyContact={removeTenancyContact}
						updateTenancyAttribute={updateTenancyAttribute}
						removeTenancyAttribute={removeTenancyAttribute}
						createTenancyAttribute={createTenancyAttribute}
						uploadTenancyImages={uploadTenancyImages}
						deleteTenancyImage={deleteTenancyImage}
						changeTenancyImageOrder={changeTenancyImageOrder}
						uploadTenancyFloorPlans={uploadTenancyFloorPlans}
						deleteTenancyFloorPlan={deleteTenancyFloorPlan}
						changeTenancyFloorPlanOrder={changeTenancyFloorPlanOrder}
						uploadTenancyDocument={uploadTenancyDocument}
						deleteTenancyDocument={deleteTenancyDocument}
						addTenancyLocationPoint={addTenancyLocationPoint}
						removeTenancyLocationPoint={removeTenancyLocationPoint} />
				}
				{tab === 1 &&
					<InterestListTab
						load={loadInterestList}
						isLoading={isInterestListLoading}
						data={interestListData}
					/>
				}
				{tab === 2 &&
					<ContractDetailsTab
						tenancyContractData={tenancyContractData}
						propertyContractData={propertyContractData}
						updateTenancyContract={updateTenancyContract}
					/>
				}
			</div>
		</div>
	);
};

export default Tenancy;