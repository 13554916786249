import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import { translateString } from "helpers";
import GoogleMaps from "components/GoogleMaps";


const Location = props => {

    const { caseData, updateCase } = props;

    const [street, setStreet] = useState(caseData.address.street ?? "");
    const [zipCode, setZipCode] = useState(caseData.address.zipCode ?? "");
    const [city, setCity] = useState(caseData.address.city ?? "");

    return (
        <Paper className="general-information__location">
            <div className="wrapper">
                <Typography variant="subtitle">Lokation</Typography>
                <div className="fields">
                    <div className="fields-section__field">
                        <Typography variant="label">Adresse</Typography>
                        <TextField
                            value={street}
                            disabled={caseData.deletedAt}
                            onChange={(event) => setStreet(event.target.value)}
                            onExit={() => setStreet(caseData.address.street)}
                            onFinish={() => {
                                street !== caseData.address.street && updateCase({ address: { street: street } })
                            }}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Postnummer</Typography>
                        <TextField
                            value={zipCode}
                            disabled={caseData.deletedAt}
                            onChange={(event) => setZipCode(event.target.value)}
                            onExit={() => setZipCode(caseData.address.zipCode)}
                            onFinish={() => {
                                zipCode !== caseData.address.zipCode && updateCase({ address: { zipCode: zipCode } })
                            }}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">By</Typography>
                        <TextField
                            value={city}
                            disabled={caseData.deletedAt}
                            onChange={(event) => setCity(event.target.value)}
                            onExit={() => setCity(caseData.address.city)}
                            onFinish={() => {
                                city !== caseData.address.city && updateCase({ address: { city: city } })
                            }}
                        />
                    </div>
                </div>
                <hr />
                <div className="fields">
                    <div className="fields-section__field">
                        <Typography variant="label">Breddegrad</Typography>
                        <p className="field-value">{caseData.address.coordinates?.latitude}</p>
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Længdegrad</Typography>
                        <p className="field-value">{caseData.address.coordinates?.longitude}</p>
                    </div>
                </div>
                <hr />
                <div className="fields points">
                    {caseData.locations.map((location, ix) => {
                        return (
                            <div key={ix} className="fields-section__field">
                                <Typography variant="label">{translateString(location.type)}</Typography>
                                <p className="field-value">{Math.round(location.distance)} m</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="map">
                <GoogleMaps
                    latitude={caseData.address?.coordinates?.latitude}
                    longitude={caseData.address?.coordinates?.longitude} />
            </div>
        </Paper>
    );
};

export default Location;