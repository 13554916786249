import React, { useState, useEffect } from 'react'
import './index.scss'
import { useTenanciesSearch } from 'hooks/api'
import { useMemorizedTableSettings } from 'hooks/memory'
import { useHistory } from 'react-router-dom'
import Typography from 'components/Typography'
import Table from 'components/Table'
import HeaderContainer from 'components/HeaderContainer'
import ChipSlim from 'components/ChipSlim'
import ColumnsModal from 'components/ColumnsModal'
import SearchInput from 'components/SearchInput'
import FilterModal from 'components/FilterModal'
import FilterChips from 'components/FilterChips'
import columns from './columns'
import './index.scss'

const Tenancies = props => {
  const {
    enabledColumns,
    enabledFilters,
    enabledSorting,
    saveEnabledFilters,
    saveEnabledColumns,
    saveEnabledSorting,
    isLoading: isLoadingTableSettings
  } = useMemorizedTableSettings(`tenancies`)
  const {
    isTenanciesLoading,
    setInitiationCompleted,
    tenanciesData,
    tenanciesMetaData,
    loadTenancies,
    query,
    setQuery,
    setPageIndex,
    pageIndex,
    filters,
    setFilters,
    setSorting,
    sorting,
    setColumns
  } = useTenanciesSearch()
  const history = useHistory()
  const [activeColumns, setActiveColumns] = useState([])

  useEffect(() => {
    if (!isLoadingTableSettings) {
      setActiveColumns(
        enabledColumns.length > 0
          ? enabledColumns
          : columns.filter(x => x.isDefault).map(x => x.accessor)
      )
      setFilters(enabledFilters)
      setSorting(enabledSorting)
      setColumns(columns)
      setInitiationCompleted(true)
    }
  }, [isLoadingTableSettings])

  const handleRowClick = (e, row) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/tenancies/${row.original.id}`, '_blank')
    } else {
      history.push(`/tenancies/${row.original.id}`)
    }
  }

  const changeFilters = filters => {
    setFilters(filters)
    saveEnabledFilters(filters)
  }

  const changeActiveColumns = newColumns => {
    setActiveColumns(newColumns)
    saveEnabledColumns(newColumns)
  }
  const addFilter = (accessor, value) => {
    const newValue = [...filters, { accessor: accessor, value: value }]
    setFilters(newValue)
    saveEnabledFilters(newValue)
  }

  const changeSorting = newSorting => {
    setSorting(newSorting)
    saveEnabledSorting(newSorting)
  }

  if (isTenanciesLoading) {
    return <div>Loading</div>
  }

  return (
    <div className='tenancies-list-container'>
      <div className='tenancies-list-header'>
        <HeaderContainer>
          <Typography variant='title'>Lejemål</Typography>
          <ChipSlim label={tenanciesMetaData.TotalTenancies} />
        </HeaderContainer>
        <div className='table-bar-container'>
          <div className='search'>
            <SearchInput value={query} setQuery={setQuery} />
          </div>
          <div className='options'>
            <FilterModal
              addFilter={(label, accessor, type, value) =>
                addFilter(accessor, value)
              }
              possibleFilters={columns}
              data={tenanciesData}
              style={{ marginRight: '16px' }}
            />
            <ColumnsModal
              possibleColumns={columns}
              activeColumns={activeColumns}
              setActiveColumns={changeActiveColumns}
            />
          </div>
        </div>
      </div>
      <div className='filters'>
        <FilterChips
          columns={columns}
          filters={filters}
          setFilters={changeFilters}
        />
      </div>
      <div className='tenancies-list-table'>
        <Table
          activeColumns={activeColumns}
          columns={columns}
          data={tenanciesData}
          pageLimit={tenanciesMetaData.PageLimit}
          total={tenanciesMetaData.TotalTenancies}
          offset={tenanciesMetaData.Offset}
          fetchData={loadTenancies}
          onRowClick={handleRowClick}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
          setSorting={changeSorting}
          sorting={sorting}
        />
      </div>
    </div>
  )
}

export default Tenancies
