// modules
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { createBrowserHistory } from "history";
import loggerMiddleware from './middleware/logger';
import thunk from "redux-thunk";
import { routerMiddleware, connectRouter } from "connected-react-router";
import apiMiddleware from './middleware/api';
// reducers 
import general from './reducers/general';
import cases from './reducers/cases';
import tenancies from './reducers/tenancies';
import properties from './reducers/properties';
import dashboard from './reducers/dashboard';
import app from './reducers/app';
import matches from './reducers/matches';
import applications from './reducers/applications';
import showcasings from "./reducers/showcasings";
import openhouses from "./reducers/openhouses";


export const history = createBrowserHistory();
const myRouterMiddleware = routerMiddleware(history);

export function configureStore(preloadedState) {
  const middlewares = [thunk, apiMiddleware, myRouterMiddleware];
  //process.env.REACT_APP_NODE_ENV === 'development' && middlewares.push(loggerMiddleware);
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const reducers = combineReducers({
    tenancies,
    properties,
    cases,
    general,
    dashboard,
    app,
    applications,
    matches,
    showcasings,
    openhouses,
    router: connectRouter(history)
  })

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)
  return createStore(reducers, preloadedState, composedEnhancers)

}

export const store = configureStore()

export default store;