import React from 'react';
import ExtendedTable from "components/ExtendedTable";
import { useOrganization } from "features/organization";

const DataValue = ({previousMonth, selectedMonth}) => {
	if (selectedMonth == 0 && previousMonth == 0) {
		return `${selectedMonth} (${previousMonth})`;
	}

	if (selectedMonth == 0) {
		return `${selectedMonth} (⬇ ${previousMonth})`;
	}

	if (previousMonth == 0) {
		return `${selectedMonth} (⬆ ${selectedMonth})`;
	}

	const delta = Math.abs(((previousMonth - selectedMonth) / previousMonth) * 100);

	if (previousMonth > selectedMonth) {
		return `${selectedMonth} (⬇${delta.toFixed(0)}%)`;

	} else {
		return `${selectedMonth} (⬆${delta.toFixed(0)}%)`;
	}
}

const EmployeesTable = ({ data }) => {
	const { employees, blockedEmployees } = useOrganization();

	console.log(data);

	const columns = [
		{
			Header: "",
			accessor: "employeePicture",
			aggregate: "unique",
			disableGroupBy: true,
			Cell: ({ value }) => <img className="profile-picture" src={value} />
		},
		{
			Header: "Navn",
			accessor: "employeeName",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`
		},
		{
			Header: "Fremvisninger",
			accessor: "showcasings",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({value}) => <DataValue previousMonth={value.previousMonth} selectedMonth={value.selectedMonth} />
		},
		{
			Header: "Åbent hus",
			accessor: "openhouses",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({value}) => <DataValue previousMonth={value.previousMonth} selectedMonth={value.selectedMonth} />
		},
		{
			Header: "Udlejninger",
			accessor: "completedCases",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({value}) => <DataValue previousMonth={value.previousMonth} selectedMonth={value.selectedMonth} />
		},
		{
			Header: "Udlejde m2",
			accessor: "completedM2",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({value}) => <DataValue previousMonth={Number(value.previousMonth.toFixed(0))} selectedMonth={Number(value.selectedMonth.toFixed(0))} />
		},
		{
			Header: "Sager",
			accessor: "cases",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({value}) => <DataValue previousMonth={value.previousMonth} selectedMonth={value.selectedMonth} />
		}
	];

	const tableData = data.map(x => {
		const employee = [...employees, ...blockedEmployees].find(y => y.employeeId == x.employeeId);

		return {
			employeePicture: employee?.picture,
			employeeName: employee?.name,
			showcasings: x.showcasings,
			openhouses: x.openhouses,
			completedCases: x.completedCases,
			completedM2: x.completedM2,
			cases: x.cases
		};
	});

	return (
		<div className="data-container employees-table">
			<ExtendedTable
				isLoading={false}
				data={tableData}
				columns={[...columns]}
				defaultSortBy={[]}
			/>
		</div>
	);
};

export default EmployeesTable;