import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";

    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

const getAllCasesNotCompleted = async () => {

    //getAllTenancies();
    let properties = await getAllProperties();
    let tenancies = await getAllTenancies();

    const url = "cases/query";
    const promises = [
        axios.post(url, getStateFilter("Draft")),
        axios.post(url, getStateFilter("Active")),
        axios.post(url, getStateFilter("Reserved"))
    ];
    var results = await Promise.all(promises);
    const cases= results.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);

    const newCases = cases.map(c => {
        const tenancy = tenancies.find(t => t.id === c.tenancyId);
        if (tenancy !== undefined) {
            const property = properties.find(p => p.id === tenancy.propertyId);
            if (property !== undefined) {
                c.propertyTitle = property.title
                return c;
            }
        }
        return c; 
    });
    return newCases;
}

const getStateFilter = (state) => {
    return {
        "filters": [
            {
                "field": "state",
                "type": "Equals",
                "params": {
                    "value": `${state}`
                }
            }
        ]
    };
}

export const updateShowcasing = async (showCasingId, showcasing) => { 
    try {
        const result = await axios.patch(`/showcasings/${showCasingId}`, showcasing);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

export const deleteShowcasing = async (showCasingId) => { 
    try {
        await axios.delete(`/showcasings/${showCasingId}`);
    } catch (err) {
        console.error(err);
    }
};

export const getAllProperties = async () => {

    const url = "/properties";
    const firstRequest = await axios.get(url, {});
    let properties = firstRequest.data;

    if (firstRequest.headers["x-total"] > 250) {
        const promises = [];
        let offset = 250;
        const total =  firstRequest.headers["x-total"]; 

        while (offset < total) {
            const offsetUrl = url + "?offset=" + offset;
            promises.push(axios.get(offsetUrl, {}));
            offset += 250;
        }

        const resolvedPromises = await Promise.all(promises);
        const data = resolvedPromises.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);
        properties = [...properties, ...data];
        return properties;
    } else {
        return properties;
    }
}

const getAllTenancies = async () => {

    const url = "/tenancies";
    const firstRequest = await axios.get(url, {});
    let tenancies = firstRequest.data;

    if (firstRequest.headers["x-total"] > 250) {
        const promises = [];
        let offset = 250;
        const total =  firstRequest.headers["x-total"]; 

        while (offset < total) {
            const offsetUrl = url + "?offset=" + offset;
            promises.push(axios.get(offsetUrl, {}));
            offset += 250;
        }

        const resolvedPromises = await Promise.all(promises);
        const data = resolvedPromises.reduce((accumulator, currentValue) => accumulator.concat(currentValue.data), []);
        tenancies = [...tenancies, ...data];
        return tenancies;
    } else {
        return tenancies;
    }
}

export const getTenancy = async (id) => {
    const url = `tenancies/${id}`;
    const tenancy = await axios.get(url, {});
    return tenancy; 
}

export const createShowcasing = async (tenancyId, data) => {
    const url = `tenancies/${tenancyId}/showcasings`;
    const result = await axios.post(url, {...data});
    if (result.status === 200){
        return true;
    }
    else {
        return false;
    }
}

export const getShowcasing = async (showcasingId) => {
    const url = `showcasings/${showcasingId}`;
    const result = await axios.get(url);
    if (result.status === 200){
        return result.data

    }
    else {
        return false;
    }
}


export default getAllCasesNotCompleted;
