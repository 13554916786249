import Icon from "components/Icon";
import React from "react";

const columns = [
	{
		Header: "",
		accessor: "picture",
		aggregate: "unique",
		disableGroupBy: true,
		Cell: ({ value }) => <img className="profile-picture" alt="" src={value} />
	},
	{
		Header: "Navn",
		accessor: "name",
		aggregate: "unique",
		disableGroupBy: true,
	},
	{
		Header: "Email",
		accessor: "email",
		disableGroupBy: true,
	},
	{
		Header: "Telefonnummer",
		accessor: "phoneNumber",
		disableGroupBy: true,
	},
	{
		Header: "Outlook",
		accessor: "outlookConnected",
		disableGroupBy: true,
		Cell: ({ value }) => {
			if (value) {
				return <Icon className="integration-icon" type="checkmark" />
			} else {
				return <Icon className="integration-icon disabled" type="remove" />
			}
		}
	},
	{
		Header: "Penneo",
		accessor: "penneoConnected",
		disableGroupBy: true,
		Cell: ({ value }) => {
			if (value) {
				return <Icon className="integration-icon" type="checkmark" />
			} else {
				return <Icon className="integration-icon disabled" type="remove" />
			}
		}
	}
];

export default columns;