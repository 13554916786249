const PhoneExtensions = [
    {
        "value": "+93",
        "country": "Afghanistan",
        "label": "Afghanistan (+93)"
    },
    {
        "value": "+355",
        "country": "Albania",
        "label": "Albania (+355)"
    },
    {
        "value": "+213",
        "country": "Algeria",
        "label": "Algeria (+213)"
    },
    {
        "value": "+376",
        "country": "Andorra",
        "label": "Andorra (+376)"
    },
    {
        "value": "+244",
        "country": "Angola",
        "label": "Angola (+244)"
    },
    {
        "value": "+1268",
        "country": "Antigua and Barbuda",
        "label": "Antigua and Barbuda (+1268)"
    },
    {
        "value": "+54",
        "country": "Argentina",
        "label": "Argentina (+54)"
    },
    {
        "value": "+374",
        "country": "Armenia",
        "label": "Armenia (+374)"
    },
    {
        "value": "+297",
        "country": "Aruba",
        "label": "Aruba (+297)"
    },
    {
        "value": "+61",
        "country": "Australia",
        "label": "Australia (+61)"
    },
    {
        "value": "+43",
        "country": "Austria",
        "label": "Austria (+43)"
    },
    {
        "value": "+994",
        "country": "Azerbaijan",
        "label": "Azerbaijan (+994)"
    },
    {
        "value": "+1242",
        "country": "Bahamas",
        "label": "Bahamas (+1242)"
    },
    {
        "value": "+973",
        "country": "Bahrain",
        "label": "Bahrain (+973)"
    },
    {
        "value": "+880",
        "country": "Bangladesh",
        "label": "Bangladesh (+880)"
    },
    {
        "value": "+1246",
        "country": "Barbados",
        "label": "Barbados (+1246)"
    },
    {
        "value": "+375",
        "country": "Belarus",
        "label": "Belarus (+375)"
    },
    {
        "value": "+32",
        "country": "Belgium",
        "label": "Belgium (+32)"
    },
    {
        "value": "+501",
        "country": "Belize",
        "label": "Belize (+501)"
    },
    {
        "value": "+229",
        "country": "Benin",
        "label": "Benin (+229)"
    },
    {
        "value": "+975",
        "country": "Bhutan",
        "label": "Bhutan (+975)"
    },
    {
        "value": "+591",
        "country": "Bolivia",
        "label": "Bolivia (+591)"
    },
    {
        "value": "+387",
        "country": "Bosnia and Herzegovina",
        "label": "Bosnia and Herzegovina (+387)"
    },
    {
        "value": "+267",
        "country": "Botswana",
        "label": "Botswana (+267)"
    },
    {
        "value": "+55",
        "country": "Brazil",
        "label": "Brazil (+55)"
    },
    {
        "value": "+246",
        "country": "British Indian Ocean Territory",
        "label": "British Indian Ocean Territory (+246)"
    },
    {
        "value": "+673",
        "country": "Brunei",
        "label": "Brunei (+673)"
    },
    {
        "value": "+359",
        "country": "Bulgaria",
        "label": "Bulgaria (+359)"
    },
    {
        "value": "+226",
        "country": "Burkina Faso",
        "label": "Burkina Faso (+226)"
    },
    {
        "value": "+257",
        "country": "Burundi",
        "label": "Burundi (+257)"
    },
    {
        "value": "+855",
        "country": "Cambodia",
        "label": "Cambodia (+855)"
    },
    {
        "value": "+237",
        "country": "Cameroon",
        "label": "Cameroon (+237)"
    },
    {
        "value": "+238",
        "country": "Cape Verde",
        "label": "Cape Verde (+238)"
    },
    {
        "value": "+236",
        "country": "Central African Republic",
        "label": "Central African Republic (+236)"
    },
    {
        "value": "+235",
        "country": "Chad",
        "label": "Chad (+235)"
    },
    {
        "value": "+56",
        "country": "Chile",
        "label": "Chile (+56)"
    },
    {
        "value": "+86",
        "country": "China",
        "label": "China (+86)"
    },
    {
        "value": "+57",
        "country": "Colombia",
        "label": "Colombia (+57)"
    },
    {
        "value": "+269",
        "country": "Comoros",
        "label": "Comoros (+269)"
    },
    {
        "value": "+243",
        "country": "Congo",
        "label": "Congo (+243)"
    },
    {
        "value": "+242",
        "country": "Congo",
        "label": "Congo (+242)"
    },
    {
        "value": "+506",
        "country": "Costa Rica",
        "label": "Costa Rica (+506)"
    },
    {
        "value": "+225",
        "country": "Côte d’Ivoire",
        "label": "Côte d’Ivoire (+225)"
    },
    {
        "value": "+385",
        "country": "Croatia",
        "label": "Croatia (+385)"
    },
    {
        "value": "+53",
        "country": "Cuba",
        "label": "Cuba (+53)"
    },
    {
        "value": "+599",
        "country": "Curaçao",
        "label": "Curaçao (+599)"
    },
    {
        "value": "+357",
        "country": "Cyprus",
        "label": "Cyprus (+357)"
    },
    {
        "value": "+420",
        "country": "Czech Republic",
        "label": "Czech Republic (+420)"
    },
    {
        "value": "+45",
        "country": "Danmark",
        "label": "Danmark (+45)"
    },
    {
        "value": "+253",
        "country": "Djibouti",
        "label": "Djibouti (+253)"
    },
    {
        "value": "+1767",
        "country": "Dominica",
        "label": "Dominica (+1767)"
    },
    {
        "value": "+593",
        "country": "Ecuador",
        "label": "Ecuador (+593)"
    },
    {
        "value": "+20",
        "country": "Egypt",
        "label": "Egypt (+20)"
    },
    {
        "value": "+503",
        "country": "El Salvador",
        "label": "El Salvador (+503)"
    },
    {
        "value": "+240",
        "country": "Equatorial Guinea",
        "label": "Equatorial Guinea (+240)"
    },
    {
        "value": "+291",
        "country": "Eritrea",
        "label": "Eritrea (+291)"
    },
    {
        "value": "+372",
        "country": "Estonia",
        "label": "Estonia (+372)"
    },
    {
        "value": "+251",
        "country": "Ethiopia",
        "label": "Ethiopia (+251)"
    },
    {
        "value": "+679",
        "country": "Fiji",
        "label": "Fiji (+679)"
    },
    {
        "value": "+358",
        "country": "Finland",
        "label": "Finland (+358)"
    },
    {
        "value": "+33",
        "country": "France",
        "label": "France (+33)"
    },
    {
        "value": "+594",
        "country": "French Guiana",
        "label": "French Guiana (+594)"
    },
    {
        "value": "+689",
        "country": "French Polynesia",
        "label": "French Polynesia (+689)"
    },
    {
        "value": "+241",
        "country": "Gabon",
        "label": "Gabon (+241)"
    },
    {
        "value": "+220",
        "country": "Gambia",
        "label": "Gambia (+220)"
    },
    {
        "value": "+995",
        "country": "Georgia",
        "label": "Georgia (+995)"
    },
    {
        "value": "+49",
        "country": "Germany",
        "label": "Germany (+49)"
    },
    {
        "value": "+233",
        "country": "Ghana",
        "label": "Ghana (+233)"
    },
    {
        "value": "+30",
        "country": "Greece",
        "label": "Greece (+30)"
    },
    {
        "value": "+1473",
        "country": "Grenada",
        "label": "Grenada (+1473)"
    },
    {
        "value": "+590",
        "country": "Guadeloupe",
        "label": "Guadeloupe (+590)"
    },
    {
        "value": "+1671",
        "country": "Guam",
        "label": "Guam (+1671)"
    },
    {
        "value": "+502",
        "country": "Guatemala",
        "label": "Guatemala (+502)"
    },
    {
        "value": "+224",
        "country": "Guinea",
        "label": "Guinea (+224)"
    },
    {
        "value": "+245",
        "country": "Guinea-Bissau",
        "label": "Guinea-Bissau (+245)"
    },
    {
        "value": "+592",
        "country": "Guyana",
        "label": "Guyana (+592)"
    },
    {
        "value": "+509",
        "country": "Haiti",
        "label": "Haiti (+509)"
    },
    {
        "value": "+504",
        "country": "Honduras",
        "label": "Honduras (+504)"
    },
    {
        "value": "+852",
        "country": "Hong Kong",
        "label": "Hong Kong (+852)"
    },
    {
        "value": "+36",
        "country": "Hungary",
        "label": "Hungary (+36)"
    },
    {
        "value": "+354",
        "country": "Iceland",
        "label": "Iceland (+354)"
    },
    {
        "value": "+91",
        "country": "India",
        "label": "India (+91)"
    },
    {
        "value": "+62",
        "country": "Indonesia",
        "label": "Indonesia (+62)"
    },
    {
        "value": "+98",
        "country": "Iran",
        "label": "Iran (+98)"
    },
    {
        "value": "+964",
        "country": "Iraq",
        "label": "Iraq (+964)"
    },
    {
        "value": "+353",
        "country": "Ireland",
        "label": "Ireland (+353)"
    },
    {
        "value": "+972",
        "country": "Israel",
        "label": "Israel (+972)"
    },
    {
        "value": "+39",
        "country": "Italy",
        "label": "Italy (+39)"
    },
    {
        "value": "+1876",
        "country": "Jamaica",
        "label": "Jamaica (+1876)"
    },
    {
        "value": "+81",
        "country": "Japan",
        "label": "Japan (+81)"
    },
    {
        "value": "+962",
        "country": "Jordan",
        "label": "Jordan (+962)"
    },
    {
        "value": "+254",
        "country": "Kenya",
        "label": "Kenya (+254)"
    },
    {
        "value": "+686",
        "country": "Kiribati",
        "label": "Kiribati (+686)"
    },
    {
        "value": "+383",
        "country": "Kosovo",
        "label": "Kosovo (+383)"
    },
    {
        "value": "+965",
        "country": "Kuwait",
        "label": "Kuwait (+965)"
    },
    {
        "value": "+996",
        "country": "Kyrgyzstan",
        "label": "Kyrgyzstan (+996)"
    },
    {
        "value": "+856",
        "country": "Laos",
        "label": "Laos (+856)"
    },
    {
        "value": "+371",
        "country": "Latvia",
        "label": "Latvia (+371)"
    },
    {
        "value": "+961",
        "country": "Lebanon",
        "label": "Lebanon (+961)"
    },
    {
        "value": "+266",
        "country": "Lesotho",
        "label": "Lesotho (+266)"
    },
    {
        "value": "+231",
        "country": "Liberia",
        "label": "Liberia (+231)"
    },
    {
        "value": "+218",
        "country": "Libya",
        "label": "Libya (+218)"
    },
    {
        "value": "+423",
        "country": "Liechtenstein",
        "label": "Liechtenstein (+423)"
    },
    {
        "value": "+370",
        "country": "Lithuania",
        "label": "Lithuania (+370)"
    },
    {
        "value": "+352",
        "country": "Luxembourg",
        "label": "Luxembourg (+352)"
    },
    {
        "value": "+853",
        "country": "Macau",
        "label": "Macau (+853)"
    },
    {
        "value": "+389",
        "country": "Macedonia",
        "label": "Macedonia (+389)"
    },
    {
        "value": "+261",
        "country": "Madagascar",
        "label": "Madagascar (+261)"
    },
    {
        "value": "+265",
        "country": "Malawi",
        "label": "Malawi (+265)"
    },
    {
        "value": "+60",
        "country": "Malaysia",
        "label": "Malaysia (+60)"
    },
    {
        "value": "+960",
        "country": "Maldives",
        "label": "Maldives (+960)"
    },
    {
        "value": "+223",
        "country": "Mali",
        "label": "Mali (+223)"
    },
    {
        "value": "+356",
        "country": "Malta",
        "label": "Malta (+356)"
    },
    {
        "value": "+692",
        "country": "Marshall Islands",
        "label": "Marshall Islands (+692)"
    },
    {
        "value": "+596",
        "country": "Martinique",
        "label": "Martinique (+596)"
    },
    {
        "value": "+222",
        "country": "Mauritania",
        "label": "Mauritania (+222)"
    },
    {
        "value": "+230",
        "country": "Mauritius",
        "label": "Mauritius (+230)"
    },
    {
        "value": "+52",
        "country": "Mexico",
        "label": "Mexico (+52)"
    },
    {
        "value": "+691",
        "country": "Micronesia",
        "label": "Micronesia (+691)"
    },
    {
        "value": "+373",
        "country": "Moldova",
        "label": "Moldova (+373)"
    },
    {
        "value": "+377",
        "country": "Monaco",
        "label": "Monaco (+377)"
    },
    {
        "value": "+976",
        "country": "Mongolia",
        "label": "Mongolia (+976)"
    },
    {
        "value": "+382",
        "country": "Montenegro",
        "label": "Montenegro (+382)"
    },
    {
        "value": "+212",
        "country": "Morocco",
        "label": "Morocco (+212)"
    },
    {
        "value": "+258",
        "country": "Mozambique",
        "label": "Mozambique (+258)"
    },
    {
        "value": "+95",
        "country": "Myanmar",
        "label": "Myanmar (+95)"
    },
    {
        "value": "+264",
        "country": "Namibia",
        "label": "Namibia (+264)"
    },
    {
        "value": "+674",
        "country": "Nauru",
        "label": "Nauru (+674)"
    },
    {
        "value": "+977",
        "country": "Nepal",
        "label": "Nepal (+977)"
    },
    {
        "value": "+31",
        "country": "Netherlands",
        "label": "Netherlands (+31)"
    },
    {
        "value": "+687",
        "country": "New Caledonia",
        "label": "New Caledonia (+687)"
    },
    {
        "value": "+64",
        "country": "New Zealand",
        "label": "New Zealand (+64)"
    },
    {
        "value": "+505",
        "country": "Nicaragua",
        "label": "Nicaragua (+505)"
    },
    {
        "value": "+227",
        "country": "Niger",
        "label": "Niger (+227)"
    },
    {
        "value": "+234",
        "country": "Nigeria",
        "label": "Nigeria (+234)"
    },
    {
        "value": "+850",
        "country": "North Korea",
        "label": "North Korea (+850)"
    },
    {
        "value": "+47",
        "country": "Norway",
        "label": "Norway (+47)"
    },
    {
        "value": "+968",
        "country": "Oman",
        "label": "Oman (+968)"
    },
    {
        "value": "+92",
        "country": "Pakistan",
        "label": "Pakistan (+92)"
    },
    {
        "value": "+680",
        "country": "Palau",
        "label": "Palau (+680)"
    },
    {
        "value": "+970",
        "country": "Palestine",
        "label": "Palestine (+970)"
    },
    {
        "value": "+507",
        "country": "Panama",
        "label": "Panama (+507)"
    },
    {
        "value": "+675",
        "country": "Papua New Guinea",
        "label": "Papua New Guinea (+675)"
    },
    {
        "value": "+595",
        "country": "Paraguay",
        "label": "Paraguay (+595)"
    },
    {
        "value": "+51",
        "country": "Peru",
        "label": "Peru (+51)"
    },
    {
        "value": "+63",
        "country": "Philippines",
        "label": "Philippines (+63)"
    },
    {
        "value": "+48",
        "country": "Poland",
        "label": "Poland (+48)"
    },
    {
        "value": "+351",
        "country": "Portugal",
        "label": "Portugal (+351)"
    },
    {
        "value": "+974",
        "country": "Qatar",
        "label": "Qatar (+974)"
    },
    {
        "value": "+262",
        "country": "Réunion",
        "label": "Réunion (+262)"
    },
    {
        "value": "+40",
        "country": "Romania",
        "label": "Romania (+40)"
    },
    {
        "value": "+7",
        "country": "Russia",
        "label": "Russia (+7)"
    },
    {
        "value": "+250",
        "country": "Rwanda",
        "label": "Rwanda (+250)"
    },
    {
        "value": "+1869",
        "country": "Saint Kitts and Nevis",
        "label": "Saint Kitts and Nevis (+1869)"
    },
    {
        "value": "+1758",
        "country": "Saint Lucia",
        "label": "Saint Lucia (+1758)"
    },
    {
        "value": "+1784",
        "country": "Saint Vincent and the Grenadines",
        "label": "Saint Vincent and the Grenadines (+1784)"
    },
    {
        "value": "+685",
        "country": "Samoa",
        "label": "Samoa (+685)"
    },
    {
        "value": "+378",
        "country": "San Marino",
        "label": "San Marino (+378)"
    },
    {
        "value": "+239",
        "country": "São Tomé and Príncipe",
        "label": "São Tomé and Príncipe (+239)"
    },
    {
        "value": "+966",
        "country": "Saudi Arabia",
        "label": "Saudi Arabia (+966)"
    },
    {
        "value": "+221",
        "country": "Senegal",
        "label": "Senegal (+221)"
    },
    {
        "value": "+381",
        "country": "Serbia",
        "label": "Serbia (+381)"
    },
    {
        "value": "+248",
        "country": "Seychelles",
        "label": "Seychelles (+248)"
    },
    {
        "value": "+232",
        "country": "Sierra Leone",
        "label": "Sierra Leone (+232)"
    },
    {
        "value": "+65",
        "country": "Singapore",
        "label": "Singapore (+65)"
    },
    {
        "value": "+421",
        "country": "Slovakia",
        "label": "Slovakia (+421)"
    },
    {
        "value": "+386",
        "country": "Slovenia",
        "label": "Slovenia (+386)"
    },
    {
        "value": "+677",
        "country": "Solomon Islands",
        "label": "Solomon Islands (+677)"
    },
    {
        "value": "+252",
        "country": "Somalia",
        "label": "Somalia (+252)"
    },
    {
        "value": "+27",
        "country": "South Africa",
        "label": "South Africa (+27)"
    },
    {
        "value": "+82",
        "country": "South Korea",
        "label": "South Korea (+82)"
    },
    {
        "value": "+211",
        "country": "South Sudan",
        "label": "South Sudan (+211)"
    },
    {
        "value": "+34",
        "country": "Spain",
        "label": "Spain (+34)"
    },
    {
        "value": "+94",
        "country": "Sri Lanka",
        "label": "Sri Lanka (+94)"
    },
    {
        "value": "+249",
        "country": "Sudan",
        "label": "Sudan (+249)"
    },
    {
        "value": "+597",
        "country": "Suriname",
        "label": "Suriname (+597)"
    },
    {
        "value": "+268",
        "country": "Swaziland",
        "label": "Swaziland (+268)"
    },
    {
        "value": "+46",
        "country": "Sweden",
        "label": "Sweden (+46)"
    },
    {
        "value": "+41",
        "country": "Switzerland",
        "label": "Switzerland (+41)"
    },
    {
        "value": "+963",
        "country": "Syria",
        "label": "Syria (+963)"
    },
    {
        "value": "+886",
        "country": "Taiwan",
        "label": "Taiwan (+886)"
    },
    {
        "value": "+992",
        "country": "Tajikistan",
        "label": "Tajikistan (+992)"
    },
    {
        "value": "+255",
        "country": "Tanzania",
        "label": "Tanzania (+255)"
    },
    {
        "value": "+66",
        "country": "Thailand",
        "label": "Thailand (+66)"
    },
    {
        "value": "+670",
        "country": "Timor-Leste",
        "label": "Timor-Leste (+670)"
    },
    {
        "value": "+228",
        "country": "Togo",
        "label": "Togo (+228)"
    },
    {
        "value": "+676",
        "country": "Tonga",
        "label": "Tonga (+676)"
    },
    {
        "value": "+1868",
        "country": "Trinidad and Tobago",
        "label": "Trinidad and Tobago (+1868)"
    },
    {
        "value": "+216",
        "country": "Tunisia",
        "label": "Tunisia (+216)"
    },
    {
        "value": "+90",
        "country": "Turkey",
        "label": "Turkey (+90)"
    },
    {
        "value": "+993",
        "country": "Turkmenistan",
        "label": "Turkmenistan (+993)"
    },
    {
        "value": "+688",
        "country": "Tuvalu",
        "label": "Tuvalu (+688)"
    },
    {
        "value": "+256",
        "country": "Uganda",
        "label": "Uganda (+256)"
    },
    {
        "value": "+380",
        "country": "Ukraine",
        "label": "Ukraine (+380)"
    },
    {
        "value": "+971",
        "country": "United Arab Emirates",
        "label": "United Arab Emirates (+971)"
    },
    {
        "value": "+44",
        "country": "United Kingdom",
        "label": "United Kingdom (+44)"
    },
    {
        "value": "+1",
        "country": "North America",
        "label": "North America (+1)"
    },
    {
        "value": "+598",
        "country": "Uruguay",
        "label": "Uruguay (+598)"
    },
    {
        "value": "+998",
        "country": "Uzbekistan",
        "label": "Uzbekistan (+998)"
    },
    {
        "value": "+678",
        "country": "Vanuatu",
        "label": "Vanuatu (+678)"
    },
    {
        "value": "+58",
        "country": "Venezuela",
        "label": "Venezuela (+58)"
    },
    {
        "value": "+84",
        "country": "Vietnam",
        "label": "Vietnam (+84)"
    },
    {
        "value": "+967",
        "country": "Yemen",
        "label": "Yemen (+967)"
    },
    {
        "value": "+260",
        "country": "Zambia",
        "label": "Zambia (+260)"
    },
    {
        "value": "+263",
        "country": "Zimbabwe",
        "label": "Zimbabwe (+263)"
    }
]

export default PhoneExtensions;