import React, { useState } from "react";
import "./index.scss";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import { NavLink, matchPath, useLocation } from 'react-router-dom';
import GlobalSearch from "./Components/GlobalSearch";
import { ReactComponent as JoratoIcon } from "assets/icons/jorato-logo.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/jorato-dashboard.svg";
import { ReactComponent as CasesIcon } from "assets/icons/jorato-cases.svg";
import { ReactComponent as InteractionsIcon } from "assets/icons/jorato-tenants.svg";
import { ReactComponent as UnitsIcon } from "assets/icons/jorato-units.svg";
import { ReactComponent as ReportsIcon } from "assets/icons/jorato-reports.svg";
import { ReactComponent as EllipseIcon } from "assets/icons/jorato-ellipse.svg";

export default function Navigation(props) {
	
	const location = useLocation()

	return (
		<div className="nav-container">
			<JoratoIcon className="logo" />
			<NavLink className="main-element" to="/dashboard">
				<div className="main-element-container">
					<DashboardIcon className="icon" />Dashboard
				</div>
			</NavLink>
			<NavLink 
				className="main-element" 
				to="/cases/active"
				isActive={() => ['/cases/draft', '/cases/active', '/cases/reserved', '/cases/completed', '/cases/deleted'].includes(location.pathname)}
			>
				<div className="main-element-container">
					<CasesIcon className="icon" />Udlejningssager
				</div>
				<div className="sub-menu-container">
					<NavLink className="sub-element" to="/cases/draft">Kladder</NavLink>
					<NavLink className="sub-element" to="/cases/active">Aktive</NavLink>
					<NavLink className="sub-element" to="/cases/reserved">Reserverede</NavLink>
					<NavLink className="sub-element" to="/cases/completed">Afsluttede</NavLink>
					<NavLink className="sub-element" to="/cases/deleted">Slettede</NavLink>
				</div>
			</NavLink>
			<NavLink 
				className="main-element" 
				to="/showcasings"
				isActive={() => ['/showcasings', '/showcasings/create', '/showcasings/edit', '/openhouses', '/interestlists', '/screening'].includes(location.pathname)}
			>
				<div className="main-element-container">
					<InteractionsIcon className="icon" />Lejere
				</div>
				<div className="sub-menu-container">
					<NavLink className="sub-element" to="/showcasings">Fremvisninger</NavLink>
					<NavLink className="sub-element" to="/openhouses">Åbent hus</NavLink>
					{/* <NavLink className="sub-element" to="/interestlists">Interesseliste</NavLink> */}
					<NavLink className="sub-element" to="/screening">Screening</NavLink>
				</div>
			</NavLink>
			<NavLink 
				className="main-element" 
				to="/properties"
				isActive={() => ['/properties', '/tenancies'].includes(location.pathname)}
			>
				<div className="main-element-container">
					<UnitsIcon className="icon" />Enheder
				</div>
				<div className="sub-menu-container">
					<NavLink className="sub-element" to="/properties">Ejendomme</NavLink>
					<NavLink className="sub-element" to="/tenancies">Lejemål</NavLink>
				</div>
			</NavLink>
			<NavLink className="main-element" to="/reports">
				<div className="main-element-container">
					<ReportsIcon className="icon" />Reporter
				</div>
			</NavLink>
		</div>	
	);
}
