import { TableCell, TableRow } from "@material-ui/core";
import ResourceTable from "components/ResourceTable";
import Typography from "components/Typography";
import React from "react";
import Paper from "components/StyledPaper";
import AttributeField from "./AttributeField";
import NewAttributeRow from "./NewAttributeRow";
import Icon from "components/Icon";

const Facilities = props => {

    const { facilities, createAttribute, removeAttribute, updateAttribute, attributeDefinitions, readOnly } = props;

    const closeFormHandler = () => {
    }

    const facilityForm = (
        <NewAttributeRow
            existingAttributes={facilities}
            attributeDefinitions={attributeDefinitions}
            createAttribute={createAttribute} />
    );

    return (
        <Paper className="general-information__facilities">
            <ResourceTable
                title={<Typography variant="subtitle">Faciliteter</Typography>}
                readOnly={readOnly}
                headers={["Type", "Værdi", ""]}
                sizes={["40%", "40%", "20%"]}
                form={facilityForm}
                onFormClose={closeFormHandler}>
                {facilities.map((facility, ix) => (
                    <TableRow key={ix}>
                        <TableCell>
                            {facility.definition.description}
                        </TableCell>
                        <TableCell>
                            <AttributeField
                                type={facility.definition.kind}
                                value={facility.value}
                                readOnly={readOnly}
                                options={facility.definition.options}
                                optionId={facility.optionId}
                                onChange={(data) => updateAttribute(facility.definition.id, { value: data })} />
                        </TableCell>
                        <TableCell className="actions-cell">
                            <Icon onClick={() => removeAttribute(facility.definition.id)} type="bin" />
                        </TableCell>
                    </TableRow>
                ))}
            </ResourceTable>
        </Paper>
    );
};

export default Facilities;