import React from "react";
import { styled } from "@material-ui/core/styles";
import MaterialTextField from "@material-ui/core/TextField";

const TextField = React.forwardRef((props, ref) => {

    const { multiline, value, disabled, type, onChange, onFinish, onExit, placeholder, defaultValue } = props;

    const handleOnKeyDown = (event) => {
        if (event.key === "Escape" && onExit) {
            onExit();
        }

        if (!event.shiftKey && event.key === "Enter" && onFinish) {
            onFinish();
        }

        if (event.key === "Escape" || (event.key === "Enter" && !event.shiftKey)) {
            event.stopPropagation();
            event.preventDefault();
            event.nativeEvent.stopImmediatePropagation();
            event.target.blur();
        }
    };

    const handleOnBlur = (event) => {
        if (onFinish != null) onFinish();
    };

    const handleOnChange = (event) => {
        if (onChange != null) onChange(event);
    }

    return (
        <MaterialTextField
            disabled={disabled}
            multiline={multiline}
            rows={props.rows}
            rowsMax={props.rowsMax}
            variant="outlined"
            type={type}
            value={value}
            className={`${props.className} ${value && !disabled ? "has-value" : ""}`}
            onChange={(e) => handleOnChange(e)}
            onBlur={(e) => handleOnBlur(e)}
            onKeyDown={(e) => handleOnKeyDown(e)}
            InputProps={{
                endAdornment: props.endAdornment && <div className="endAdornment">{props.endAdornment}</div>
            }}
            placeholder={placeholder}
            ref={ref}
            defaultValue={defaultValue}
        />
    );

});

export default styled(TextField)({
    '&': {
        borderRadius: 30,
        border: "1px solid #A8A8A8",
        margin: "8px 0px"
    },
    "& .MuiInputBase-root input": {
        color: "rgba(0, 0, 0, 0.87)",
        border: "none",
        fontFamily: "Montserrat",
        padding: "14px 16px 14px 30px",
        fontSize: "16px !important"
    },
    "& .Mui-disabled input, & .Mui-disabled textarea": {
        color: "rgba(0, 0, 0, 0.38)",
        cursor: "not-allowed"
    },
    "& .MuiInputBase-root input[type=datetime-local]": {
        color: "rgba(0, 0, 0, 0.5)",
    },
    "& .MuiInputBase-root textarea": {
        border: "none",
        fontFamily: "Montserrat",
        padding: "24.5px 14px 14.5px 30px !important",
        fontSize: "16px !important",
        fontWeight: "400"
    },
    '&.has-value': {
        border: "1px solid #566FFF",
    },
    '&.has-value input, &.has-value input[type=datetime-local]': {
        color: "#566FFF",
    },
    '&.has-value textarea': {
        color: "#566FFF",
    },
    "& fieldset": {
        border: "none !important"
    },
    "& input[type='date']::-webkit-calendar-picker-indicator": {
        filter: "invert(0.7)"
    },
    "& div.endAdornment": {
        fontSize: "16px"
    },
    '&.has-value .endAdornment': {
        color: "#566FFF"
    },
});