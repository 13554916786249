import React from 'react';
import './index.scss'
import Chip from '@material-ui/core/Chip';
import ChipFilter from 'components/ChipFilter';
import { translateString } from "helpers/index";

const FilterChips = ({ columns, filters, setFilters }) => {

	const getChipFromFilter = (f, i) => {
		const column = columns.find(column => column.accessor === f.accessor);
		if (!column) {
			return null;
		}

		console.log(column)

		if (column.filter === "date-range" && f.value === "noValue") {
			return <ChipFilter key={i}  label={`${column.Header}: Ingen værdi`} onDelete={() => { handleDeleteFilter(i) }}/>;
		}
		if (column.filter === "between" || column.filter === "date-range") {
			if (f.value.min !== "" && f.value.max === "") {
				return <ChipFilter key={i} label={`${column.Header}: Minimum ${f.value.min}`} onDelete={() => { handleDeleteFilter(i) }}/>;
			} else if (f.value.min === "" && f.value.max !== "") {
				return <ChipFilter  key={i}  label={`${column.Header}: Maksimum ${f.value.max}`} onDelete={() => { handleDeleteFilter(i) }}/>;
			} else if (f.value.min !== "" && f.value.max !== "") {
				return <ChipFilter key={i} label={`${column.Header}: Fra ${f.value.min} til ${f.value.max}`} onDelete={() => { handleDeleteFilter(i) }}/>;
			}
		} else if (column.filter === "exact") {
			return <ChipFilter  key={i}  label={`${column.Header}: ${f.value}`} onDelete={() => { handleDeleteFilter(i) }}/>
		} else if (column.filter === "contains") {
			return <ChipFilter  key={i}  label={`${column.Header}: Indeholder "${f.value}"`} onDelete={() => { handleDeleteFilter(i) }}/>
		} else if (column.filter === "boolean") {
			return <ChipFilter  key={i}  label={`${column.Header}: ${f.value}`} onDelete={() => { handleDeleteFilter(i) }}/>
		} else if (column.filter === "equal") {
			let label = translateString(f.value);
			if (column.options) {
				const option = column.options.find(x => x.value === f.value);
				if (option) {
					label = option.label;
				}
			}

			return <ChipFilter key={i}  label={`${column.Header}: ${label}`} onDelete={() => { handleDeleteFilter(i) }}  />
		}
	}

	const handleDeleteFilter = (i) => {
		const temp = [...filters]
		temp.splice(i, 1);
		setFilters([...temp]);
	}

	return (
		<div className="chips-container">
			{filters.map((f, index) => getChipFromFilter(f, index))}
		</div>
	);
}

export default FilterChips;
