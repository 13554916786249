import { FETCH_TENANCIES, SET_TENANCY_DOCUMENT, DELETE_TENANCY_DOCUMENT, FETCH_TENANCY, SET_TENANCY_IMAGE, UPDATE_TENANCY_TYPES, DELETE_TENANCY_IMAGE, SET_TENANCY_FLOOR_PLAN, DELETE_TENANCY_FLOOR_PLAN, SET_TENANCIES_EXPENSE, DELETE_TENANCIES_EXPENSE, DELETE_TENANCIES_CONTACT, SET_TENANCIES_ATTRIBUTE, SET_TENANCIES_CONTACT, DELETE_TENANCIES_ATTRIBUTE, UPDATE_TENANCY_LOCATIONS } from "redux/actionTypes";

const tenancies = (
  state = {
    list: {
      data: [],
      loaded: false
    },
    selected: {
      data: [],
      loaded: false
    },
    error: undefined
  },
  action
) => {
  switch (action.type) {
    case DELETE_TENANCY_IMAGE: {
      const newImages = state.selected.data.images.filter(image => image.id !== action.payload.id);
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            images: newImages
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case SET_TENANCY_DOCUMENT: {
      const alreadyExisting = state.selected.data.documents.find((document) => {
        return document.id === action.payload.document.id;
      });
      let newDocuments = [];
      if (alreadyExisting) {
        newDocuments = state.selected.data.documents
          .map((image) =>
            image.id === action.payload.document.id ? action.payload.document : document
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newDocuments = [...state.selected.data.documents, action.payload.document];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }

    case DELETE_TENANCY_DOCUMENT: {
      const newDocuments = state.selected.data.documents.filter(
        (document) => document.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          ...state.selected,
          loaded: true,
          data: {
            ...state.selected.data,
            documents: newDocuments,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_TENANCIES_ATTRIBUTE: {
      const alreadyExisting = state.selected.data.attributes.find(attribute => {
        return attribute.definition.id === action.payload.definition.id
      })
      let newAttributes = [];
      if (!!alreadyExisting) {
        newAttributes = state.selected.data.attributes.map(attribute => attribute.definition.id === action.payload.definition.id ? action.payload : attribute).sort((a, b) => a.order < b.order ? -1 : 1);
      } else {
        newAttributes = [...state.selected.data.attributes, action.payload]
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            attributes: newAttributes,
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case SET_TENANCIES_CONTACT: {
      const alreadyExisting = state.selected.data.contacts.find(contact => {
        return contact.id === action.payload.id
      })
      let newContacts = [];
      if (!!alreadyExisting) {
        newContacts = state.selected.data.contacts.map(contact => contact.id === action.payload.id ? action.payload : contact).sort((a, b) => a.order < b.order ? -1 : 1);
      } else {
        newContacts = [...state.selected.data.contacts, action.payload]
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            contacts: newContacts,
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case SET_TENANCIES_EXPENSE: {
      const alreadyExisting = state.selected.data.expenses.find(expense => {
        return expense.id === action.payload.id
      })
      let newExpenses = [];
      if (!!alreadyExisting) {
        newExpenses = state.selected.data.expenses.map(expense => expense.id === action.payload.id ? action.payload : expense).sort((a, b) => a.order < b.order ? -1 : 1);
      } else {
        newExpenses = [...state.selected.data.expenses, action.payload]
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            expenses: newExpenses,
            images: [...state.selected.data.images]
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case DELETE_TENANCIES_ATTRIBUTE: {
      const newAttributes = state.selected.data.attributes.filter(attribute => attribute.definition.id !== action.payload.id);
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            attributes: newAttributes
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case DELETE_TENANCIES_EXPENSE: {
      const newExpenses = state.selected.data.expenses.filter(expense => expense.id !== action.payload.id);
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            expenses: newExpenses
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case DELETE_TENANCIES_CONTACT: {
      const newContacts = state.selected.data.contacts.filter(contact => contact.id !== action.payload.id);
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            contacts: newContacts
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case SET_TENANCY_IMAGE: {
      const alreadyExisting = state.selected.data.images.find(image => {
        return image.id === action.payload.image.id
      })
      let newImages = [];
      if (alreadyExisting) {
        newImages = state.selected.data.images.map(image => image.id === action.payload.image.id ? action.payload.image : image).sort((a, b) => a.order < b.order ? -1 : 1);
      } else {
        newImages = [...state.selected.data.images, action.payload.image]
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            images: newImages
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case UPDATE_TENANCY_LOCATIONS: {
      let newLocations = [...state.selected.data.locations];
      if (action.payload.operation === "POST") {
        newLocations.push(action.payload.location)
      } else if (action.payload.operation === "DELETE") {
        newLocations = newLocations.filter(mappedLocation => mappedLocation !== action.payload.location)
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            locations: newLocations
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case UPDATE_TENANCY_TYPES: {
      let newTypes = [...state.selected.data.types];
      if (action.payload.operation === "POST") {
        newTypes.push(action.payload.type)
      } else if (action.payload.operation === "DELETE") {
        newTypes = newTypes.filter(location => location !== action.payload.type)
      }
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            types: newTypes
          }
        },
        stats: {
          loaded: false,
          data: {}
        }
      }
    }
    case FETCH_TENANCIES:
      return {
        list: {
          data: action.payload.data,
          resHeaders: action.payload.headers,
          loaded: true
        },
        selected: {
          loaded: false
        }
      };
    case FETCH_TENANCY:
      return {
        list: {
          loaded: false
        },
        selected: {
          loaded: true,
          data: action.payload
        },
        stats: {
          loaded: false,
          data: {}
        }
      };
    case DELETE_TENANCY_FLOOR_PLAN: {
      const newImages = state.selected.data.floorPlans.filter(
        (image) => image.id !== action.payload.id
      );
      return {
        list: {
          loaded: false,
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            floorPlans: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    case SET_TENANCY_FLOOR_PLAN: {
      const alreadyExisting = state.selected.data.floorPlans.find((image) => {
        return image.id === action.payload.image.id;
      });
      let newImages = [];
      if (alreadyExisting) {
        newImages = state.selected.data.floorPlans
          .map((image) =>
            image.id === action.payload.image.id ? action.payload.image : image
          )
          .sort((a, b) => (a.order < b.order ? -1 : 1));
      } else {
        newImages = [...state.selected.data.floorPlans, action.payload.image];
      }
      return {
        list: {
          loaded: false,
        },
        selected: {
          loaded: true,
          data: {
            ...state.selected.data,
            floorPlans: newImages,
          },
        },
        stats: {
          loaded: false,
          data: {},
        },
      };
    }
    default:
      return state;
  }
};

export default tenancies;
