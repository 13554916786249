import { useState, useCallback } from "react";
import produce from "immer";
import TenanciesService from "services/api/tenancies";

/*
    useTenancyAnalytics loads tenancy's analytics data. 
*/
const useTenancyAnalytics = () => {

    const [isAnalyticsLoading, setAnalyticsLoading] = useState(true);
    const [analyticsData, setAnalyticsData] = useState(null);

    const loadTenancyAnalytics = useCallback(async (tenancyId) => {
        const result = await TenanciesService.getTenancyAnalytics(tenancyId);
        if (result !== null) {
            setAnalyticsData({
                ...result.data
            });
        }

        setAnalyticsLoading(false);
    }, []);

    return {
		isAnalyticsLoading,
		analyticsData,
		loadTenancyAnalytics
    }
};

export default useTenancyAnalytics;