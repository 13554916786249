import React, { useState } from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import { PrimaryButton } from "components/Buttons";
import { TextField } from "components/Fields";
import ContractsService from "services/api/contracts";
import LoadingComponent from "components/LoadingComponent";

const SendApplicationLinkModal = ({ isEnabled, onClose, caseId }) => {

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [isSending, setSending] = useState(false);

	const send = async () => {
		setSending(true);
		const result = await ContractsService.sendApplicatonLink(name, email, caseId);
		setSending(false);
		onClose();
	};

	return (
		<FullScreenModal
			className="case__send-application-link-modal"
			open={isEnabled}
			onClose={onClose}>
			<Typography variant="subtitle">Send Application Link</Typography>
			<div className="fields-section__field">
				<Typography variant="label">Name</Typography>
				<TextField
					value={name}
					type="text"
					onChange={(event) => setName(event.target.value)}
				/>
			</div>
			<div className="fields-section__field">
				<Typography variant="label">Email</Typography>
				<TextField
					value={email}
					type="text"
					onChange={(event) => setEmail(event.target.value)}
				/>
			</div>
			{isSending
				? <PrimaryButton><LoadingComponent /></PrimaryButton>
				: <PrimaryButton disabled={email.length == 0 || name.length == 0} onClick={send}>Godkend</PrimaryButton>
			}
		</FullScreenModal>
	);
};

export default SendApplicationLinkModal;