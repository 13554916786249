const reducer = (draft, action) => {
	switch (action.type) {
		case "SET_CASE_QUERY":
			return void (draft.caseQuery = action.payload);
		case "SET_CASE_ID":
			return void (draft.caseId = action.payload);
		case "SET_START_DATE":
			return void (draft.startDate = action.payload);
		case "SET_START_TIME":
			return void (draft.startTime = action.payload);
		case "SET_HOST_ID":
			return void (draft.hostId = action.payload);
		case "SET_DURATION":
			return void (draft.duration = action.payload);
		case "SET_GUEST_NAME":
			return void (draft.guestName = action.payload);
		case "SET_GUEST_EMAIL":
			return void (draft.guestEmail = action.payload);
		case "SET_COMMUNICATION_LANGUAGE":
			return void (draft.communicationLanguage = action.payload);
		case "SET_GUEST_PHONE_EXTENSION":
			return void (draft.guestPhoneExtension = action.payload);
		case "SET_GUEST_PHONE_NUMBER":
			return void (draft.guestPhoneNumber = action.payload);
		case "SET_IS_VIRTUAL":
			if (draft.hasAlternativeMeetingLocation === true) {
				draft.hasAlternativeMeetingLocation = false;
			}
			return void (draft.isVirtual = action.payload);
		case "SET_HAS_ALTERNATIVE_MEETING_LOCATION":
			if (draft.isVirtual === true) {
				draft.isVirtual = false;
			}
			return void (draft.hasAlternativeMeetingLocation = action.payload);
		case "SET_ALTERNATIVE_MEETING_LOCATION":
			return void (draft.alternativeMeetingLocation = action.payload);
		case "SET_STEP":
			return void (draft.step = action.payload);
		case "LOAD_SHOWCASING":
			const d = new Date(action.payload.schedule.startsAt);
			const hours = d.getHours();
			const minutes = d.getMinutes();
			const twoDigitHour = ('0' + (hours)).slice(-2);
			const twoDigitMinute = ('0' + (minutes)).slice(-2);
			draft.startTime = twoDigitHour + ":" + twoDigitMinute;
			draft.startDate = new Date(action.payload.schedule.startsAt);
			draft.duration = action.payload.schedule.duration.toString();
			draft.hostId = action.payload.hostId;
			draft.guestName = action.payload.guest.name;
			draft.guestEmail = action.payload.guest.email;
			draft.communicationLanguage = action.payload.communicationLanguage;
			draft.guestPhoneExtension = action.payload.guest.phone.extension;
			draft.guestPhoneNumber = action.payload.guest.phone.number;
			if (action.payload.meetingLocation) {
				draft.hasAlternativeMeetingLocation = true;
				draft.alternativeMeetingLocation = action.payload.meetingLocation;
			}
			return void (draft.isVirtual = action.payload.isOnline);
	}
};

export default reducer;