import React from "react";
import ExtendedTable from "components/ExtendedTable";
import LoadingComponent from "components/LoadingComponent";
import { formatDate } from 'helpers/date';

const ReportContent = ({ isLoading, data }) => {

	if (isLoading) {
		return (
			<div className="loading-container"><LoadingComponent /></div>
		);
	}

	const columns = [
		{
			Header: "Title",
			accessor: "property.title",
			aggregate: "unique",
			disableGroupBy: true,
		},
		{
			Header: "Street",
			accessor: "address.street",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`
		},
		{
			Header: "City",
			accessor: "address.city",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`
		},
		{
			Header: "ZipCode",
			accessor: "address.zipCode",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`
		}
	];

	const reportColumns = data.tenancies.length > 0 ? data.tenancies[0].data.map(x => {

		const fromDate = new Date(x.from);
		const toDate = new Date(x.to);

		if (data.interval == "weekly") {
			return {
				Header: () => (
					<div className="date-column">
						<div className="date-column-title">Uge {x.week}</div>
						<div className="date-column-subtitle">{formatDate(fromDate, "dd/MM")} - {formatDate(toDate, "dd/MM")}</div>
					</div>
				),
				accessor: `week${x.week}-${x.year}`,
				aggregate: "sum",
				disableGroupBy: true,
				Cell: ({ value }) => <div className="date-column-result">{value}</div>
			}
		} else {
			return {
				Header: () => (
					<div className="date-column">
						<div className="date-column-title">{formatDate(fromDate, 'LLLL')}</div>
						<div className="date-column-subtitle">{x.year}</div>
					</div>
				),
				accessor: `month${x.month}-${x.year}`,
				aggregate: "sum",
				disableGroupBy: true,
				Cell: ({ value }) => <div className="date-column-result">{value}</div>
			}
		}
	}) : [];

	const sumColumn = {
		Header: () => <div className="date-column">Sum</div>,
		accessor: `sum`,
		aggregate: "sum",
		disableGroupBy: true,
		Cell: ({ value }) => <div className="date-column-result">{value}</div>
	}

	const tableData = data.tenancies.map(x => {
		const res = {
			id: x.id,
			address: x.address,
			property: x.property
		}

		let sum = 0;

		for (let i = 0; i < x.data.length; i++) {
			if (data.interval == "weekly") {
				res[`week${x.data[i].week}-${x.data[i].year}`] = x.data[i].showcasings; 
			} else {
				res[`month${x.data[i].month}-${x.data[i].year}`] = x.data[i].showcasings; 
			}

			sum = sum + x.data[i].showcasings;
		}

		res["sum"] = sum;

		return res;
	});

	return (
		<ExtendedTable
			isLoading={isLoading}
			data={tableData}
			columns={[...columns, ...reportColumns, sumColumn]}
			defaultSortBy={[]}
			defaultGroupBy={["property.title"]}
		/>
	);
};

export default ReportContent;