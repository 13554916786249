import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import { translateString, locationTypes } from "helpers";
import { Switch } from "components/Fields";
import GoogleMaps from "components/GoogleMaps";

const Location = props => {

    const { tenancyData, updateTenancy, addTenancyLocationPoint, removeTenancyLocationPoint } = props;

    const [street, setStreet] = useState(tenancyData.address.street ?? "");
    const [zipCode, setZipCode] = useState(tenancyData.address.zipCode ?? "");
    const [city, setCity] = useState(tenancyData.address.city ?? "");

    return (
        <Paper className="general-information__location">
            <div className="wrapper">
                <Typography variant="subtitle">Lokation</Typography>
                <div className="fields">
                    <div className="fields-section__field">
                        <Typography variant="label">Adresse</Typography>
                        <TextField
                            value={street}
                            onChange={(event) => setStreet(event.target.value)}
                            onExit={() => setStreet(tenancyData.address.street)}
                            onFinish={() => {
                                street !== tenancyData.address.street && updateTenancy({ address: { street: street } })
                            }}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Postnummer</Typography>
                        <TextField
                            value={zipCode}
                            onChange={(event) => setZipCode(event.target.value)}
                            onExit={() => setZipCode(tenancyData.address.zipCode)}
                            onFinish={() => {
                                zipCode !== tenancyData.address.zipCode && updateTenancy({ address: { zipCode: zipCode } })
                            }}
                        />
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">By</Typography>
                        <TextField
                            value={city}
                            onChange={(event) => setCity(event.target.value)}
                            onExit={() => setCity(tenancyData.address.city)}
                            onFinish={() => {
                                city !== tenancyData.address.city && updateTenancy({ address: { city: city } })
                            }}
                        />
                    </div>
                </div>
                <hr />
                <div className="fields">
                    <div className="fields-section__field">
                        <Typography variant="label">Breddegrad</Typography>
                        <p className="field-value">{tenancyData.address.coordinates?.latitude}</p>
                    </div>
                    <div className="fields-section__field">
                        <Typography variant="label">Længdegrad</Typography>
                        <p className="field-value">{tenancyData.address.coordinates?.longitude}</p>
                    </div>
                </div>
                <hr />
                <div className="fields points">
                    {locationTypes
                        .sort((a, b) => (translateString(a) > translateString(b) ? 1 : -1))
                        .map((type, ix) => {
                            return (
                                <div key={ix} className="fields-section__field">
                                    <Typography variant="label">{translateString(type)}</Typography>
                                    <Switch
                                        checked={tenancyData.locations.includes(type)}
                                        onChange={(e) => {
                                            if (tenancyData.locations.includes(type)) {
                                                removeTenancyLocationPoint(type);
                                            } else {
                                                addTenancyLocationPoint(type);
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="map">
                <GoogleMaps 
                    latitude={tenancyData.address?.coordinates?.latitude}
                    longitude={tenancyData.address?.coordinates?.longitude} />
            </div>
        </Paper>
    );
};

export default Location;