import React, { useRef } from 'react'
import './index.scss'
import Select from 'react-select'

const StyledSelect2 = ({ options, value, onChange }) => {
  const inputRef = useRef(null)

  const handleChange = selectedOption => {
    onChange(selectedOption)
    inputRef.current.blur()
  }

  return (
    <Select
      ref={inputRef}
      options={options}
      value={options.filter(option => {
        return option.value === value
      })}
      onChange={(e) => handleChange(e.value)}
      className={'styled_select'}
      classNamePrefix={'styled_select'}
    />
  )
}

export default StyledSelect2
