import {
    FETCH_SHOWCASINGS
  } from "redux/actionTypes";
  
  const showcasings = (
    state = {
      showcasings: {
        data: [],
        loaded: false,
      },
    },
    action
  ) => {
    switch (action.type) {
      case FETCH_SHOWCASINGS:
        return {
          ...state,
          showcasings: {
            data: action.payload,
            loaded: true,
          },
        };
      default:
        return state;
    }
  };
  
  export default showcasings;
  