import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const customButton = (props) => {
  return <Button 
    {...props}
    disableElevation
  />
}

export default styled(customButton)({
  '&': {
    borderRadius: 30,
    padding: "10px 26px"
  },
  '& .Mui-disabled': {
    backgroundColor: "#A8A8A8"
  },
});