import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField } from "components/Fields";
import { Tooltip } from "@material-ui/core";
import "./index.scss";
import Icon from "components/Icon";
import { formatRelative } from "date-fns";
import { da } from 'date-fns/locale';
import { Switch } from "components/Fields";
import { MenuItem } from "@material-ui/core";
import { Select } from "components/Fields";

const EnergyInformation = props => {

	const { tenancyData, updateTenancy } = props;

	const [cO2EmissionPerM2, setCO2EmissionPerM2] = useState(tenancyData.energyLabel.cO2EmissionPerM2 ?? "");

	const classifications = ["Undefined", "A2020", "A2015", "A2010", "B", "C", "D", "E", "F", "G"];

	return (
		<Paper className="general-information__energy-information">
			<Typography variant="subtitle">Energimærke</Typography>
			<Typography className="updated_at_info">Opdateret kl {formatRelative(new Date(tenancyData.energyLabel.updatedAt), new Date(), { locale: da })}</Typography>
			<div className="fields">
				<div className="fields-section__field">
					<Typography variant="label">Opdater automatisk</Typography>
					<Switch
						checked={tenancyData.energyLabel.synchronizationEnabled}
						onChange={(e) => {
							updateTenancy({ energyLabel: { synchronizationEnabled: !tenancyData.energyLabel.synchronizationEnabled } })
						}}
					/>
				</div>
			</div>
			<div className="fields">
				<div className="fields-section__field">
					<Typography variant="label">
						Energimærke
						<Tooltip title="Enerimærke opdateres automatisk hver uge.">
							<Icon className="info-icon" type="info" />
						</Tooltip>
						<Tooltip title="Download energimærkerapporten.">
							<a href={tenancyData.energyLabel.energyReportUrl} target="_blank" className="document-url"><Icon className="document-icon" type="document" /></a>
						</Tooltip>
					</Typography>
					<Select
						value={tenancyData.energyLabel.energyClassification}
						disabled={tenancyData.energyLabel.synchronizationEnabled}
						onChange={(e) => {
							e.target.value !== tenancyData.energyLabel.energyClassification && updateTenancy({ energyLabel: { energyClassification: e.target.value } })
						}}>
						{classifications.map(x => (
							<MenuItem key={x} value={x}>
								{x}
							</MenuItem>

						))}
					</Select>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">CO2-udledning</Typography>
					<TextField
						value={cO2EmissionPerM2/1000}
						disabled={tenancyData.energyLabel.synchronizationEnabled}
						type="number"
						onChange={(event) => setCO2EmissionPerM2(event.target.value*1000)}
						onExit={() => setCO2EmissionPerM2(tenancyData.energyLabel.cO2EmissionPerM2)}
						onFinish={() => {
							cO2EmissionPerM2 !== tenancyData.energyLabel.cO2EmissionPerM2 && updateTenancy({ energyLabel: { cO2EmissionPerM2: cO2EmissionPerM2 } })
						}}
						endAdornment={<>kg/m2</>}
					/>
				</div>
			</div>
		</Paper>
	);
}

export default EnergyInformation;