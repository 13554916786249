import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";
import InheritedValueIcon from "../InheritedValueIcon";

const ElectricityUtility = ({ propertyDetails, tenancyDetails, updateTenancyContract }) => {

	const isLandlordResponsible = tenancyDetails.utilities?.electricity?.isLandlordResponsible ?? propertyDetails.utilities?.electricity?.isLandlordResponsible;
	const isLandlordReponsibleInherited = tenancyDetails.utilities?.electricity?.isLandlordResponsible == null || tenancyDetails.utilities?.electricity?.isLandlordResponsible == undefined;

	const hasIndividualMeters = tenancyDetails.utilities?.electricity?.hasIndividualMeters ?? propertyDetails.utilities?.electricity?.hasIndividualMeters;
	const isHasIndividualMetersInherited = tenancyDetails.utilities?.electricity?.hasIndividualMeters == null || tenancyDetails.utilities?.electricity?.hasIndividualMeters == undefined;

	const accountingStartDateInitialValue = tenancyDetails.utilities?.electricity?.accountingStartDate ?? propertyDetails.utilities?.electricity?.accountingStartDate ?? "";
	const isAccountingStartDateInherited = !tenancyDetails.utilities?.electricity?.accountingStartDate;
	const [accountingStartDate, setAccountingStartDate] = useState(accountingStartDateInitialValue);

	return (
		<>
			<Typography variant="label" className="utility-title">El</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer ansvarlig {isLandlordReponsibleInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={isLandlordResponsible}
						onChange={(e) => updateTenancyContract({ utilities: { electricity: { isLandlordResponsible: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Lejemålet har individuelle målere {isHasIndividualMetersInherited && <InheritedValueIcon />}</Typography>
					<Switch
						checked={hasIndividualMeters}
						onChange={(e) => updateTenancyContract({ utilities: { electricity: { hasIndividualMeters: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Elregnskabsåret begynder {isAccountingStartDateInherited && <InheritedValueIcon />}</Typography>
					<TextField
						value={accountingStartDate ?? ""}
						type="date"
						onChange={(event) => setAccountingStartDate(event.target.value)}
						onExit={() => setAccountingStartDate(accountingStartDateInitialValue)}
						onFinish={() => {
							accountingStartDate !== accountingStartDateInitialValue && updateTenancyContract({ utilities: { electricity: { accountingStartDate } } })
						}}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default ElectricityUtility;