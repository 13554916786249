import Dashboard from "./Dashboard";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
      tenancies: state.app.tenancies,
      properties: state.app.properties
    };
  };

export default connect(mapStateToProps)(Dashboard);
