import { useState, useCallback } from "react";
import ShowcasingsService from 'services/api/showcasings';

/*
    useShowcasing hook handles showcasing operations. 
    The only requirement for it is to load showcasing first, before doing operations.
*/
const useShowcasing = () => {

    const [isShowcasingLoading, setShowcasingLoading] = useState(true);
    const [showcasingData, setShowcasingData] = useState(null);

    const loadShowcasing = useCallback(async (showcasingId) => {
        const result = await ShowcasingsService.getShowcasing(showcasingId);
        if (result !== null) {
            setShowcasingData({
                ...result.data
            });
        }
        setShowcasingLoading(false);
    }, []);

    const updateShowcasing = useCallback(async (data) => {
        if (showcasingData == null)
            return;
		
        const result = await ShowcasingsService.updateShowcasing(showcasingData.id, data);

        if (result !== null) {
            setShowcasingData({
                ...showcasingData,
                ...result.data,
            });
        }
    }, [showcasingData]);

	const deleteShowcasing = useCallback(async () => {
        if (showcasingData == null)
            return;

        await ShowcasingsService.deleteShowcasing(showcasingData.id);
    }, [showcasingData]);

	return {
		isShowcasingLoading,
		showcasingData,
		loadShowcasing,
		updateShowcasing,
		deleteShowcasing
    }
};

export default useShowcasing;
