import React from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { useOrganization } from "features/organization";
import { Switch } from "components/Fields";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";

const Integrations = props => {

	const { caseData, enableIntegration, disableIntegration } = props;
	const { integrations, isOrganizationLoading } = useOrganization();

	const toggleBoligportal = (value) => {
		if (!value) {
			disableIntegration("boligportal");
		} else {
			enableIntegration("boligportal");
		}
	};

	const toggleLokalebasen = (value) => {
		if (!value) {
			disableIntegration("lokalebasen");
		} else {
			enableIntegration("lokalebasen");
		}
	};

	const toggleEjendomstorvet = (value) => {
		if (!value) {
			disableIntegration("ejendomstorvet");
		} else {
			enableIntegration("ejendomstorvet");
		}
	};

	const toggleBoligzonen = (value) => {
		if (!value) {
			disableIntegration("boligzonen");
		} else {
			enableIntegration("boligzonen");
		}
	};

	const toggleLejebolig = (value) => {
		if (!value) {
			disableIntegration("lejebolig");
		} else {
			enableIntegration("lejebolig");
		}
	};

	if (caseData.state === "Draft" || caseData.state === "Completed") {
		return null
	}

	const boligportalIntegration = !isOrganizationLoading && integrations?.boligportal?.integrationEnabled
		? (
			<div className="fields-section__field">
				<Typography variant="label">Boligportal</Typography>
				<div className="aligned-integration-switch">
					<Switch
						disabled={caseData.deletedAt}
						checked={caseData.integrations?.boligportal?.status !== "Disabled"}
						onChange={(e) => {
							toggleBoligportal(e.target.checked);
						}}
					/>
					{caseData.integrations.boligportal.status === "Invalid" &&
						<Tooltip title={caseData.integrations?.boligportal?.error?.message} placement="right-start"><Icon type="error" /></Tooltip>}
				</div>
			</div>
		)
		: null;

	const boligzonenIntegration = !isOrganizationLoading && integrations?.boligzonen?.enabled
		? (
			<div className="fields-section__field">
				<Typography variant="label">Boligzonen</Typography>
				<div className="aligned-integration-switch">
					<Switch
						disabled={caseData.deletedAt}
						checked={caseData.integrations?.boligzonen?.status !== "Disabled"}
						onChange={(e) => {
							toggleBoligzonen(e.target.checked);
						}}
					/>
					{caseData.integrations.boligzonen.status === "Invalid" &&
						<Tooltip title={caseData.integrations?.boligzonen?.error?.message} placement="right-start"><Icon type="error" /></Tooltip>}
				</div>
			</div>
		)
		: null;

	const lejeboligIntegration = !isOrganizationLoading && integrations?.lejebolig?.enabled
		? (
			<div className="fields-section__field">
				<Typography variant="label">Lejebolig</Typography>
				<div className="aligned-integration-switch">
					<Switch
						disabled={caseData.deletedAt}
						checked={caseData.integrations?.lejebolig?.status !== "Disabled"}
						onChange={(e) => {
							toggleLejebolig(e.target.checked);
						}}
					/>
					{caseData.integrations.lejebolig.status === "Invalid" &&
						<Tooltip title={caseData.integrations?.lejebolig?.error?.message} placement="right-start"><Icon type="error" /></Tooltip>}
				</div>
			</div>
		)
		: null;

	const businessIntegrations = (
		<>
			<div className="fields-section__field">
				<Typography variant="label">Lokalebasen</Typography>
				<div className="aligned-integration-switch">
					<Switch
						disabled={caseData.deletedAt}
						checked={caseData.integrations?.lokalebasen?.status !== "Disabled"}
						onChange={(e) => {
							toggleLokalebasen(e.target.checked);
						}}
					/>
					{caseData.integrations.lokalebasen.status === "Invalid" &&
						<Tooltip title={caseData.integrations?.lokalebasen?.error?.message} placement="right-start"><Icon type="error" /></Tooltip>}
				</div>
			</div>
			<div className="fields-section__field">
				<Typography variant="label">Ejendomstorvet</Typography>
				<div className="aligned-integration-switch">
					<Switch
						disabled={caseData.deletedAt}
						checked={caseData.integrations?.ejendomstorvet?.status !== "Disabled"}
						onChange={(e) => {
							toggleEjendomstorvet(e.target.checked);
						}}
					/>
					{caseData.integrations.ejendomstorvet.status === "Invalid" &&
						<Tooltip title={caseData.integrations?.ejendomstorvet?.error?.message} placement="right-start"><Icon type="error" /></Tooltip>}
				</div>
			</div>
		</>
	);

	return (
		<Paper className="general-information__integrations">
			<Typography variant="subtitle">Integrationer</Typography>
			<div className="integrations">
				{caseData.classification === "Residential" && (
					<>
						{boligportalIntegration}
						{boligzonenIntegration}
						{lejeboligIntegration}
					</>
				)}
				{caseData.classification === "Business" && businessIntegrations}
			</div>
		</Paper>
	);
};

export default Integrations;