import { formatNumber } from "helpers";
import { SelectColumnFilter, NumberRangeColumnFilter } from "components/ExtendedTable/config/filters.js";
import { translateString } from "helpers/index";
import fromUnixTime from 'date-fns/fromUnixTime'
import { format } from "date-fns";
import { da } from 'date-fns/locale';

const getColumns = (state, employees) => {

	const defaultColumns = [
		{
			Header: "Gade",
			accessor: "addressStreet",
			disableGroupBy: true,
			disableSortBy: false,
			disableFilter: true,
			Aggregated: ({ value }) => `-`,
			isDefault: true
		},
		{
			Header: "By",
			accessor: "addressCity",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `${value}`,
			filter: "equal",
			isDefault: true
		},
		{
			Header: "Postnummer",
			accessor: "addressZipCode",
			aggregate: "unique",
			disableGroupBy: true,
			Aggregated: ({ value }) => `${value}`,
			filter: 'between',
			isDefault: true,
			disableFilter: true
		},
		{
			Header: "Type",
			accessor: "classification",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			filter: "equal",
			isDefault: true,
			options: [
				{
					label: translateString("residential"),
					value: "residential"
				},
				{
					label: translateString("business"),
					value: "business"
				},
				{
					label: translateString("other"),
					value: "other"
				}
			],
			Cell: ({ value }) => translateString(value),
			disableFilter: true
		},
		{
			Header: "Størrelse",
			accessor: "sizeValue",
			aggregate: "sum",
			Aggregated: ({ value }) => value === 0 ? "-" : `${Math.round(value)} m2`,
			filter: 'between',
			disableGroupBy: true,
			disableSortBy: false,
			isDefault: true
		},
		{
			Header: "Værelser",
			accessor: "rooms",
			aggregate: "sum",
			Aggregated: ({ value }) => `-`,
			filter: 'between',
			disableGroupBy: true,
			disableSortBy: false,
			isDefault: true
		},
		{
			Header: "Oprettelsesdato",
			accessor: "createdAt",
			Aggregated: ({ value }) => `-`,
			filter: 'between',
			disableGroupBy: true,
			disableFilter: true,
			Cell: ({ value }) => value === 0 ? "-" : `${format(fromUnixTime(value), "d MMM yyyy HH:mm", { locale: da })}`,
		},
		{
			Header: "Husleje",
			accessor: "monthlyRent",
			Aggregated: ({ value }) => `-`,
			Filter: NumberRangeColumnFilter,
			filter: 'between',
			disableGroupBy: true,
			disableFilter: true,
			Cell: ({ value }) => value === 0 ? "-" : `${formatNumber(Math.round(value), "financial")}`,
		},
		{
			Header: "Ledig fra",
			accessor: "availableFrom",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({ value }) => value === 0 ? "-" : `${format(fromUnixTime(value), "d MMM yyyy", { locale: da })}`,
			filter: 'between',
			disableFilter: true,
		},
		{
			Header: "Tilgængelig fra",
			accessor: "accessibleFrom",
			disableGroupBy: true,
			Aggregated: ({ value }) => `-`,
			Cell: ({ value }) => value === 0 ? "-" : `${format(fromUnixTime(value), "d MMM yyyy", { locale: da })}`,
			filter: 'between',
			disableFilter: true,
		},
		{
			Header: "Synlighed",
			accessor: "visibility",
			Aggregated: ({ value }) => `-`,
			Filter: SelectColumnFilter,
			options: [
				{
					label: translateString("Invisible"),
					value: "Invisible"
				},
				{
					label: translateString("Private"),
					value: "Private"
				},
				{
					label: translateString("Public"),
					value: "Public"
				}
			],
			disableGroupBy: true,
			Cell: ({ value }) => value === null ? "-" : `${translateString(value)}`,
			filter: 'equal'
		},
		{
			Header: "Udsat",
			accessor: "postponedUntil",
			Aggregated: ({ value }) => `-`,
			disableGroupBy: true,
			Cell: ({ value }) => value === 0 ? "-" : `${format(fromUnixTime(value), "d MMM yyyy", { locale: da })}`,
			filter: "boolean",
			disableSortBy: true,
		},
		{
			Header: "Ansvarlig",
			accessor: "responsiblePerson",
			disableGroupBy: true,
			Filter: SelectColumnFilter,
			options: employees.map(x => {
				return {
					label: x.name,
					value: x.employeeId
				}
			}
			),
			Cell: ({ value }) => !value ? "-" : `${employees.find(x => x.employeeId === value)?.name}`,
			filter: "equal",
			disableSortBy: false,
		},
		{
			Header: "Kommentar",
			accessor: "lastComment",
			aggregate: "unique",
			disableGroupBy: true,
			isDefault: false,
			disableFilter: true,
			Cell: ({ value }) => !value ? "-" : value,
		},
	];

	if (state === "deleted") {
		return [...defaultColumns, ...deletedCasesColumns];
	}

	return defaultColumns;
};

const deletedCasesColumns = [
	{
		Header: "Slettet",
		accessor: "deletedAt",
		Aggregated: ({ value }) => `-`,
		disableGroupBy: true,
		Cell: ({ value }) => value === 0 ? "-" : `${format(fromUnixTime(value), "d MMM yyyy HH:mm", { locale: da })}`,
		filter: "boolean",
		disableSortBy: true,
	}
];

export default getColumns;