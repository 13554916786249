import React, { useState } from "react";
import Typography from "components/Typography";
import { translateString } from "helpers";
import { CircularProgress, MenuItem } from "@material-ui/core";
import { TextField, Select } from "components/Fields";
import { useOrganization } from "features/organization";


const BaseFields = props => {

	const { caseData, updateCase, integrationId } = props;

	const [availableFrom, setAvailableFrom] = useState(caseData.availableFrom ?? "");
	const [accessibleFrom, setAccessibleFrom] = useState(caseData.accessibleFrom ?? "");
	const [postponedUntil, setPostponedUntil] = useState(caseData.postponedUntil ?? "");

	const { isOrganizationLoading, employees, blockedEmployees } = useOrganization();

	const updatePostponedUntil = () => {
		if (postponedUntil === caseData.postponedUntil) {
			return;
		}

		if (!postponedUntil) {
			updateCase({ removePostponedUntil: true });
			return;
		}

		updateCase({ postponedUntil: postponedUntil });
	}

	return (
		<div className="fields-section">
			<div className="fields-section__state-container">
				<Typography variant="label">Status</Typography>
				<div className="state"><Typography variant="label">{caseData.deletedAt ? "Slettet" : translateString(caseData.state)}</Typography></div>
			</div>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Synlighed</Typography>
					<Select
						value={caseData.visibility}
						disabled={caseData.deletedAt}
						onChange={(e) => {
							e.target.value !== caseData.visibility && updateCase({ visibility: e.target.value })
						}}>
						<MenuItem value="Invisible">
							Ikke synlig
						</MenuItem>
						<MenuItem value="Private">
							Publiceret - ikke søgbar
						</MenuItem>
						<MenuItem value="Public">
							Publiceret - søgbar
						</MenuItem>
					</Select>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Ansvarlig</Typography>
					{!isOrganizationLoading
						? <Select
							value={caseData.responsiblePerson?.id ?? ""}
							disabled={caseData.deletedAt}
							onChange={(e) => {
								e.target.value !== caseData.responsiblePerson?.id && updateCase({ responsiblePerson: e.target.value })
							}}>
							{[...employees, ...blockedEmployees.filter(x => x.employeeId === caseData.responsiblePerson?.id)].map(employee => {
								return (
									<MenuItem
										key={employee.employeeId}
										value={employee.employeeId}
										disabled={employee.blocked}
									>
										{employee.name}
									</MenuItem>
								)
							})}
						</Select>
						: <CircularProgress size={30} />
					}
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Ledig Fra</Typography>
					<TextField
						value={availableFrom}
						disabled={caseData.deletedAt}
						type="date"
						onChange={(event) => setAvailableFrom(event.target.value)}
						onExit={() => setAvailableFrom(caseData.availableFrom)}
						onFinish={() => {
							availableFrom !== caseData.availableFrom && updateCase({ availableFrom: availableFrom })
						}}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Tilgængelig Fra</Typography>
					<TextField
						value={accessibleFrom}
						disabled={caseData.deletedAt}
						type="date"
						onChange={(event) => setAccessibleFrom(event.target.value)}
						onExit={() => setAccessibleFrom(caseData.accessibleFrom)}
						onFinish={() => {
							accessibleFrom !== caseData.accessibleFrom && updateCase({ accessibleFrom: accessibleFrom })
						}}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Udsat indtil</Typography>
					<TextField
						value={postponedUntil}
						disabled={caseData.deletedAt}
						type="date"
						onChange={(event) => setPostponedUntil(event.target.value)}
						onExit={() => setPostponedUntil(caseData.postponedUntil)}
						onFinish={updatePostponedUntil}
					/>
				</div>
				{integrationId && (
					<div className="fields-section__field">
						<Typography variant="label">Eksternt ID</Typography>
						<p className="field-value">{integrationId}</p>
					</div>
				)}
			</div>
		</div>
	)
}

export default BaseFields;