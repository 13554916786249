import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const BankFields = ({ contractData, updatePropertyContract }) => {

	const [name, setName] = useState(contractData.bankAccount.name ?? "");
	const [identifier, setIdentifier] = useState(contractData.bankAccount.identifier ?? "");
	const [number, setNumber] = useState(contractData.bankAccount.number ?? "");

	const bankComponent = (
		<>
			<div className="fields-section__field">
				<Typography variant="label">Navn</Typography>
				<TextField
					value={name}
					onChange={(event) => setName(event.target.value)}
					onExit={() => setName(contractData.bankAccount.name)}
					onFinish={() => {
						name !== contractData.bankAccount.name && updatePropertyContract({ bankAccount: { name: name } })
					}}
				/>
			</div>
			<div className="fields-section__field">
				<Typography variant="label">Reg. nummer</Typography>
				<TextField
					value={identifier}
					onChange={(event) => setIdentifier(event.target.value)}
					onExit={() => setIdentifier(contractData.bankAccount.identifier)}
					onFinish={() => {
						name !== contractData.bankAccount.identifier && updatePropertyContract({ bankAccount: { identifier: identifier } })
					}}
				/>
			</div>
			<div className="fields-section__field">
				<Typography variant="label">Kontonummer</Typography>
				<TextField
					value={number}
					onChange={(event) => setNumber(event.target.value)}
					onExit={() => setNumber(contractData.bankAccount.number)}
					onFinish={() => {
						name !== contractData.bankAccount.number && updatePropertyContract({ bankAccount: { number: number } })
					}}
				/>
			</div>
		</>
	);

	return (
		<Paper className="property-contract-details__company-bank-fields">
			<Typography variant="subtitle">Bank informationer</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Brug BetailingService</Typography>
					<Switch
						checked={contractData.bankAccount.paymentProvider === "BetailingService"}
						onChange={(e) => updatePropertyContract({ bankAccount: { paymentProvider: e.target.checked ? "BetailingService" : "Bank" } })}
					/>
				</div>
				{contractData.bankAccount.paymentProvider == "Bank" ? bankComponent : null}
			</div>
		</Paper>
	);
};

export default BankFields;