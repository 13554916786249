import StackedChart from 'components/StackedChart';
import React from 'react';

const StateTimeline = ({ data }) => {
	const getMax = () => {
		let max = 0;

		for (let week of data.weeks) {
			const sum = week.draft + week.active + week.reserved;

			if (sum > max) {
				max = sum;
			}
		}

		return max;
	};

	const getSumStart = () => {
		return data.start.draft + data.start.active + data.start.reserved;
	}

	const getSumEnd = () => {
		return data.end.draft + data.end.active + data.end.reserved;
	}

	return (
		<div className="state-timeline data-container">
			<p className="title">Udvikling i sager</p>
			<div className="timeline-container">
				<div className="data-summary">
					<div className="state">
						<div className="name">Kladder</div>
						<div className="value">{`${data.start.draft} > ${data.end.draft}`}</div>
					</div>
					<div className="state">
						<div className="name">Aktive</div>
						<div className="value">{`${data.start.active} > ${data.end.active}`}</div>
					</div>
					<div className="state">
						<div className="name">Reserverede</div>
						<div className="value">{`${data.start.reserved} > ${data.end.reserved}`}</div>
					</div>
					<div className="state">
						<div className="name">Samlet</div>
						<div className="value">{`${getSumStart()} > ${getSumEnd()}`}</div>
					</div>
				</div>
				<div className="graph">
					<StackedChart
						labels={data.weeks.map(x => `W${x.week}`)}
						datasets={[
							{
								label: "Kladder",
								data: data.weeks.map(x => x.draft),
								borderColor: '#566FFF',
								backgroundColor: '#566FFF',
								yAxisID: 'y',
								fill: true,
								pointRadius: 0
							},
							{
								label: "Aktive",
								data: data.weeks.map(x => x.active),
								borderColor: '#3742A9',
								backgroundColor: '#3742A9',
								yAxisID: 'y',
								fill: true,
								pointRadius: 0
							},
							{
								label: "Reserverede",
								data: data.weeks.map(x => x.reserved),
								borderColor: '#2C3589',
								backgroundColor: '#2C3589',
								yAxisID: 'y',
								fill: true,
								pointRadius: 0
							}
						]}
						scales={{
							x: {
								title: {
									display: false,
								},
								grid: {
									display: false
								}
							},
							y: {
								stacked: true,
								title: {
									display: false,
								},
								min: 0,
								suggestedMax: getMax() + 25,
								stepSize: 50,
								grid: {
									display: false
								}
							}
						}}
					/>
				</div>
			</div>
		</div >
	);
};

export default StateTimeline;