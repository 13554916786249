import React from "react";
import FullScreenModal from "components/FullScreenModal";
import Typography from "components/Typography";
import LoadingComponent from "components/LoadingComponent";

const GeneratingContractModal = ({ isEnabled }) => {
	return (
		<FullScreenModal
			className="application-container__generating-contract-modal"
			open={isEnabled}>
			<Typography variant="subtitle">Generating Contract</Typography>
			<div className="contract-generating-loading-container"><LoadingComponent /></div>
		</FullScreenModal>
	);
};

export default GeneratingContractModal;