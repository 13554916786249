import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const HeaderContainer = props => {

	const { children } = props;

   return (
		<div className="header-container">
			{children}
		</div>
   )
};

HeaderContainer.propTypes = {
  children: PropTypes.array,
};

HeaderContainer.defaultProps = {
	children: "<div>No children defined</div>",
  };
  
export default HeaderContainer;