import React from 'react';
import "./index.scss";

const NumberInput = ({ value, onChange, className, placeholder}) => {
  
  return <input
  	type='text'
	className={`styled_text_input ${className}`}
	value={value}
	onChange={(e) => onChange(e.target.value)}
	placeholder={placeholder}
  />
}

export default NumberInput;