import React from "react";
import "./index.scss";
import Paper from "components/StyledPaper";
import ResourceTable from "components/ResourceTable";
import { TableCell, TableRow } from "@material-ui/core";
import Typography from "components/Typography";
import { useHistory } from "react-router-dom";
import { formatRelative } from "date-fns";
import { da } from 'date-fns/locale';

const ApplicationsTab = props => {

	const { applications } = props;
	const history = useHistory();

	const redirectToApplication = (id) => {
		history.push(`/application/${id}`);
	}

	return (
		<Paper>
			<ResourceTable
				title={<Typography variant="subtitle">Forespørgsler</Typography>}
				headers={["Navn", "E-mail", "Telefon", "Gade", "Postnummer", "By", "Land", "Oprettet"]} >
				{applications.map((application, ix) => {
					const applicant = application.applicants.find(x => x.userId);
					return (
						<TableRow className="application-row" key={ix} onClick={() => redirectToApplication(application.id)}>
							<TableCell>{applicant.name}</TableCell>
							<TableCell>{applicant.email}</TableCell>
							<TableCell>{applicant.phone?.extension} {applicant.phone?.number}</TableCell>
							<TableCell>{applicant.address?.street}</TableCell>
							<TableCell>{applicant.address?.zipCode}</TableCell>
							<TableCell>{applicant.address?.city}</TableCell>
							<TableCell>{applicant.address?.country}</TableCell>
							<TableCell>{formatRelative(new Date(application.createdAt), new Date(), { locale: da })}</TableCell>
						</TableRow>
					);
				})}
			</ResourceTable>
		</Paper>
	);
};

export default ApplicationsTab;