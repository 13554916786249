import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, history } from "redux/configureStore";
import Auth0Provider from "helpers/auth";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router";

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <Auth0Provider>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter >
      </Auth0Provider>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
