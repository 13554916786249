import React, { useReducer } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import produce from "immer";
import { TextField, Switch, Select, MoneyField } from "components/Fields";
import { translateString } from "helpers";
import { MenuItem } from "@material-ui/core";

const reducer = (state, action) => {

	switch (action.type) {
		case 'update.landlord.name':
			return produce(state, draft => {
				draft.landlord.name = action.payload
			})
		case 'update.landlord.companyNumber':
			return produce(state, draft => {
				draft.landlord.companyNumber = action.payload
			})
		case 'update.landlord.fullAddress':
			return produce(state, draft => {
				draft.landlord.fullAddress = action.payload
			})
		case 'update.landlord.email':
			return produce(state, draft => {
				draft.landlord.email = action.payload
			})
		case 'update.landlord.phoneNumber.number':
			return produce(state, draft => {
				draft.landlord.phoneNumber.number = action.payload
			})
		case 'update.tenancy.number':
			return produce(state, draft => {
				draft.tenancy.number = action.payload
			})
		case 'update.tenancy.size':
			return produce(state, draft => {
				draft.tenancy.size = action.payload
			})
		case 'update.tenancy.rooms':
			return produce(state, draft => {
				draft.tenancy.rooms = action.payload
			})
		case 'update.tenancy.facilities.custom1':
			return produce(state, draft => {
				draft.tenancy.facilities.custom1 = action.payload
			})
		case 'update.tenancy.facilities.custom2':
			return produce(state, draft => {
				draft.tenancy.facilities.custom2 = action.payload
			})
		case 'update.tenancy.facilities.custom3':
			return produce(state, draft => {
				draft.tenancy.facilities.custom3 = action.payload
			})
		case 'update.property.facilities.custom1':
			return produce(state, draft => {
				draft.property.facilities.custom1 = action.payload
			})
		case 'update.property.facilities.custom2':
			return produce(state, draft => {
				draft.property.facilities.custom2 = action.payload
			})
		case 'update.property.facilities.custom3':
			return produce(state, draft => {
				draft.property.facilities.custom3 = action.payload
			})
		case 'update.rent.amount':
			return produce(state, draft => {
				draft.rent.amount = action.payload
			})
		case 'update.rent.interval':
			return produce(state, draft => {
				draft.rent.interval = action.payload
			})
		case 'update.rent.dueDayOfTheMonth':
			return produce(state, draft => {
				draft.rent.dueDayOfTheMonth = action.payload
			})
		case 'update.expenses.heating.amount':
			return produce(state, draft => {
				draft.expenses.heating.amount = action.payload
			})
		case 'update.expenses.heating.accountingStartDate':
			return produce(state, draft => {
				draft.expenses.heating.accountingStartDate = action.payload
			})
		case 'update.expenses.water.amount':
			return produce(state, draft => {
				draft.expenses.water.amount = action.payload
			})
		case 'update.expenses.water.accountingStartDate':
			return produce(state, draft => {
				draft.expenses.water.accountingStartDate = action.payload
			})

		case 'update.expenses.electricity.amount':
			return produce(state, draft => {
				draft.expenses.electricity.amount = action.payload
			})
		case 'update.expenses.electricity.accountingStartDate':
			return produce(state, draft => {
				draft.expenses.electricity.accountingStartDate = action.payload
			})
		case 'update.expenses.cooling.amount':
			return produce(state, draft => {
				draft.expenses.cooling.amount = action.payload
			})
		case 'update.expenses.cooling.accountingStartDate':
			return produce(state, draft => {
				draft.expenses.cooling.accountingStartDate = action.payload
			})
		case 'update.expenses.antenna.amount':
			return produce(state, draft => {
				draft.expenses.antenna.amount = action.payload
			})
		case 'update.expenses.internet.amount':
			return produce(state, draft => {
				draft.expenses.internet.amount = action.payload
			})
		case 'update.expenses.residentRepresentation.amount':
			return produce(state, draft => {
				draft.expenses.residentRepresentation.amount = action.payload
			})
		case 'update.landlord.bank.name':
			return produce(state, draft => {
				draft.landlord.bank.name = action.payload
			})
		case 'update.landlord.bank.registrationNumber':
			return produce(state, draft => {
				draft.landlord.bank.registrationNumber = action.payload
			})
		case 'update.landlord.bank.accountNumber':
			return produce(state, draft => {
				draft.landlord.bank.accountNumber = action.payload
			})
		case 'update.startAt':
			return produce(state, draft => {
				draft.startAt = action.payload
			})
		case 'update.paymentDeadline':
			return produce(state, draft => {
				draft.paymentDeadline = action.payload
			})
		case 'update.nextPaymentDeadline':
			return produce(state, draft => {
				draft.nextPaymentDeadline = action.payload
			})
		case 'update.taxesCalculatedPerDate':
			return produce(state, draft => {
				draft.taxesCalculatedPerDate = action.payload
			})
		case 'update.deposit.amount':
			return produce(state, draft => {
				draft.deposit.amount = action.payload
			})
		case 'update.deposit.deadline':
			return produce(state, draft => {
				draft.deposit.deadline = action.payload
			})
		case 'update.deposit.period':
			return produce(state, draft => {
				draft.deposit.period = action.payload
			})
		case 'update.prepaidRent.amount':
			return produce(state, draft => {
				draft.prepaidRent.amount = action.payload
			})
		case 'update.prepaidRent.deadline':
			return produce(state, draft => {
				draft.prepaidRent.deadline = action.payload
			})
		case 'update.prepaidRent.period':
			return produce(state, draft => {
				draft.prepaidRent.period = action.payload
			})
		case 'update.customPayment.name':
			return produce(state, draft => {
				draft.customPayment.name = action.payload
			})
		case 'update.customPayment.amount':
			return produce(state, draft => {
				draft.customPayment.amount = action.payload
			})
		case 'update.firstRent.reduction':
			return produce(state, draft => {
				draft.firstRent.reduction = action.payload
			})
		case 'update.maintenance.account.date':
			return produce(state, draft => {
				draft.maintenance.account.date = action.payload
			})
		case 'update.maintenance.account.amount':
			return produce(state, draft => {
				draft.maintenance.account.amount = action.payload
			})
		case 'update.petsSpecialConditions':
			return produce(state, draft => {
				draft.petsSpecialConditions = action.payload
			})
		case 'update.generalInformation':
			return produce(state, draft => {
				draft.generalInformation = action.payload
			})
		case 'update.rentControl.uncontrolledRentJustification':
			return produce(state, draft => {
				draft.rentControl.uncontrolledRentJustification = action.payload
			})
		case 'update.rentIncrease.date':
			return produce(state, draft => {
				draft.rentIncrease.date = action.payload
			})
		case 'update.rentIncrease.startsFrom':
			return produce(state, draft => {
				draft.rentIncrease.startsFrom = action.payload
			})

		case 'update.paragraph11':
			return produce(state, draft => {
				draft.paragraph11 = action.payload
			})
		case 'update.usageDescription':
			return produce(state, draft => {
				draft.usageDescription = action.payload
			})
		default:
			throw new Error();
	}
}

const ContractData = ({ contract, updateContractData, toggleContractPropertyFacility, toggleContractTenancyFacility, documents }) => {
	const contractData = contract?.data;
	const readonly = contract?.status !== "Negotiation";

	const [state, dispatch] = useReducer(reducer, contractData);

	const propertyFacilityTypes = [
		"CommunalLaundry",
		"BicycleParking",
		"AtticOrBasementRoom",
		"SharedCourtyard",
		"Garage",
	];

	const tenancyFacilityTypes = [
		"Stove",
		"Refrigerator",
		"Freezer",
		"Dishwasher",
		"WashingMachine",
		"Dryer",
		"KitchenHood",
		"RefrigeratorAndFreezer",
		"WashingMachineAndDryer"
	];

	const tenancyTypes = [
		"SingleRoom",
		"HousingCooperative",
		"Apartment",
		"Condominium",
		"TownHouse"
	];

	const months = [
		{ value: 1, label: "januar" },
		{ value: 2, label: "februar" },
		{ value: 3, label: "marts" },
		{ value: 4, label: "april" },
		{ value: 5, label: "maj" },
		{ value: 6, label: "juni" },
		{ value: 7, label: "juli" },
		{ value: 8, label: "august" },
		{ value: 9, label: "september" },
		{ value: 10, label: "oktober" },
		{ value: 11, label: "november" },
		{ value: 12, label: "december" }
	]

	if (contract == null || state == null)
		return null;

	return (
		<>
			<Paper className="application-content__contract">
				<Typography variant="subtitle">Kontrakt</Typography>
				<div className="paragraph-container">
					<p className="paragraph">§ 1. Parterne og det lejede</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Udlejeren</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Det lejede er</Typography>
								<Select
									disabled={readonly}
									value={contractData.tenancy.type}
									onChange={(e) => {
										updateContractData({ tenancy: { type: e.target.value } })
									}}>
									{tenancyTypes.map((type, ix) => {
										return (
											<MenuItem key={ix} value={type}>
												{translateString(type)}
											</MenuItem>
										);
									})}
								</Select>
							</div>
							<div className="field-group">
								<Typography variant="label">Lejemåls nr.</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.number}
									onChange={(event) => dispatch({ type: 'update.tenancy.number', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.number', payload: contractData.tenancy.number })}
									onFinish={() => {
										updateContractData({ tenancy: { number: state.tenancy.number } })
									}}
								/>
							</div>
						</div>
					</div>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Udlejeren</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Navn</Typography>
								<TextField
									disabled={readonly}
									value={state.landlord.name}
									onChange={(event) => dispatch({ type: 'update.landlord.name', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.landlord.name', payload: contractData.landlord.name })}
									onFinish={() => {
										updateContractData({ landlord: { name: state.landlord.name } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">CVR-nr/reg. nr.:</Typography>
								<TextField
									disabled={readonly}
									value={state.landlord.companyNumber}
									onChange={(event) => dispatch({ type: 'update.landlord.companyNumber', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.landlord.companyNumber', payload: contractData.landlord.companyNumber })}
									onFinish={() => {
										updateContractData({ landlord: { companyNumber: state.landlord.companyNumber } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Adresse</Typography>
								<TextField
									disabled={readonly}
									value={state.landlord.fullAddress}
									onChange={(event) => dispatch({ type: 'update.landlord.fullAddress', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.landlord.fullAddress', payload: contractData.landlord.fullAddress })}
									onFinish={() => {
										updateContractData({ landlord: { fullAddress: state.landlord.fullAddress } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">E-mail</Typography>
								<TextField
									disabled={readonly}
									value={state.landlord.email}
									onChange={(event) => dispatch({ type: 'update.landlord.email', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.landlord.email', payload: contractData.landlord.email })}
									onFinish={() => {
										updateContractData({ landlord: { email: state.landlord.email } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Nummer</Typography>
								<TextField
									disabled={readonly}
									value={state.landlord.phoneNumber.number}
									onChange={(event) => dispatch({ type: 'update.landlord.phoneNumber.number', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.landlord.phoneNumber.number', payload: contractData.landlord.phoneNumber.number })}
									onFinish={() => {
										updateContractData({ landlord: { phoneNumber: { number: state.landlord.phoneNumber.number } } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Areal</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Størrelse</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.size}
									onChange={(event) => dispatch({ type: 'update.tenancy.size', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.size', payload: contractData.tenancy.size })}
									onFinish={() => {
										updateContractData({ tenancy: { size: state.tenancy.size } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Værelser</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.rooms}
									onChange={(event) => dispatch({ type: 'update.tenancy.rooms', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.rooms', payload: contractData.tenancy.rooms })}
									onFinish={() => {
										updateContractData({ tenancy: { rooms: state.tenancy.rooms } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Brugsret til:</Typography>
						<div className="fields">
							{propertyFacilityTypes
								.map((type, ix) => {
									return (
										<div key={ix} className="field-group">
											<Typography variant="label">{translateString(type)}</Typography>
											<Switch
												disabled={readonly}
												checked={contractData.property.facilities.predefined.includes(type)}
												onChange={() => toggleContractPropertyFacility(type)}
											/>
										</div>
									);
								})}
							<div className="field-group">
								<Typography variant="label">{translateString("Andet 1")}</Typography>
								<TextField
									disabled={readonly}
									value={state.property.facilities.custom1 ?? ""}
									onChange={(event) => dispatch({ type: 'update.property.facilities.custom1', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.property.facilities.custom1', payload: contractData.property.facilities.custom1 })}
									onFinish={() => {
										updateContractData({ property: { facilities: { custom1: state.property.facilities.custom1 } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">{translateString("Andet 2")}</Typography>
								<TextField
									disabled={readonly}
									value={state.property.facilities.custom2 ?? ""}
									onChange={(event) => dispatch({ type: 'update.property.facilities.custom2', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.property.facilities.custom2', payload: contractData.property.facilities.custom2 })}
									onFinish={() => {
										updateContractData({ property: { facilities: { custom2: state.property.facilities.custom2 } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">{translateString("Andet 3")}</Typography>
								<TextField
									disabled={readonly}
									value={state.property.facilities.custom3 ?? ""}
									onChange={(event) => dispatch({ type: 'update.property.facilities.custom3', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.property.facilities.custom3', payload: contractData.property.facilities.custom3 })}
									onFinish={() => {
										updateContractData({ property: { facilities: { custom3: state.property.facilities.custom3 } } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Benyttelse</Typography>
						<div className="fields flex-fields">
							<div className="field-group">
								<TextField
									value={state.usageDescription}
									multiline
									rows={2}
									rowsMax={2}
									onChange={(event) => dispatch({ type: 'update.usageDescription', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.usageDescription', payload: contractData.usageDescription })}
									onFinish={() => {
										updateContractData({ usageDescription: state.usageDescription })
									}}
								/>
							</div>
						</div>
					</div>

				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 2. Lejeforholdets begyndelse og ophør</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Begyndelse</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Startdato</Typography>
								<TextField
									disabled={readonly}
									value={state.startAt ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.startAt', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.startAt', payload: contractData.startAt })}
									onFinish={() => {
										updateContractData({ startAt: state.startAt })
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 3. Betaling af leje</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Leje og betaling</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Forfaldsperiode</Typography>
								<Select
									disabled={readonly}
									value={contractData.rent.interval}
									onChange={(e) => {
										updateContractData({ rent: { interval: e.target.value } })
									}}>
									<MenuItem value="Monthly">
										{translateString("Monthly")}
									</MenuItem>
									<MenuItem value="Quarterly">
										{translateString("Quarterly")}
									</MenuItem>
								</Select>
							</div>
							<div className="field-group">
								<Typography variant="label">Forfaldsdag</Typography>
								<TextField
									disabled={readonly}
									value={state.rent.dueDayOfTheMonth}
									onChange={(event) => dispatch({ type: 'update.rent.dueDayOfTheMonth', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.rent.dueDayOfTheMonth', payload: contractData.rent.dueDayOfTheMonth })}
									onFinish={() => {
										updateContractData({ rent: { dueDayOfTheMonth: state.rent.dueDayOfTheMonth } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">{`Leje pr. ${translateString(contractData.rent.interval)}`}</Typography>
								<MoneyField
									disabled={readonly}
									value={state.rent.amount}
									onChange={(amount) => dispatch({ type: 'update.rent.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.rent.amount', payload: contractData.rent.amount })}
									onFinish={() => {
										updateContractData({ rent: { amount: state.rent.amount } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Beregnet dato</Typography>
								<TextField
									disabled={readonly}
									value={state.paymentDeadline ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.paymentDeadline', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.paymentDeadline', payload: contractData.paymentDeadline })}
									onFinish={() => {
										updateContractData({ paymentDeadline: state.paymentDeadline })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Betalinger udover lejen</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">A conto varmebidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.heating?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.heating.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.heating.amount', payload: contractData.expenses.heating?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { heating: state.expenses.heating } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">A conto vandbidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.water?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.water.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.water.amount', payload: contractData.expenses.water?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { water: state.expenses.water } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">A conto elbidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.electricity?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.electricity.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.electricity.amount', payload: contractData.expenses.electricity?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { electricity: state.expenses.electricity } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">A conto kølingsbidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.cooling?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.cooling.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.cooling.amount', payload: contractData.expenses.cooling?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { cooling: state.expenses.cooling } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Antennebidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.antenna?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.antenna.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.antenna.amount', payload: contractData.expenses.antenna?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { antenna: state.expenses.antenna } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Internetbidrag</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.internet?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.internet.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.internet.amount', payload: contractData.expenses.internet?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { internet: state.expenses.internet } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Beboerrepræsentation</Typography>
								<MoneyField
									disabled={readonly}
									value={state.expenses.residentRepresentation?.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.expenses.residentRepresentation.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.expenses.residentRepresentation.amount', payload: contractData.expenses.residentRepresentation?.amount ?? "" })}
									onFinish={() => {
										updateContractData({ expenses: { residentRepresentation: state.expenses.residentRepresentation } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Yderligere betaling</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beskrivelse</Typography>
								<TextField
									disabled={readonly}
									value={state.customPayment.name ?? ""}
									onChange={(event) => dispatch({ type: 'update.customPayment.name', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.customPayment.name', payload: contractData.customPayment.name ?? "" })}
									onFinish={() => {
										updateContractData({ customPayment: { name: state.customPayment.name } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Beløb</Typography>
								<MoneyField
									disabled={readonly}
									value={state.customPayment.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.customPayment.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.customPayment.amount', payload: contractData.customPayment.amount ?? "" })}
									onFinish={() => {
										updateContractData({ customPayment: { amount: state.customPayment.amount } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Lejereducering</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beløb</Typography>
								<MoneyField
									disabled={readonly}
									value={state.firstRent.reduction ?? ""}
									onChange={(amount) => dispatch({ type: 'update.firstRent.reduction', payload: amount })}
									onExit={() => dispatch({ type: 'update.firstRent.reduction', payload: contractData.firstRent.reduction ?? "" })}
									onFinish={() => {
										updateContractData({ firstRent: { reduction: state.firstRent.reduction } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Skatter og afgifter</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beregnet dato</Typography>
								<TextField
									disabled={readonly}
									value={state.taxesCalculatedPerDate ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.taxesCalculatedPerDate', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.taxesCalculatedPerDate', payload: contractData.taxesCalculatedPerDate })}
									onFinish={() => {
										updateContractData({ taxesCalculatedPerDate: state.taxesCalculatedPerDate })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Betalingssted</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Betalingsudbyder</Typography>
								<Select
									disabled={readonly}
									value={contractData.landlord.bank.paymentProvider ?? ""}
									onChange={(e) => {
										updateContractData({ landlord: { bank: { paymentProvider: e.target.value } } })
									}}>
									<MenuItem disabled value="">
										Not selected
									</MenuItem>
									<MenuItem value="Bank">
										Bank
									</MenuItem>
									<MenuItem value="BetailingService">
										BetailingService
									</MenuItem>
								</Select>
							</div>
							{
								contractData.landlord.bank.paymentProvider == "Bank" && (
									<>
										<div className="field-group">
											<Typography variant="label">Bank</Typography>
											<TextField
												disabled={readonly}
												value={state.landlord.bank.name ?? ""}
												onChange={(event) => dispatch({ type: 'update.landlord.bank.name', payload: event.target.value })}
												onExit={() => dispatch({ type: 'update.landlord.bank.name', payload: contractData.landlord.bank.name })}
												onFinish={() => {
													updateContractData({ landlord: { bank: { name: state.landlord.bank.name } } })
												}}
											/>
										</div>
										<div className="field-group">
											<Typography variant="label">Reg. nr.</Typography>
											<TextField
												disabled={readonly}
												value={state.landlord.bank.registrationNumber ?? ""}
												onChange={(event) => dispatch({ type: 'update.landlord.bank.registrationNumber', payload: event.target.value })}
												onExit={() => dispatch({ type: 'update.landlord.bank.registrationNumber', payload: contractData.landlord.bank.registrationNumber })}
												onFinish={() => {
													updateContractData({ landlord: { bank: { registrationNumber: state.landlord.bank.registrationNumber } } })
												}}
											/>
										</div>
										<div className="field-group">
											<Typography variant="label">Konto nr.</Typography>
											<TextField
												disabled={readonly}
												value={state.landlord.bank.accountNumber ?? ""}
												onChange={(event) => dispatch({ type: 'update.landlord.bank.accountNumber', payload: event.target.value })}
												onExit={() => dispatch({ type: 'update.landlord.bank.accountNumber', payload: contractData.landlord.bank.accountNumber })}
												onFinish={() => {
													updateContractData({ landlord: { bank: { accountNumber: state.landlord.bank.accountNumber } } })
												}}
											/>
										</div>
									</>
								)
							}
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Next Payment</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beregnet dato</Typography>
								<TextField
									disabled={readonly}
									value={state.nextPaymentDeadline ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.nextPaymentDeadline', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.nextPaymentDeadline', payload: contractData.nextPaymentDeadline })}
									onFinish={() => {
										updateContractData({ nextPaymentDeadline: state.nextPaymentDeadline })
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 4. Depositum og forudbetalt leje</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Depositum</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beløb</Typography>
								<MoneyField
									disabled={readonly}
									value={state.deposit.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.deposit.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.deposit.amount', payload: contractData.deposit.amount })}
									onFinish={() => {
										updateContractData({ deposit: { amount: state.deposit.amount } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Betalingsdato</Typography>
								<TextField
									disabled={readonly}
									value={state.deposit.deadline ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.deposit.deadline', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.deposit.deadline', payload: contractData.deposit.deadline })}
									onFinish={() => {
										updateContractData({ deposit: { deadline: state.deposit.deadline } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Periode</Typography>
								<TextField
									disabled={readonly}
									value={state.deposit.period ?? ""}
									onChange={(event) => dispatch({ type: 'update.deposit.period', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.deposit.period', payload: contractData.deposit.period })}
									onFinish={() => {
										updateContractData({ deposit: { period: state.deposit.period } })
									}}
								/>
							</div>
						</div>
					</div>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Forudbetalt leje</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beløb</Typography>
								<MoneyField
									disabled={readonly}
									value={state.prepaidRent.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.prepaidRent.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.prepaidRent.amount', payload: contractData.prepaidRent.amount })}
									onFinish={() => {
										updateContractData({ prepaidRent: { amount: state.prepaidRent.amount } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Betalingsdato</Typography>
								<TextField
									disabled={readonly}
									value={state.prepaidRent.deadline ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.prepaidRent.deadline', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.prepaidRent.deadline', payload: contractData.prepaidRent.deadline })}
									onFinish={() => {
										updateContractData({ prepaidRent: { deadline: state.prepaidRent.deadline } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Periode</Typography>
								<TextField
									disabled={readonly}
									value={state.prepaidRent.period ?? ""}
									onChange={(event) => dispatch({ type: 'update.prepaidRent.period', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.prepaidRent.period', payload: contractData.prepaidRent.period })}
									onFinish={() => {
										updateContractData({ prepaidRent: { period: state.prepaidRent.period } })
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 5. Varme, køling, vand og el</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Varme</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Dato</Typography>
								<TextField
									disabled={readonly}
									value={state.expenses.heating?.accountingStartDate ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.expenses.heating.accountingStartDate', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.expenses.heating.accountingStartDate', payload: contractData.expenses.heating?.accountingStartDate })}
									onFinish={() => {
										updateContractData({ expenses: { heating: { accountingStartDate: state.expenses.heating?.accountingStartDate } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.heating?.landlordResponsible}
									onChange={() => updateContractData({ expenses: { heating: { landlordResponsible: !contractData.expenses.heating?.landlordResponsible } } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Udgift til varme inkluderet i husleje</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.heating?.isHeatingIncludedInRent}
									onChange={() => updateContractData({ expenses: { heating: { isHeatingIncludedInRent: !contractData.expenses.heating?.isHeatingIncludedInRent } } })}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Vand</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Dato</Typography>
								<TextField
									disabled={readonly}
									value={state.expenses.water?.accountingStartDate ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.expenses.water.accountingStartDate', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.expenses.water.accountingStartDate', payload: contractData.expenses.water?.accountingStartDate })}
									onFinish={() => {
										updateContractData({ expenses: { water: { accountingStartDate: state.expenses.water?.accountingStartDate } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.water?.landlordResponsible}
									onChange={() => updateContractData({ expenses: { water: { landlordResponsible: !contractData.expenses.water?.landlordResponsible } } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Individuelle forbrugsmålere</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.water?.individualMeters}
									onChange={() => updateContractData({ expenses: { water: { individualMeters: !contractData.expenses.water?.individualMeters } } })}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">El</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Dato</Typography>
								<TextField
									disabled={readonly}
									value={state.expenses.electricity?.accountingStartDate ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.expenses.electricity.accountingStartDate', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.expenses.electricity.accountingStartDate', payload: contractData.expenses.electricity?.accountingStartDate })}
									onFinish={() => {
										updateContractData({ expenses: { electricity: { accountingStartDate: state.expenses.electricity?.accountingStartDate } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.electricity?.landlordResponsible}
									onChange={() => updateContractData({ expenses: { electricity: { landlordResponsible: !contractData.expenses.electricity?.landlordResponsible } } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Individuelle forbrugsmålere</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.electricity?.individualMeters}
									onChange={() => updateContractData({ expenses: { electricity: { individualMeters: !contractData.expenses.electricity?.individualMeters } } })}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Køling</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Dato</Typography>
								<TextField
									disabled={readonly}
									value={state.expenses.cooling?.accountingStartDate ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.expenses.cooling.accountingStartDate', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.expenses.cooling.accountingStartDate', payload: contractData.expenses.cooling?.accountingStartDate })}
									onFinish={() => {
										updateContractData({ expenses: { cooling: { accountingStartDate: state.expenses.cooling?.accountingStartDate } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.cooling?.landlordResponsible}
									onChange={() => updateContractData({ expenses: { cooling: { landlordResponsible: !contractData.expenses.cooling?.landlordResponsible } } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Individuelle forbrugsmålere</Typography>
								<Switch
									disabled={readonly || !contractData.expenses.cooling?.landlordResponsible}
									checked={contractData.expenses.cooling?.individualMeters}
									onChange={() => updateContractData({ expenses: { cooling: { individualMeters: !contractData.expenses.cooling?.individualMeters } } })}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 6. Fællesantenner m.v. og adgang til elektroniske kommunikationstjenester</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Fællesantenne</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.antenna?.landlordResponsible}
									onChange={() => updateContractData({ expenses: { antenna: { landlordResponsible: !contractData.expenses.antenna?.landlordResponsible } } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Antenneforening ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.expenses.antenna?.unionSupplied}
									onChange={() => updateContractData({ expenses: { antenna: { unionSupplied: !contractData.expenses.antenna?.unionSupplied } } })}
								/>
							</div>
						</div>
					</div>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Internet</Typography>
						<div className="field-group">
							<Typography variant="label">Udlejer ansvarlig</Typography>
							<Switch
								disabled={readonly}
								checked={contractData.expenses.internet?.landlordResponsible}
								onChange={() => updateContractData({ expenses: { internet: { landlordResponsible: !contractData.expenses.internet?.landlordResponsible } } })}
							/>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 7. Lejemålets stand ved ind- og fraflytning</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Inspection</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Indflytningssyn</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.isInspectedByLandlordWhenMoveIn}
									onChange={() => updateContractData({ isInspectedByLandlordWhenMoveIn: !contractData.isInspectedByLandlordWhenMoveIn })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Fraflytningssyn</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.isInspectedByLandlordWhenMoveOut}
									onChange={() => updateContractData({ isInspectedByLandlordWhenMoveOut: !contractData.isInspectedByLandlordWhenMoveOut })}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 8. Vedligeholdelse</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Pligt</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Udlejer ansvarlig</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.maintenance.landlordResponsible}
									onChange={() => updateContractData({ maintenance: { landlordResponsible: !contractData.maintenance.landlordResponsible } })}
								/>
							</div>
						</div>
					</div>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Konto</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Dato</Typography>
								<TextField
									disabled={readonly}
									value={state.maintenance.account.date ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.maintenance.account.date', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.maintenance.account.date', payload: contractData.maintenance.account.date })}
									onFinish={() => {
										updateContractData({ maintenance: { account: { date: state.maintenance.account.date } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Beløb</Typography>
								<MoneyField
									disabled={readonly}
									value={state.maintenance.account.amount ?? ""}
									onChange={(amount) => dispatch({ type: 'update.maintenance.account.amount', payload: amount })}
									onExit={() => dispatch({ type: 'update.maintenance.account.amount', payload: contractData.maintenance.account.amount })}
									onFinish={() => {
										updateContractData({ maintenance: { account: { amount: state.maintenance.account.amount } } })
									}}
								/>
							</div>
						</div>
					</div>
				</div>


				<div className="paragraph-container">
					<p className="paragraph">§ 9. Inventar</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Tilhører udlejer</Typography>
						<div className="fields">
							{tenancyFacilityTypes
								.map((type, ix) => {
									return (
										<div key={ix} className="field-group">
											<Typography variant="label">{translateString(type)}</Typography>
											<Switch
												disabled={readonly}
												checked={contractData.tenancy.facilities.predefined.includes(type)}
												onChange={() => toggleContractTenancyFacility(type)}
											/>
										</div>
									);
								})}
							<div className="field-group">
								<Typography variant="label">{translateString("Ekstra felt 1")}</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.facilities.custom1 ?? ""}
									onChange={(event) => dispatch({ type: 'update.tenancy.facilities.custom1', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.facilities.custom1', payload: contractData.tenancy.facilities.custom1 })}
									onFinish={() => {
										updateContractData({ tenancy: { facilities: { custom1: state.tenancy.facilities.custom1 } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">{translateString("Ekstra felt 2")}</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.facilities.custom2 ?? ""}
									onChange={(event) => dispatch({ type: 'update.tenancy.facilities.custom2', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.facilities.custom2', payload: contractData.tenancy.facilities.custom2 })}
									onFinish={() => {
										updateContractData({ tenancy: { facilities: { custom2: state.tenancy.facilities.custom2 } } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">{translateString("Ekstra felt 3")}</Typography>
								<TextField
									disabled={readonly}
									value={state.tenancy.facilities.custom3 ?? ""}
									onChange={(event) => dispatch({ type: 'update.tenancy.facilities.custom3', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.tenancy.facilities.custom3', payload: contractData.tenancy.facilities.custom3 })}
									onFinish={() => {
										updateContractData({ tenancy: { facilities: { custom3: state.tenancy.facilities.custom3 } } })
									}}
								/>
							</div>
						</div>
					</div>
				</div>


				<div className="paragraph-container">
					<p className="paragraph">§ 10. Beboerrepræsentation, husdyrhold, husorden og øvrige oplysninger om det lejede</p>
					<div className="paragraph-fields">
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Beboerrepræsentation</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.tenantRepresentation}
									onChange={() => updateContractData({ tenantRepresentation: !contractData.tenantRepresentation })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Husdyr</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.petsAllowed}
									onChange={() => updateContractData({ petsAllowed: !contractData.petsAllowed })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Husorden</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.houseRules}
									onChange={() => updateContractData({ houseRules: !contractData.houseRules })}
								/>
							</div>
						</div>
					</div>
					{
						contractData.petsAllowed && (
							<div className="field-group">
								<Typography variant="label">Særlige vilkår for husdyrtilladelsen</Typography>
								<TextField
									disabled={readonly}
									value={state.petsSpecialConditions}
									multiline
									rows={2}
									rowsMax={2}
									onChange={(event) => dispatch({ type: 'update.petsSpecialConditions', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.petsSpecialConditions', payload: contractData.petsSpecialConditions })}
									onFinish={() => {
										updateContractData({ petsSpecialConditions: state.petsSpecialConditions })
									}}
								/>
							</div>
						)
					}
					<div className="field-group general-information-field">
						<Typography variant="label">§ 10. Øvrige oplysninger om det lejede</Typography>
						<TextField
							disabled={readonly}
							value={state.generalInformation}
							multiline
							rows={5}
							rowsMax={20}
							onChange={(event) => dispatch({ type: 'update.generalInformation', payload: event.target.value })}
							onExit={() => dispatch({ type: 'update.generalInformation', payload: contractData.generalInformation })}
							onFinish={() => {
								updateContractData({ generalInformation: state.generalInformation })
							}}
						/>
					</div>
				</div>

				<div className="paragraph-container">
					<p className="paragraph">§ 11. Særlige vilkår</p>
					<div className="paragraph-fields">
						<Typography className="section" variant="label">Lejefastsættelse</Typography>
						<div className="fields flex-fields">
							<div className="field-group">
								<Typography variant="label">Lejeforholdet er omfattet af reglerne om fri leje</Typography>
								<Switch
									disabled={readonly}
									checked={!contractData.rentControl?.enabled}
									onChange={() => updateContractData({ rentControl: { enabled: !contractData.rentControl.enabled } })}
								/>
							</div>
							{
								!contractData.rentControl?.enabled && (
									<div className="field-group">
										<Typography variant="label">Grundlag for fri leje</Typography>
										<TextField
											disabled={readonly}
											value={state.rentControl?.uncontrolledRentJustification}
											multiline
											rows={4}
											rowsMax={4}
											onChange={(event) => dispatch({ type: 'update.rentControl.uncontrolledRentJustification', payload: event.target.value })}
											onExit={() => dispatch({ type: 'update.rentControl.uncontrolledRentJustification', payload: contractData.rentControl?.uncontrolledRentJustification })}
											onFinish={() => {
												updateContractData({ rentControl: { uncontrolledRentJustification: state.rentControl.uncontrolledRentJustification } })
											}}
										/>
									</div>
								)
							}
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Lejeregulering</Typography>
						<div className="fields">
							<div className="field-group">
								<Typography variant="label">Aktiveret</Typography>
								<Switch
									disabled={readonly}
									checked={contractData.rentIncrease.enabled}
									onChange={() => updateContractData({ rentIncrease: { enabled: !contractData.rentIncrease.enabled } })}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Dag for lejeregulering</Typography>
								<TextField
									disabled={readonly}
									value={state.rentIncrease.date ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.rentIncrease.date', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.rentIncrease.date', payload: contractData.rentIncrease.date })}
									onFinish={() => {
										updateContractData({ rentIncrease: { date: state.rentIncrease.date } })
									}}
								/>
							</div>
							<div className="field-group">
								<Typography variant="label">Fra måned</Typography>
								<Select
									disabled={readonly}
									value={contractData.rentIncrease.fromMonth ?? ""}
									onChange={(e) => {
										updateContractData({ rentIncrease: { fromMonth: e.target.value } })
									}}>
									{months.map(month => (
										<MenuItem key={month.value} value={month.value}>
											{month.label}
										</MenuItem>
									))}
								</Select>
							</div>
							<div className="field-group">
								<Typography variant="label">Til måned</Typography>
								<Select
									disabled={readonly}
									value={contractData.rentIncrease.toMonth ?? ""}
									onChange={(e) => {
										updateContractData({ rentIncrease: { toMonth: e.target.value } })
									}}>
									{months.map(month => (
										<MenuItem key={month.value} value={month.value}>
											{month.label}
										</MenuItem>
									))}
								</Select>
							</div>
							<div className="field-group">
								<Typography variant="label">Første regulering</Typography>
								<TextField
									disabled={readonly}
									value={state.rentIncrease.startsFrom ?? ""}
									type="date"
									onChange={(event) => dispatch({ type: 'update.rentIncrease.startsFrom', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.rentIncrease.startsFrom', payload: contractData.rentIncrease.startsFrom })}
									onFinish={() => {
										updateContractData({ rentIncrease: { startsFrom: state.rentIncrease.startsFrom } })
									}}
								/>
							</div>
						</div>
					</div>

					<div className="paragraph-fields">
						<Typography className="section" variant="label">Øvrige fravigelser</Typography>
						<div className="fields flex-fields">
							<div className="field-group">
								<TextField
									disabled={readonly || documents.filter(x => x.type === "Paragraph11").length > 0}
									value={documents.filter(x => x.type === "Paragraph11").length === 0 ? state.paragraph11 : "De for lejemålet gældende særlige vilkår er anført i vedhæftede §11 dokument, der betragtes som en integreret del af lejekontrakten, og som tillige underskrives af parterne."}
									multiline
									rows={5}
									rowsMax={20}
									onChange={(event) => dispatch({ type: 'update.paragraph11', payload: event.target.value })}
									onExit={() => dispatch({ type: 'update.paragraph11', payload: contractData.paragraph11 })}
									onFinish={() => {
										updateContractData({ paragraph11: state.paragraph11 })
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Paper>
		</>
	)
};

export default ContractData;