const initialOrganizationState = {
    name: null,
    isLoading: true,
    contactRoles: [],
    employees: [],
	blockedEmployees: [],
    integrations: [],
    attributeDefinitions: []
};

export default initialOrganizationState;