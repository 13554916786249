import React, { useState } from "react";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const ElectricityUtility = ({ contractDetails, updatePropertyContract }) => {

	const [accountingStartDate, setAccountingStartDate] = useState(contractDetails.utilities.electricity.accountingStartDate ?? "");

	return (
		<>
			<Typography variant="label" className="utility-title">El</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Udlejer ansvarlig</Typography>
					<Switch
						checked={contractDetails.utilities.electricity.isLandlordResponsible}
						onChange={(e) => updatePropertyContract({ utilities: { electricity: { isLandlordResponsible: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Lejemålet har individuelle målere</Typography>
					<Switch
						checked={contractDetails.utilities.electricity.hasIndividualMeters}
						onChange={(e) => updatePropertyContract({ utilities: { electricity: { hasIndividualMeters: e.target.checked } } })}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Elregnskabsåret begynder</Typography>
					<TextField
						value={accountingStartDate ?? ""}
						type="date"
						onChange={(event) => setAccountingStartDate(event.target.value)}
						onExit={() => setAccountingStartDate(contractDetails.utilities.electricity.accountingStartDate)}
						onFinish={() => {
							accountingStartDate !== contractDetails.utilities.electricity.accountingStartDate && updatePropertyContract({ utilities: { electricity: { accountingStartDate } } })
						}}
					/>
				</div>
			</div>
			<hr />
		</>
	);
};

export default ElectricityUtility;