import React from 'react'
import '../index.scss'
import Typography from 'components/Typography'
import PhoneExtensionPicker from 'components/StyledPhoneExtensionPicker'
import TextInput from 'components/TextInput/TextInput'
import ShowcasingLanguages from 'helpers/showcasing-languages'
import StyledSelect from 'components/StyledSelect2'
import PhoneNumberInput from 'components/NumberInput'

const GuestSection = ({
  state,
  dispatch,
}) => {
  return (
    <div className='guest-section-wrapper'>
      <div className='section guest'>
        <Typography className='pb30' variant='subtitle'>
          Deltager
        </Typography>
        <div className='field-container'>
          <div className='field email'>
            <Typography className='pb15' variant='label'>
              Navn
            </Typography>
            <TextInput
              value={state.guestName}
              onChange={value => dispatch({ type: 'SET_GUEST_NAME', payload: value })}
            />
          </div>
          <div className='field name'>
            <Typography className='pb15' variant='label'>
              Email
            </Typography>
            <TextInput
              value={state.guestEmail}
              onChange={value => dispatch({ type: 'SET_GUEST_EMAIL', payload: value })}
            />
          </div>
          <div className='field language'>
            <Typography className='pb15' variant='label'>
              Sprog
            </Typography>
            <StyledSelect
              options={ShowcasingLanguages}
              value={state.communicationLanguage}
              onChange={value => dispatch({ type: 'SET_COMMUNICATION_LANGUAGE', payload: value })}
            />
          </div>
          <div className='field phone'>
            <Typography className='pb15' variant='label'>
              Telefonnummer
            </Typography>
            <div className='field-sub-container'>
              <PhoneExtensionPicker
                className={'phone-extension'}
                value={state.guestPhoneExtension}
                onChange={value => dispatch({ type: 'SET_GUEST_PHONE_EXTENSION', payload: value })}
              />
              <PhoneNumberInput
                className={'phone-number'}
                value={state.guestPhoneNumber}
                onChange={value => dispatch({ type: 'SET_GUEST_PHONE_NUMBER', payload: value })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuestSection
