import { useState, useCallback, useEffect } from "react";

/*
    useMemorizedToggleSettings hook handles saved data toggle for cases page.
*/
const useMemorizedToggleSettings = () => {

	const [isLoading, setLoading] = useState(true);
	const [activeToggle, setActiveToggle] = useState([]);
	const id = 'cases_v2'

    const loadData = useCallback(() => {
		setActiveToggle(JSON.parse(localStorage.getItem(`${id}_toggle`)) ?? 'all');
		setLoading(false);
    }, [id]);

	const saveActiveToggle = (toggle) => {
		localStorage.setItem(`${id}_toggle`, JSON.stringify(toggle));
		setActiveToggle(toggle);
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	return {
		activeToggle,
		saveActiveToggle,
		isLoading
	};
}

export default useMemorizedToggleSettings;