import React, { useRef } from "react";
import LoadingComponent from "components/LoadingComponent";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import "./index.scss";
import PropTypes from 'prop-types';
import { ReactComponent as AddDocumentIcon } from "assets/icons/jorato-add-document.svg"

function FileSelector(props) {

	function onChange(e) {
		const selectedFilesArray = [...e.target.files];
		props.filesSelected(selectedFilesArray);
		inputElement.current.value = "";
	}

	function onClick() {
		inputElement.current.click();
	}

	const inputElement = useRef(null);

	return (
		<div className="fileSelector" onClick={() => onClick()}>
			<input
				ref={inputElement}
				onChange={(e) => onChange(e)}
				accept={props.acceptedFileTypes}
				type="file"
			/>
			{props.isUploadingImage ? (
				<LoadingComponent variant={"image"} />
			) : (
				<div className="addFile">
					<AddDocumentIcon />
				</div>
			)}
		</div>
	)
}

FileSelector.propTypes = {
	addText: PropTypes.string,
	acceptMultiple: PropTypes.bool,
	acceptedFileTypes: PropTypes.string,
	filesSelectedFunction: PropTypes.func,
};

FileSelector.defaultProps = {
	addFileText: "Tilføj fil",
	addFileImage: <CameraAltIcon className="addImageIcon" />,
	allowMultipleFileSelection: false,
	acceptedFileTypes: "",
	filesSelected: (selectedFilesArray) => { console.log("No file selected function specified", selectedFilesArray) },
};

export default FileSelector;
