import {
  FETCH_LATEST_DRAFT_CASES,
  FETCH_LATEST_ACTIVE_CASES,
  FETCH_CASES_STATS,
  FETCH_TENANCIES_STATS,
} from "redux/actionTypes";

const dashboard = (
  state = {
    latestDraft: {
      data: [],
      loaded: false,
    },
    latestActive: {
      data: [],
      loaded: false,
    },
    caseStats: {
      loaded: false,
      data: {},
    },
    tenanciesStats: {
      loaded: false,
      data: {},
    },
  },
  action
) => {
  switch (action.type) {
    case FETCH_LATEST_DRAFT_CASES:
      return {
        ...state,
        latestDraft: {
          data: action.payload,
          loaded: true,
        },
      };
    case FETCH_LATEST_ACTIVE_CASES:
      return {
        ...state,
        latestActive: {
          data: action.payload,
          loaded: true,
        },
      };
    case FETCH_CASES_STATS:
      return {
        ...state,
        caseStats: {
          loaded: true,
          data: action.payload,
        },
      };
    case FETCH_TENANCIES_STATS:
      return {
        ...state,
        tenanciesStats: {
          loaded: true,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default dashboard;
