import React, { useEffect } from "react";
import "./index.scss";
import MyCases from "./components/MyCases";
import UpcomingEvents from "./components/UpcomingEvents";
import { useUser } from "features/user";
import Typography from "components/Typography";
import useCases from "hooks/api/useCases";
import { CircularProgress } from "@material-ui/core";
import CompletedCasesChart from "./components/CompletedCasesChart";
import CompletedCasesSummary from "./components/CompletedCasesSummary";
import CasesStateChart from "./components/CasesStateChart";

const Dashboard = props => {

    const { tenancies, properties } = props;
    const { userData } = useUser();

    const { isCasesLoading: isCompletedCasesLoading, casesData: completedCasesData, loadCurrentEmployeeCompletedCases } = useCases();

    useEffect(() => {
        loadCurrentEmployeeCompletedCases();
    }, [loadCurrentEmployeeCompletedCases]);

    if (isCompletedCasesLoading) {
        return (
            <div className="completed-cases">
                <div className="loader-container">
                    <CircularProgress className="loader" />
                </div>
            </div>
        );
    }

    return (
        <div className="dashboard-container">
            <Typography variant="subtitle" className="greeting">Velkommen, {userData.name}</Typography>
            <div className="sections-container charts">
                <div className="left">
                    <CompletedCasesChart casesData={completedCasesData} />
                </div>
                <div className="right">
                    <CasesStateChart casesData={completedCasesData} />
                </div>
            </div>
            <div className="completed-cases-summary">
                <CompletedCasesSummary casesData={completedCasesData} tenancies={tenancies} properties={properties} />
            </div>
            <div className="sections-container">
                <div className="left">
                    <MyCases />
                </div>
                <div className="right">
                    <UpcomingEvents />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;