import axios from "axios";
import { getToken } from "helpers/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.interceptors.request.use(async function (config) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

export const getOpenHouses = async () => {
        const openHouses = await axios.get("open-houses")
        return openHouses
}

export const getOpenHouse = async (id) => {
    const openHouses = await axios.get(`open-houses/${id}`)
    return openHouses.data
    
}

export const deleteOpenHouse = async (id) => {
    const openHouses = await axios.delete(`open-houses/${id}`)
    return openHouses
}

export const createOpenHouse = async (data) => {
    const result = await axios.post("open-houses", {...data});
    if (result.status === 200){
        return true;
    }
    else {
        return false;
    }
}