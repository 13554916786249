import React, { useState } from "react";
import Paper from "components/StyledPaper";
import Typography from "components/Typography";
import { TextField, Switch } from "components/Fields";

const OtherFields = ({ contractData, updatePropertyContract }) => {

	const [usageDescription, setUsageDescription] = useState(contractData.usageDescription ?? "");
	const [specialConditions, setSpecialConditions] = useState(contractData.specialConditions ?? "");
	const [leaseAdditionalDescription, setLeaseAdditionalDescription] = useState(contractData.leaseAdditionalDescription ?? "");
	const [petsSpecialConditions, setPetsSpecialConditions] = useState(contractData.petsSpecialConditions ?? "");

	return (
		<Paper className="property-contract-details__other-fields">
			<Typography variant="subtitle">Andet</Typography>
			<div className="fields-section__container">
				<div className="fields-section__field">
					<Typography variant="label">Indflytningssyn</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.isInspectedByLandlordWhenMoveIn}
							onChange={(e) => updatePropertyContract({ isInspectedByLandlordWhenMoveIn: e.target.checked })}
						/>
					</div>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Fraflytningssyn</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.isInspectedByLandlordWhenMoveOut}
							onChange={(e) => updatePropertyContract({ IsInspectedByLandlordWhenMoveOut: e.target.checked })}
						/>
					</div>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Benyttelse</Typography>
					<TextField
						value={usageDescription}
						onChange={(event) => setUsageDescription(event.target.value)}
						onExit={() => setUsageDescription(contractData.usageDescription)}
						onFinish={() => {
							usageDescription !== contractData.usageDescription && updatePropertyContract({ usageDescription })
						}}
					/>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Beboerrepræsentation</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.residentRepresentation}
							onChange={(e) => updatePropertyContract({ residentRepresentation: e.target.checked })}
						/>
					</div>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Husorden</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.houseOrder}
							onChange={(e) => updatePropertyContract({ houseOrder: e.target.checked })}
						/>
					</div>
				</div>
				<div className="fields-section__field">
					<Typography variant="label">Husdyr</Typography>
					<div className="switch-container">
						<Switch
							checked={contractData.petsAllowed}
							onChange={(e) => updatePropertyContract({ petsAllowed: e.target.checked })}
						/>
					</div>
				</div>
			</div>
			{contractData.petsAllowed && (
				<div>
					<div className="fields-section__field">
						<Typography variant="label">Særlige vilkår for husdyrtilladelsen</Typography>
						<TextField
							value={petsSpecialConditions}
							multiline
							rows={5}
							rowsMax={5}
							onChange={(event) => setPetsSpecialConditions(event.target.value)}
							onExit={() => setPetsSpecialConditions(contractData.petsSpecialConditions)}
							onFinish={() => {
								petsSpecialConditions !== contractData.petsSpecialConditions && updatePropertyContract({ petsSpecialConditions })
							}}
						/>
					</div>
				</div>
			)}
			<div>
				<div className="fields-section__field">
					<Typography variant="label">§ 10. Øvrige oplysninger om det lejede</Typography>
					<TextField
						value={leaseAdditionalDescription}
						multiline
						rows={5}
						rowsMax={20}
						onChange={(event) => setLeaseAdditionalDescription(event.target.value)}
						onExit={() => setLeaseAdditionalDescription(contractData.leaseAdditionalDescription)}
						onFinish={() => {
							leaseAdditionalDescription !== contractData.leaseAdditionalDescription && updatePropertyContract({ leaseAdditionalDescription })
						}}
					/>
				</div>
			</div>
			<div>
				<div className="fields-section__field">
					<Typography variant="label">§ 11. Særlige vilkår</Typography>
					<TextField
						value={specialConditions}
						multiline
						rows={5}
						rowsMax={20}
						onChange={(event) => setSpecialConditions(event.target.value)}
						onExit={() => setSpecialConditions(contractData.specialConditions)}
						onFinish={() => {
							specialConditions !== contractData.specialConditions && updatePropertyContract({ specialConditions })
						}}
					/>
				</div>
			</div>
		</Paper>
	);
};

export default OtherFields;