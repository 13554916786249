import axios from "./axios";

export default class ReportsService {
	static async getEmployeeShowcasingsReport(from, to, interval) {
		try {
			const result = await axios.get(`reports/employee-showcasings?from=${from}&to=${to}&interval=${interval}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getTenancyShowcasingsReport(from, to, interval) {
		try {
			const result = await axios.get(`reports/tenancy-showcasings?from=${from}&to=${to}&interval=${interval}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}

	static async getOrganizationReport(date) {
		try {
			const result = await axios.get(`reports/organization?date=${date}`);
			return result;
		}
		catch (err) {
			return null;
		}
	}
}