import { formatDate } from "helpers/date";

const columns = [
	{
		Header: "Navn",
		accessor: "name",
		disableGroupBy: true,
		disableSortBy: true,
		disableFilter: true,
		isDefault: true
	},
	{
		Header: "E-mail",
		accessor: "email",
		disableGroupBy: true,
		disableSortBy: true,
		disableFilter: true,
		isDefault: true
	},
	{
		Header: "Telefonnummer",
		accessor: "phoneNumber",
		disableGroupBy: true,
		disableSortBy: true,
		disableFilter: true,
		isDefault: true
	},
	{
		Header: "Oprettet",
		accessor: "createdAt",
		disableGroupBy: true,
		disableSortBy: true,
		disableFilter: true,
		Cell: ({ value }) => value === 0 ? "-" : `${formatDate(value, "d MMM yyyy HH:mm")}`,
		isDefault: true
	},
];

export default columns;