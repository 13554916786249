import React, { useState } from 'react';
import PrimaryButton from "components/Buttons/PrimaryButton";
import Icon from "components/Icon/Icon";
import UpdatePenneoCredentials from './UpdatePenneoCredentials';

const PenneoCredentials = ({ connected, update }) => {

	const [updateFormEnabled, setUpdateForm] = useState(false);

	if (updateFormEnabled || !connected) {
		return (
			<UpdatePenneoCredentials
				update={update}
			/>
		);
	}

	return (
		<div className="integration-status-container">
			<PrimaryButton className="status-button">Penneo konto forbundet</PrimaryButton>
			<PrimaryButton className="update-button" onClick={() => setUpdateForm(true)}><Icon type="edit" /></PrimaryButton>
		</div>
	);
};

export default PenneoCredentials;