import Showcasing from "./Showcasing";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
    
  return {
    user: state.general.user,
    employees: state.app.employees,
    tenancies: state.properties.selected.tenancies
  };
};

export default connect(mapStateToProps)(Showcasing);
