import React from "react";
import { styled } from "@material-ui/core/styles";
import MaterialSelect from "@material-ui/core/Select";

const Select = props => {
    return (
        <MaterialSelect
            {...props}
            displayEmpty
            onChange={props.onChange}
            value={props.value}
            variant="outlined"
            className={`${props.className} ${props.value.length !== 0 ? "hasValue" : ""}`}
        >
            {props.children}
        </MaterialSelect>
    );
};

export default styled(Select)({
    "&": {
        borderRadius: 30,
        border: "1px solid #A8A8A8",
        margin: "8px 0px",
        width: "100%",
        "&.Mui-disabled": {
            border: "1px solid #A8A8A8" 
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "& .MuiSelect-select": {
        color: "rgba(0, 0, 0, 0.40)",
        border: "none",
        fontFamily: "Montserrat",
        padding: "14.5px 14px 14.5px 30px",
        fontSize: "16px !important",
        "&.Mui-disabled": {
            cursor: "not-allowed",
            color: "rgba(0, 0, 0, 0.40)"
        }
    },
    "&.hasValue .MuiSelect-select": {
        color: "#566FFF"
    },
    "&.hasValue": {
        border: "1px solid #566FFF"
    }    
});
