import React from 'react';
import "./index.scss";
import Select from 'react-select';
import options from './timeOptions';

const TimePicker = ({ value, onChange}) => {

	return <Select 
		options={options} 
		value={options.filter((option) => {
			return option.value === value;
		})}
		onChange={(e) => onChange(e.value)}
		className={"styled_timepicker"}
		classNamePrefix={"styled_timepicker"}
	/>
}

export default TimePicker;