import React, { useState, useEffect } from "react";
import "./index.scss";
import Tabs, { Tab } from "components/Tabs"
import useCase from "hooks/api/useCase";
import useTenancy from "hooks/api/useTenancy";
import useProperty from "hooks/api/useProperty";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/LoadingComponent";
import GeneralInformationTab from "./components/GeneralInformationTab";
import ApplicationsTab from "./components/ApplicationsTab";
import InterestListTab from "components/InterestListTab/InterestListTab";
import CaseActions from "./components/CaseActions";
import AnalyticsTab from "./components/AnalyticsTab";


const Case = () => {

	const [tab, setTab] = useState(0);
	const { isCaseLoading, caseData, loadCase, addCaseTenancyType, removeCaseTenancyType,
		removeComment, createComment, updateCase, createCaseExpense, removeCaseExpense, createCaseContact,
		removeCaseContact, updateCaseAttribute, removeCaseAttribute, createCaseAttribute,
		isCaseIntegrationProcessing, enableCaseIntegration, disableCaseIntegration, uploadCaseImages,
		deleteCaseImage, changeCaseImageOrder, uploadCaseFloorPlans, deleteCaseFloorPlan, changeCaseFloorPlanOrder,
		deleteCase, deleteCaseProspectus, uploadCaseProspectus, updateCaseExpense } = useCase();
	const { isTenancyLoading, tenancyData, loadTenancy, updateTenancy } = useTenancy();
	const { propertyData, loadProperty, loadInterestList, isInterestListLoading, interestListData } = useProperty();
	const params = useParams();

	const handleTabChange = (_, newValue) => {
		setTab(newValue);
	};

	useEffect(() => {
		loadCase(params.id);
	}, [params.id, loadCase]);

	useEffect(() => {
		if (caseData) {
			loadTenancy(caseData.tenancyId);
		}
	}, [caseData, loadTenancy]);

	useEffect(() => {
		if (tenancyData) {
			loadProperty(tenancyData.propertyId);
		}
	}, [tenancyData, loadProperty]);

	if (isCaseLoading || isTenancyLoading) {
		return (
			<div className="loading-container"><LoadingComponent /></div>
		);
	}

	if (!caseData) {
		return (
			<div className="case-container">Error</div>
		);
	}

	return (
		<div className="case-container">
			<div className="case-header">
				<Tabs className="tabs-navigation" value={tab} onChange={handleTabChange} aria-label="case tabs navigation">
					<Tab label="Generel information" />
					<Tab
						disabled={caseData.state === "Draft"}
						label={
						<div className="applications-tab">
							Forespørgsler
							<div className="applications-counter">{caseData.applications?.length ?? 0}</div>
						</div>
					}/>
					<Tab label="Interesseliste" disabled={caseData.state === "Draft"} />
					<Tab label="Analytics" disabled={caseData.state === "Draft"} />
				</Tabs>
				<CaseActions
					caseData={caseData}
					tenancyData={tenancyData}
					propertyData={propertyData}
					updateCase={updateCase}
					createComment={createComment}
					deleteCase={deleteCase} />
			</div>

			<div className="tab-content">
				{tab === 0 &&
					<GeneralInformationTab
						caseData={caseData}
						tenancyData={tenancyData}
						updateCase={updateCase}
						updateTenancy={updateTenancy}
						removeComment={removeComment}
						createComment={createComment}
						addCaseTenancyType={addCaseTenancyType}
						removeCaseTenancyType={removeCaseTenancyType}
						createCaseExpense={createCaseExpense}
						removeCaseExpense={removeCaseExpense}
						createCaseContact={createCaseContact}
						removeCaseContact={removeCaseContact}
						updateCaseAttribute={updateCaseAttribute}
						removeCaseAttribute={removeCaseAttribute}
						createCaseAttribute={createCaseAttribute}
						isCaseIntegrationProcessing={isCaseIntegrationProcessing}
						enableCaseIntegration={enableCaseIntegration}
						disableCaseIntegration={disableCaseIntegration}
						uploadCaseImages={uploadCaseImages}
						deleteCaseImage={deleteCaseImage}
						changeCaseImageOrder={changeCaseImageOrder}
						uploadCaseFloorPlans={uploadCaseFloorPlans}
						deleteCaseFloorPlan={deleteCaseFloorPlan}
						changeCaseFloorPlanOrder={changeCaseFloorPlanOrder}
						integrationId={tenancyData.integration ? tenancyData.integration.id : null}
						integrationData={tenancyData.integration ? tenancyData.integration.data : null}
						deleteProspectus={deleteCaseProspectus}
						uploadProspectus={uploadCaseProspectus}
						updateCaseExpense={updateCaseExpense}
					/>
				}
				{tab === 1 &&
					<ApplicationsTab
						applications={caseData.applications}
					/>
				}
				{tab === 2 &&
					<InterestListTab
						load={loadInterestList}
						isLoading={isInterestListLoading}
						data={interestListData}
					/>
				}
				{tab === 3 &&
					<AnalyticsTab 
						tenancyData={tenancyData}
					/>
				}
			</div>
		</div>
	);
}

export default Case;
