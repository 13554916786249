const columns = [
    {
        Header: "Navn",
        accessor: "name",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        defaultCanFilter: true
    },
    {
        Header: "E-mail",
        accessor: "email",
        aggregate: "unique",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`        
    },
    {
        Header: "Telefonnummer",
        accessor: "phone",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`,     
        defaultCanFilter: true
    },
    {
        Header: "Prioritet",
        accessor: "priority",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`,     
        defaultCanFilter: true
    }
];

export default columns;