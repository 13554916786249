import React from "react";
import LineChart from "components/LineChart";
import { formatDate } from 'helpers/date';

const WebStatsChart = ({ data }) => {

    return (
		<LineChart
			labels={data.map(x => formatDate(x.date, 'dd/MM'))}
			datasets={[
				{
					label: "Web Views",
					data: data.map(x => x.views),
					borderColor: '#2C3589',
					backgroundColor: 'rgba(44, 53, 137, 0.5)',
					yAxisID: 'y'
				},
			]}
			scales={{
				x: {
					grid: {
						display: true
					}
				},
				y: {
					type: 'linear',
					display: true,
					position: 'left',
					suggestedMin: 0,
					suggestedMax: 5,
					grid: {
						display: true
					},
					ticks: {
						stepSize: 10
					}
				}
			}}
			hideLegend
		/>
    );
};
export default WebStatsChart;