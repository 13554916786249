const getStartsAtFromParams = (startDate, startTime) => {
	const d = new Date(startDate)
    const t = startTime.split(':')
    const hour = parseInt(t[0])
    const minute = parseInt(t[1])
    d.setHours(hour)
    d.setMinutes(minute)
	const startsAt = d.toISOString().replace(/\.000Z$/, 'Z')
	return startsAt;
}
export default getStartsAtFromParams;