import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";
import Container from "./components/Container";
import { TableCell, TableRow } from "@material-ui/core";
import Icon from "components/Icon";
import ActionButton from "components/Buttons/ActionButton";

const ResourceTable = props => {

    const { form, onFormClose, sizes, readOnly } = props;

    const [isFormActive, setFormActive] = useState(false);

    const formCloseHandler = () => {
        if (isFormActive) {
            onFormClose();
        }
        setFormActive(!isFormActive);
    }

    return (
        <Container>
            <div className="resource-table__header">
                {props.title}
                {form &&
                    <ActionButton
                        disabled={readOnly}
                        onClick={formCloseHandler}
                        className={`resource-table__header__new-resource`}>
                        {!isFormActive ? <Icon type="plus" /> : <Icon type="close" />}
                    </ActionButton>
                }
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {props.headers.map((header, ix) => {
                            return <TableCell key={ix} width={sizes?.length >= ix ? sizes[ix] : undefined}>{header}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.children}
                    {isFormActive && form}
                </TableBody>
            </Table>
        </Container>
    );
};

export default ResourceTable;