import { styled } from "@material-ui/core/styles";

const Container = styled('div')({
    '& .resource-table__header': {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
    }
});

export default Container;