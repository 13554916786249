const columns = [
    {
        Header: "Fremviser",
        accessor: "hostNames",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
        defaultCanFilter: true
    },
    {
        Header: "Dato",
        accessor: "date",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`,     
        defaultCanFilter: true
    },
    {
        Header: "Tidspunkt",
        accessor: "time",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`,     
        defaultCanFilter: true
    },
    {
        Header: "Lejemål",
        accessor: "locationNames",
        disableGroupBy: true,
        Aggregated: ({ value }) => `${value}`,     
        defaultCanFilter: true
    },
    {
        Header: "Ejendom",
        accessor: "propertyName",
        disableGroupBy: true,
        Aggregated: ({ value }) => `-`,
    }
];

export default columns;