import React, { useEffect, useMemo, useState } from 'react'
import './index.scss'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useUser } from 'features/user'
import { useCreateShowcasing } from 'hooks/api'
import { useCase } from 'hooks/api'
import { useToasts } from 'features/toasts'
import { useImmerReducer } from 'use-immer'
import { useShowcasing } from 'hooks/api'
import ShowcasingsService from 'services/api/showcasings'
import TenanciesService from 'services/api/tenancies'

import LoadingComponent from 'components/LoadingComponent'
import HeaderContainer from 'components/HeaderContainer'
import Typography from 'components/Typography'
import ShowcasingLanguages from 'helpers/showcasing-languages'
import getInitialStartTime from 'helpers/event-initial-date-and-time'
import getStartsAtFromParams from 'helpers/get-starts-at-from-params'
import Content from './Components/Content'
import states from './states'
import reducer from './reducer'

export default function Showcasing (props) {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const { userData } = useUser()
  const addToast = useToasts()
  const { createShowcasing } = useCreateShowcasing()
  const { caseData, loadCase } = useCase()
  const [isLoading, setIsLoading] = useState(false)
  const {
    isShowcasingLoading,
    showcasingData,
    loadShowcasing,
    updateShowcasing,
    deleteShowcasing
  } = useShowcasing()

  const initialState = {
    caseQuery: '',
    caseId: '',
    step: states.input,
    startDate: getInitialStartTime().date,
    startTime: getInitialStartTime().hour,
    hostId: userData.sub,
    duration: '15',
    guestName: '',
    guestEmail: '',
    communicationLanguage: ShowcasingLanguages[0].value,
    guestPhoneExtension: '+45',
    guestPhoneNumber: '',
    isVirtual: false,
    hasAlternativeMeetingLocation: false,
    alternativeMeetingLocation: ''
  }
  const [state, dispatch] = useImmerReducer(reducer, initialState)

  const isEditing = useMemo(() => {
    return params.id ? true : false
  }, [params.id])

  const hasUpdates = useMemo(() => {
    let update = {}
    if (showcasingData && caseData) {
      if (caseData.tenancyId !== showcasingData.tenancyId) {
        update.tenancyId = caseData.tenancyId
      }
      if (state.guestEmail !== showcasingData.guest.email) {
        update.guest = update.guest || {}
        update.guest.email = state.guestEmail
      }
      if (state.guestName !== showcasingData.guest.name) {
        update.guest = update.guest || {}
        update.guest.name = state.guestName
      }
      if (state.guestPhoneExtension !== showcasingData.guest.phone.extension) {
        update.guest = update.guest || {}
        update.guest.phone = update.guest.phone || {}
        update.guest.phone.extension = state.guestPhoneExtension
      }
      if (state.guestPhoneNumber !== showcasingData.guest.phone.number) {
        update.guest = update.guest || {}
        update.guest.phone = update.guest.phone || {}
        update.guest.phone.number = state.guestPhoneNumber
      }
      if (state.hostId !== showcasingData.hostId) {
        update.hostId = state.hostId
      }
      if (state.duration !== showcasingData.schedule.duration) {
        update.schedule = update.schedule || {}
        update.schedule.duration = state.duration
      }
      const startsAt = getStartsAtFromParams(state.startDate, state.startTime)
      if (startsAt !== showcasingData.schedule.startsAt) {
        update.schedule = update.schedule || {}
        update.schedule.startDate = state.startDate
      }
      if (state.isVirtual !== showcasingData.isOnline) {
        update.isVirtual = state.isVirtual
      }
      if (state.alternativeMeetingLocation !== showcasingData.meetingLocation) {
        update.alternativeMeetingLocation = state.alternativeMeetingLocation
      }
      if (
        state.communicationLanguage !== showcasingData.communicationLanguage
      ) {
        update.communicationLanguage = state.communicationLanguage
      }
    }
    return update
  }, [state, showcasingData, caseData])

  // if the user is editing a showcasing, load the showcasing data. Setting the case id resault in the case being loaded.
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const caseId = query.get('caseId')
    if (caseId) {
      dispatch({ type: 'SET_CASE_ID', payload: caseId })
    }
  }, [])

  // if the user is editing a showcasing, load the showcasing data. Then load the tenancy data and set the case id to the current case, which results in the case being loaded.
  useEffect(() => {
    if (params.id) {
      const startFromParam = async () => {
        const showcasing = await ShowcasingsService.getShowcasing(params.id)
        if (showcasing) {
          dispatch({ type: 'LOAD_SHOWCASING', payload: showcasing.data })
          const tenancy = await TenanciesService.getTenancy(
            showcasing.data.tenancyId
          )
          if (tenancy.data.currentCase) {
            dispatch({ type: 'SET_CASE_ID', payload: tenancy.data.currentCase })
          }
        }
      }
      startFromParam()
      loadShowcasing(params.id)
    } else {
      setIsLoading(false)
    }
  }, [params.id, loadShowcasing])

  // load showcasing data into the reducer when it's retrieved from the api
  useEffect(() => {
    if (showcasingData && !isShowcasingLoading) {
      dispatch({ type: 'LOAD_SHOWCASING', payload: showcasingData })
    }
  }, [showcasingData, isShowcasingLoading])

  // when user selects a case from the dropdown, load the full case data
  useEffect(() => {
    if (state.caseId !== '' && state.caseId !== undefined) {
      loadCase(state.caseId)
    }
  }, [state.caseId])

  // when case data is loaded, set the case query to the case address
  useEffect(() => {
    if (caseData && state.selectedCaseId !== '' && state.caseQuery === '') {
      dispatch({ type: 'SET_CASE_QUERY', payload: caseData.address.street })
      setIsLoading(false)
    }
  }, [caseData])

  // when employee data is loaded, set the host id to the current user
  useEffect(() => {
    if (userData.sub && props.employees && !isEditing) {
      dispatch({ type: 'SET_HOST_ID', payload: userData.sub })
    }
  }, [userData, props.employees])

  const submitDisabled = useMemo(() => {
    if (
      Object.keys(hasUpdates).length === 0 &&
      isEditing === false &&
      state.selectedCaseId !== '' &&
      state.startDate !== '' &&
      state.startTime !== '' &&
      state.hostId !== ''
    ) {
      return false
    }
    if (isEditing && Object.keys(hasUpdates).length !== 0) {
      return false
    }

    return true
  }, [
    props.employees,
    state.selectedCaseId,
    state.startDate,
    state.startTime,
    state.hostId,
    hasUpdates,
    isEditing
  ])

  const hasHostChanged = useMemo(() => {
    if (state.hostId && showcasingData?.hostId) {
      return state.hostId !== showcasingData.hostId ? true : false
    }
    return false
  }, [state.hostId, showcasingData])

  const employeeSelectOptions = useMemo(() => {
    if (props.employees.loaded) {
      const options = props.employees.data.map(e => {
        let r = {}
        r.value = e.employeeId
        r.label = e.name
        return r
      })
      return options
    } else {
      return []
    }
  }, [props.employees])

  const hostOutlookEnabled = useMemo(() => {
    if (props.employees.loaded && state.hostId) {
      const host = props.employees.data.find(e => e.employeeId === state.hostId)
      return host.outlookConnected
    }
    return false
  }, [props.employees, state.hostId])

  const handleCreate = async () => {
    // not handling scenario where case is not loaded before create is clicked
    const d = new Date(state.startDate)
    const t = state.startTime.split(':')
    const hour = parseInt(t[0])
    const minute = parseInt(t[1])
    d.setHours(hour)
    d.setMinutes(minute)
    const startsAt = d.toISOString()
    const success = await createShowcasing(
      caseData.tenancyId,
      state.guestEmail,
      state.guestName,
      state.guestPhoneExtension,
      state.guestPhoneNumber,
      state.hostId,
      state.duration,
      startsAt,
      state.isVirtual,
      state.alternativeMeetingLocation,
      state.communicationLanguage
    )
    if (success) {
      dispatch({ type: 'SET_STEP', payload: states.success })
    } else {
      addToast('Oprettelse af fremvisning fejlede. Prøv igen.')
      dispatch({ type: 'SET_STEP', payload: states.input })
    }
  }
  const handleBack = () => {
    history.push(`/showcasings`)
  }

  const handleUpdate = () => {
    let update = {}
    if (caseData.tenancyId !== showcasingData.tenancyId) {
      update.tenancyId = caseData.tenancyId
    }
    if (state.guestEmail !== showcasingData.guest.email) {
      update.guest = update.guest || {}
      update.guest.email = state.guestEmail
    }
    if (state.guestName !== showcasingData.guest.name) {
      update.guest = update.guest || {}
      update.guest.name = state.guestName
    }
    if (state.guestPhoneExtension !== showcasingData.guest.phone.extension) {
      update.guest = update.guest || {}
      update.guest.phone = update.guest.phone || {}
      update.guest.phone.extension = state.guestPhoneExtension
    }
    if (state.guestPhoneNumber !== showcasingData.guest.phone.number) {
      update.guest = update.guest || {}
      update.guest.phone = update.guest.phone || {}
      update.guest.phone.number = state.guestPhoneNumber
    }
    if (state.hostId !== showcasingData.hostId) {
      update.hostId = state.hostId
    }
    if (state.duration !== showcasingData.schedule.duration) {
      update.schedule = update.schedule || {}
      update.schedule.duration = state.duration
    }
    const startsAt = getStartsAtFromParams(state.startDate, state.startTime)
    if (startsAt !== showcasingData.schedule.startsAt) {
      update.schedule = update.schedule || {}
      update.schedule.startDate = state.startDate
    }
    if (state.isVirtual !== showcasingData.isOnline) {
      update.isVirtual = state.isVirtual
    }
    if (state.alternativeMeetingLocation !== showcasingData.meetingLocation) {
      update.alternativeMeetingLocation = state.alternativeMeetingLocation
    }
    if (state.communicationLanguage !== showcasingData.communicationLanguage) {
      update.communicationLanguage = state.communicationLanguage
    }
    updateShowcasing(update)
    dispatch({ type: 'SET_STEP', payload: states.success })
  }

  const handleDelete = () => {
	deleteShowcasing()
	dispatch({ type: 'SET_STEP', payload: states.deleted })
  }


  if (isLoading) return <LoadingComponent />

  return (
    <div className='showcasings-create'>
      <HeaderContainer>
        <Typography variant='title'>
          {isEditing ? 'Rediger fremvisning' : 'Opret fremvisning'}
        </Typography>
      </HeaderContainer>
      <Content
        employeeSelectOptions={employeeSelectOptions}
        submitDisabled={submitDisabled}
        handleBack={handleBack}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
		handleDelete={handleDelete}
        state={state}
        dispatch={dispatch}
        hostOutlookEnabled={hostOutlookEnabled}
        isEditing={isEditing}
        hasHostChanged={hasHostChanged}
        hasUpdates={hasUpdates}
      />
    </div>
  )
}
