import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import Typography from "components/Typography";
import StyledPaper from "components/StyledPaper";
import StyledTextField from "components/StyledTextField";
import StyledAutocomplete from "components/StyledAutocomplete";
import StyledSelect from "components/StyledSelect";
import StyledButton from "components/StyledButton";
import LoadingComponent from "components/LoadingComponent";
import { TextField } from "components/Fields";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ReactComponent as CheckedIcon } from "assets/icons/confirmedChecked.svg";
import { ReactComponent as GeneralIcon } from "assets/icons/Generelt.svg";
import { ReactComponent as KeyIcon } from "assets/icons/KeyIcon.svg";
import { ReactComponent as TimeIcon } from "assets/icons/Tidspunkt.svg";

import { createOpenHouse } from "helpers/api/openhouses";
import { getPropertyKinds, getPropertyTenancies } from "helpers/api/properties";
import "./index.scss";

export default function CreateOpenHouse(props) {
	const [isLoading, setIsLoading] = useState(true);
	const [createEnabled, setCreateEnabled] = useState(false);
	const [description, setDescription] = useState("");
	const [startsAt, setStartsAt] = useState("");
	const [duration, setDuration] = useState("");
	const [hosts, setHosts] = useState([]);
	const [hostsLocations, setHostsLocations] = useState([]);
	const [properties, setProperties] = useState(null);
	const [employees, setEmployees] = useState(null);
	const [selectedProperty, setSelectedProperty] = useState("");
	const [selectedPropertyTenancies, setSelectedPropertyTenancies] = useState([]);
	const [selectedTenancies, setSelectedTenancies] = useState([]);
	const [showSuccessPage, setshowSuccessPage] = useState(false);
	const [meetingLocation, setMeetingLocation] = useState("");
	const [announceForAllTenancies, setAnnounceForAllTenancies] = useState(false);

	const timeValues = [
		{ value: 15, label: "15 minutter" },
		{ value: 30, label: "30 minutter" },
		{ value: 45, label: "45 minutter" },
		{ value: 60, label: "60 minutter" },
	];

	useEffect(() => {
		if (props.properties.loaded && props.employees.loaded) {
			setProperties(props.properties.data);
			setEmployees(props.employees.data);
			setIsLoading(false);
		}
	}, [props.properties, props.employees]);

	useEffect(() => {
		const updateKindsAndTenancies = async (propertyId) => {
			const kinds = await getPropertyKinds(propertyId);
			const tenancies = await getPropertyTenancies(propertyId);
			setSelectedPropertyTenancies(tenancies);
		};
		if (selectedProperty !== "") {
			updateKindsAndTenancies(selectedProperty.id);
		} else {
			setSelectedTenancies([]);
			setHostsLocations([]);
			setSelectedPropertyTenancies([]);
		}
	}, [selectedProperty]);

	useEffect(() => {
		startsAt !== "" &&
			(selectedTenancies.length > 0 || announceForAllTenancies) && hosts.length > 0
			? setCreateEnabled(true)
			: setCreateEnabled(false);
	}, [startsAt, selectedTenancies, hosts, hostsLocations, announceForAllTenancies]);

	const onCreate = async () => {
		const newOpenHouse = {
			description: description,
			property: selectedProperty.id,
			tenancies: selectedTenancies.map((tenancy) => tenancy.id),
			hosts: hosts.map((host) => host.employeeId),
			scheduled: {
				startsAt: new Date(startsAt),
				duration: parseInt(duration),
			},
			announceForAllTenancies: announceForAllTenancies,
			meetingLocation: meetingLocation
		};
		const success = await createOpenHouse(newOpenHouse);
		if (success) {
			// success
		}
	};

	if (isLoading) return <LoadingComponent />;

	if (showSuccessPage) {
		return (
			<div className="openhouseSuccessContainer">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<CheckedIcon style={{ marginBottom: "24px" }} />
					<h1 style={{ color: "#515386", margin: "24px 0px" }}>
						Fremvisning er oprettet.
					</h1>
					<Button
						className="goBackButton"
						onClick={() => (window.location.href = "/openhouses")}
						variant="contained"
					>
						Fortsæt
					</Button>
				</div>
			</div>
		);
	}

	return (
		<Grid container className="pageContainer openhousesContainer" spacing={2}>
			<Grid className="header" item xs={12}>
				<Typography variant="title">
					Åbent hus
				</Typography>
				<div>
					<Link to="/openhouses">
						<StyledButton disableElevation={true} variant="contained">
							Annuller
						</StyledButton>
					</Link>
					<StyledButton
						disabled={!createEnabled}
						style={{ marginLeft: "16px" }}
						onClick={() => onCreate()}
						disableElevation={true}
						variant="contained"
						color="primary"
					>
						Opret fremvisning
					</StyledButton>
				</div>
			</Grid>
			<Grid item container xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container item xs={12} spacing={2}>
						<Grid className="headerSection" item xs={12}>
							<KeyIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
								className="titleIcon"
							/>
							<Typography variant="subtitle">
								Lejemål til åbent hus
							</Typography>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Typography variant="label">
								Ejendom
							</Typography>
							<StyledAutocomplete
								options={properties}
								getOptionLabel={(option) => option ? option.title : ""}
								renderInput={(params) => (
									<StyledTextField {...params} variant="outlined" placeholder="Vælg ejendom" />
								)}
								onChange={(value) => {
									setSelectedProperty(value ? value : "");
								}}
								getOptionSelected={(option, value) => {
									if (value === "" || value.id === option.id) {
										return true;
									}
								}}
								value={selectedProperty ? selectedProperty : ""}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<div className="selectSection">
								<Typography variant="label">
									Mødested
								</Typography>
								<TextField
									value={meetingLocation}
									onChange={(event) => setMeetingLocation(event.target.value)}
								/>
							</div>
						</Grid>
						<Grid className="tenancies-selector" item xs={12} lg={12}>
							<Typography variant="label">
								Vis på alle lejemål
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										style={{ color: "#566FFF" }}
										checked={announceForAllTenancies}
										onChange={() => setAnnounceForAllTenancies(!announceForAllTenancies)}
										name="checkedA"
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={12}>
							<div className="selectSection">
								<Typography variant="label">
									Lejemål
								</Typography>
								<StyledAutocomplete
									fullWidth
									multiple
									options={selectedPropertyTenancies}
									disabled={
										selectedProperty === "" ||
										selectedPropertyTenancies.length === 0
									}
									getOptionLabel={(option) => {
										return option ?
											selectedPropertyTenancies.find((e) => e.id === option.id).address.street :
											"";
									}
									}
									renderOption={(option) => <>{option.address.street}</>}
									getOptionSelected={(option, value) => {
										if (value.length === 0 || value.id === option.id) {
											return true;
										}
									}}
									renderInput={(params) => (
										<StyledTextField
											{...params}
											placeholder={
												selectedProperty === ""
													? "Vælg lejemål"
													: selectedPropertyTenancies.length === 0
														? "Ejendommen har ingen lejemål"
														: "Vælg lejemål"
											}
											variant="outlined"
										/>
									)}
									noOptionsText={"Ejendommen har ingen lejemål"}
									value={selectedTenancies ? selectedTenancies : ""}
									onChange={(value) => {
										setSelectedTenancies(value ? value : []);
									}}
								/>
							</div>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
			<Grid item container xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container item xs={12} spacing={2}>
						<Grid className="headerSection" item xs={12}>
							<GeneralIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
								className="titleIcon"
							/>
							<Typography variant="subtitle">
								Yderligere information
							</Typography>
						</Grid>
						<Grid item xs={12} lg={12}>
							<Typography variant="label">
								Beskrivelse (valgfri)
							</Typography>
							<StyledTextField
								placeholder="Tilføj en eventuel beskrivelse"
								inputProps={{ style: { minHeight: "150px" } }}
								value={description}
								className="descriptionTextField"
								onChange={(value) => setDescription(value)}
								multiline
								variant="outlined"
							/>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
			<Grid item container xs={12} md={6}>
				<StyledPaper elevation={0} >
					<Grid container spacing={2}>
						<Grid item className="headerSection" xs={12}>
							<TimeIcon
								stroke={"#2c3b8e"}
								fill={"#2c3b8e"}
								className="titleIcon"
							/>
							<Typography variant="subtitle">
								Tidspunkt og Fremviser
							</Typography>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Typography variant="label">
								Start Tidspunkt
							</Typography>
							<StyledTextField
								type="datetime-local"
								value={startsAt}
								onChange={(value) => setStartsAt(value)}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Typography variant="label">
								Varighed
							</Typography>
							<StyledSelect
								displayEmpty
								onChange={(e) => setDuration(e.target.value)}
								renderValue={(option) =>
									option ? (
										timeValues.find((e) => option === e.value).label
									) : (
										<p className="unselected">Vælg varighed</p>
									)
								}
								value={duration}
								variant="outlined"
							>
								{timeValues.map((e, index) => (
									<MenuItem key={index} value={e.value}>
										{`${e.label}`}
									</MenuItem>
								))}
							</StyledSelect>
						</Grid>
						<Grid item xs={12} lg={12}>
							<Typography variant="label">
								Fremviser
							</Typography>
							<StyledAutocomplete
								multiple
								options={employees}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<StyledTextField {...params} variant="outlined" placeholder="Vælg fremviser" />
								)}
								onChange={(value) => setHosts(value)}
							/>
						</Grid>
					</Grid>
				</StyledPaper>
			</Grid>
			<Grid item xs={12}>
				<Grid container justify="flex-end">
					<Grid item>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
