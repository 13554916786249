//Module imports
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "@material-ui/core/Slider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";


import Typography from "components/Typography";
import StyledTextField from "components/StyledTextField";
import StyledPaper from "components/StyledPaper";
import StyledSelect from "components/StyledSelect";
import StyledAutocomplete from "components/StyledAutocomplete";
import StyledButton from "components/StyledButton";
import LoadingComponent from "components/LoadingComponent";

import { ReactComponent as PersonIcon } from "assets/icons/jorato-person.svg";
import { ReactComponent as PriorityIcon } from "assets/icons/jorato-priority.svg";
import { ReactComponent as InterestsIcon } from "assets/icons/jorato-interests.svg";
import { ReactComponent as CommentsIcon } from "assets/icons/jorato-comments.svg";
import { ReactComponent as AddedIcon } from "assets/icons/jorato-added.svg";
import { ReactComponent as PlusIcon } from "assets/icons/jorato-plus.svg";
import { ReactComponent as CheckmarkIcon } from "assets/icons/jorato-checkmark.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/jorato-delete.svg";

import { getPropertyKinds, getPropertyTenancies } from "helpers/api/properties";

import {
  createInterestList,
  createInterestListInterest,
  createInterestListComment,
  addInterestListOwner,
} from "helpers/api/interestLists";

import "./index.scss";

export default function CreateInterestList(props) {
  const phoneExtensions = [
    { value: 44, label: "UK" },
    { value: 45, label: "DK" },
    { value: 46, label: "SE" },
    { value: 47, label: "NO" },
    { value: 48, label: "PL" },
    { value: 49, label: "DE" },
    { value: 354, label: "IS" },
    { value: 299, label: "GL" },
    { value: 33, label: "FR" },
  ];

  const priorities = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [createEnabled, setCreateEnabled] = useState(false);

  const [primaryContactName, setPrimaryContactName] = useState("");
  const [primaryContactEmail, setPrimaryContactEmail] = useState("");
  const [
    primaryContactPhoneExtension,
    setPrimaryContactPhoneExtension,
  ] = useState(45);
  const [primaryContactPhoneNumber, setPrimaryContactPhoneNumber] = useState(
    ""
  );

  const [secondaryContactName, setSecondaryContactName] = useState("");
  const [secondaryContactEmail, setSecondaryContactEmail] = useState("");
  const [
    secondaryContactPhoneExtension,
    setSecondaryContactPhoneExtension,
  ] = useState(45);
  const [
    secondaryContactPhoneNumber,
    setSecondaryContactPhoneNumber,
  ] = useState("");

  const [priority, setPriority] = useState(3);

  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedPropertyKinds, setSelectedPropertyKinds] = useState([]);
  const [selectedPropertyTenancies, setSelectedPropertyTenancies] = useState(
    []
  );
  const [selectedKind, setSelectedKind] = useState("");
  const [selectedTenancy, setSelectedTenancy] = useState("");
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [
    selectedInterestsDisplayData,
    setSelectedInterestsDisplayData,
  ] = useState([]);

  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const updateKindsAndTenancies = async (propertyId) => {
      const kinds = await getPropertyKinds(propertyId);
      const tenancies = await getPropertyTenancies(propertyId);
      setSelectedPropertyKinds(kinds);
      setSelectedPropertyTenancies(tenancies);
    };
    if (selectedProperty !== "") {
      updateKindsAndTenancies(selectedProperty.id);
    }
    setSelectedKind("");
    setSelectedTenancy("");
  }, [selectedProperty]);

  useEffect(() => {
    const getInterestLabel = (interest) => {
      if (interest.propertyId) {
        const propertyName = properties.find((e) => (e.id === interest.propertyId))
          .title;
        if (interest.tenancyType) {
          return propertyName + " - type: " + interest.tenancyType;
        } else return propertyName;
      } else if (interest.tenancyId) {
        const tenancy = props.tenancies.data.find(
          (e) => e.id === interest.tenancyId
        );
        const propertyName = properties.find((e) => e.id === tenancy.propertyId)
          .title;
        return propertyName + " - " + tenancy.address.street;
      }
    };
    
    if (properties.length !== 0 && props.tenancies.loaded) {
      let data = [];
      selectedInterests.forEach((e) => {
        data.push(getInterestLabel(e));
      });
      setSelectedInterestsDisplayData(data);
    }
  }, [selectedInterests, properties, props.tenancies]);

  useEffect(() => {
    if (props.properties.loaded) {
      setProperties(props.properties.data);
      setIsLoading(false);
    }
  }, [props.properties]);

  useEffect(() => {
    primaryContactName !== "" &&
    primaryContactEmail !== "" &&
    primaryContactPhoneNumber !== "" &&
    selectedInterests.length > 0
      ? setCreateEnabled(true)
      : setCreateEnabled(false);
  }, [
    primaryContactName,
    primaryContactEmail,
    primaryContactPhoneNumber,
    selectedInterests,
  ]);

  

  const onDeleteInterest = (index) => {
    const newSelectedInterests = selectedInterests.filter((elem, elemIndex) => index !== elemIndex);
    setSelectedInterests(newSelectedInterests)
  };

  const onDeleteComment = (index) => {
    const newComments = comments.filter((elem, elemIndex) => index !== elemIndex);
    setComments(newComments)
  };

  const onAddInterest = async (type) => {
    switch (type) {
      case "property":
        setSelectedInterests([
          ...selectedInterests,
          { propertyId: selectedProperty.id },
        ]);
        break;
      case "kind":
        setSelectedInterests([
          ...selectedInterests,
          { propertyId: selectedProperty.id, tenancyType: selectedKind },
        ]);
        break;
      case "tenancy":
        setSelectedInterests([
          ...selectedInterests,
          { tenancyId: selectedTenancy },
        ]);
        break;
      default:
        return;
    }
  };

  const onAddComment = async () => {
    const author = props.user;
    setComments([
      ...comments,
      {
        author: author,
        date: new Date(),
        body: newComment,
      },
    ]);
    setNewComment("");
  };

  const onCreateInterestsList = async () => {
    const listObject = {
      email: primaryContactEmail,
      name: primaryContactName,
      phone: {
        extension: primaryContactPhoneExtension.toString(),
        number: primaryContactPhoneNumber,
      },
      priority: priority,
    };

    const createdInterestList = await createInterestList(listObject);

    const promises = [];

    selectedInterests.forEach((e) => {
      promises.push(createInterestListInterest(createdInterestList.id, e));
    });
    comments.forEach((e) => {
      promises.push(createInterestListComment(createdInterestList.id, e.body));
    });

    const secondaryOwnerObject = {
      email: secondaryContactEmail,
      name: secondaryContactName,
      phone: {
        extension: secondaryContactPhoneExtension.toString(),
        number: secondaryContactPhoneNumber,
      },
    };

    if(secondaryContactEmail !== "" && secondaryContactName !== "" && secondaryContactPhoneNumber !== ""){
      promises.push(addInterestListOwner(createdInterestList.id, secondaryOwnerObject));
    }
    
    history.push("/interestlists");
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Grid container className="interestListContainer" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="title">
          Interesseliste
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper elevation={0}>
          <Grid container spacing={2}>
            <Grid className="headerSection" item xs={12}>
              <PersonIcon
                stroke={"#2c3b8e"}
                fill={"#2c3b8e"}
                className="titleIcon"
              />
              <Typography variant="subtitle">
                Primær Kontakt
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="label">
								Navn
							</Typography>
              <StyledTextField
                value={primaryContactName}
                onChange={(value) => setPrimaryContactName(value)}
                placeholder="Navn"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="label">
                E-mail
							</Typography>
              <StyledTextField
                value={primaryContactEmail}
                onChange={(value) => setPrimaryContactEmail(value)}
                placeholder="E-mail"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={6}>
              <Typography variant="label">
                Telefonnummer
							</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <StyledSelect
                    displayEmpty
                    onChange={(e) =>
                      setPrimaryContactPhoneExtension(e.target.value)
                    }
                    renderValue={(option) =>
                      `${
                        phoneExtensions.find((e) => option === e.value).label
                      } (+${option})`
                    }
                    value={primaryContactPhoneExtension}
                    variant="outlined"
                  >
                    {phoneExtensions.map((e, index) => (
                      <MenuItem key={index} value={e.value}>
                        {`${e.label} (+${e.value})`}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    value={primaryContactPhoneNumber}
                    onChange={(value) =>
                      setPrimaryContactPhoneNumber(value)
                    }
                    placeholder="Telefonnummer"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <StyledPaper elevation={0}>
          <Grid container spacing={2}>
            <Grid className="headerSection" item xs={12}>
              <PersonIcon
                stroke={"#2c3b8e"}
                fill={"#2c3b8e"}
                className="titleIcon"
              />
              <Typography variant="subtitle">
                Sekundær Kontakt
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="label">
								Navn
							</Typography>
              <StyledTextField
                value={secondaryContactName}
                onChange={(value) => setSecondaryContactName(value)}
                placeholder="Navn"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="label">
								E-mail
							</Typography>
              <StyledTextField
                value={secondaryContactEmail}
                onChange={(value) => setSecondaryContactEmail(value)}
                placeholder="E-mail"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={12} xl={6}>
              <Typography variant="label">
                Telefonnummer
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <StyledSelect
                    displayEmpty
                    onChange={(e) =>
                      setSecondaryContactPhoneExtension(e.target.value)
                    }
                    renderValue={(option) =>
                      `${
                        phoneExtensions.find((e) => option === e.value).label
                      } (+${option})`
                    }
                    value={secondaryContactPhoneExtension}
                    variant="outlined"
                  >
                    {phoneExtensions.map((e, index) => (
                      <MenuItem key={index} value={e.value}>
                        {`${e.label} (+${e.value})`}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Grid>
                <Grid item xs={8}>
                  <StyledTextField
                    onChange={(value) =>
                      setSecondaryContactPhoneNumber(value)
                    }
                    value={secondaryContactPhoneNumber}
                    placeholder="Telefonnummer"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper className="priortyContainer" elevation={0}>
          <Grid container spacing={2}>
            <Grid item className="headerSection" xs={12}>
              <PriorityIcon
                stroke={"#2c3b8e"}
                fill={"#2c3b8e"}
                className="titleIcon"
              />
              <Typography variant="subtitle">
                Prioritet
              </Typography>
            </Grid>
            <Grid className="slider" item xs={12}>
              <Slider
                value={priority}
                valueLabelFormat={(value) => value}
                getAriaValueText={(value) => value}
                aria-labelledby="discrete-slider-restrict"
                onChangeCommitted={(e, v) => setPriority(v)}
                step={1}
                max={5}
                min={1}
                track={false}
                valueLabelDisplay={"off"}
                marks={priorities}
              />
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper elevation={0}>
          <Grid container className="interests" spacing={10}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid className="headerSection" item xs={12}>
                  <InterestsIcon
                    stroke={"#2c3b8e"}
                    fill={"#2c3b8e"}
                    className="titleIcon"
                  />
                  <Typography variant="subtitle">
                    Interesse
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                    <Typography variant="label">
                      Label
                    </Typography>
                    </Grid>
                    <Grid className="autocompleteWithAddButton" item xs={12}>
                      <StyledAutocomplete
                        fullWidth
                        options={properties}
                        getOptionLabel={(option) => option.title}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            placeholder="Vælg ejendom"
                            variant="outlined"
                          />
                        )}
                        value={selectedProperty}
                        onChange={(newValue) => {
                          setSelectedProperty(newValue ? newValue : "");
                        }}
                      />
                      <IconButton
                        disabled={selectedProperty === ""}
                        onClick={() => onAddInterest("property")}
                        className="addButton"
                        color="primary"
                      >
                        <PlusIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="label">
                        Type
                      </Typography>
                    </Grid>
                    <Grid className="autocompleteWithAddButton" item xs={12}>
                      <StyledAutocomplete
                        fullWidth
                        options={selectedPropertyKinds}
                        disabled={
                          selectedProperty === "" ||
                          selectedPropertyKinds.length === 0
                        }
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            placeholder={
                              selectedProperty === ""
                                ? "Vælg type"
                                : selectedPropertyKinds.length === 0
                                ? "Ejendommen har ingen typer"
                                : "Vælg type"
                            }
                            variant="outlined"
                          />
                        )}
                        noOptionsText={"Ejendommen har ingen typer"}
                        value={selectedKind ? selectedKind : ""}
                        onChange={(newValue) => {
                          setSelectedKind(newValue ? newValue : "");
                        }}
                      />
                      <IconButton
                        disabled={selectedKind === ""}
                        onClick={() => onAddInterest("kind")}
                        className="addButton"
                        color="primary"
                      >
                        <PlusIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant="label">
                        Lejemål
                      </Typography>
                    </Grid>
                    <Grid className="autocompleteWithAddButton" item xs={12}>
                      <StyledAutocomplete
                        fullWidth
                        options={selectedPropertyTenancies}
                        disabled={
                          selectedProperty === "" ||
                          selectedPropertyTenancies.length === 0
                        }
                        getOptionLabel={(option) =>
                          option
                            ? option.address
                              ? option.address.street
                              : selectedPropertyTenancies.find(
                                  (e) => e.id === option
                                ).address.street
                            : ""
                        }
                        renderOption={(option) => <>{option.address.street}</>}
                        getOptionSelected={(option, value) => {
                          if (value === "" || value === option.id) {
                            return true;
                          }
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            placeholder={
                              selectedProperty === ""
                                ? "Vælg lejemål"
                                : selectedPropertyTenancies.length === 0
                                ? "Ejendommen har ingen lejemål"
                                : "Vælg lejemål"
                            }
                            variant="outlined"
                          />
                        )}
                        noOptionsText={"Ejendommen har ingen lejemål"}
                        value={selectedTenancy ? selectedTenancy : ""}
                        onChange={(newValue) =>
                          setSelectedTenancy(newValue ? newValue.id : "")
                        }
                      />
                      <IconButton
                        disabled={selectedTenancy === ""}
                        onClick={() => onAddInterest("tenancy")}
                        className="addButton"
                        color="primary"
                      >
                        <PlusIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid className="headerSection" item xs={12}>
                  <AddedIcon
                    stroke={"#2c3b8e"}
                    fill={"#2c3b8e"}
                    className="titleIcon"
                  />
                  <Typography variant="subtitle">
                    Tilføjet
                  </Typography>
                </Grid>
                <Grid id="interestList" item xs={12}>
                  <List dense={true}>
                    {selectedInterestsDisplayData.map((e, index) => {
                      return (
                        <ListItem key={e}>
                          <ListItemIcon>
                            <CheckmarkIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={e}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={() => onDeleteInterest(index)}
                              edge="end"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper elevation={0}>
          <Grid container className="commentsSection" spacing={10}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid className="headerSection" item xs={12}>
                  <CommentsIcon
                    stroke={"#2c3b8e"}
                    fill={"#2c3b8e"}
                    className="titleIcon"
                  />
                  <Typography variant="subtitle">
                    Kommentarer
                  </Typography>
                </Grid>
                <Grid className="addCommentsGroup" item xs={12}>
                  <Typography variant="label">
                    Kommentar
                  </Typography>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={8}
                    rowsMax={8}
                    onChange={(value) => setNewComment(value)}
                    value={newComment}
                    placeholder="Skriv en kommentar"
                    variant="outlined"
                  />
                  <StyledButton
                    disabled={newComment === ""}
                    onClick={() => onAddComment()}
                    disableElevation={true}
                    variant="contained"
                    color="primary"
                  >
                    Tilføj
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid className="headerSection" item xs={12}>
                  <AddedIcon
                    stroke={"#2c3b8e"}
                    fill={"#2c3b8e"}
                    className="titleIcon"
                  />
                  <Typography variant="subtitle">
                    Eksisterende
                  </Typography>
                </Grid>
                <Grid id="interestList" item xs={12}>
                  <List dense={true}>
                    {comments.map((e, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={e.author.picture} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                className="name"
                                color="textPrimary"
                              >
                                {e.author.name}
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                className="date"
                                color="textPrimary"
                              >
                                
                              </Typography>
                              <Typography
                                component="span"
                                variant="body2"
                                className="text"
                                color="textPrimary"
                              >
                                {e.body}
                              </Typography>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => onDeleteComment(index)}
                            edge="end"
                            aria-label="delete"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Grid item>
            <Link to="/interestlists">
              <StyledButton disableElevation={true} variant="contained">
                Annuller
              </StyledButton>
            </Link>
            <StyledButton
              disabled={!createEnabled}
              style={{ marginLeft: "16px" }}
              onClick={() => onCreateInterestsList()}
              disableElevation={true}
              variant="contained"
              color="primary"
            >
              Opret
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
