import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useTable, useGroupBy, useExpanded, useFilters, useAsyncDebounce, useSortBy, usePagination  } from 'react-table';
import { useHistory } from "react-router-dom";

import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GroupIcon from '@material-ui/icons/Group';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from "@material-ui/core/Typography";

import LoadingComponent from "components/LoadingComponent";
import DefaultColumnFilter from "components/ExtendedTable/config/filters.js";

import "./index.scss";

export default function ExtendedTable(props) {
  // const [isLoading, setIsLoading] = useState([false]); 
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const history = useHistory()

  const manualFilters = props.setFiltersFn ? true : false;
  const defaultSortBy = props.defaultSortBy ?? [];
  const defaultGroupBy = props.defaultGroupBy ?? [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize, sortBy, filters }
  } = useTable(
    {
      autoResetFilters: false,
      columns: props.columns,
      data: props.data,
      defaultColumn: defaultColumn,
      initialState: {
        hiddenColumns: [
          ...props.hiddenColumns
        ],
        columnOrder: [
          ...props.columnOrder
        ],
        pageSize: 250,
        sortBy: [ ...defaultSortBy ],
		groupBy: [ ...defaultGroupBy ]
      },
      manualFilters: manualFilters,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  )

  const onFetchData = () => {
    if (props.setFiltersFn) {
      props.setFiltersFn(filters);
    }
  }

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 100)

  useEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy, filters })
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, filters])

  const handleClick = (e, row, linkPath) => {
	if (!linkPath) return;

    const path = row?.original?.linkPath ?? props?.linkPath + row?.original?.id;
    if (path) {
      if (e.ctrlKey || e.metaKey) {
        window.open(path, '_blank');
      }
      else {
        history.push(path);
      }
    }
  }

  if (props.isLoading) return <LoadingComponent />;

  return (
    <div className="extendedTableContainer">
      <MaUTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell {...column.getHeaderProps()}>
                  <span className="column">
                    {column.canGroupBy ? (
                        <span className="groupByToggle" {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? <GroupIcon/> : <GroupOutlinedIcon/>}
                        </span>
                      ) : null}
                      <span className="header" {...column.getSortByToggleProps()}>
                        <Typography className="title" color="primary" variant="h3">
                        {column.render('Header')}
                        </Typography>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <ArrowDropDownIcon/>
                              : <ArrowDropUpIcon/>
                            : ""}
                      </span>
                      <span className="filter">{column.canFilter ? column.render('Filter') : null}</span>
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <TableRow className={props.linkPath ? "clickable" : ""} onClick={(e) => handleClick(e, row, props.linkPath)} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} >
                      {cell.isGrouped ? (
                          <span className="flexedNotJustified">
                            <span className="expandToggle" {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                            </span>{' '}
                            <span>{cell.render('Cell')} ({row.subRows.length})</span>
                          </span>
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (cell.render('Cell')
                        )}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
    </div>
  )
}

ExtendedTable.propTypes = {
  data: PropTypes.array,
  hiddenColumns: PropTypes.array,
  columnOrder: PropTypes.array,
  setFiltersFn: PropTypes.func,
  linkPath: PropTypes.string
};

ExtendedTable.defaultProps = {
  data: [],
  hiddenColumns: [],
  columnOrder: [],
  linkPath: null,
  setFiltersFn: null
};
