import React, { useEffect, useMemo } from "react";
import useCases from "hooks/api/useCases";
import DoughnutChart from "components/DoughnutChart/DoughnutChart";
import Paper from "components/StyledPaper";
import { CircularProgress } from "@material-ui/core";

const CasesStateChart = props => {

    const { isCasesLoading, casesData, loadCurrentEmployeeCases } = useCases();

    useEffect(() => {
        loadCurrentEmployeeCases();
    }, [loadCurrentEmployeeCases]);

    const labels = ["Kladde", "Aktiv", "Reserveret"];

    const draftCases = useMemo(() => casesData.filter(x => x.state === "Draft").length, [casesData]);
    const activeCases = useMemo(() => casesData.filter(x => x.state === "Active").length, [casesData]);
    const reservedCases = useMemo(() => casesData.filter(x => x.state === "Reserved").length, [casesData]);

    if (isCasesLoading) {
        return (
            <Paper className="doughnut-chart-container">
                <CircularProgress className="loader" />
            </Paper>
        );
    }

    return (
        <Paper className="doughnut-chart-container">
            <DoughnutChart labels={labels} data={[draftCases, activeCases, reservedCases]} />
        </Paper>
    );
};

export default CasesStateChart;