import { useOrganization } from "features/organization";
import { Facilities, Terms } from "pages/components/Attributes";
import Contacts from "pages/components/Contacts";
import EnergyInformation from "pages/components/EnergyInformation";
import Expenses from "pages/components/Expenses";
import Files from "pages/components/Files";
import LeaseInformation from "pages/components/LeaseInformation";
import React from "react";
import BaseFields from "./components/BaseFields";
import Location from "./components/Location";
import Descriptions from "./components/Descriptions";

const GeneralInformationTab = props => {

    const { propertyData, tenancyData, updateTenancy, addTenancyType, removeTenancyType,
        createTenancyExpense, removeTenancyExpense, createTenancyContact, removeTenancyContact,
        updateTenancyAttribute, removeTenancyAttribute, createTenancyAttribute, uploadTenancyImages,
        deleteTenancyImage, changeTenancyImageOrder, uploadTenancyFloorPlans, deleteTenancyFloorPlan,
        changeTenancyFloorPlanOrder, uploadTenancyDocument, deleteTenancyDocument, addTenancyLocationPoint, 
		removeTenancyLocationPoint, updateTenancyExpense } = props;

    const { attributeDefinitions } = useOrganization();

    return (
        <>
            <BaseFields
                tenancyData={tenancyData}
                propertyData={propertyData}
                update={updateTenancy} />
            <LeaseInformation
                data={tenancyData}
                update={updateTenancy}
                addTenancyType={addTenancyType}
                removeTenancyType={removeTenancyType} />
            <Location
                tenancyData={tenancyData}
                updateTenancy={updateTenancy}
                addTenancyLocationPoint={addTenancyLocationPoint}
                removeTenancyLocationPoint={removeTenancyLocationPoint}
            />
			<EnergyInformation
				tenancyData={tenancyData}
				updateTenancy={updateTenancy}
			/>
			<Descriptions data={tenancyData} update={updateTenancy} />
            <Expenses
                data={tenancyData}
				updateExpense={updateTenancyExpense}
                createExpense={createTenancyExpense}
                removeExpense={removeTenancyExpense}
                update={updateTenancy} />
            <Files
                images={tenancyData.images}
                floorPlans={tenancyData.floorPlans}
                uploadImages={uploadTenancyImages}
                deleteImage={deleteTenancyImage}
                changeImageOrder={changeTenancyImageOrder}
                uploadFloorPlans={uploadTenancyFloorPlans}
                deleteFloorPlan={deleteTenancyFloorPlan}
                changeFloorPlanOrder={changeTenancyFloorPlanOrder}
                documents={tenancyData.documents}
                uploadDocument={uploadTenancyDocument}
                deleteDocument={deleteTenancyDocument}
				virtualTour={tenancyData.virtualTour?.url}
				updateVirtualTour={(value) => updateTenancy({virtualTour: {url: value}})}
            />
            <Contacts
                contacts={tenancyData.contacts}
                createContact={createTenancyContact}
                removeContact={removeTenancyContact}
            />
            <Facilities
                facilities={tenancyData.attributes.filter(x => x.definition.group.text === "Facilities")}
                updateAttribute={updateTenancyAttribute}
                removeAttribute={removeTenancyAttribute}

                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Facilities")}
                createAttribute={createTenancyAttribute}
            />
            <Terms
                terms={tenancyData.attributes.filter(x => x.definition.group.text === "Terms")}
                updateAttribute={updateTenancyAttribute}
                removeAttribute={removeTenancyAttribute}
                attributeDefinitions={attributeDefinitions.filter(x => x.group.text === "Terms")}
                createAttribute={createTenancyAttribute}
            />
        </>
    );
};

export default GeneralInformationTab;