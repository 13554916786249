import initialUserState from './user-state';
import { createContext } from 'react';

/**
 * @ignore
 */
const stub = () => {
    throw new Error('You forgot to wrap your component in <UserProvider>.');
};

/**
 * @ignore
 */
const initialContext = {
    ...initialUserState,
    updateUserProfileImage: stub,
    updateUser: stub
};

const UserContext = createContext(initialContext);

export default UserContext;